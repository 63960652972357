import React, { Component } from "react";
import "./ApprovalSetup.css";
import { toast } from "react-toastify";
import { API } from "aws-amplify";
import ic_clear_24px from "../../assets/images/ic_clear_24px@2x.png";
import Header from "../Common/Header/Header";
import ApprovalListItem from "./Components/ApprovalListItem";

// - -   jQUARY INSTANCE  - -//
// - - - - - - - - - - - - - //
import $ from "jquery";
window.jQuery = $;
window.$ = $;
global.jQuery = $;

var Modal = require("react-bootstrap-modal");
require("react-bootstrap-modal/lib/css/rbm-patch.css");
//=============================================//
// APPROVALSETUP AS A PARENT COMPONENT DEFINE  //
// - - - - - - - IN THIS SCOPE - - - - - - - - //
//---------------------------------------------//

class ApprovalSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userTypeForRoleManagement: localStorage.getItem("userType"),
      isLoading: true,
      // Guid:"",
      approvalGuid: "",
      approverIndex: "",
      selectedUserGuid: "",
      TenantGuid: "",
      userName: "",
      approver: "",
      signaturePosition: "",

      //______ Erros ______//
      //___________________//
      formErrors: {
        userName: "",
        approver: "",
        signaturePosition: "",
      },

      isEdit: false,
      isDelete: false,
      modalOpen: false,
      formValid: false,
      userNameValid: false,
      approverValid: false,
      signaturePositionValid: false,

      //______ Arrays ______//
      //___________________//

      usersList: [],
      Approvers: [],
      approversList: [],
      userIndex: false,
    };
    if (this.props.isAuthenticated === false) {
      this.props.history.push("/");
    }
    if (
      this.state.userTypeForRoleManagement === "Approver" ||
      this.state.userTypeForRoleManagement === "Operator" ||
      this.state.userTypeForRoleManagement === "Operator/Approver"
    ) {
      this.props.history.push("/dashboard");
    }
  }

  // componentWillUnmount() {
  //     var confirm = window.confirm('Changes are saved, but not published yet. Do that now?')
  //     if(confirm)
  //     {
  //
  //     }
  //     else{
  //       console.log("else");
  //       return false;
  //     }
  // }
  editApproval(event) {
    var Guid = $(event.currentTarget).attr("data-guid");
    var Index = $(event.currentTarget).attr("data-index");
    var TenantGuid = $(event.currentTarget).attr("data-tenantguid");
    var editAppGroup = $(event.currentTarget).attr("data-editappgroup");

    var usersList = this.state.usersList;
    var thisobj = this;

    this.setState({
      approvalGuid: Guid,
      approverIndex: Index,
      TenantGuid: TenantGuid,
      appGroup: editAppGroup
    });

    // -- -- Getting Data -- -- //
    usersList.find(function (element) {
      if (element.approvalData) {
        if (element.approvalData.TenantGuid === TenantGuid) {
          //console.log(element.approvalData.Approvers[Index].userGuid);

          thisobj.setState({
            Approvers: element.approvalData.Approvers,
            approvalGuid: element.approvalData.Guid,
            selectedUserGuid: element.approvalData.Approvers[Index].userGuid,
            approver: element.approvalData.Approvers[Index].Sequence,
            approverValid: true,
            signaturePosition:
              element.approvalData.Approvers[Index].SigPosition,
            signaturePositionValid: true,
            userName: element.approvalData.Approvers[Index].ApproverName,
            userNameValid: true,
            modalOpen: true,
            isEdit: true,
            formValid: true,
          });
        }
      }
      return null;
    });
  }

  deleteApproval(event) {
    var Guid = $(event.currentTarget).attr("data-guid");
    var Index = $(event.currentTarget).attr("data-index");
    var TenantGuid = $(event.currentTarget).attr("data-tenantguid");
    var usersList = this.state.usersList;
    var thisobj = this;

    this.setState({
      approvalGuid: Guid,
      approverIndex: Index,
      TenantGuid: TenantGuid,
    });

    // -- -- Getting Data -- -- //
    usersList.find(function (element) {
      if (element.approvalData) {
        if (element.approvalData.TenantGuid === TenantGuid) {
          var Approvers = element.approvalData.Approvers;
          Approvers.splice(Index, 1);
          thisobj.setState({
            Approvers: Approvers,
            isDelete: true,
          });
          event.persist();
          setTimeout(function () {
            thisobj.addApproval(event);
          });
        }
      }
      return null;
    });
  }

  onModalOpen(event) {
    var userID = event.currentTarget.id;
    var appGroup = $(event.currentTarget).attr("data-appgroup");
    var userIndex = $(event.currentTarget).attr("data-index");
    //console.log(appGroup);
    console.log("userIndex=>", userIndex);
    this.setState({ userIndex: userIndex });
    var usersList = this.state.usersList;
    var thisobj = this;
    usersList.find(function (element) {
      if (element.approvalData) {
        if (element.approvalData.TenantGuid === userID) {
          thisobj.setState({
            Approvers: element.approvalData.Approvers,
            approvalGuid: element.approvalData.Guid,
            appGroup: appGroup,
            selectedUserGuid: userID,
          });
        }
      }
      return null;
    });

    this.setState({
      modalOpen: true,
      TenantGuid: userID,
    });
  }

  onModalClose() {
    this.setState({
      userName: "",
      selectedUserGuid: "",
      signaturePosition: "",
      approver: "",
      Approvers: [],
      modalOpen: false,
      approvalGuid: "",
      isEdit: false,
      approverIndex: "",
      TenantGuid: "",
      formValid: false,
      userNameValid: false,
      approverValid: false,
      signaturePositionValid: false,
    });
  }

  createSelectItems() {
    let items = [];
    let currentApproversList = this.state.approversList;
    //console.log('currentApproversList', currentApproversList);
    currentApproversList.map((results, key) => {
      items.push(
        <option
          key={key}
          id={results.Guid}
          data-username={results.UserLogin}
          value={results.Guid}
        >
          {results.UserLogin}
        </option>
      );
      return null;
    });
    return items;
  }

  handleSelect(event) {
    let name = event.target.name;
    let value = event.target.value;
    var username = $('select[name="userName"] :selected').attr("data-username");
    this.setState(
      {
        userName: username,
        selectedUserGuid: value,
      },
      () => {
        this.validateField(name, value);
      }
    );
  }

  validateField(fieldName, value) {
    const re = /^[0-9\b]+$/;
    let fieldValidationErrors = this.state.formErrors;
    let userNameValid = this.state.userNameValid;
    let approverValid = this.state.approverValid;
    let signaturePositionValid = this.state.signaturePositionValid;
    switch (fieldName) {
      case "userName":
        userNameValid = value.length > 0;
        fieldValidationErrors.userName = userNameValid
          ? ""
          : "This field is required";
        break;
      case "approver":
        approverValid = re.test(value) && value > 0 && value.length > 0;
        fieldValidationErrors.approver = approverValid
          ? ""
          : "This field must be a number and greater then 0";
        break;
      case "signaturePosition":
        signaturePositionValid =
          re.test(value) && value > 0 && value.length > 0;
        fieldValidationErrors.signaturePosition = signaturePositionValid
          ? ""
          : "This field must be a number and greater then 0";
        break;

      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        userNameValid: userNameValid,
        approverValid: approverValid,
        signaturePositionValid: signaturePositionValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.userNameValid &&
        this.state.approverValid &&
        this.state.signaturePositionValid,
    });
  }

  addApproval = async (event) => {
    event.preventDefault();

    var Approvers = [];
    if (this.state.isDelete !== true) {
      let userName = this.state.userNameValid;
      let approver = this.state.approverValid;
      let signaturePosition = this.state.signaturePositionValid;

      let formErrors = Object.assign({}, this.state.formErrors);

      if (userName === false || userName === null) {
        formErrors.userName = "This field is required";
      }
      if (approver === false || approver === null) {
        formErrors.approver = "This field must be a number and greater then 0";
      }
      if (signaturePosition === false || signaturePosition === null) {
        formErrors.signaturePosition =
          "This field must be a number and greater then 0";
      }

      this.setState({ formErrors });

      var obj = {
        Sequence: this.state.approver,
        SigPosition: this.state.signaturePosition,
        ApproverName: this.state.userName,
        userGuid: this.state.selectedUserGuid,
        Signed: 0,
      };
    }

    // -- if form valid -- //
    if (this.state.formValid || this.state.isDelete === true) {
      if (this.state.isEdit) {
        const approverSequences = this.state.Approvers.map(a => a.Sequence);
        approverSequences[this.state.approverIndex] = this.state.approver;

        if (approverSequences.sort((a, b) => a - b).some((a, i) => i > 0 && (a - approverSequences[i - 1] > 1))){
          toast.error('Approval sequence needs to be sequential');
          return;
        }

        Approvers = this.state.Approvers;
        Approvers[this.state.approverIndex].Sequence = this.state.approver;
        Approvers[this.state.approverIndex].SigPosition =
          this.state.signaturePosition;
        Approvers[this.state.approverIndex].ApproverName = this.state.userName;
        Approvers[this.state.approverIndex].userGuid =
          this.state.selectedUserGuid;
      } else if (this.state.isDelete) {
      } else {
        var isDuplicate = false;
        /*this.state.Approvers.find(function(element) {

          if(element.SigPosition === obj.SigPosition || element.Sequence === obj.Sequence )
          {
            isDuplicate = true;
          }
          else{

          }
          return null;
        });*/
        if (!isDuplicate) {
           const approverSequences = this.state.Approvers.map(
             (a) => a.Sequence
           );
           approverSequences.push(obj.Sequence);

           if (
             approverSequences
               .sort((a, b) => a - b)
               .some((a, i) => i > 0 && a - approverSequences[i - 1] > 1)
           ) {
             toast.error("Approval sequence needs to be sequential");
             return;
           }
          this.state.Approvers.push(obj);
        } else {
          alert("already exist with the same Sig pos or Seq pos ");
          return false;
        }
      }

      this.setState({
        isLoading: true,
        modalOpen: false,
      });

      await this.state.Approvers.sort(function (a, b) {
        return a.Sequence - b.Sequence;
      });

      var approval_data = {
        Approvers: this.state.Approvers,
        Guid: this.state.approvalGuid,
        AppGroup: this.state.appGroup,
        approvarGuid: this.state.TenantGuid,
      };

      try {
        var response = await this.props.postRequest(
          "/approval-setup/create-approval",
          approval_data
        );
        if (response.status) {
          this.setState({
            userName: "",
            approver: "",
            approvalGuid: "",
            selectedUserGuid: "",
            signaturePosition: "",
            TenantGuid: "",
            Approvers: [],
            formValid: false,
            userNameValid: false,
            approverValid: false,
            signaturePositionValid: false,
            isEdit: false,
            isDelete: false,
            approverIndex: "",
            isLoading: false,
          });
          if (this.state.isDelete) {
            toast.success("Record has deleted successfully.", {
              autoClose: 5000,
            });
          } else {
            if (this.state.userIndex) {
              var usersList = this.state.usersList;
              usersList[this.state.userIndex].approvalData =
                response.approvalData;
              this.setState({ usersList: usersList });
            }
            toast.success(response.message, { autoClose: 5000 });
          }
        } else {
          this.setState({ isLoading: false });
          toast.error(response.error, { autoClose: 5000 });
        }
        console.log("/approval-setup/create-approval=>", response);
      } catch (err) {
        console.log("/approval-setup/create-approval error=>", err);
        toast.error(err.message, { autoClose: 5000 });
        this.setState({ isLoading: false });
      }
    } else {
      $("#formPackModal form .modal-body").animate({ scrollTop: 0 }, "slow");
    }
  };

  handleInputs(event) {
    let name = event.target.name;
    let value = event.target.value;
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.validateField(name, value);
      }
    );
  }

  componentDidMount = async () => {
    $(document).ready(function () {
      $("#music").click(function () {
        $("#musicinfo").toggle();
      });
    });

    try {
      var response = await this.props.postRequest(
        "/approval-setup/approver-group-list",
        { CompanyGuid: localStorage.getItem("companyGuid") }
      );
      if (response.status) {
        this.setState({ usersList: response.result, isLoading: false });
      } else {
        this.setState({ isLoading: false });
        toast.error(response.error, { autoClose: 5000 });
      }
      console.log("/approval-setup/approver-group-list=>", response);
    } catch (err) {
      console.log("/approval-setup/approver-group-list error=>", err);
      toast.error(err.message, { autoClose: 5000 });
      this.setState({ isLoading: false });
    }

    try {
      var approvers_response = await this.props.postRequest(
        `/approval-setup/get-approvers-list/${localStorage.getItem(
          "companyGuid"
        )}`,
        { forApprovalSequence: true }
      );
      if (approvers_response.status) {
        this.setState({ approversList: approvers_response.result });
      }
      console.log("/approval-setup/get-approvers-list=>", approvers_response);
    } catch (err) {
      console.log("/approval-setup/get-approvers-list error=>", err);
    }
  };

  render() {
    return (
      <div className="ApprovalSetup">
        <Header
          pageHeading="Approval Setup"
          isLoading={this.state.isLoading}
          props={this.props}
        />
        <div className="clearfix"></div>
        <div className="col-xs-12 col-sm-9 col-md-10 pull-right p0 top_mrg_general top_mrg_general_custom">
          <div className="">
            <div className="col-sm-12 col-md-12 col-lg-10 col-lg-offset-1 general_setting_hd">
              <div className="col-xs-12 col-sm-12 col-md-12">
                <div className="clear10"></div>
                <div className="clearfix"></div>
                <div className="overflow_scroll_sm">
                  <table className="table table-bordered timecard_table approvalsetup_approvel_field_table">
                    <thead>
                      <tr>
                        <th
                          width="15%"
                          align="center"
                          className="text_center_tb"
                        >
                          Detail
                        </th>
                        <th width="20%" align="left">
                          User Group
                        </th>
                        <th width="20%" align="left">
                          User Name
                        </th>
                        <th width="25%" align="left">
                          Initials
                        </th>
                        <th width="20%" align="left">
                          Department
                        </th>
                      </tr>
                    </thead>
                    <ApprovalListItem
                      usersList={this.state.usersList}
                      props={this.props}
                      onModalOpen={this.onModalOpen.bind(this)}
                      editApproval={this.editApproval.bind(this)}
                      deleteApproval={this.deleteApproval.bind(this)}
                    />
                  </table>
                </div>
              </div>
            </div>
            <div className="clear10"></div>
          </div>
        </div>
        <div className="clear10"></div>
        <Modal
          id="myModal"
          show={this.state.modalOpen}
          onHide={(event) => this.onModalClose(event)}
          className="modal modal2 fade"
          role="dialog"
        >
          <form onSubmit={(event) => this.addApproval(event)}>
            <div className="modal-header">
              <button
                type="button"
                className="close approvalsetup_OutlineNone"
                onClick={(event) => this.onModalClose(event)}
              >
                <img
                  src={ic_clear_24px}
                  className="mt22"
                  width="18"
                  height="18"
                  alt=""
                />
              </button>
              <h4 className="modal-title modal_customefield">Approver Setup</h4>
            </div>
            <div className="modal-body label_modal_custom approvalsetup_heigtAuto approvalsetup_OverflowAuto">
              <div className="col-sm-12 p0 mrg_bot_custom10">
                <div className="col-sm-3 col-md-4 label_csutom_pop">
                  Approver Name:
                </div>
                <div className="col-sm-9 col-md-8">
                  <select
                    name="userName"
                    id="userName"
                    value={this.state.selectedUserGuid}
                    onChange={(event) => this.handleSelect(event)}
                  >
                    <option value="">Select</option>
                    <option
                      id="external-signer"
                      data-username="External Signer"
                      value="external-signer"
                    >
                      External Signer
                    </option>
                    {this.createSelectItems()}
                  </select>
                  <p
                    className={
                      "error validation " +
                      (this.state.formErrors.userName ? "" : "displayNone")
                    }
                  >
                    {this.state.formErrors.userName}
                  </p>
                </div>
              </div>
              <div className="col-sm-12 p0 mrg_bot_custom10">
                <div className="col-sm-3 col-md-4 label_csutom_pop">
                  Approver #:
                </div>
                <div className="col-sm-9 col-md-8">
                  <input
                    name="approver"
                    type="text"
                    value={this.state.approver}
                    onChange={(event) => this.handleInputs(event)}
                  />
                  <p
                    className={
                      "error validation " +
                      (this.state.formErrors.approver ? "" : "displayNone")
                    }
                  >
                    {this.state.formErrors.approver}
                  </p>
                </div>
              </div>
              <div className="col-sm-12 p0 mrg_bot_custom10">
                <div className="col-sm-3 col-md-4 label_csutom_pop">
                  Signature Position:
                </div>
                <div className="col-sm-9 col-md-8">
                  <input
                    name="signaturePosition"
                    type="text"
                    value={this.state.signaturePosition}
                    onChange={(event) => this.handleInputs(event)}
                  />
                  <p
                    className={
                      "error validation " +
                      (this.state.formErrors.signaturePosition
                        ? ""
                        : "displayNone")
                    }
                  >
                    {this.state.formErrors.signaturePosition}
                  </p>
                </div>
              </div>
              <div className="clear30"></div>
              <div className="col-sm-6">
                <div className="btn_cance_save">
                  <button
                    type="submit"
                    className="btn_save_pro"
                    data-dismiss="modal"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn_cancel_pro"
                    onClick={(event) => this.onModalClose(event)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
              <div className="clear20"></div>
            </div>
          </form>
        </Modal>
      </div>
    );
  }
}
export default ApprovalSetup;
