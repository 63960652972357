import React from "react";
import { Switch } from "react-router-dom";
import AppliedRoute from "./components/AppliedRoute/AppliedRoute";
import Login from "./containers/Login/Login";
import ChangePassword from "./containers/ChangePassword/ChangePassword";
import AddCompany from "./containers/AddCompany/AddCompany";
import FpStepFirst from "./containers/FpStepFirst/FpStepFirst";
import FpStepSecond from "./containers/FpStepSecond/FpStepSecond";
import FpStepThird from "./containers/FpStepThird/FpStepThird";
import TermsOfUse from "./containers/TermsOfUse/TermsOfUse";
import PrivacyPolicy from "./containers/PrivacyPolicy/PrivacyPolicy";
import CpStepFirst from "./containers/CpStepFirst/CpStepFirst";
import CpStepSecond from "./containers/CpStepSecond/CpStepSecond";
import CpStepThird from "./containers/CpStepThird/CpStepThird";
import VerifyCode from "./containers/VerifyCode/VerifyCode";
import NotFound from "./containers/NotFound/NotFound";
import DashboardContainer from "./containers/Dashboard/DashboardContainer";

export default ({ childProps }) =>
  <Switch>
    <AppliedRoute path="/" exact component={Login} props={childProps} />
    <AppliedRoute path="/add-company" exact component={AddCompany} props={childProps} />
    <AppliedRoute path="/dashboard" exact component={DashboardContainer} props={childProps} />
    <AppliedRoute path="/dashboard/*" exact component={DashboardContainer} props={childProps} />
    <AppliedRoute path="/change-password" exact component={ChangePassword} props={childProps} />
    <AppliedRoute path="/VerifyCode" exact component={VerifyCode} props={childProps} />
    <AppliedRoute path="/forget-password-step-1" exact component={FpStepFirst} props={childProps} />
    <AppliedRoute path="/forget-password-step-2" exact component={FpStepSecond} props={childProps} />
    <AppliedRoute path="/forget-password-step-3" exact component={FpStepThird} props={childProps} />
    <AppliedRoute path="/terms-of-use" exact component={TermsOfUse} />
    <AppliedRoute path="/privacy-policy" exact component={PrivacyPolicy} />
    <AppliedRoute path="/change-password-step-1" exact component={CpStepFirst} props={childProps} />
    <AppliedRoute path="/change-password-step-2" exact component={CpStepSecond} props={childProps} />
    <AppliedRoute path="/change-password-step-3" exact component={CpStepThird} props={childProps} />
    {/* <AppliedRoute path="*" exact={true} component={NotFound} /> */}
  </Switch>;
