import React, { Component } from "react";
import { LinkContainer } from "react-router-bootstrap";
import "./FpStepSecond.css";
import "../../assets/css/bootstrap.css";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/style.css";
import LOGO from "../../assets/images/reset_logo.jpg";
import { Auth} from "aws-amplify";
import { toast } from 'react-toastify';

class FpStepSecond extends Component {
      constructor(props) {
        super(props);
        this.state = {
           "pincode":"",
            codeerror:""
        }
      }
    async componentWillMount(){
        if(this.props.location.state===undefined){
              this.props.history.push('/forget-password-step-1');
        }else{
          return null;
        }
    }

    componentDidMount = async() =>{
      if(this.props.location.state===undefined){
         if(this.props.location.state.codeStatus){
            
         }
      }
    }

CheckCode=()=>{
  if(this.state.pincode.length>0){
    this.props.history.push({
      pathname: '/forget-password-step-3',
      state: { authenticatepin:this.state.pincode, email: this.props.location.state.email }
    });
  }else{
    this.setState({codeerror:'This Field Is Required'});
  }
}

getchange=async (event)=>{
    var name=event.target.name;
    var value=event.target.value;
    await this.setState({[name]: value});
}

resendCode = () =>{
    Auth.forgotPassword(this.props.location.state.email).then(data =>{ 
      toast.success("Code resent to " + data.CodeDeliveryDetails.Destination)
    }).catch(err => toast.error(err.message));
}

    render() {
        return (
                <div className="FpStepFirst">
                {this.state.isLoading ? <div className="se-pre-con"></div> : ''}
   <div className="forgot_bg">
      <div className="col-sm-12">
         <div className="container p0">
            <div className="forgot_box1">
               <div className="forgot_box">
                  <div className="col-sm-12 reset_password" >
                     <LinkContainer to="/">
                        <img src={LOGO} alt="LOGO" id="forgetpassword_marginRight10" />
                     </LinkContainer>
                     Forget Password
                  </div>
                  <div className="reset_under_box">
                     <div className="clear50"></div>
                     Enter the PIN Code
                     <div className="clear20"></div>
                     <input name="pincode" className="frogot_input" type="text" placeholder="PIN Code" onChange={this.getchange} />
                     <div className="error_fp2 fp2_validation">{this.state.codeerror!==''?this.state.codeerror:''}</div>
                     <div className="clearfix"></div>
                     <div className="col-sm-12 resend_label p0" onClick={this.resendCode}> <a href={null}>Resend code</a></div>
                     <div className="clear20"></div>
                     <LinkContainer to="/forget-password-step-1">
                        <a href={null} className="btn_frogot_back">Back</a>
                     </LinkContainer>
                    
                        <a href={null}  className="btn_frogot_blue"onClick={this.CheckCode}>Proceed</a>
            
                     <div className="clearfix"></div>
                  </div>
                  <div className="clearfix"></div>
               </div>
            </div>
            <div className="clearfix"></div>
         </div>
         <div className="clearfix"></div>
      </div>
   </div>
</div>
);
};
}
export default FpStepSecond;
