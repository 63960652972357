import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { toast } from 'react-toastify';
import { API} from "aws-amplify";
import "./FormPacks.css";
import ic_clear_24px from "../../assets/images/ic_clear_24px@2x.png";


// import down_arrow_amprvoe from "../../assets/images/down_arrow_amprvoe.png";

// import x_icon from "../../assets/images/x_icon.png";
import Header from "../Common/Header/Header";
import FormPackListItem from "./Components/FormPackListItem";
import CustomFieldsListItem from "./Components/CustomFieldsListItem";
import FormSettingsListItem from "./Components/FormSettingsListItem";
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

// import UsersListItem from "./Components/UsersListItem";

// - -   jQUARY INSTANCE  - -//
// - - - - - - - - - - - - - //
import $ from 'jquery';
window.jQuery = $;
window.$ = $;
global.jQuery = $;
var Modal = require('react-bootstrap-modal');
require('react-bootstrap-modal/lib/css/rbm-patch.css');
const reorder = (list, startIndex, endIndex) => {
    // console.log(">>>",list,startIndex,endIndex)
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };
  
  const getItemStyle = (isDragging, draggableStyle) => ({
  //   // some basic styles to make the items look a bit nicer
  //   userSelect: "none",
  //   padding: grid * 2,
  //   margin: `0 0 ${grid}px 0`,
  display:isDragging?"table":"",  
  cursor:isDragging?"all-scroll":"all-scroll",
    // change background colour if dragging
    // background: isDragging ? "lightgreen" : "grey",
    // styles we need to apply on draggables
    ...draggableStyle
  });
  
  const getListStyle = isDraggingOver => ({
    // background: isDraggingOver ? "lightblue" : "lightgrey",
    // padding: grid,
    // width: 250
    cursor:isDraggingOver?"all-scroll":"all-scroll"
  });
  
class FormPacks extends Component {

    constructor(props) {
        super(props);
        this.state = {
        
            isLoading: true,
            Guid:"",
            sendLinkToUserInEmail:false,
            sendLinkGuid:"",
            packCode:"",
            description:"",
            userAccess:"",
            project:"",
            projectGuid:"",
            CarbonCopyEmail:"",
            EmailExtraText:"",
            templateGuid:"",
            payRollPack:"",
            externalPartyToComplete:"",
            temp:[],
            customFieldData:[],
            customFieldValue:'',

            //____ Custom Fields States ____//
            //______________________________//
            customFieldsDescription:"",
            customFieldsValue:"",
            projectedUsersHTMLState:'',
            customFieldsType:"",
            customFieldsLength:"",
            selectProjectedUserError:"",
            formpackReceiverEmail:"",
            formpackReceiverRname:"",
            formpackReceiverRphone:"",
            formpackReceiverGuid:"",
            formpacksendableGuid:"",
            customFieldsRequired:"",
            customFieldsTag:"",
            stimulsoftData: [],
            projectValid:false,
            packCodeValid:false,
            descriptionValid:false,
            formValid:false,
            projectedUsers:'',
            //____ Form Settings States ____//
            //______________________________//
            formSettingsDesciption:"",
            formSettingsExtPrty:false,
            formSettingsConfidential:false,
            formSettingsEmailDist:"",
            formSettingsDocName:"",

            //______ Erros ______//
            //___________________//
            formErrors:{
                project:"",
                packCode:"",
                description:""
            },

            customFieldsFormErrors:{
                customFieldsDescription:"",
                customFieldsValue:"",
                customFieldsLength:"",
                customFieldsTag:""
            },

            formSettingsFormErrors:{
                formSettingsEmailDist:"",
            },

            modalOpen:false,
            addUserModalOpen:false,
            customFieldsModalOpen:false,
            formSettingsModalOpen:false,

            isCustomFieldsEditModal:false,
            isFormSettingsEditModal:false,

            customFieldIndex:null,
            formSettingIndex:null,

            //______ Arrays ______//
            //___________________//
            formPacksList:[],
            customFieldsList:[],
            projectList:[],
            usersList:[],
            checkboxArray: [],
            checkedUserList:[],
            formSettingsList:[],
            formSettingsList2:[],
            templatesList:[],

            //______ Extra _______//
            //____________________//

            isString:true,
            isNumber:false,
            isDate:false,
            isDroplist:false,
            isFile:false,
            startDate: moment(),

            description_error:false,
            defaultValue_error:false,
            numberdefaultValue_error:false,
            datedefaultValue_error:false,

            add_datalist:false,
            datalist:[{'option':'yes','value':1},{'option':'no','value':0}],
            datalist_option:'',
            datalist_value:'',
            add_datalist_key:'',
            value_error:false,
            option_error:false,
            userTypeForRoleManagement:localStorage.getItem('userType'),
            selectedTemp:false
        };
        if (this.props.isAuthenticated === false) {
            this.props.history.push("/");
        }
        if(this.state.userTypeForRoleManagement === 'Approver' || this.state.userTypeForRoleManagement === 'Operator' || this.state.userTypeForRoleManagement === 'Operator/Approver'){
            this.props.history.push("/dashboard");
        }
    };

    componentDidMount = async () => {
        
        this.getAllFormpacks().then(response=>{
            console.log("formPacksList => ", response);
            if(response.status){
              this.setState({ formPacksList: response.result, });  
            }else{
                toast.error(response.error, {autoClose: 5000});
                this.setState({ isLoading:false }); 
            }
        }).catch(err => {
            console.error("formPacksList error=> ", err);
            this.setState({ isLoading:false });
        });

        try{
            var approvers_response = await this.props.postRequest(`/approval-setup/get-approvers-list/${localStorage.getItem('companyGuid')}`, {});
            if(approvers_response.status){
                this.setState({ usersList: approvers_response.result })
            }
            console.log("/approval-setup/get-approvers-list=>", approvers_response);
        }catch(err){
            console.log("/approval-setup/get-approvers-list error=>", err);
        }

        try{
            var proj_response = await this.props.postRequest(`/projects/get-list/${localStorage.getItem('companyGuid')}`, {});
            if(proj_response.status){
                this.setState({projectList: proj_response.result});
            }
            console.log("/projects/get-list=>", proj_response);
        }catch(err){
            console.log("/projects/get-list error=>", err);
        }

        try{
            var temp_response = await this.props.postRequest(`/form-template/get-list/${localStorage.getItem('companyGuid')}`, {});
            if(temp_response.status){
                this.setState({templatesList: temp_response.result});
            }
            console.log("/form-template/get-list=>", temp_response);
        }catch(err){
            console.log("/form-template/get-list error=>", err);
        }

        $(document).ready(
            function() {
                $("#music").click(function() {
                $("#formpack_musicinfo").toggle();
            });
        });
        this.get_customfield();
    }
	get_customfield_list(){
        return this.props.postRequest(`/custom-fields/get-list/${localStorage.getItem('companyGuid')}`, {});
	}
	
	get_customfield = async () => {
		this.setState({isLoading: true});
		try{
			const response = await this.get_customfield_list();
			if(response.status){
				this.setState({
					customFieldData:response.result,
					isLoading: false,
                });
                console.log("2nd",this.state.customFieldData)
			}
		}catch(e){
			this.setState({isLoading: false});
			console.log(e.message);
		}
	}


    // datalist function
    datalist_closeModal = () => {
        this.setState({
            add_datalist: false,
            datalist_option: '',
            datalist_value: '',
            add_datalist_key: '',
        });
    }
    datalist_openModal = () => {
        this.setState({
            add_datalist: true
        });
    }

    delete_datalist = (e, key) => {
        e.preventDefault();
        let found = false;
        let datalist = this.state.datalist;
        let defaultValue = this.state.defaultValue;
        // console.log('defaultValue',defaultValue);
        let isDroplist = this.state.isDroplist;

        if (isDroplist) {
            datalist.forEach(function(item) {
                //console.log('defaultValue', item.value === defaultValue);
                if (item.value === defaultValue) {

                    found = true;
                }
            });
        }
        if (found) {
            //console.log('found', found);
            this.setState({
                defaultValue: '',
                defaultValue_error: true,
            });
        }
        datalist.splice(key, 1);
        this.setState({
            datalist: datalist
        });



    }

    edit_datalist = (e, key) => {
        e.preventDefault();
        
        let value = this.state.datalist[key].value;
        let option = this.state.datalist[key].option;


        this.setState({
            datalist_option: option,
            datalist_value: value,
            add_datalist_key: key,
        })
        //console.log("option", this.state.datalist_option);
        //console.log("value", this.state.datalist_value);
    }


    add_datalist = (e) => {
        e.preventDefault();

        let datalist = this.state.datalist;
        let defaultValue = this.state.defaultValue
        let isDroplist = this.state.isDroplist;
        var value = this.state.datalist_value;
        let found = false;
        if (value || value === 0) {
            if (isDroplist) {
                datalist.forEach(function(item) {
                    //console.log('defaultValue', item.value === defaultValue);
                    if (item.value === defaultValue) {

                        found = true;
                    }
                });
            }
            if (found === false) {
                this.setState({
                    defaultValue: ''
                });
            }
        }
        var option = this.state.datalist_option;
        if (option || option === 0) {
            this.setState({
                option_error: false
            });
        } else {
            this.setState({
                option_error: true
            });
        }

        if (value || value === 0) {
            this.setState({
                value_error: false
            });
        } else {
            this.setState({
                value_error: true
            });
        }
        let key = this.state.add_datalist_key;
        console.log('key=>', key);


        if (value && option) {
            if (key || key === 0) {
                datalist[key].option = option;
                datalist[key].value = value;
            } else {
                let tmp_array = {
                    'option': option,
                    'value': value
                };
                datalist.push(tmp_array);

            }
            this.setState({
                datalist: datalist,
                datalist_option: '',
                datalist_value: '',
                add_datalist_key: '',
                value_error: false,
                option_error: false
            });
        }
    }

    option_change = (e) => {
        e.preventDefault();
        let option = e.target.value;
        if (option || option === 0) {
            this.setState({
                datalist_option: option,
                option_error: false
            });
        } else {
            this.setState({
                datalist_option: option,
                option_error: true
            });
        }

    }

    value_change = (e) => {
        e.preventDefault();
        let value = e.target.value;
        if (value || value === 0) {
            this.setState({
                datalist_value: value,
                value_error: false
            });
        } else {
            this.setState({
                value_error: true,
                datalist_value: value
            });
        }

    }

//datalist function end

    onModalClose(){
        this.setState({
            modalOpen: false,
            packCode:"",
            description:"",
            userAccess:"",
            project:"",
            projectGuid:"",
            payRollPack:"",
            isLoading:false,
            externalPartyToComplete:"",
            Guid:"",
            customFieldsList:[],
            checkboxArray: [],
            checkedUserList:[],
            formSettingsList:[],
            formSettingsList2:[],
            formErrors:{
                project:"",
                packCode:"",
                description:""
            }
        });
    }
    onModalOpen(){
        this.setState({ modalOpen: true, defaultValue: "" });
    }



    // - - - Custom Fields Model Methods - - -//
    // - - - - - - - - - - - - - - - - - - - -//
    handleCustomFieldsModalInputs(event) {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({
                [name]: value
            },
            () => {
                this.customFieldValidate(name, value)
            });
    }

    typeHandler = (event) => {
        let type = event.target.value;
        console.log('type', type);
        this.setState({
            customFieldsType: type
        });
        if (!type) {
            this.setState({
                type_error: true
            });
        } else {
            if (type === 'Numeric') {
                this.setState({
                    isNumber: true,
                    isString: false,
                    isDate: false,
                    isDroplist: false,
                    isFile:false,
                    defaultValue: '',
                });
            } else if (type === 'Drop List') {
                this.setState({
                    isNumber: false,
                    isString: false,
                    isDate: false,
                    isFile:false,
                    isDroplist: true,
                    defaultValue: null,
                });
            }
            else if (type === 'File') {
                this.setState({
                    isNumber: false,
                    isString: false,
                    isDate: false,
                    isFile:true,
                    isDroplist: false,
                    defaultValue: null,
                });
            } else if (type === 'Date') {
                this.setState({
                    isNumber: false,
                    isString: false,
                    isDate: true,
                    isFile:false,
                    isDroplist: false,
                    defaultValue: moment(),
                });
            } else {
                this.setState({
                    isNumber: false,
                    isString: true,
                    isDate: false,
                    isFile:false,
                    isDroplist: false,
                    defaultValue: '',
                });
            }
            this.setState({
                type_error: false
            });
        }
    }


    customFieldValidate(fieldName, value) {

        let customFieldsFormErrors = this.state.customFieldsFormErrors;

        let customFieldsDescriptionValid = this.state.customFieldsDescription;
        let customFieldsValueValid = this.state.customFieldsValue;
        let customFieldsLengthValid = this.state.customFieldsLength;
        let customFieldsTagValid = this.state.customFieldsTag;

        switch (fieldName) {
            case 'customFieldsDescription':
                customFieldsDescriptionValid = value.length > 0;
                customFieldsFormErrors.customFieldsDescription = customFieldsDescriptionValid ? '' : "This field is required";
                break;
            case 'customFieldsValue':
                customFieldsValueValid = value.length > 0;
                customFieldsFormErrors.customFieldsValue = customFieldsValueValid ? '' : "This field is required";
                break;
            case 'customFieldsLength':
                customFieldsLengthValid = value.match(/^\d*$/i);
                customFieldsFormErrors.customFieldsLength = customFieldsLengthValid ? '' : "This field should be a number";
                break;
            case 'customFieldsTag':
                customFieldsTagValid = value.length > 0;
                customFieldsFormErrors.customFieldsTag = customFieldsTagValid ? '' : "This field is required";
                break;
            default:
                break;
        }
        this.setState({
            customFieldsFormErrors: customFieldsFormErrors,
            customFieldsDescriptionValid: customFieldsDescriptionValid,
            customFieldsValueValid: customFieldsValueValid,
            customFieldsLengthValid:customFieldsLengthValid,
            customFieldsTagValid:customFieldsTagValid,
        }, this.customFieldsFormErrors);
    }
    onCustomFieldsModalClose(){
        this.setState({
            customFieldsModalOpen: false,
            customFieldIndex:null,
            isCustomFieldsEditModal:false,
            customFieldsDescription:"",
            customFieldsValue:"",
            customFieldsType:"",
            defaultValue:"",
            customFieldsLength:"",
            customFieldsRequired:"",
            customFieldsTag:"",
            customFieldsFormErrors:{
                customFieldsDescription:"",
                customFieldsValue:"",
                customFieldsLength:"",
                customFieldsTag:"",
                customFieldValue:"",
            }
        });
        this.setState({ customFieldsModalOpen: false });
    }
    onCustomFieldsModalOpen(){
        this.setState({ customFieldsModalOpen: true });
    }
    handleInputCheckbox(event){
        let name = event.target.name;
        var value = event.target.value;
            if(value === '')
            {
             this.setState({
                [name]: 'checked'
            })
            }
            else{
                this.setState({
                [name]: ''
            })
        }
    }
    
    addCustomFieldsInToArray(event){
        event.preventDefault();

        let customFieldsDescription = this.state.customFieldsDescription;
        // let customFieldsValue = this.state.defaultValue;
        let customFieldsLength = this.state.customFieldsLength;
        let customFieldsTag = this.state.customFieldsTag;



        let customFieldsFormErrors = Object.assign({}, this.state.customFieldsFormErrors);
        if(customFieldsDescription.length < 1)
        {
            customFieldsFormErrors.customFieldsDescription = "This field is required";
        }
        // if(customFieldsValue.length < 1)
        // {
        //     customFieldsFormErrors.customFieldsValue = "This field is required";
        // }
        if(customFieldsLength.length < 1)
        {
            customFieldsFormErrors.customFieldsLength = "This field should be a number";
        }
        if(customFieldsTag.length < 1)
        {
            customFieldsFormErrors.customFieldsTag = "This field is required";
        }

        if(customFieldsDescription.length < 1  || customFieldsLength.length < 1 || customFieldsTag.length < 1){
            //console.log("false");
            this.setState({customFieldsFormErrors});
            return false;
        }


        var dataObj = {
            "Desc": this.state.customFieldsDescription,
            "Value": this.state.defaultValue? this.state.defaultValue : null,
            "Type": this.state.customFieldsType,
            "Length": this.state.customFieldsLength,
            "Tag": this.state.customFieldsTag,
            "Required": (this.state.customFieldsRequired) ? true: false,
            "DataList": this.state.datalist,
        };

//console.log('custom dataObj=>',dataObj); return false;
        if(customFieldsDescription.length > 0  && customFieldsLength.length > 0 && customFieldsTag.length > 0){

            var isCustomFieldsEditModal = this.state.isCustomFieldsEditModal;

            var customFieldsList1 = this.state.customFieldsList;
            var thisobj = this;
            // var customFieldsDuplicate = customFieldsList1.find(function(el) {
            //     var result;
            //     if (el.Desc === thisobj.state.customFieldsDescription && el.Value === thisobj.state.customFieldsValue) {
            //         result = true;
            //     }
            //     return result;
            // });
            // if (customFieldsDuplicate) {
            //     alert("already exist");
            //     return false;
            // }

            if(isCustomFieldsEditModal){
              var count = 0;
              var isSameRow = false;
              var customFieldsDuplicate = customFieldsList1.find(function(el, index) {
                  var result;
                  //if (el.Desc === thisobj.state.customFieldsDescription && el.Value === thisobj.state.customFieldsValue) {
                  if (el.Desc === thisobj.state.customFieldsDescription) {
                      count += 1;
                      if(index === thisobj.state.customFieldIndex)
                      {
                        isSameRow = true;
                      }
                      return result;
                  }
                  else{
                      return null;
                  }
                  // else{
                  //   if(index === thisobj.state.customFieldIndex)
                  //   {
                  //     isSameRow = true;
                  //   }
                  // }
                  // console.log(index);
                  // console.log(thisobj.state.customFieldIndex);


              });
              //console.log(isSameRow);
              //console.log(count);

              if (count === 1 && !isSameRow) {
                  alert("already exist");
                  return false;
              }
              // else if(count === 1 && isSameRow){
              //     alert("already exist");
              //     return false;
              // }

                var customFieldsList = this.state.customFieldsList;
                var customFieldIndex = this.state.customFieldIndex;
                customFieldsList[customFieldIndex] = dataObj;

                this.setState({
                    "customFieldIndex": customFieldIndex,
                    "isCustomFieldsEditModal":false,
                    "customFieldsDescription": "",
                    "customFieldsValue": "",
                    "defaultValue":"",
                    "customFieldsType": "",
                    "customFieldsLength":"",
                    "customFieldsTag":"",
                    "customFieldsRequired": "",
                })
            }
            else{

                customFieldsDuplicate = customFieldsList1.find(function(el) {
                    var result;
                    //if (el.Desc === thisobj.state.customFieldsDescription && el.Value === thisobj.state.customFieldsValue) {
                    if (el.Desc === thisobj.state.customFieldsDescription) {
                        result = true;
                    }
                    return result;
                });
                if (customFieldsDuplicate) {
                    alert("already exist");
                    return false;
                }

                let customFieldsList = this.state.customFieldsList;
                customFieldsList.push(dataObj);
                this.setState({
                    "customFieldIndex": customFieldIndex,
                    "customFieldsDescription": "",
                    "customFieldsValue": "",
                    "defaultValue":"",
                    "customFieldsType": "",
                    "customFieldsLength":"",
                    "customFieldsTag":"",
                    "customFieldsRequired": "",
                })
            }

            this.setState({
                customFieldsDescriptionValid:false,
                customFieldsValueValid:false,
                customFieldsLengthValid:false,
                customFieldsTagValid:false,
            });

            this.onCustomFieldsModalClose();

        }
    }
    editCustomFieldsListFromArray(event, id){

        this.setState({"customFieldIndex": id})
        event.preventDefault();
        this.setState({
                "isCustomFieldsEditModal": true,
                //"isActive2": true
                });
        var customFieldsList = this.state.customFieldsList;

        customFieldsList = customFieldsList[id];


        this.setState({
            customFieldsDescription: customFieldsList.Desc,
            defaultValue: customFieldsList.Value,
            customFieldsType: customFieldsList.Type,
            customFieldsLength: customFieldsList.Length,
            customFieldsRequired: (customFieldsList.Required)?"checked":"",
            customFieldsTag: customFieldsList.Tag
        });

        let type = customFieldsList.Type;
        if (!type) {
            this.setState({
                type_error: true
            });
        } else {
            if (type === 'Numeric') {
                this.setState({
                    isNumber: true,
                    isString: false,
                    isDate: false,
                    isDroplist: false,
                    isFile:false,
                    defaultValue: customFieldsList.Value,
                });
            } else if (type === 'Drop List') {
                this.setState({
                    isNumber: false,
                    isString: false,
                    isDate: false,
                    isDroplist: true,
                    isFile:false,
                    defaultValue: customFieldsList.Value,
                });
            }
            else if (type === 'File') {
                this.setState({
                    isNumber: false,
                    isString: false,
                    isDate: false,
                    isDroplist: false,
                    isFile:true,
                    defaultValue: customFieldsList.Value,
                });
            }
            
            else if (type === 'Date') {
                this.setState({
                    isNumber: false,
                    isString: false,
                    isDate: true,
                    isDroplist: false,
                    isFile:false,
                    defaultValue: customFieldsList.Value,
                });
            } else {
                this.setState({
                    isNumber: false,
                    isString: true,
                    isDate: false,
                    isFile:false,
                    isDroplist: false,
                    defaultValue: customFieldsList.Value,
                });
            }

            this.setState({
                type_error: false
            });
        }

        this.onCustomFieldsModalOpen();
    }
    removeCustomFieldsFromArray(event, id){
        event.preventDefault();
        var customFieldsList = this.state.customFieldsList;
        customFieldsList.splice(id, 1);
        this.setState({
            "customFieldsList": customFieldsList
        })
    }


    // - - - Form Settings Model Methods - - -//
    // - - - - - - - - - - - - - - - - - - - -//
    handleFormSettingsModalInputs(event) {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({
                [name]: value
            },
            () => {
                this.formSettingsValidate(name, value)
            });
    }
    formSettingsValidate(fieldName, value) {

        let formSettingsFormErrors = this.state.formSettingsFormErrors;

        //let formSettingsEmailDistValid = this.state.formSettingsEmailDist;
        let formSettingsDesciptionValid = this.state.formSettingsDesciption;

        switch (fieldName) {
            // case 'formSettingsEmailDist':
            //     formSettingsEmailDistValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
            //     formSettingsFormErrors.formSettingsEmailDist = formSettingsEmailDistValid ? '' : "Email should be username@domain.com";
            //     break;
          case 'formSettingsDesciption':
              formSettingsDesciptionValid = value.length > 0;
              formSettingsFormErrors.formSettingsDesciption = formSettingsDesciptionValid ? '' : "This field is required";
              break;
            default:
                break;
        }
        this.setState({
            formSettingsFormErrors: formSettingsFormErrors,
            // formSettingsEmailDistValid: formSettingsEmailDistValid,
            formSettingsDesciptionValid:formSettingsDesciptionValid,
        }, this.formSettingsFormErrors);
    }
    
    findInFormSettingsList(){
       //this.state.templateGuid;
       var FormTemplates = this.state.formSettingsList;
        for (var i = 0; i < FormTemplates.length; i++){
          
          if (FormTemplates[i].Guid === this.state.templateGuid){
            // we found it
            return true;
          }
        }
        return false; 
    }
    

    addFormSettingsInToArray(event){

        event.preventDefault();
        
        let formSettingsDesciption = this.state.formSettingsDesciption;

        //let formSettingsFormErrors = Object.assign({}, this.state.formSettingsFormErrors);
        let formSettingsFormErrors = this.state.formSettingsFormErrors;
        
        if(typeof formSettingsDesciption === 'undefined'){
          formSettingsDesciption = "";
        }

        if(this.findInFormSettingsList() && !this.state.isFormSettingsEditModal){
            toast.error("Form template already added.", {
                autoClose: 5000
            });
            return false;
        }

        if(formSettingsDesciption.length < 1 )
        {
            formSettingsFormErrors.formSettingsDesciption = "This field is required";
            return false;
        }
        
        var dataObj = {
          "TenantGuid": this.state.selectedTemp.TenantGuid,
          "DocumentAtt": this.state.selectedTemp.DocumentAtt,
          "ExtParty": (this.state.formSettingsExtPrty) ? true : false,
          "Confidential": (this.state.formSettingsConfidential) ? true : false,
          "filename": this.state.selectedTemp.filename,
          "TemplateCode": this.state.selectedTemp.TemplateCode,
          "DocDetails": this.state.selectedTemp.DocDetails,
          "DocPassword": this.state.selectedTemp.DocPassword,
          "EmailDist": (this.state.formSettingsEmailDist) ? this.state.formSettingsEmailDist.split(",").map(item => item.trim()) : [],
          "Guid": this.state.selectedTemp.Guid,
          "DigitalDoc": this.state.selectedTemp.DigitalDoc,
          "CustomList": this.state.selectedTemp.CustomList,
          "Desc": this.state.selectedTemp.Desc
        }
        
        //console.log("dataObj=>", dataObj); return false;
        var dataObj2 = {
            "TemplateGuid": this.state.templateGuid
        }


         // if(this.state.formSettingsEmailDistValid){
            var isFormSettingsEditModal = this.state.isFormSettingsEditModal;
            if(isFormSettingsEditModal){
                var formSettingsList  = this.state.formSettingsList;
                var formSettingsList2 = this.state.formSettingsList2;
                var formSettingIndex  = this.state.formSettingIndex;
                formSettingsList[formSettingIndex] = dataObj;
                formSettingsList2[formSettingIndex] = dataObj2;

                this.setState({
                    "formSettingIndex": formSettingIndex,
                    "isFormSettingsEditModal":false,
                    "templateCode":"",
                    "formSettingsDesciption": "",
                    "formSettingsExtPrty": false,
                    "formSettingsConfidential": false,
                    "formSettingsEmailDist":"",
                })
            }
            else{
                let formSettingsList = this.state.formSettingsList;
                let formSettingsList2 = this.state.formSettingsList2;
                formSettingsList.push(dataObj);
                formSettingsList2.push(dataObj2);
                this.setState({
                    "formSettingIndex": formSettingIndex,
                    "templateGuid": "",
                    "templateCode":"",
                    "formSettingsDesciption": "",
                    "formSettingsExtPrty": false,
                    "formSettingsConfidential": false,
                    "formSettingsEmailDist":"",
                })
            }

            this.setState({
                formSettingsEmailDistValid:false,
            });

            this.onFormSettingsModalClose();

    }

    editFormSettingsListFromArray(event, id) {
        event.preventDefault();
        this.setState({
                "formSettingIndex": id,
                "isFormSettingsEditModal": true,
                });
        var formSettingsList = this.state.formSettingsList;
        formSettingsList = formSettingsList[id];

        //console.log("formSettingsList=>",formSettingsList); return false;
        this.setState({
            selectedTemp: formSettingsList,
            templateGuid:formSettingsList.Guid,
            templateCode:formSettingsList.TemplateCode,
            formSettingsDesciption: formSettingsList.Desc,
            formSettingsExtPrty: (formSettingsList.ExtParty)?"checked":"",
            formSettingsConfidential: (formSettingsList.Confidential)?"checked":"",
            formSettingsEmailDist: formSettingsList.EmailDist.toString(),
        });
        this.onFormSettingsModalOpen();
    }

    removeFormSettingsFromArray(event, id){
        event.preventDefault();
        var formSettingsList = this.state.formSettingsList;
        var formSettingsList2 = this.state.formSettingsList2;
        formSettingsList.splice(id, 1);
        formSettingsList2.splice(id, 1);
        this.setState({
            "formSettingsList": formSettingsList,
            "formSettingsList2": formSettingsList2,
        })
    }

    // - - - Add Users Model Methods - - -//
    // - - - - - - - - - - - - - - - - - -//
    addUser(event){
        event.preventDefault();
        var array = this.state.checkboxArray;
        var string = "";
        array.map(function(value, key){
            if(value.value !== null){
                string += value.value+" ";
            }else{
                string= "";
            }
            return null;
        });
        this.setState({
            checkedUserList:array,
            checkboxArray:[],
            userAccess:string,
        });
        this.onAddUserModalClose();
    }
    handleUserInputCheckbox(event){
        var checekdUserArray = [];
        var i;
        var length= this.state.usersList.length;
        for (i = 0; i < length ; i++) { 
        var checked = $("input[name=select"+i+"]:checked").length;
        if (checked != 0) {
        checekdUserArray.push({value : $("input[name=select"+i+"]").data('userlogin'), key : i});
        }
        }
        this.setState({
        checkboxArray:checekdUserArray,
        });
        }
    onAddUserModalClose(){
        this.setState({ addUserModalOpen: false, checkboxArray: [] });
    }
    onAddUserModalOpen(){
        var checkedUsers = this.state.checkedUserList.slice();
        //var checkedUsers = this.state.checkboxArray;
        this.setState({ addUserModalOpen: true, checkboxArray: checkedUsers});
        if(checkedUsers)
        {
            setTimeout(function(){
                console.log("checked Users => " , checkedUsers);
                checkedUsers.map(function(user ){
                    if(user.value != null){
                        var cls = "input.checkbox"+user.key;
                        $(cls).prop('checked', true);
                    }
                    return null;
                });
            },100);

        }

    }

    // - - - Form Form Pack Methods - - -//
    // - - - - - - - - - - - - - - - - - - - -//
    addFormPack = async event => {
        event.preventDefault();
        let packCode = this.state.packCodeValid;
        let description = this.state.descriptionValid;
        let project = this.state.projectValid;
        let formErrors = Object.assign({}, this.state.formErrors);

        if(packCode === false || packCode === null)
        {
            formErrors.packCode = "This field is required";

        }
        if(description === false || description === null)
        {
            formErrors.description = "This field is required";

        }
        if(project === false || project === null)
        {
            formErrors.project = "This field is required";
        }
        this.setState({formErrors});
        // this.setState({
        //     formValid: this.state.packCodeValid && this.state.descriptionValid && this.state.projectValid
        // });

        //console.log(this.state.packCodeValid && this.state.descriptionValid && this.state.projectValid); return false;
        if(this.state.packCodeValid && this.state.descriptionValid && this.state.projectValid){

            this.setState({
                isLoading: true,
                modalOpen:false,
                formValid: false,
                packCodeValid:false,
                descriptionValid:false,
                projectValid:false,
            });

            try {
                //console.log("formSettingsList at create=>", this.state.formSettingsList); return false;
                const response = await this.createFormPack(this.state.packCode, this.state.customFieldsList, this.state.description, this.state.formSettingsList, this.state.externalPartyToComplete, this.state.payRollPack,this.state.checkedUserList, this.state.Guid, localStorage.getItem('useableGuid'), this.state.formSettingsList2, this.state.datalist);
                
                if (response.status) {
                    this.setState({ isLoading:false });
                    toast.success(response.message, { autoClose: 5000 });

                    this.setState({
                        packCode:"",
                        description:"",
                        userAccess:"",
                        project:"",
                        projectGuid:"",
                        payRollPack:"",
                        isLoading:false,
                        externalPartyToComplete:"",
                        Guid:"",
                        customFieldsList:[],
                        checkboxArray: [],
                        checkedUserList:[],
                        formSettingsList:[],
                    });

                    this.getAllFormpacks().then(response=>{
                        console.log("formPacksList => ", response);
                        if(response.status){
                          this.setState({ formPacksList: response.result});  
                        }
                    }).catch(err => {
                        console.error("formPacksList error=> ", err);
                    });

                }else{
                    this.setState({ isLoading:false });
                    toast.success(response.error, { autoClose: 5000 });
                }
            } catch (e) {
                toast.error(e.message, { autoClose: 5000 });
            }
        }else{
          $("#formPackModal form .modal-body").animate({ scrollTop: 0 }, 'slow');
        }

    }

    createFormPack(Code, CustomFields, Desc, Documents, ExtParty, Payroll, UserAccess, Guid, TenantGuid, DocumentsIDs, dataList) {
        
        return this.props.postRequest("/form-pack/create-pack", {
            "Code": Code,
            "CustomFields": CustomFields,
            "Desc": Desc,
            "Documents": Documents,
            "Documents2": DocumentsIDs,
            "ExtParty": ExtParty,
            "Payroll": Payroll,
            "UserAccess": UserAccess,
            "upadteableid":Guid,
            "dataList": dataList,
            "CompanyGuid": localStorage.getItem('companyGuid'),
            "projectGuid": this.state.projectGuid === '' || this.state.projectGuid === null ? 'NA' : this.state.projectGuid
        });
    }

    handleDelete = (Guid)=> async event => {

        var result = window.confirm('Are you sure you want to delete?');
        if (result) {
            this.setState({isLoading: true });
            await this.deleteFormPack(Guid).then((response) => {
                if(response.status){
                    this.setState({ isLoading: false });
                    toast.success("Record has deleted successfully.", { autoClose: 5000 });
                    this.getAllFormpacks().then(response=>{
                        console.log("formPacksList => ", response);
                        if(response.status){
                          this.setState({ formPacksList: response.result});  
                        }
                    }).catch(err => {
                        console.error("formPacksList error=> ", err);
                    });
                }else{
                    this.setState({ isLoading: false });
                    toast.error(response.error, { autoClose: 5000 });
                }
            });
        }
    }

    // - - - Form Settings Model Methods - - -//
    // - - - - - - - - - - - - - - - - - - - -//
    onFormSettingsModalClose(){
        this.setState({
            formSettingsModalOpen: false,
            formSettingIndex:null,
            isFormSettingsEditModal:false,
            templateGuid: "",
            templateCode:"",
            formSettingsDesciption:"",
            formSettingsExtPrty:"",
            formSettingsEmailDist:"",
            formSettingsConfidential:"",
            formSettingsEmailDistValid:false,
            formSettingsFormErrors:{
                formSettingsEmailDist:"",
            }
        });
        this.setState({  });
    }
    onFormSettingsModalOpen(){
        this.setState({ formSettingsModalOpen: true });
    }

    createSelectItems() {
        let items = [];
        let currentProjectList = this.state.projectList;
        currentProjectList.map((results, key) => {
             items.push(<option key={key} className={results.Desc} value={results.Guid}>{results.Desc}</option>);
             return null;
        });
        return items;

    }

    createTemplateSelectItems() {
        let items = [];
        let currentTemplatesList = this.state.templatesList;
        currentTemplatesList.map((results, key) => {
             items.push(<option key={key} data-code={results.TemplateCode} id={results.filename} className={results.Desc} value={results.Guid}>{results.Desc}</option>);
             return null;
        });
        return items;

    }

    handleProjectSelect(event) {
        let name = event.target.name;
        var projectName = $('select[name="project"] :selected').attr('class');


        let value = event.target.value;
        this.setState({
                project:projectName,
                projectGuid: value
            },
            () => {
                this.validateField(name, value)
            });
    }


    //find form Template by Guid
    findFormTemplate(needle){
        var FormTemplates = this.state.templatesList;
        for (var i = 0; i < FormTemplates.length; i++){
          
          if (FormTemplates[i].Guid === needle){
            // we found it
            return FormTemplates[i];
          }
        }
        return 0;
    }

    handleTemplateSelect(event) {
        let value = event.target.value;
        let name = event.target.name;
        var selectedTemp = this.findFormTemplate(event.target.value);
        if(selectedTemp){
            console.log("selectedTemp=>",selectedTemp);
            //var templateDesc = $('select[name="formSettingsDesciption"] :selected').attr('class');
            //var fileName =  $('select[name="formSettingsDesciption"] :selected').attr('id');
            //var templateCode =  $('select[name="formSettingsDesciption"] :selected').attr('data-code');


            
            this.setState({
                    selectedTemp: selectedTemp,
                    formSettingsDesciption: selectedTemp.Desc,
                    templateGuid: selectedTemp.Guid,
                    formSettingsDocName: selectedTemp.filename,
                    templateCode:selectedTemp.TemplateCode,
                    formSettingsExtPrty: selectedTemp.ExtParty? true : false,
                    formSettingsConfidential: selectedTemp.Confidential? true : false,
                    formSettingsEmailDist:selectedTemp.EmailDist.toString()
                },
                () => {
                    this.formSettingsValidate(name, value)
                });
        }else{
            //console.log("selectedTemp=>",selectedTemp);
            this.setState({
                    selectedTemp: false,
                    formSettingsDesciption:"",
                    templateGuid: value,
                    formSettingsDocName: "",
                    templateCode:"",
                    formSettingsExtPrty: false,
                    formSettingsConfidential: false,
                    formSettingsEmailDist:""
                },
                () => {
                    this.formSettingsValidate(name, value)
                });
        }
    }
    handleInputs(event) {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({
                [name]: value
            },
            () => {
                this.validateField(name, value)
            });
    }
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let projectValid = this.state.projectValid;
        let packCodeValid = this.state.packCodeValid;
        let descriptionValid = this.state.descriptionValid;


        switch (fieldName) {
            case 'packCode':
                packCodeValid = value.length > 0;
                fieldValidationErrors.packCode = packCodeValid ? '' : "This field is required";
                break;
            case 'description':
                descriptionValid = value.length > 0;
                fieldValidationErrors.description = descriptionValid ? '' : "This field is required";
                break;

            default:
                break;
        }
        this.setState({
            formErrors: fieldValidationErrors,
            projectValid: true,
            packCodeValid:packCodeValid,
            descriptionValid:descriptionValid


        }, this.validateForm);
    }
    //edit form pack
    
    editFormPack(event, id){
        this.setState({ isLoading:true });

        this.getFormPack(id).then(response => {
            if(response.status){
                var result = response.result;
                this.setState({
                    packCode:result.Code,
                    customFieldsList:result.CustomFields,
                    description:result.Desc,
                    formSettingsList:result.Documents,
                    formSettingsList2:result.Documents2,
                    externalPartyToComplete:(result.ExtParty)?"checked":"",
                    Guid:result.Guid,
                    payRollPack:(result.Payroll)?"checked":"",
                    checkedUserList:result.UserAccess,
                    checkboxArray:result.UserAccess,
                    projectGuid:result.ProjectGuid,
                    modalOpen:true,
                    isLoading:false,
                    packCodeValid:true,
                    descriptionValid:true,
                    projectValid:true,
                    formSettingsEmailDistValid:true,
                    datalist:result.dataList
                })

                //var array = this.state.checkedUserList;
                var array = result.UserAccess;
                var string = "";
                array.map(function(value){
                    if(value.value !== null){
                        string += value.value+" ";
                    }
                    return null;
                });
                // console.log(string);
                this.setState({
                    userAccess:string,
                });
            }else{
                this.setState({ isLoading:false });
                toast.success(response.error, { autoClose: 5000 });
            }
        }).catch((err)=>{
            this.setState({ isLoading:false });
            toast.warning(err.message, { autoClose: 5000 });
        });
    }

    getAllFormpacks() {
        return this.props.postRequest(`/form-pack/get-list/${localStorage.getItem('companyGuid')}`, {});
    }

    deleteFormPack(Guid) {
        return this.props.postRequest("/form-pack/delete-pack", {Guid: Guid});
    }

    getFormPack(id){
        return this.props.postRequest("/form-pack/get-pack", {Guid: id});
    }

    handleChange(date) {
      if(date){
          this.setState({
            startDate: date,
            defaultValue:date,
            datedefaultValue_error:false
          });
      }else{
          this.setState({
            startDate: date,
            defaultValue:'',
            datedefaultValue_error:true
          });
      }

    }

defaultValueHandler = (event) => {
    let defaultValue = event.target.value;
    console.log('defaultValue', defaultValue);
    const re = /^[0-9\b]+$/;
    console.log(re.test(defaultValue));
    if (this.state.isDate) {
        // var startDate = moment('test');
        // console.log(startDate);
    } else if (this.state.isNumber) {
        const re = /^[0-9\b]+$/;
        if (re.test(defaultValue)) {
            this.setState({
                defaultValue: defaultValue,
                numberdefaultValue_error: false
            });
        } else {
            this.setState({
                defaultValue: '',
                numberdefaultValue_error: true
            });
        }
    } else if (this.state.isDroplist) {
        if (defaultValue || defaultValue === 0) {
            this.setState({
                defaultValue: defaultValue,
                defaultValue_error: false
            });
        } else {
            this.setState({
                defaultValue: '',
                defaultValue_error: true
            });
        }
    } else {
        if (defaultValue) {
            this.setState({
                defaultValue: defaultValue,
                defaultValue_error: false
            });
        } else {
            this.setState({
                defaultValue: '',
                defaultValue_error: true
            });
        }
    }

    console.log(this.state.defaultValue);
}


viewMrtFile = async (event, id) =>{
    
    this.setState({ isLoading:true });

    this.getFormPack(id).then(response => {
        if(response.status){
            var FormPackData = response.result;
            let dataPack = JSON.stringify(FormPackData);
            localStorage.setItem('getformdata',dataPack);
            this.setState({ isLoading:false });
            var path = window.location.protocol + '//' + window.location.host + '/formpacks/print';
            window.open(path, '_blank');
        }else{
            toast.error(response.error, { autoClose: 5000 });
            this.setState({ isLoading:false });  
        }
    }).catch((err)=>{
        toast.error(err.message, { autoClose: 5000 });
        this.setState({ isLoading:false });
    });   
     
}
handleCustomField=(event)=>
{
var name=event.target.name
var value=event.target.value
this.setState({customFieldValue:value},()=>{console.log(this.state.customFieldValue)})
var found=this.state.customFieldData.find(obj=> obj.Guid=== value)
if(found){
this.setState({
    isLoading:true,
    customFieldsDescription:found.Desc,
    customFieldsType:found.Type,
    defaultValue:found.Value,
    customFieldsLength:found.Length,
    customFieldsTag:found.Tag,
   
})
this.setState({isLoading:false})
}
else{
    this.setState({
        customFieldsDescription:"",
        customFieldsType:"",
        defaultValue:"",
        customFieldsLength:"",
        customFieldsTag:""})
}
}

setSettingsList = (data) => {
    //console.log("reored temp list=>", data);
    let formSettingsList2 = data.map((temp)=>{
        return { TemplateGuid: temp.Guid}
    });
    //console.log("formSettingsList2=>", formSettingsList2);
    this.setState({
        formSettingsList:data,
        formSettingsList2: formSettingsList2   
    });
}

    render() {
        if(typeof this.state.usersList !== 'undefined' && this.state.usersList.length > 0)
        {
            let currentUsersList = this.state.usersList;
            var usersList = currentUsersList.map((field, key) => {
                return (
                    <tr key={key}>
                       <td align="left" valign="middle">
                          <label className="check_box_pop">
                              <input className={"checkbox"+key} id={+key} type="checkbox" name={"select"+key} data-userlogin={field.UserLogin} data-emailaddress={field.EmailAddress} onChange={(event) => this.handleUserInputCheckbox(event)}/>
                              <span className="checkmark left0_check checkmark_form_pck_pop"></span>
                          </label>
                       </td>
                       <td align="left" valign="middle">{field.UserLogin}</td>
                       <td align="left" valign="middle">{field.EmailAddress}</td>
                    </tr>
                )
            });
            if(usersList.length === 0)
            {

                 usersList = <tr><td  colSpan="2">Record not found</td><td></td></tr>;
            }
        }
        else
        {
             usersList = <tr><td  colSpan="2">Record not found</td><td></td></tr>;
        }

        return (
            <div className="FormPacks">
               <Header pageHeading="Form Packs" isLoading={this.state.isLoading} props={this.props}/>
               <div className="clearfix"></div>
               <div className="clearfix"></div>
                <div className="col-xs-12 col-sm-9 col-md-10 pull-right p0 top_mrg_general top_mrg_general_custom">
                  <div className="">
                     <div className="col-sm-12 col-md-12 col-lg-10 col-lg-offset-1 general_setting_hd">
                        <div className="col-xs-12 col-sm-12 col-md-12">
                           <div className="clear10"></div>
                           <a href={null} className="CursorPointer">
                           <button type="button" className="btn btn-primary pull-right plus_icon_table btn_plus_cusotm formpack_btn_plus_cusotm formpack_btn_plus_cusotm" onClick={(event) => this.onModalOpen()} data-toggle="modal">+</button>
                           </a>
                           <div className="clearfix"></div>
                           <div className="overflow_scroll_sm">
                              <table className="table table-bordered timecard_table custome_field_table formpack_approvel_field_table">
                                 <thead>
                                    <tr>
                                       <th width="15%" align="center">Pack Code</th>
                                       <th width="10%" align="center">Detail</th>
                                       <th width="20%" align="center">Description</th>
                                       <th width="55%" align="center">&nbsp;</th>
                                       {/*<th align="center">&nbsp;</th>*/}
                                    </tr>
                                 </thead>
                                     <FormPackListItem formPacks={this.state.formPacksList}
                                       props={this.props} handleDelete={this.handleDelete}
                                       handleEdit={this.editFormPack.bind(this)}
                                       ViewMrtFile= {this.viewMrtFile.bind(this)}
                                     />

                              </table>
                           </div>
                        </div>
                     </div>
                     <div className="clear10"></div>
                  </div>
               </div>
               <div className="clear10"></div>

               <Modal id="formPackModal" show={this.state.modalOpen} onHide={(event) => this.onModalClose(event)} className="modal modal2 fade modal-dialog_w_pckform1" role="dialog">
                    <form onSubmit={(event) => this.addFormPack(event)}>
                        <div className="modal-header" id="errorsTop">
                           <button type="button" className="close OutlineNone" data-dismiss="modal" ><img src={ic_clear_24px} onClick={(event) => this.onModalClose()} className="mt22" width="18" height="18" alt="" /></button>
                           <h4 className="modal-title modal_customefield">Add Form Pack</h4>
                        </div>
                        <div className="modal-body label_modal_custom">
                           <div className="col-sm-12 p0 mrg_bot_custom10">
                              <div className="col-sm-3 label_csutom_pop">Pack Code:</div>
                              <div className="col-sm-6">
                                <input name="packCode" value={this.state.packCode} onChange={(event) => this.handleInputs(event)} type="text" />
                                <p className={"error validation " + (this.state.formErrors.packCode ? '': 'displayNone')}>{this.state.formErrors.packCode}</p>
                              </div>
                           </div>
                           <div className="col-sm-12 p0 mrg_bot_custom10">
                              <div className="col-sm-3 label_csutom_pop">Description:</div>
                              <div className="col-sm-6">
                                <input name="description" value={this.state.description} onChange={(event) => this.handleInputs(event)} type="text" />
                                <p className={"error validation " + (this.state.formErrors.description ? '': 'displayNone')}>{this.state.formErrors.description}</p>
                              </div>
                           </div>
                           <div className="col-sm-12 p0 mrg_bot_custom10">
                              <div className="col-sm-3 label_csutom_pop">User Access:</div>
                              <div className="col-sm-6"><input name="userAccess" value={this.state.userAccess} readOnly /></div>
                              <div className="col-sm-3"><a href={null} data-toggle="modal" onClick={(event) => this.onAddUserModalOpen()} className="btn_adduser_pck_pop CursorPointer">Add Users</a></div>
                           </div>
                           <div className="col-sm-12 p0 mrg_bot_custom10">
                              <div className="col-sm-3 label_csutom_pop">Project:</div>
                              <div className="col-sm-6">
                                 <select id="projectName" name="project" value={this.state.projectGuid} onChange={(event) => this.handleProjectSelect(event)} >
                                    <option value="">Select</option>
                                    {this.createSelectItems()}
                                 </select>
                                 <p className={"error validation " + (this.state.formErrors.project ? '': 'displayNone')}>{this.state.formErrors.project}</p>
                              </div>
                           </div>
                           <div className="col-sm-12 p0 mrg_bot_custom10 checkbox_align">
                              <div className="col-lg-4 col-sm-6 col-xs-10 label_csutom_pop">External Party To Complete:</div>
                              <div className="col-lg-8 col-sm-6 col-xs-2 mrg_check_form_pack">
                                 <label className="check_box_pop">
                                 <input type="checkbox" name="externalPartyToComplete" value={this.state.externalPartyToComplete} checked={this.state.externalPartyToComplete} onChange={(event) => this.handleInputCheckbox(event)}/>
                                 <span className="checkmark left0_check"></span>
                                 </label>
                              </div>
                           </div>
                           <div className="col-sm-12 p0 mrg_bot_custom10 checkbox_align">
                              <div className="col-lg-4 col-sm-6 col-xs-10 label_csutom_pop">Payroll Pack?</div>
                              <div className="col-lg-8 col-sm-6 col-xs-2 mrg_check_form_pack">
                                 <label className="check_box_pop">
                                 <input type="checkbox" name="payRollPack" value={this.state.payRollPack} checked={this.state.payRollPack} onChange={(event) => this.handleInputCheckbox(event)}/>
                                 <span className="checkmark left0_check"></span>
                                 </label>
                              </div>
                           </div>
                           <div className="clear10"></div>
                           <div className="col-sm-12 custome_form_pck_hd">
                              <h2>Custom Fields</h2>
                              <div className="col-sm-12 table_formpck_pop_1 p0">
                                 <a href={null} className="CursorPointer">
                                 <button type="button" className="btn btn-primary pull-right plus_icon_table btn_plus_cusotm formpack_btn_plus_cusotm" data-toggle="modal" onClick={(event)=> this.onCustomFieldsModalOpen()}>+</button>
                                 </a>
                                 <div className="clear5"></div>
                                 <div className="scroll_form_pcktable">
                                    <table width="100%" border="0" cellSpacing="2" cellPadding="2">
                                       <thead>
                                          <tr>
                                             <th width="12%" align="left" valign="middle">Description</th>
                                             <th width="12%" align="left" valign="middle">Value</th>
                                             <th width="12%" align="left" valign="middle">Type</th>
                                             <th width="12%" align="left" valign="middle">Length</th>
                                             <th width="12%" align="left" valign="middle">Required</th>
                                             <th width="12%" align="left" valign="middle">Tag</th>
                                             <th width="28%" align="center">&nbsp;</th>
                                          </tr>
                                       </thead>
                                       <CustomFieldsListItem
                                        customFieldsList={this.state.customFieldsList}
                                        isLoading={this.state.isLoading}
                                        props={this.props}
                                        editField={this.editCustomFieldsListFromArray.bind(this)}
                                        removeField={this.removeCustomFieldsFromArray.bind(this)}
                                        />
                                    </table>
                                 </div>
                              </div>
                           </div>
                           <div className="clear20"></div>
                           <div className="col-sm-12 custome_form_pck_hd">
                              <h2>Details</h2>
                              <div className="col-sm-12 table_formpck_pop_1 p0">
                                 <a href={null} className="CursorPointer">
                                 <button type="button" className="btn btn-primary pull-right plus_icon_table btn_plus_cusotm formpack_btn_plus_cusotm" data-toggle="modal" onClick={(event) => this.onFormSettingsModalOpen()}>+</button>
                                 </a>
                                 <div className="clear5"></div>

                                       <FormSettingsListItem
                                        settingsList={this.state.formSettingsList}
                                        isLoading={this.state.isLoading}
                                        editField={this.editFormSettingsListFromArray.bind(this)}
                                        removeField={this.removeFormSettingsFromArray.bind(this)}
                                        setSettingsList={this.setSettingsList.bind(this)}
                                        props={this.props}
                                        />
                              </div>
                           </div>
                           <div className="clear40"></div>
                           <div className="col-sm-6">
                              <div className="btn_cance_save">
                                 <button type="submit" className="btn_save_pro"  data-dismiss="modal">Save</button>
                                 <button type="button" className="btn_cancel_pro"  onClick={(event) => this.onModalClose(event)}>Cancel</button>
                              </div>
                           </div>
                           <div className="clear20"></div>
                        </div>
                    </form>
               </Modal>
               <Modal id="addUserModal" show={this.state.addUserModalOpen} onHide={(event) => this.onAddUserModalClose(event)} className="modal modal2 fade" role="dialog">
                    <div className="modal-header">
                       <button type="button" className="close OutlineNone" data-dismiss="modal" onClick={(event) => this.onAddUserModalClose(event)} ><img src={ic_clear_24px} className="mt22" width="18" height="18" alt="" /></button>
                       <h4 className="modal-title modal_customefield">User Access</h4>
                    </div>
                    <div className="modal-body">
                       <form onSubmit={(event) => this.addUser(event)}>
                           <div className="col-sm-12 p0 mrg_bot_custom10">
                              <div className="table_formpck_pop_1">
                                 <table width="100%" border="0" cellSpacing="2" cellPadding="2">
                                    <thead>
                                       <tr>
                                          <th width="20%" align="left" valign="middle">Select</th>
                                          <th width="40%" align="left" valign="middle">User Login</th>
                                          <th width="40%" align="left" valign="middle">User Name</th>
                                       </tr>
                                    </thead>

                                    <tbody>
                                            {
                                                usersList
                                            }
                                    </tbody>
                                 </table>
                              </div>
                           </div>
                           <div className="clear10"></div>
                           <div className="col-sm-6 p0">
                              <div className="btn_cance_save">
                                 <button type="submit" className="btn_save_pro"  data-dismiss="modal">Save</button>
                                 <button type="button" className="btn_cancel_pro"  onClick={(event) => this.onAddUserModalClose()}>Cancel</button>
                              </div>
                           </div>
                           <div className="clear20"></div>
                       </form>
                    </div>
               </Modal>
               <Modal id="customFieldsModal" show={this.state.customFieldsModalOpen} onHide={(event) =>
                  this.onCustomFieldsModalClose(event)} className="modal modal2 fade" role="dialog">
                  <div className="modal-header">
                     <button type="button" className="close OutlineNone" data-dismiss="modal" onClick={(event) => this.onCustomFieldsModalClose()}><img src={ic_clear_24px} className="mt22" width="18" height="18" alt="" /></button>
                     <h4 className="modal-title modal_customefield">Custom Fields</h4>
                  </div>
                  <form onSubmit={(event) => this.addCustomFieldsInToArray(event)}>
                  
                      
                      
                      <div className="modal-body label_modal_custom overflow_inherti_custom">

                      <div className="col-sm-12 p0 mrg_bot_custom10">
                            <div className="col-sm-3 col-md-3 label_csutom_pop">Custom Field:</div>
                            <div className="col-sm-9 col-md-9">
                               <select name="customFieldValue" onChange={(event)=>this.handleCustomField(event)} >
                                   <option value="">Select</option>
                                   {
                                       this.state.customFieldData.map((listvalue,index)=>
                                       <option value={listvalue.Guid}>
                                           {listvalue.Desc}
                                       </option>
                                           
                                       )
                                   }
                               </select>
                            </div>
                         </div>

                         <div className="col-sm-12 p0 mrg_bot_custom10">
                            <div className="col-sm-3 col-md-3 label_csutom_pop">Description:</div>
                            <div className="col-sm-9 col-md-9">
                                <input type="text" name="customFieldsDescription" onChange={(event) => this.handleCustomFieldsModalInputs(event)} value={this.state.customFieldsDescription}  />
                                <p className={"error validation " + (this.state.customFieldsFormErrors.customFieldsDescription ? '': 'displayNone')}>{this.state.customFieldsFormErrors.customFieldsDescription}</p>
                            </div>

                         </div>
                        
                        <div className="col-sm-12 p0 mrg_bot_custom10">
                            <div className="col-sm-3 col-md-3 label_csutom_pop">Type:</div>
                            <div className="col-sm-9 col-md-9">
                               <select name="customFieldsType" onChange={(event) => this.typeHandler(event)} value={this.state.customFieldsType}>
                                   <option value="">Select Type</option>
                                   <option value="String">String</option>
                                   <option value="Text">Text</option>
                                   <option value="Numeric">Numeric</option>
                                   <option value="Drop List">Drop List</option>
                                   <option value="Date">Date</option>
                                   <option value="File">File</option>
                               </select>
                            </div>
                         </div>
{this.state.isFile?"":
                         <div className="col-sm-12 p0 mrg_bot_custom10">
                            <div className="col-sm-3 col-md-3 label_csutom_pop">Value:</div>
                            <div className="col-sm-9 col-md-9">
                                {(this.state.isString)?
                                  <React.Fragment>
                                  <input name="" type="text" value={this.state.defaultValue} onChange={(e)=>this.defaultValueHandler(e)} />

                                  </React.Fragment>
                                  :''}
                                  {(this.state.isNumber)?
                                  <React.Fragment>
                                  <input name="" type="text" value={this.state.defaultValue} onChange={(e)=>this.defaultValueHandler(e)} />
                                  {(this.state.numberdefaultValue_error)?<p className="error validation ">This field should be Numeric </p>:''}
                                  </React.Fragment>
                                  :''}

                                  {(this.state.isDate)?
                                  <React.Fragment>
                                  <DatePicker id="showDateFrom"
                                  selected={this.state.startDate}
                                  onChange={this.handleChange.bind(this)}
                                  />

                                  </React.Fragment>
                                  :''}


                                  {(this.state.isDroplist)?
                                  <React.Fragment>
                                  <div className="display_flex">
                                  <select value={this.state.defaultValue} onChange={(e)=>this.defaultValueHandler(e)} >
                                  <option value='select'>Select Option</option>
                                  {this.state.datalist.map((datalst,key)=>{
                                  return(
                                  <option key={key} value={datalst.value}>{datalst.option}</option>
                                  )
                                  }
                                  )}


                                  </select>

                                  <a href={null} className="btn_margin_left">
                                  <button type="button" className="btn btn-primary pull-right plus_icon_table btn_plus_cusotm customfields_btn_plus_cusotm" data-toggle="modal" data-target="#myModal" onClick={this.datalist_openModal}>+</button>
                                  </a>
                                  </div>
                                  <div className="clearfix"></div>
                                  </React.Fragment>
                                  :''}

                            </div>
                         </div>

}                         <div className="col-sm-12 p0 mrg_bot_custom10">
                            <div className="col-sm-3 col-md-3 label_csutom_pop">Length:</div>
                            <div className="col-sm-9 col-md-9">
                                <input name="customFieldsLength" onChange={(event) => this.handleCustomFieldsModalInputs(event)} value={this.state.customFieldsLength} type="text" />
                                <p className={"error validation " + (this.state.customFieldsFormErrors.customFieldsLength ? '': 'displayNone')}>{this.state.customFieldsFormErrors.customFieldsLength}</p>
                            </div>

                         </div>
                         <div className="col-sm-12 p0 mrg_bot_custom10">
                            <div className="col-xs-4 col-sm-3 col-md-3 label_csutom_pop">Required:</div>
                            <div className="col-xs-6 col-sm-9 col-md-9">
                               <label className="check_box_pop mt8">
                               <input type="checkbox" name="customFieldsRequired" value={this.state.customFieldsRequired} checked={this.state.customFieldsRequired} onChange={(event) => this.handleInputCheckbox(event)}/>
                               <span className="checkmark left0_check"></span>
                               </label>
                            </div>
                         </div>
                         <div className="clearfix"></div>
                         <div className="col-sm-12 p0 mrg_bot_custom10">
                            <div className="col-sm-3 col-md-3 label_csutom_pop">Tag:</div>
                            <div className="col-sm-9 col-md-9">
                                <input name="customFieldsTag" onChange={(event) => this.handleCustomFieldsModalInputs(event)} value={this.state.customFieldsTag} type="text" />
                                <p className={"error validation " + (this.state.customFieldsFormErrors.customFieldsTag ? '': 'displayNone')}>{this.state.customFieldsFormErrors.customFieldsTag}</p>
                            </div>

                         </div>
                         <div className="clear20"></div>
                         <div className="col-sm-6">
                            <div className="btn_cance_save">
                               <button type="submit" className="btn_save_pro"  data-dismiss="modal">Save</button>
                               <button type="button" className="btn_cancel_pro" onClick={(event) => this.onCustomFieldsModalClose()}>Cancel</button>
                            </div>
                         </div>
                         <div className="clear20"></div>
                      </div>
                  </form>
               </Modal>
               <Modal id="formSettingsModal" show={this.state.formSettingsModalOpen} onHide={(event) =>
                  this.onFormSettingsModalClose(event)} className="modal modal2 fade" role="dialog">
                        <div className="modal-header">
                           <button type="button" className="close OutlineNone" onClick={(event) => this.onFormSettingsModalClose()} ><img src={ic_clear_24px} className="mt22" width="18" height="18" alt="" /></button>
                           <h4 className="modal-title modal_customefield">Form Settings</h4>
                        </div>
                        <div className="modal-body label_modal_custom overflow_inherti_custom">
                            <form onSubmit={(event) => this.addFormSettingsInToArray(event)}>
                               <div className="col-sm-12 p0 mrg_bot_custom10">
                                  <div className="col-sm-4 col-md-4 label_csutom_pop">Form Description:</div>
                                  <div className="col-sm-8 col-md-8">

                                     <select name="formSettingsDesciption" id="formSettingsDesciption"  value={this.state.templateGuid} onChange={(event) => this.handleTemplateSelect(event)} >
                                     <option value="">Select</option>
                                     {this.createTemplateSelectItems()}
                                     </select>
                                     <p className={"error validation " + (this.state.formSettingsFormErrors.formSettingsDesciption ? '': 'displayNone')}>{this.state.formSettingsFormErrors.formSettingsDesciption}</p>
                                  </div>
                               </div>
                               <div className="col-sm-12 p0 mrg_bot_custom10">
                                  <div className="col-xs-4 col-sm-6 col-md-6 label_csutom_pop">External Party To Complete:</div>
                                  <div className="col-xs-6 col-sm-6 col-md-6 p0">
                                     <label className="check_box_pop">
                                     <input name="formSettingsExtPrty" value={this.state.formSettingsExtPrty} checked={this.state.formSettingsExtPrty} onChange={(event) => this.handleInputCheckbox(event)}  type="checkbox" />
                                     <span className="checkmark left0_check"></span>
                                     </label>
                                  </div>
                               </div>
                               <div className="col-sm-12 p0 mrg_bot_custom10">
                                  <div className="col-xs-4 col-sm-6 col-md-6 label_csutom_pop">Confidential:</div>
                                  <div className="col-xs-6 col-sm-6 col-md-6 p0">
                                     <label className="check_box_pop">
                                     <input name="formSettingsConfidential" value={this.state.formSettingsConfidential} checked={this.state.formSettingsConfidential} onChange={(event) => this.handleInputCheckbox(event)}  type="checkbox"/>
                                     <span className="checkmark left0_check"></span>
                                     </label>
                                  </div>
                               </div>
                               <div className="clearfix"></div>
                               <div className="col-sm-12 p0 mrg_bot_custom10">
                                  <div className="col-sm-4 col-md-4 label_csutom_pop">Email Dist:</div>
                                  <div className="col-sm-8 col-md-8">
                                    <input name="formSettingsEmailDist" onChange={(event) => this.handleFormSettingsModalInputs(event)} value={this.state.formSettingsEmailDist} type="text" />
                                    <p className={"error validation " + (this.state.formSettingsFormErrors.formSettingsEmailDist ? '': 'displayNone')}>{this.state.formSettingsFormErrors.formSettingsEmailDist}</p>
                                  </div>
                               </div>
                               <div className="clear20"></div>
                               <div className="col-sm-6">
                                  <div className="btn_cance_save">
                                     <button type="submit" className="btn_save_pro"  data-dismiss="modal">Save</button>
                                     <button type="button" className="btn_cancel_pro" onClick={(event) => this.onFormSettingsModalClose()}>Cancel</button>
                                  </div>
                               </div>
                               <div className="clear20"></div>
                            </form>
                        </div>
               </Modal>


               <Modal show={this.state.add_datalist} onHide={this.datalist_closeModal} className="modal modal2 fade" role="dialog">
                  <div className="modal-header">
                     <button type="button" className="close customfields_OutlineNone" data-dismiss="modal" onClick={this.datalist_closeModal}><img className="customfields_mt20" src={ic_clear_24px} width="18" height="18" alt="" /></button>
                     <h4 className="modal-title modal_customefield">Custom Fields</h4>
                  </div>
                  <div className="modal-body label_modal_custom">
                     <div className="overflow_scroll_sm">
                        <table className="table table-bordered timecard_table custome_field_table">
                           <thead>
                              <tr>
                                 <th width="11%" align="center">Option</th>
                                 <th width="11%" align="center">Value</th>
                                 <th width="13%" align="center">&nbsp;</th>
                              </tr>
                           </thead>
                           <tbody>
                              {this.state.datalist.map((datalst,key)=>
                              {
                              return(
                              <tr key={key}>
                                 <td width="11%" align="center">{datalst.option}</td>
                                 <td align="center">{datalst.value}</td>
                                 <td align="center">
                                    <div className="col-sm-4 col-xs-6 p0 pull-right text-center timecard_dele pull-right">
                                       <a href={null} className="cursorPointer" data-toggle="modal" data-target="#exampleModalCenter" onClick={(event)=>
                                          this.delete_datalist(event,key)}>
                                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="1700 296 15 19.286">
                                             <path id="ic_delete_24px" className="cls-1" d="M6.071,20.143a2.149,2.149,0,0,0,2.143,2.143h8.571a2.149,2.149,0,0,0,2.143-2.143V7.286H6.071ZM20,4.071H16.25L15.179,3H9.821L8.75,4.071H5V6.214H20Z" transform="translate(1695 293)"/>
                                          </svg>
                                       </a>
                                    </div>
                                    <div className="col-sm-4 col-xs-6 p0 text-center timecard_edit6 pull-right">
                                       <a href={null} className="cursorPointer" onClick={(event)=>
                                          this.edit_datalist(event,key)}>
                                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="1656.776 299 17.515 18.003">
                                             <path id="ic_create_24px" className="cls-1" d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z" transform="translate(1653.776 296.002)"/>
                                          </svg>
                                       </a>
                                    </div>
                                 </td>
                              </tr>
                              )}
                              )}
                           </tbody>
                        </table>
                        {(this.state.datalist.length)?'':'Record not found'}
                     </div>
                     <div className="clear20"></div>
                     <h2>Add Option/Value</h2>
                     <div>
                        <div className="col-sm-12 p0 mrg_bot_custom10">
                           <div className="col-sm-3 col-md-3 label_csutom_pop">Option:</div>
                           <div className="col-sm-9 col-md-9"><input name="" type="text" value={this.state.datalist_option} onChange={(e)=>this.option_change(e)}/></div>
                           {(this.state.option_error)?
                           <p className="error validation ">This field is required </p>
                           :''}
                        </div>
                        <div className="col-sm-12 p0 mrg_bot_custom10">
                           <div className="col-sm-3 col-md-3 label_csutom_pop">Value:</div>
                           <div className="col-sm-9 col-md-9"><input name="" type="text" value={this.state.datalist_value} onChange={(e)=>this.value_change(e)}/></div>
                           {(this.state.value_error)?
                           <p className="error validation ">This field is required </p>
                           :''}
                        </div>
                        <div className="col-sm-6">
                           <div className="btn_cance_save">
                              <button type="submit" className="btn_save_pro" onClick={(e)=>this.add_datalist(e)}>Save</button>
                              <button type="button" className="btn_cancel_pro" data-dismiss="modal" onClick={this.datalist_closeModal}>Cancel</button>
                           </div>
                        </div>
                     </div>
                     <div className="clear20"></div>
                  </div>
               </Modal>
            </div>
        );
    }
};
export default FormPacks;
