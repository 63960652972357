import React, { Component }
from "react";
import Header from "../Common/Header/Header";
import { toast } from 'react-toastify';
import logo2 from "../../assets/images/logo2.png";
import ic_info_outline_24px from "../../assets/images/ic_info_outline_24px.svg";
import ic_receipt_24px from "../../assets/images/ic_receipt_24px.svg";
import ic_business_24px from "../../assets/images/ic_business_24px.svg";
import ic_library_books_24px from "../../assets/images/ic_library_books_24px.svg";
import ic_chevron_left_24px from "../../assets/images/ic_chevron_left_24px.svg";
import ic_chevron_right_24px from "../../assets/images/ic_chevron_right_24px.svg";
import ic_mail_outline1_24px from "../../assets/images/ic_mail_outline1_24px.svg";
import ic_place_24px from "../../assets/images/ic_place_24px.svg";
import ic_local_phone_24px from "../../assets/images/ic_local_phone_24px.svg";
import ic_local_printshop_24px from "../../assets/images/ic_local_printshop_24px.svg";
import "./Help.css";

class Help extends Component {
     constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            cName:'',
            cEmail:'',
            cMessage:'',
            formErrors:{
                cName:'',
                cEmail:'',
                cMessage:''
            }
        }
     }
     componentDidMount() {
         this.setState({
        isLoading: false
        });
     }
     
     SendSupportEmail= async(e)=>{
         e.preventDefault();
        var cName= this.state.cName;
        var cEmail= this.state.cEmail;
        var cMessage= this.state.cMessage;
        let formErrors = Object.assign({}, this.state.formErrors);
        if(cName.length<1){
            formErrors.cName = "This field is required";
        }
        if(cEmail.length<1){
            formErrors.cEmail = "This field is required";
        }
        else if(cEmail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) === null){
           formErrors.cEmail = "Email should be username@domain.com"; 
        }
        if(cMessage.length<1){
            formErrors.cMessage = "This field is required";
        }
        this.setState({
            formErrors: formErrors,
            });
        if(cName.length<1 || cEmail.length<1 || cMessage.length<1 || cEmail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) === null){
            return false;
        }else{

          this.setState({isLoading: true});
        
          await this.sendEmail('aliabbas500@gmail.com', cMessage , 'Message From digital Forms Support' , '').then(data=>{
                if(data.status === true){
                            toast.success('Email has been sent successfully.', {
                            autoClose: 5000
                        });
                    }
          }).catch(error=>{console.log(error)});
        
        this.setState({
          isLoading: false,
          cName:'',
          cEmail:'',
          cMessage:'',
          formErrors:{
            cName:'',
            cEmail:'',
            cMessage:''
          }
        });
      }
     }
     
    async sendEmail(to , msg , subject, cc){
        var sendTo=to +( cc!=='' ? ', '+cc :'');
        return this.props.postRequest("/users/send-mail", {
                "TO" : sendTo,
                "CC" : cc,
                "MSG" : msg,
                "SUBJECT" : subject
        });
    }

    handelChange=async(event)=>{
        let name=event.target.name;
        let value=event.target.value;
       await this.setState({
            [event.target.name]:event.target.value
        });
        this.validateFields(name,value);
        
    }
    validateFields=async(name,value)=>{
        let cNameValid=false;
        let cEmailValid=false;
        let cEMailFormatValid=false;
        let cMessageValid=false;
        let fieldValidationErrors = this.state.formErrors;
         switch (name) {
            case 'cName':
                cNameValid = value.length > 0;
                fieldValidationErrors.cName = cNameValid ? '' : "This field is required";
                break;
            case 'cEmail':
                cEmailValid = (value.length > 0);
                cEMailFormatValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
                fieldValidationErrors.cEmail = cEmailValid ? '' : "This field is required";
                fieldValidationErrors.cEmail = cEMailFormatValid ? '' : "Email should be username@domain.com";
                break;
            case 'cMessage':
                cMessageValid = value.length > 0;
                fieldValidationErrors.cMessage = cMessageValid ? '' : "This field is required";
                break;
 default:
                break;
        }
        this.setState({
                formErrors:fieldValidationErrors
            });
    }
    render() {
        return (
                <div className="Help">
   <Header pageHeading="" props={this.props} isLoading={this.state.isLoading}/>
              <div className="col-xs-12 col-sm-9 col-md-10 pull-right p0 top_mrg_general_custom help_bg_top_mrg">
      <div className="help_bg_top">
         <img src={logo2} width="60" height="50" alt="" className="mR5px"/>
         SUPPORT CENTER 
      </div>
      <div className="col-xs-12 col-sm-12 col-md-8 col-md-offset-2 time_table_mrg">
         <div className="clear20"></div>
         <div className="panel-group help-panel-group" id="accordion">
            <div className="panel panel-default help_panel_group">
               <div className="panel-heading help_panel_heading">
                  <h4 className="panel-title cusTom help-panel-title">
                     <a className="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseOne">
                     <img src={ic_info_outline_24px} width="30" height="30" alt="" /> &nbsp; Guides</a>
                  </h4>
               </div>
               <div id="collapseOne" className="panel-collapse collapse">
                  <div className="clear30"></div>
                  <div className="panel-body help_body_box">
                     <div id="myCarousel_help" className="carousel slide" data-ride="carousel">
                        <ol className="carousel-indicators help_carousel_indicators">
                           <li data-target="#myCarousel_help" data-slide-to="0" className="active"></li>
                           <li data-target="#myCarousel_help" data-slide-to="1"></li>
                           <li data-target="#myCarousel_help" data-slide-to="2"></li>
                        </ol>
                        <div className="carousel-inner help_carsoul_img">
                           <div className="item active">
                              <div className="carousel-caption help_carsoulcaption">
                                 <img src={ic_receipt_24px} width="120" height="133" alt="" />
                                 <div className="clear10"></div>
                                 <h3>TimeCards</h3>
                                 <p>This is the instruction of TimeCards list </p>
                              </div>
                              <div className="clear10"></div>
                           </div>
                           <div className="item">
                              <div className="carousel-caption help_carsoulcaption">
                                 <img src={ic_business_24px} width="120" height="133" alt="" />
                                 <div className="clearfix"></div>
                                 <h3>Companies </h3>
                                 <p>This is the instruction of Companies list  </p>
                              </div>
                              <div className="clear10"></div>
                           </div>
                           <div className="item">
                              <div className="carousel-caption help_carsoulcaption">
                                 <img src={ic_library_books_24px} width="120" height="133" alt="" />
                                 <div className="clear5"></div>
                                 <h3>Documents </h3>
                                 <p>This is the instruction of Document list  </p>
                              </div>
                              <div className="clear10"></div>
                           </div>
                        </div>
                        <a className="left carousel-control" href="#myCarousel_help" data-slide="prev">
                        <img src={ic_chevron_left_24px} width="10" height="16" alt="" />
                        </a>
                        <a className="right carousel-control" href="#myCarousel_help" data-slide="next">
                        <img src={ic_chevron_right_24px} width="10" height="16" alt="" />
                        </a>
                     </div>
                  </div>
               </div>
            </div>
            <div className="panel panel-default help_panel_group">
               <div className="panel-heading help_panel_heading">
                  <h4 className="panel-title cusTom">
                     <a className="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseTen">
                     <img src={ic_mail_outline1_24px} width="30" height="30" alt="" /> &nbsp; Contact Us</a>
                  </h4>
               </div>
               <div id="collapseTen" className="panel-collapse  col-sm-9 mrg_left_con help_mrg_left_con collapse">
                  <div className="clear30"></div>
                  <div className="panel-body help_body_box">
                     <div className="col-sm-12 help_img_contact">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-10048 7569.276 16 16.809">
                           <path id="ic_person_outline_24px" className="cls-1" d="M12,6A2.155,2.155,0,0,1,14.1,8.2a2.1,2.1,0,1,1-4.2,0A2.155,2.155,0,0,1,12,6m0,9.455c2.97,0,6.1,1.534,6.1,2.206v1.156H5.9V17.658c0-.672,3.13-2.206,6.1-2.206M12,4A4.1,4.1,0,0,0,8,8.2a4.1,4.1,0,0,0,4,4.2,4.1,4.1,0,0,0,4-4.2A4.1,4.1,0,0,0,12,4Zm0,9.455c-2.67,0-8,1.408-8,4.2v3.152H20V17.658C20,14.863,14.67,13.455,12,13.455Z" transform="translate(-10052 7565.276)"/>
                        </svg>
                        <input className="form-control help_input_contact" id="" value={this.state.cName} name="cName" placeholder="Name" type="text" onChange={this.handelChange}/>
                        <p className={"error validation " + (this.state.formErrors.cName ? '': 'displayNone')}>{this.state.formErrors.cName}</p>
                     </div>
                     <div className="clear10"></div>
                     <div className="col-sm-12 help_img_contact">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-7956 7441 30 24">
                           <path id="ic_mail_outline_24px" className="cls-1" d="M29,4H5A3,3,0,0,0,2.015,7L2,25a3.009,3.009,0,0,0,3,3H29a3.009,3.009,0,0,0,3-3V7A3.009,3.009,0,0,0,29,4Zm0,21H5V10l12,7.5L29,10ZM17,14.5,5,7H29Z" transform="translate(-7958 7437)"/>
                        </svg>
                        <input className="form-control help_input_contact" id="" value={this.state.cEmail}  name="cEmail" placeholder="Email Address" type="text" onChange={this.handelChange}/>
                        <p className={"error validation " + (this.state.formErrors.cEmail ? '': 'displayNone')}>{this.state.formErrors.cEmail}</p>
                     </div>
                     <div className="clear10"></div>
                     <div className="col-sm-12 help_img_contact">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-10048 7722.143 16 16">
                           <path id="ic_edit_24px" className="cls-1" d="M3,15.665V19H6.333l9.83-9.83L12.83,5.835ZM18.74,6.59a.885.885,0,0,0,0-1.253l-2.08-2.08a.885.885,0,0,0-1.253,0L13.781,4.884l3.333,3.333L18.74,6.59Z" transform="translate(-10051 7719.146)"/>
                        </svg>
                        <textarea name="cMessage" value={this.state.cMessage} className="form-control help_textraea_contact" cols="" rows="" placeholder="Message" onChange={this.handelChange}></textarea>
                        <p className={"error validation " + (this.state.formErrors.cMessage ? '': 'displayNone')}>{this.state.formErrors.cMessage}</p>
                     </div>
                     <div className="clear30"></div>
                     <div className="col-sm-12 text-center">
                     <button className="btn_send_msg_help" onClick={(e)=>this.SendSupportEmail(e)}>Send Message</button>
                </div>
                     <div className="clear10"></div>
                  </div>
               </div>
            </div>
            <div className="clear10"></div>
            <div className="panel panel-default help_panel_group">
               <div className="panel-heading help_panel_heading">
                  <h4 className="panel-title cusTom">
                     <a className="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseEleven">
                     <img src={ic_place_24px} width="30" height="35" alt="" /> &nbsp; Address and Map</a>
                  </h4>
               </div>
               <div id="collapseEleven" className="panel-collapse collapse">
                  <div className="panel-body help_body_box help_borderNone">
                     <div className="clear30"></div>
                     <div className="tabbable-panel col-md-8 col-md-offset-2">
                        <div className="tabbable-line">
                           <ul className="nav nav-tabs ">
                              <li className="active">
                                 <a href="#tab_default_1" data-toggle="tab">
                                 Australia </a>
                              </li>
                              <li>
                                 <a href="#tab_default_2" data-toggle="tab">
                                 United Kingdom </a>
                              </li>
                           </ul>
                           <div className="tab-content tab_label_help">
                              <div className="tab-pane active" id="tab_default_1">
                                 <table width="100%" border="0" align="center" cellPadding="2" cellSpacing="2">
                                 <tbody>
                                    <tr>
                                       <td height="40" colSpan="2" align="center" valign="middle" className="text-center">
                                          <p className="post_adrs_label">Postal Address:	</p>
                                       </td>
                                    </tr>
                                    <tr>
                                       <td width="10%" height="40" align="left" valign="middle"><img src={ic_place_24px} width="13" height="19" alt="" /></td>
                                       <td width="90%" align="left" valign="middle">PO Box 6145, South Yarra, Victoria 3141 Australia</td>
                                    </tr>
                                    <tr>
                                       <td height="40" colSpan="2" valign="middle">&nbsp;</td>
                                    </tr>
                                    <tr>
                                       <td height="40" colSpan="2" align="center" valign="middle" className="text-center">
                                          <p className="post_adrs_label">	Head Office Address:	</p>
                                       </td>
                                    </tr>
                                    <tr>
                                       <td height="40" align="left" valign="middle"><img src={ic_place_24px} width="13" height="19" alt="" /></td>
                                       <td align="left" valign="middle">Suite 8 25 Claremont Street, South Yarra, Victoria 3141 Australia</td>
                                    </tr>
                                    <tr>
                                       <td height="40" align="left" valign="middle"><img src={ic_local_phone_24px} width="16" height="16" alt="" /></td>
                                       <td align="left" valign="middle">Phone: +613 9829 2300</td>
                                    </tr>
                                    <tr>
                                       <td height="40" align="left" valign="middle"><img src={ic_local_printshop_24px} width="16" height="14" alt="" /></td>
                                       <td align="left" valign="middle">Fax: +613 9827 4310</td>
                                    </tr>
                                    </tbody>
                                 </table>
                                 <p>
                                 </p>
                                 <p>
                                 </p>
                              </div>
                              <div className="tab-pane" id="tab_default_2">
                                 <table width="90%" border="0" align="center" cellPadding="2" cellSpacing="2">
                                    <tbody>
                                    <tr>
                                       <td height="50" colSpan="2" align="center" valign="middle" className="text-center">
                                          <p className="post_adrs_label">Head Office Address:</p>
                                       </td>
                                    </tr>
                                    <tr>
                                       <td width="10%" height="50" align="left" valign="top"><img src={ic_place_24px} className="help_mt5px" width="13" height="19" alt="" /></td>
                                       <td width="90%" align="left" valign="middle">The River Unit, Phoenix Wharf, Eel Pie Island, Twickenham TW1 3DY United Kingdom</td>
                                    </tr>
                                    <tr>
                                       <td height="50" align="left" valign="middle"><img src={ic_local_phone_24px} width="16" height="16" alt="" /></td>
                                       <td align="left" valign="middle">Phone: +44(0) 333 6000 113</td>
                                    </tr>
                                    <tr>
                                       <td height="50" align="left" valign="middle"><img src={ic_local_phone_24px} width="16" height="16" alt="" /></td>
                                       <td align="left" valign="middle"> Support: +44(0) 020 3286 6726</td>
                                    </tr>
                                    </tbody>
                                 </table>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
                );
    }
}
;
export default Help;
