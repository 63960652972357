export const stringToArray = async (timeSheetName) => {
  console.log("timeSheetName", timeSheetName);
  let timeSheetNameStr = timeSheetName;
  let time_sheet_name = timeSheetNameStr;
  let placeHolderArray = [];
  if (timeSheetNameStr?.length > 0) {
    let word = /\{(.*?)\}/g;

    for (
      let m = word.exec(timeSheetNameStr);
      m;
      m = word.exec(timeSheetNameStr)
    ) {
      placeHolderArray.push(m[1]);
    }
    return placeHolderArray;
  } else {
    return placeHolderArray;
  }
};
