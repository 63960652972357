import React, { useState } from 'react'
import menuToggleImg from "../../assets/images/ic_dehaze_-19@2x.png";
import { LinkContainer } from "react-router-bootstrap";


const SideBar = ({ props }) => {

    const [userTypeForRoleManagement] = useState(() => window.localStorage.getItem('userType'));
    return (
        <div className="col-sm-2 col-md-3 p0 sidebar">
            <div id="wrapper" className="display_none_full_menu">
                <div id="sidebar-wrapper">
                    <ul className="sidebar-nav">
                        <li className="sidebar-brand">
                            <span className="navi_hdg"> Navigation</span>
                            <a href={null} className="menu_toggle_mrg CursorPointer" id="menu-toggle">
                                <img src={menuToggleImg} width="30" height="18" alt="" />
                            </a>
                        </li>
                        <li>
                            <LinkContainer to="/dashboard" activeClassName={props.location.pathname === '/dashboard' ? 'active' : ''}>
                                <a href={null}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5378 759 13.333 11.333">
                                        <path id="ic_home_24px" className="cls-1" d="M7.333,14.333v-4H10v4h3.333V9h2L8.667,3,2,9H4v5.333Z" transform="translate(-5380 756)" />
                                    </svg>
                                    <span> Home </span>
                                </a>
                            </LinkContainer>
                        </li>
                        <li>
                            <LinkContainer to="/dashboard/documents" activeClassName="active">
                                <a href={null}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 23.158">
                                        <path id="ic_content_copy_24px" className="cls-1" d="M16.737,1H4.105A2.111,2.111,0,0,0,2,3.105V17.842H4.105V3.105H16.737Zm3.158,4.211H8.316A2.111,2.111,0,0,0,6.211,7.316V22.053a2.111,2.111,0,0,0,2.105,2.105H19.895A2.111,2.111,0,0,0,22,22.053V7.316A2.111,2.111,0,0,0,19.895,5.211Zm0,16.842H8.316V7.316H19.895Z" transform="translate(-2 -1)" />
                                    </svg>
                                    <span> Documents </span>
                                </a>
                            </LinkContainer>
                        </li>
                        <li>
                            <LinkContainer to="/dashboard/report">
                                <a href={null}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 22.222">
                                        <path id="ic_receipt_24px" className="cls-1" d="M19.667,18.667H6.333V16.444H19.667Zm0-4.444H6.333V12H19.667Zm0-4.444H6.333V7.556H19.667ZM3,24.222l1.667-1.667,1.667,1.667L8,22.556l1.667,1.667,1.667-1.667L13,24.222l1.667-1.667,1.667,1.667L18,22.556l1.667,1.667,1.667-1.667L23,24.222V2L21.333,3.667,19.667,2,18,3.667,16.333,2,14.667,3.667,13,2,11.333,3.667,9.667,2,8,3.667,6.333,2,4.667,3.667,3,2Z" transform="translate(-3 -2)" />
                                    </svg>
                                    <span> Report</span>
                                </a>
                            </LinkContainer>
                        </li>
                        <li>
                            <a href={null}>
                                <strong> Admin </strong> </a>
                        </li>
                        {(userTypeForRoleManagement !== 'Admin' && userTypeForRoleManagement !== 'Approver' && userTypeForRoleManagement !== 'Operator' && userTypeForRoleManagement !== 'Operator/Approver' ?
                            <li>
                                <LinkContainer to="/dashboard/form-template">
                                    <a href={null} className="CursorPointer">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                            <path id="ic_description_24px" className="cls-1" d="M16.5,2H6.5A2.287,2.287,0,0,0,4.013,4L4,20a2.287,2.287,0,0,0,2.487,2H21.5A2.3,2.3,0,0,0,24,20V8ZM19,18H9V16H19Zm0-4H9V12H19ZM15.25,9V3.5L22.125,9Z" transform="translate(-4 -2)" />
                                        </svg>
                                        <span> Form Templates</span>
                                    </a>
                                </LinkContainer>
                            </li>
                            : '')}
                        {(userTypeForRoleManagement !== 'Approver' && userTypeForRoleManagement !== 'Operator' && userTypeForRoleManagement !== 'Operator/Approver' ?
                            <li>
                                <LinkContainer to="/dashboard/form-packs">
                                    <a href={null} className="CursorPointer">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 16">
                                            <path id="ic_folder_24px" className="cls-1" d="M10,4H4A2,2,0,0,0,2.01,6L2,18a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V8a2.006,2.006,0,0,0-2-2H12Z" transform="translate(-2 -4)" />
                                        </svg>
                                        <span> Form Packs</span>
                                    </a>
                                </LinkContainer>
                            </li>
                            : '')}
                        {(userTypeForRoleManagement !== 'Approver' && userTypeForRoleManagement !== 'Operator' && userTypeForRoleManagement !== 'Operator/Approver' ?
                            <li>
                                <a href={null} className="CursorPointer" data-toggle="modal" onClick={(event) => {
                                    props.history.push({
                                        pathname: '/dashboard',
                                        state: { modalOpen: true, createMode: true }
                                    })
                                }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 18">
                                        <path id="ic_business_24px" className="cls-1" d="M12,7V3H2V21H22V7ZM6,19H4V17H6Zm0-4H4V13H6Zm0-4H4V9H6ZM6,7H4V5H6Zm4,12H8V17h2Zm0-4H8V13h2Zm0-4H8V9h2Zm0-4H8V5h2ZM20,19H12V17h2V15H12V13h2V11H12V9h8Zm-2-8H16v2h2Zm0,4H16v2h2Z" transform="translate(-2 -3)" />
                                    </svg>
                                    <span> Company</span>
                                </a>
                            </li>
                            : '')}
                        {(userTypeForRoleManagement !== 'Approver' && userTypeForRoleManagement !== 'Operator' && userTypeForRoleManagement !== 'Operator/Approver' ?
                            <li>
                                <a className="CursorPointer" href={null} data-toggle="modal" onClick={(event) => {
                                    props.history.push({
                                        pathname: '/dashboard',
                                        state: { projectModalOpen: true, createMode: true }
                                    })
                                }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 8.967">
                                        <path id="ic_all_inclusive_24px" className="cls-1" d="M15.5,6.62a4.443,4.443,0,0,0-3.142,1.275L10,9.987,8.733,11.1h.008L6.5,13.1a2.822,2.822,0,1,1-2-4.808,2.833,2.833,0,0,1,2.033.858l.942.833L8.733,8.862l-1.05-.925a4.483,4.483,0,1,0-.042,6.375L10,12.228l.008.008L11.267,11.1h-.008L13.5,9.112a2.822,2.822,0,1,1,2,4.808,2.847,2.847,0,0,1-2.033-.858l-.95-.842-1.258,1.117,1.058.933A4.491,4.491,0,1,0,15.5,6.62Z" transform="translate(0 -6.62)" />
                                    </svg>
                                    <span> Project</span>
                                </a>
                            </li>
                            : '')}
                        {(userTypeForRoleManagement !== 'Approver' && userTypeForRoleManagement !== 'Operator' && userTypeForRoleManagement !== 'Operator/Approver' ?
                            <li>
                                <LinkContainer to="/dashboard/users">
                                    <a href={null}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                            <path id="ic_person_24px" className="cls-1" d="M14,14A5,5,0,1,0,9,9,5,5,0,0,0,14,14Zm0,2.5c-3.337,0-10,1.675-10,5V24H24V21.5C24,18.175,17.337,16.5,14,16.5Z" transform="translate(-4 -4)" />
                                        </svg>
                                        <span> Users</span>
                                    </a>
                                </LinkContainer>
                            </li>
                            : '')
                        }
                        {(userTypeForRoleManagement !== 'Approver' && userTypeForRoleManagement !== 'Operator' && userTypeForRoleManagement !== 'Operator/Approver' ?
                            <li>
                                <LinkContainer to="/dashboard/approval-setup">
                                    <a href={null} className="CursorPointer">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 14">
                                            <path id="ic_supervisor_account_24px" className="cls-1" d="M16.5,12A2.5,2.5,0,1,0,14,9.5,2.492,2.492,0,0,0,16.5,12ZM9,11A3,3,0,1,0,6,8,2.987,2.987,0,0,0,9,11Zm7.5,3c-1.83,0-5.5.92-5.5,2.75V19H22V16.75C22,14.92,18.33,14,16.5,14ZM9,13c-2.33,0-7,1.17-7,3.5V19H9V16.75a3.941,3.941,0,0,1,2.37-3.47A12.283,12.283,0,0,0,9,13Z" transform="translate(-2 -5)" />
                                        </svg>
                                        <span> Approval Setup</span>
                                    </a>
                                </LinkContainer>
                            </li>
                            : '')}
                        {(userTypeForRoleManagement !== 'Approver' && userTypeForRoleManagement !== 'Operator' && userTypeForRoleManagement !== 'Operator/Approver' ? <li>
                            <LinkContainer to="/dashboard/email-templates">
                                <a href={null}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 16">
                                        <path id="ic_markunread_24px" className="cls-1" d="M20,4H4A2,2,0,0,0,2.01,6L2,18a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V6A2.006,2.006,0,0,0,20,4Zm0,4-8,5L4,8V6l8,5,8-5Z" transform="translate(-2 -4)" />
                                    </svg>
                                    <span> Email Templates</span>
                                </a>
                            </LinkContainer>
                        </li> : '')}
                        {(userTypeForRoleManagement !== 'Approver' && userTypeForRoleManagement !== 'Operator' && userTypeForRoleManagement !== 'Operator/Approver' ?
                            <li>
                                <LinkContainer to="/dashboard/custom-fields">
                                    <a href={null}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 21.818">
                                            <path id="ic_pan_tool_24px" className="cls-1" d="M21,5V18.182a3.647,3.647,0,0,1-3.636,3.636H10.727a3.63,3.63,0,0,1-2.591-1.082L1,13.482s1.145-1.118,1.182-1.136a1.077,1.077,0,0,1,.718-.264,1.111,1.111,0,0,1,.545.145c.036.009,3.918,2.236,3.918,2.236V3.636a1.364,1.364,0,1,1,2.727,0V10H11V1.364a1.364,1.364,0,0,1,2.727,0V10h.909V2.273a1.364,1.364,0,0,1,2.727,0V10h.909V5A1.364,1.364,0,0,1,21,5Z" transform="translate(-1)" />
                                        </svg>
                                        <span> Custom Fields</span>
                                    </a>
                                </LinkContainer>
                            </li>
                            : '')}
                        {(userTypeForRoleManagement !== 'Approver' && userTypeForRoleManagement !== 'Operator' && userTypeForRoleManagement !== 'Operator/Approver' ?
                            <li>
                                <LinkContainer to="/dashboard/custom-fields-list">
                                    <a href="{null}">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 13.333">
                                            <path id="ic_sort_24px" className="cls-1" d="M3,19.333H9.667V17.111H3ZM3,6V8.222H23V6Zm0,7.778H16.333V11.556H3Z" transform="translate(-3 -6)" />
                                        </svg>
                                        <span> Custom List Fields</span>
                                    </a>
                                </LinkContainer>
                            </li>
                            : '')}
                    </ul>
                </div>
            </div>
            <div id="mobile_menu_sm" className="displayNoneimp">
                <div id="sidebar-wrapper">
                    <ul className="sidebar-nav">
                        <li className="sidebar_active">
                            <LinkContainer to="/dashboard">
                                <a href={null}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5378 759 13.333 11.333">
                                        <path id="ic_home_24px" className="cls-1" d="M7.333,14.333v-4H10v4h3.333V9h2L8.667,3,2,9H4v5.333Z" transform="translate(-5380 756)" />
                                    </svg>
                                    <span> Home </span>
                                </a>
                            </LinkContainer>
                        </li>
                        <li>
                            <LinkContainer to="/dashboard/documents">
                                <a href={null}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5378 806 13.333 10.909">
                                        <defs>
                                        </defs>
                                        <path id="ic_picture_in_picture_alt_24px" className="cls-1" d="M11.909,7.848H7.061v3.636h4.848ZM14.333,12.7V4.2A1.205,1.205,0,0,0,13.121,3H2.212A1.205,1.205,0,0,0,1,4.2v8.5a1.216,1.216,0,0,0,1.212,1.212H13.121A1.216,1.216,0,0,0,14.333,12.7Zm-1.212.012H2.212V4.194H13.121Z" transform="translate(-5379 803)" />
                                    </svg>
                                    <span> Documents </span>
                                </a>
                            </LinkContainer>
                        </li>
                        <li>
                            <LinkContainer to="/dashboard/report">
                                <a href={null}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5378 850 13.333 14.074">
                                        <defs>
                                        </defs>
                                        <path id="ic_location_city_24px" className="cls-1" d="M11.889,8.667V4.222L9.667,2,7.444,4.222V5.7H3v10.37H16.333V8.667ZM5.963,14.593H4.481V13.111H5.963Zm0-2.963H4.481V10.148H5.963Zm0-2.963H4.481V7.185H5.963Zm4.444,5.926H8.926V13.111h1.481Zm0-2.963H8.926V10.148h1.481Zm0-2.963H8.926V7.185h1.481Zm0-2.963H8.926V4.222h1.481Zm4.444,8.889H13.37V13.111h1.481Zm0-2.963H13.37V10.148h1.481Z" transform="translate(-5381 848)" />
                                    </svg>
                                    <span> Report</span>
                                </a>
                            </LinkContainer>
                        </li>
                        <li>
                            <a href={null}>
                                <strong> Admin </strong> </a>
                        </li>
                        {userTypeForRoleManagement !== 'Admin' && userTypeForRoleManagement !== 'Approver' && userTypeForRoleManagement !== 'Operator' && userTypeForRoleManagement !== 'Operator/Approver' ?
                            <li>
                                <LinkContainer to="/dashboard/form-template">
                                    <a href={null} className="CursorPointer">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5378 945 13.333 13.707">
                                            <defs>
                                            </defs>
                                            <path id="ic_settings_24px" className="cls-1" d="M14.031,9.525a4.726,4.726,0,0,0,0-1.343l1.446-1.131a.346.346,0,0,0,.082-.439L14.189,4.241a.344.344,0,0,0-.418-.151l-1.707.685A5.007,5.007,0,0,0,10.906,4.1l-.26-1.816A.334.334,0,0,0,10.31,2H7.568a.334.334,0,0,0-.336.288L6.972,4.1a5.266,5.266,0,0,0-1.158.672L4.107,4.09a.334.334,0,0,0-.418.151L2.318,6.613a.338.338,0,0,0,.082.439L3.847,8.182a5.435,5.435,0,0,0-.048.672,5.435,5.435,0,0,0,.048.672L2.4,10.656a.346.346,0,0,0-.082.439l1.371,2.371a.344.344,0,0,0,.418.151l1.707-.685a5.007,5.007,0,0,0,1.158.672l.26,1.816a.334.334,0,0,0,.336.288H10.31a.334.334,0,0,0,.336-.288l.26-1.816a5.266,5.266,0,0,0,1.158-.672l1.707.685a.334.334,0,0,0,.418-.151l1.371-2.371a.346.346,0,0,0-.082-.439ZM8.939,11.252a2.4,2.4,0,1,1,2.4-2.4A2.4,2.4,0,0,1,8.939,11.252Z" transform="translate(-5380.271 943)" />
                                        </svg>
                                        <span> Form Templates</span>
                                    </a>
                                </LinkContainer>
                            </li>
                            : ''}
                        {(userTypeForRoleManagement !== 'Approver' && userTypeForRoleManagement !== 'Operator' && userTypeForRoleManagement !== 'Operator/Approver' ?
                            <li>
                                <LinkContainer to="/dashboard/form-packs">
                                    <a href={null} className="CursorPointer">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-4935 7662 20 20">
                                            <defs>
                                            </defs>
                                            <path id="ic_mode_edit_24px" className="cls-1" d="M3,18.831V23H7.166L19.453,10.71,15.287,6.544ZM22.675,7.489a1.106,1.106,0,0,0,0-1.566l-2.6-2.6a1.106,1.106,0,0,0-1.566,0L16.476,5.356l4.166,4.166Z" transform="translate(-4938 7659.002)" />
                                        </svg>
                                        <span> Form Packs</span>
                                    </a>
                                </LinkContainer>
                            </li>
                            : '')}
                        {(userTypeForRoleManagement !== 'Approver' && userTypeForRoleManagement !== 'Operator' && userTypeForRoleManagement !== 'Operator/Approver' ?
                            <li>
                                <a href={null} className="CursorPointer" data-toggle="modal" onClick={(event) => {
                                    props.history.push({
                                        pathname: '/dashboard',
                                        state: { modalOpen: true, createMode: true }
                                    })
                                }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-10829 7335 20 20">
                                        <defs>
                                        </defs>
                                        <path id="ic_info_outline_24px" className="cls-1" d="M11,17h2V11H11ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.011,8.011,0,0,1,12,20ZM11,9h2V7H11Z" transform="translate(-10831 7333)" />
                                    </svg>
                                    <span> Company</span>
                                </a>
                            </li>
                            : '')}
                        {(userTypeForRoleManagement !== 'Approver' && userTypeForRoleManagement !== 'Operator' && userTypeForRoleManagement !== 'Operator/Approver' ?
                            <li>

                                <a href={null} className="CursorPointer" onClick={(event) => {
                                    props.history.push({
                                        pathname: '/dashboard',
                                        state: { projectModalOpen: true, createMode: true }
                                    })
                                }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5378 850 13.333 14.074">
                                        <defs>
                                        </defs>
                                        <path id="ic_location_city_24px" className="cls-1" d="M11.889,8.667V4.222L9.667,2,7.444,4.222V5.7H3v10.37H16.333V8.667ZM5.963,14.593H4.481V13.111H5.963Zm0-2.963H4.481V10.148H5.963Zm0-2.963H4.481V7.185H5.963Zm4.444,5.926H8.926V13.111h1.481Zm0-2.963H8.926V10.148h1.481Zm0-2.963H8.926V7.185h1.481Zm0-2.963H8.926V4.222h1.481Zm4.444,8.889H13.37V13.111h1.481Zm0-2.963H13.37V10.148h1.481Z" transform="translate(-5381 848)" />
                                    </svg>
                                    <span> Project</span>
                                </a>
                            </li>
                            : '')}
                        {(userTypeForRoleManagement !== 'Approver' && userTypeForRoleManagement !== 'Operator' && userTypeForRoleManagement !== 'Operator/Approver' ?
                            <li>
                                <LinkContainer to="/dashboard/users">
                                    <a href={null}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                            <path id="ic_person_24px" className="cls-1" d="M14,14A5,5,0,1,0,9,9,5,5,0,0,0,14,14Zm0,2.5c-3.337,0-10,1.675-10,5V24H24V21.5C24,18.175,17.337,16.5,14,16.5Z" transform="translate(-4 -4)" />
                                        </svg>
                                        <span> Users</span>
                                    </a>
                                </LinkContainer>
                            </li>
                            : '')}
                        {(userTypeForRoleManagement !== 'Approver' && userTypeForRoleManagement !== 'Operator' && userTypeForRoleManagement !== 'Operator/Approver' ?
                            <li>
                                <LinkContainer to="/dashboard/approval-setup">
                                    <a href={null} className="CursorPointer">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5378 850 13.333 14.074">
                                            <defs>
                                            </defs>
                                            <path id="ic_location_city_24px" className="cls-1" d="M11.889,8.667V4.222L9.667,2,7.444,4.222V5.7H3v10.37H16.333V8.667ZM5.963,14.593H4.481V13.111H5.963Zm0-2.963H4.481V10.148H5.963Zm0-2.963H4.481V7.185H5.963Zm4.444,5.926H8.926V13.111h1.481Zm0-2.963H8.926V10.148h1.481Zm0-2.963H8.926V7.185h1.481Zm0-2.963H8.926V4.222h1.481Zm4.444,8.889H13.37V13.111h1.481Zm0-2.963H13.37V10.148h1.481Z" transform="translate(-5381 848)" />
                                        </svg>
                                        <span> Approval Setup</span>
                                    </a>
                                </LinkContainer>
                            </li>
                            : '')}
                        <li>
                            <LinkContainer to="/dashboard/email-templates">
                                <a href={null}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5378 850 13.333 14.074">
                                        <defs>
                                        </defs>
                                        <path id="ic_location_city_24px" className="cls-1" d="M11.889,8.667V4.222L9.667,2,7.444,4.222V5.7H3v10.37H16.333V8.667ZM5.963,14.593H4.481V13.111H5.963Zm0-2.963H4.481V10.148H5.963Zm0-2.963H4.481V7.185H5.963Zm4.444,5.926H8.926V13.111h1.481Zm0-2.963H8.926V10.148h1.481Zm0-2.963H8.926V7.185h1.481Zm0-2.963H8.926V4.222h1.481Zm4.444,8.889H13.37V13.111h1.481Zm0-2.963H13.37V10.148h1.481Z" transform="translate(-5381 848)" />
                                    </svg>
                                    <span> Email Templates</span>
                                </a>
                            </LinkContainer>
                        </li>
                        {(userTypeForRoleManagement !== 'Approver' && userTypeForRoleManagement !== 'Operator' && userTypeForRoleManagement !== 'Operator/Approver' ?
                            <li>
                                <LinkContainer to="/dashboard/custom-fields">
                                    <a href={null}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5378 850 13.333 14.074">
                                            <defs>
                                            </defs>
                                            <path id="ic_location_city_24px" className="cls-1" d="M11.889,8.667V4.222L9.667,2,7.444,4.222V5.7H3v10.37H16.333V8.667ZM5.963,14.593H4.481V13.111H5.963Zm0-2.963H4.481V10.148H5.963Zm0-2.963H4.481V7.185H5.963Zm4.444,5.926H8.926V13.111h1.481Zm0-2.963H8.926V10.148h1.481Zm0-2.963H8.926V7.185h1.481Zm0-2.963H8.926V4.222h1.481Zm4.444,8.889H13.37V13.111h1.481Zm0-2.963H13.37V10.148h1.481Z" transform="translate(-5381 848)" />
                                        </svg>
                                        <span> Custom Fields</span>
                                    </a>
                                </LinkContainer>
                            </li>
                            : '')}
                        {(userTypeForRoleManagement !== 'Approver' && userTypeForRoleManagement !== 'Operator' && userTypeForRoleManagement !== 'Operator/Approver' ?
                            <li>
                                <LinkContainer to="/dashboard/custom-fields-list">
                                    <a href="{null}">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5378 850 13.333 14.074">
                                            <defs>
                                            </defs>
                                            <path id="ic_location_city_24px" className="cls-1" d="M11.889,8.667V4.222L9.667,2,7.444,4.222V5.7H3v10.37H16.333V8.667ZM5.963,14.593H4.481V13.111H5.963Zm0-2.963H4.481V10.148H5.963Zm0-2.963H4.481V7.185H5.963Zm4.444,5.926H8.926V13.111h1.481Zm0-2.963H8.926V10.148h1.481Zm0-2.963H8.926V7.185h1.481Zm0-2.963H8.926V4.222h1.481Zm4.444,8.889H13.37V13.111h1.481Zm0-2.963H13.37V10.148h1.481Z" transform="translate(-5381 848)" />
                                        </svg>
                                        <span> Custom List Fields</span>
                                    </a>
                                </LinkContainer>
                            </li>
                            : '')}
                    </ul>
                </div>
            </div>
            <div className="clearfix"></div>
        </div>
    )
}

export default SideBar