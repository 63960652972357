import React, { Component } from "react";
import Header from "../Common/Header/Header";
import SignaturePad from "./index";
import "./SignSettings.css";
import { toast } from "react-toastify";
import $ from "jquery";
//import "./custom.css";

//var SignaturePad = require('react-signature-pad');

class SignSettings extends Component {
	constructor(props) {
		super();
		this.file = null;
		this.state = {
			isLoading: false,
			isSaving: false,
			SignatureImage: "",
			SignatureImageTyped: "",
			SignatureText: "",
			SignatureTyped: "",
			DisplayPad: "block",
			SignatureInput: "block",
			CurrentTab: "Draw",
			SignaturePad: "",
			filename: "",
			email: "",
		};
	}

	async componentDidMount() {
		try {
			var response = await this.props.postRequest(
				"/attachments/get-signature",
				{
					CompanyGuid: localStorage.getItem("companyGuid"),
					CompanyId: localStorage.getItem("companyID"),
					tenantGuid: localStorage.getItem("currentTenantGuid"),
				}
			);
			if (response.status && (response.result.signature || response.result.typed_signature )) {
				this.setState({
					isLoading: false,
					SignatureImage: response.result.signature ?? "",
					DisplayPad: "none",
					SignatureTyped: response.result.typed_signature ?? "",
					SignatureInput: "none",
				});
			}
			console.log("/attachments/get-signature=>", response);
		} catch (err) {
			console.log("/attachments/get-signature error=>", err);
		}
	}

	handleChange = (event) => {
		this.setState({
			[event.target.id]: event.target.value,
		});
	};

	clearSignature = async (event) => {
		if (this.state.CurrentTab === "Draw") {
			this.setState({ SignatureImage: "", DisplayPad: "block" });
			const signature = this.refs.Signature;
			signature.clear();
		}

		if (this.state.CurrentTab === "Typed") {
			this.setState({
				SignatureTyped: "",
				SignatureText: "",
				SignatureInput: "block",
			});
		}
	};

	saveSignature = async (event) => {
		this.setState({ isLoading: true });
		if (this.state.CurrentTab === "Draw") {
			const signature = this.refs.Signature;
			let email = this.state.email;
			if (signature.isEmpty()) {
				console.log("remove signatur");
				try {
					var rem_response = await this.props.postRequest(
						"/attachments/upload-signature",
						{}
					);
					if (rem_response.status) {
						toast.success("Signature successfully Updated.", {
							autoClose: 5000,
						});
						this.setState({
							isLoading: false,
							SignatureImage: "",
							DisplayPad: "block",
						});
						this.props.history.goBack();
					} else {
						this.setState({ isLoading: false });
						console.log("upload signature rem_response=>", rem_response);
						toast.error("Signature update failed", { autoClose: 5000 });
					}
				} catch (err) {
					this.setState({ isLoading: false });
					console.log("upload signature rem_response Error=>", err);
					toast.error(err.message, { autoClose: 5000 });
				}
			} else {
				const base64 = signature.toDataURL("image/jpeg");

				try {
					var response = await this.props.postRequest(
						"/attachments/upload-signature",
						{
							base64Data: base64,
							companyGuid: localStorage.getItem("companyGuid"),
							tenantGuid: localStorage.getItem("currentTenantGuid"),
						}
					);
					if (response.status) {
						console.log("/attachments/upload-signature=>", response);
						toast.success("Signature successfully Updated.", {
							autoClose: 5000,
						});
						this.setState({
							isLoading: false,
							SignatureImage: base64,
							DisplayPad: "none",
						});

						this.props.history.goBack();
					} else {
						this.setState({ isLoading: false });
						console.log("upload signature response=>", response);
						toast.error("Signature update failed", { autoClose: 5000 });
					}
				} catch (err) {
					this.setState({ isLoading: false });
					console.log("upload signature Error=>", err);
					toast.error(err.message, { autoClose: 5000 });
				}
			}
		}

		if (this.state.CurrentTab === "Typed") {
			let SignatureText = this.state.SignatureText;
			if (SignatureText) {
				try {
					var img_response = await this.props.postRequest("/text-to-image", {
						SignatureText: SignatureText,
					});
					if (img_response.status) {
						const base64Image = img_response.base64Image;
						console.log("base64Image=>", base64Image);
						var typed_response = await this.props.postRequest(
							"/attachments/upload-signature",
							{
								base64Data: base64Image,
								Typed: true,
								companyGuid: localStorage.getItem("companyGuid"),
								tenantGuid: localStorage.getItem("currentTenantGuid"),
							}
						);
						if (typed_response.status) {
							toast.success("Signature successfully Updated.", {
								autoClose: 5000,
							});
							this.setState({
								isLoading: false,
								SignatureTyped: base64Image,
								SignatureInput: "none",
							});

							this.props.history.goBack();
						} else {
							this.setState({ isLoading: false });
							console.log("upload typed signature response=>", response);
							toast.error("Signature update failed", { autoClose: 5000 });
						}
					} else {
						this.setState({ isLoading: false });
						console.log("html to image response=>", typed_response);
						toast.error("Signature update failed", { autoClose: 5000 });
					}
				} catch (err) {
					this.setState({ isLoading: false });
					console.log("upload signature Error=>", err);
					toast.error(err.message, { autoClose: 5000 });
				}
			} else {
				console.log("remove typed signatur");
				var rem_response = await this.props.postRequest(
					"/attachments/upload-signature",
					{ Typed: true }
				);
				if (rem_response.status) {
					toast.success("Signature successfully Updated.", { autoClose: 5000 });
					this.setState({
						isLoading: false,
						SignatureTyped: "",
						SignatureInput: "block",
					});

					this.props.history.goBack();
				} else {
					this.setState({ isLoading: false });
					console.log("upload signature rem_response=>", rem_response);
					toast.error("Signature update failed", { autoClose: 5000 });
				}
			}
		}
		
	};

	handleTab = (element) => async (event) => {
		event.preventDefault();
		this.setState({ CurrentTab: element });
	};

	handleImageError = async (event) => {
		this.clearSignature();
		//this.setState({ SignatureImage: "", DisplayPad: "block" });
	};

	handleImageTypeError = async (event) => {
		this.setState({
			SignatureTyped: "",
			SignatureText: "",
			SignatureInput: "block",
		});
		//this.setState({ SignatureTyped: "", SignatureInput: "block" });
	};

	render() {
		return (
			<div className="SignSettings">
				<Header
					pageHeading=""
					props={this.props}
					isLoading={this.state.isLoading}
				/>
				<div className="clearfix"></div>
				<div className="col-xs-12 col-sm-9 col-md-10 pull-right p0 top_mrg_general top_mrg_general_custom">
					<div className="">
						<div className="col-sm-12 col-md-8 col-md-offset-2 general_setting_hd">
							<div className=" mrg_dashboard_right_s_s">
								<div className="">
									<div className="col-xs-12 col-sm-12 mrg_left_sign">
										<div className="tabbable-panel sign-tabbable-panel">
											<div className="tabbable-line_ss">
												<ul className="nav nav-tabs ss_nav_tabs">
													<li className="">
														<a
															href="#tab_default_1"
															data-toggle="tab"
															className="text_right_tab1"
															onClick={this.handleTab("Typed")}
														>
															<svg
																xmlns="http://www.w3.org/2000/svg"
																viewBox="-2112 7612 20 14"
															>
																<path
																	id="ic_keyboard_24px"
																	className="cls-1"
																	d="M20,5H4A2,2,0,0,0,2.01,7L2,17a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V7A2.006,2.006,0,0,0,20,5ZM11,8h2v2H11Zm0,3h2v2H11ZM8,8h2v2H8Zm0,3h2v2H8ZM7,13H5V11H7Zm0-3H5V8H7Zm9,7H8V15h8Zm0-4H14V11h2Zm0-3H14V8h2Zm3,3H17V11h2Zm0-3H17V8h2Z"
																	transform="translate(-2114 7607)"
																/>
															</svg>
															&nbsp;&nbsp; Type
														</a>
													</li>
													<li className="svg_down_pencil active">
														<a
															href="#tab_default_2"
															data-toggle="tab"
															className="text_left_tab2"
															onClick={this.handleTab("Draw")}
														>
															<svg
																xmlns="http://www.w3.org/2000/svg"
																viewBox="-3800 7611 15 15"
															>
																<path
																	id="ic_mode_edit_24px"
																	className="cls-1"
																	d="M3,14.873V18H6.125L15.34,8.782,12.215,5.658ZM17.756,6.366a.83.83,0,0,0,0-1.175l-1.95-1.95a.83.83,0,0,0-1.175,0L13.107,4.766l3.125,3.125Z"
																	transform="translate(-3803 7608.002)"
																/>
															</svg>
															&nbsp;&nbsp; Draw
														</a>
													</li>
												</ul>
												<div className="tab-content tab-content-signsetting">
													<div
														className="tab-pane ss_tab_pane"
														id="tab_default_1"
													>
														<p></p>
														<img
															src={this.state.SignatureTyped}
															onError={this.handleImageTypeError}
														/>
														<p style={{ display: this.state.SignatureInput }}>
															<input
																name="SignatureText"
																id="SignatureText"
																className=""
																value={this.state.SignatureText}
																onChange={this.handleChange}
																type="text"
																placeholder="Type Here"
																defaultValue=""
															/>
														</p>
														<p></p>
													</div>
													<div
														className="tab-pane ss_tab_pane active"
														id="tab_default_2"
													>
														<p></p>

														<SignaturePad
															SignaturePad={this.state}
															ref="Signature"
														/>
														<p>
															<img
																className="img-responsive"
																src={this.state.SignatureImage}
																alt=""
																onError={this.handleImageError}
															/>
														</p>

														<p></p>
													</div>
													<div className="clearfix"></div>
													<div className="btn_cance_save mL50px mT20px">
														<button
															name=""
															className="btn_save_pro"
															value="Save"
															type="button"
															onClick={this.saveSignature}
														>
															Save
														</button>
														<button
															name=""
															className="btn_cancel_pro"
															value="Clear"
															type="button"
															onClick={this.clearSignature}
														>
															Clear
														</button>
													</div>
													<div className="clear20"></div>
													<div>
														<canvas
															id="myCanvas"
															height="40"
															style={{
																border: "1px solid #d3d3d3",
																display: "none",
															}}
														>
															<img
																id="image"
																alt="Signature File"
																style={{ display: "none" }}
															/>
														</canvas>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="clear40"></div>
							</div>
						</div>
						<div className="clear10"></div>
					</div>
				</div>
				<div className="clear10"></div>
			</div>
		);
	}
}
export default SignSettings;
