
/* Get the current year for the copy right */
export const getCurrentYearForCopyRight = () => new Date().getFullYear();

/**
 * Exclude duplicates from an array of objects based on a key
 * @param {*} arr array of objects
 * @param {*} key key to compare between the objects of the array 
 * @returns an array of distinct objects
 * 
 */
export const getUniqueListBy = (arr, key) => [...new Map(arr.map(item => [item[key], item])).values()];
