import React from 'react'
import DashboardRoutes from '../../DashboardRoutes'
import SideBar from '../SideBar/SideBar'

const DashboardContainer = (childProps) => {
    return (
        <div className='dashboard-container'>
            <SideBar props={childProps} />
        </div>
    )
}

export default DashboardContainer