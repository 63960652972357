import React from "react";
import { Switch } from "react-router-dom";
import AppliedRoute from "./components/AppliedRoute/AppliedRoute";
import AddEmailTemplates from "./containers/AddEmailTemplates/AddEmailTemplates";
import Dashboard from "./containers/Dashboard/Dashboard";
import Report from "./containers/Report/Report";
import Pdf from "./containers/Report/Pdf";
import PdfDetail from "./containers/Report/PdfDetail";
import FormTemplate from "./containers/FormTemplate/FormTemplate";
import FormPacks from "./containers/FormPacks/FormPacks";
import FormPacksPdf from "./containers/FormPacks/Pdf1";
import ApprovalSetup from "./containers/ApprovalSetup/ApprovalSetup";
import CustomFields from "./containers/CustomFields/CustomFields";
import CustomFieldsList from "./containers/CustomFieldsList/CustomFieldsList";
import FormTemplateDetail from "./containers/FormTemplateDetail/FormTemplateDetail";
import SignSettings from "./containers/SignSettings/SignSettings";
import GeneralSettings from "./containers/GeneralSettings/GeneralSettings";
import Help from "./containers/Help/Help";
import Users from "./containers/Users/Users";
import EmailTemplates from "./containers/EmailTemplates/EmailTemplates";
import CompanyDetail from "./containers/CompanyDetail/CompanyDetail";
import InsertDocument from "./containers/InsertDocument/InsertDocument";
import Documents from "./containers/Documents/Documents";
import CompanyEdit from "./containers/CompanyEdit/CompanyEdit";
import CompanyView from "./containers/CompanyView/CompanyView";
import AddProject from "./containers/AddProject/AddProject";
import EditProject from "./containers/EditProject/EditProject";
import header from "./containers/Common/Header/Header";
import NotFound from "./containers/NotFound/NotFound";

export default ({ childProps }) =>
  <Switch>
    <AppliedRoute path="/dashboard/header" exact component={header} props={childProps} />
    <AppliedRoute path="/dashboard" exact component={Dashboard} props={childProps} />
    <AppliedRoute path="/dashboard/report" exact component={Report} props={childProps} />
    <AppliedRoute path="/report/print" exact component={Pdf} props={childProps} />
    <AppliedRoute path="/report/print_detail" exact component={PdfDetail} props={childProps} />
    <AppliedRoute path="/dashboard/form-template" exact component={FormTemplate} props={childProps} />
    <AppliedRoute path="/dashboard/form-packs" exact component={FormPacks} props={childProps} />
    <AppliedRoute path="/formpacks/print" exact component={FormPacksPdf} props={childProps} />
    <AppliedRoute path="/dashboard/approval-setup" exact component={ApprovalSetup} props={childProps} />
    <AppliedRoute path="/dashboard/custom-fields" exact component={CustomFields} props={childProps} />
    <AppliedRoute path="/dashboard/custom-fields-list" exact component={CustomFieldsList} props={childProps} />
    <AppliedRoute path="/dashboard/form-template-detail" exact component={FormTemplateDetail} props={childProps} />
    <AppliedRoute path="/dashboard/signature-settings" exact component={SignSettings} props={childProps} />
    <AppliedRoute path="/dashboard/general_settings" exact component={GeneralSettings} props={childProps} />
    <AppliedRoute path="/dashboard/help" exact component={Help} props={childProps} />
    <AppliedRoute path="/dashboard/users" exact component={Users} props={childProps} />
    <AppliedRoute path="/dashboard/email-templates" exact component={EmailTemplates} props={childProps} />
    <AppliedRoute path="/dashboard/company-detail" exact component={CompanyDetail} props={childProps} />
    <AppliedRoute path="/dashboard/insert-document" exact component={InsertDocument} props={childProps} />
    <AppliedRoute path="/dashboard/documents" exact component={Documents} props={childProps} />
    <AppliedRoute path="/dashboard/edit-company/:company_id" exact component={CompanyEdit} props={childProps} />
    <AppliedRoute path="/dashboard/view-company/:company_id" exact component={CompanyView} props={childProps} />
    <AppliedRoute path="/dashboard/add-project" exact component={AddProject} props={childProps} />
    <AppliedRoute path="/dashboard/edit-project/:id" exact component={EditProject} props={childProps} />
    <AppliedRoute path="/dashboard/add-email-templates" exact component={AddEmailTemplates} props={childProps} />
    {window.location.pathname != '/' && <AppliedRoute path="*" exact={true} component={NotFound} />}
  </Switch>;
