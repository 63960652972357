import React, { Component } from "react";
import "./InsertDocument.css";
import Header from "../Common/Header/Header";
import { Auth, API, Storage } from "aws-amplify";
import { toast } from "react-toastify";
import ic_clear_24px from "../../assets/images/ic_clear_24px@2x.png";
import arrow_back_from from "../../assets/images/arrow_back_from.png";
import { LinkContainer } from "react-router-bootstrap";
import TextInput from "./Components/TextInput";
import CustomField from "./Components/CustomField";
import CustomCodeList from "./Components/CustomCodeList";
import ListInput from "./Components/ListInput";
import InputMask from "react-input-mask";
import moment from "moment";
import "jquery-ui-bundle";
import "jquery-ui-bundle/jquery-ui.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
//import ip from 'ip';
import axios from "axios";
import DatePicker from "react-datepicker";

// - -   jQUARY INSTANCE  - -//
// - - - - - - - - - - - - - //
import $ from "jquery";
window.jQuery = $;
window.$ = $;
global.jQuery = $;
//const publicIp = require("react-public-ip");
// - -  React Modal   - -//
// - - - - - - - - - - - - - //
const showDateTo = () => {
  document.getElementById("showDateTo").click();
};
const showDateFrom = () => {
  document.getElementById("showDateFrom").click();
};
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

// const grid = 10;

const getItemStyle = (isDragging, draggableStyle) => ({
  cursor: "all-scroll",
  cursor: isDragging ? "all-scroll" : "all-scroll",
  margin: isDragging ? "-25px 10px 5px -400px" : "",
  // marginTop:isDragging?"-80px":"",
  // background: isDragging ? "lightgreen" : "grey",

  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  cursor: "all-scroll",
  cursor: isDraggingOver ? "all-scroll" : "all-scroll",
});

var Modal = require("react-bootstrap-modal");

require("react-bootstrap-modal/lib/css/rbm-patch.css");

class InsertDocument extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      setup_data: {},
      setup_chartSort: "",
      setup_chartCode: "",
      trackingList: [],
      FileAttachments: [],
      FileAttachments_urls: [],

      //___ Modal Status ___//
      //____________________//
      signByFormModalOpen: false,
      customListCodeModalOpen: false,
      disableDatePicker: false,
      //----------- Arrays --------//
      //--------------------------//
      generalFormPack: [],
      generalProjectList: [],
      digitalForms: [],
      ApprovalChain: [],
      Tags: [],

      //_______ General TAB ______//
      //__________________________//

      generalFirstName: "",
      generalLastName: "",
      generalEmail: "",
      generalDepartment: "",
      generalPosition: "",
      generalDescription: "",
      generalFormPackGuid: "",
      generalProjectGuid: "",
      generalProjectName: "",
      generalCountryCode: "",
      generalMobile: "",
      approverGroupID: "",
      AppGroup: "",
      packname: "",
      Rguid_user: null,
      recordlink: false,
      dfdocsCreated: false,
      sendLinkGuid: "",
      //_____ Flags ____//
      //________________//
      generalFirstNameValid: false,
      generalLastNameValid: false,
      generalEmailValid: false,
      generalDepartmentValid: false,
      generalPositionValid: false,
      generalDescriptionValid: false,
      generalFormPackGuidValid: false,
      generalProjectGuidValid: false,
      generalCountryCodeValid: false,
      generalMobileValid: false,
      generalFormValid: false,
      signByFirstNameValid: false,
      signByLastNameValid: false,
      signByEmailValid: false,
      signByMobileValid: false,
      signByCountryCodeValid: false,
      signByPositionValid: false,
      signBySequenceValid: false,
      signByTable: [],
      isRender: false,
      signByFirstName: "",
      signByLastName: "",
      signByEmail: "",
      signByMobile: "",
      signByCountryCode: "",
      signByPosition: "",
      signBySequence: "",
      signByUpdating: false,
      signByUpdatingIndex: "",
      addFirstSignByFirstname: "",
      addFirstSignByLastname: "",
      addFirstSignByEmail: "",
      addFirstSignByMobile: "",
      //____MOazam___//
      customfields: [],
      CreatedTS: "",
      temp_guid: "",
      //_____ Errors _____//
      //__________________//
      generalFormErrors: {
        firstName: "",
        lastName: "",
        email: "",
        department: "",
        position: "",
        description: "",
        generalFormpackGuid: "",
        projectGuid: "",
        countryCode: "",
        mobile: "",
        signByFirstName: "",
        signByLastName: "",
        signByEmail: "",
        signByMobile: "",
        signByCountryCode: "",
        signByPosition: "",
        signBySequence: "",
        generalSettings: "",
        approvalGroupTitle: "",
      },
      customFormErrors: {},
      EmailTemplates: [],
      placeHolderArray: [],
      CustomListHeader: "",
      oldDFDocs: [],
      documents: [],
      oldDFDocsDel: false,
      ipv4: null,
      TBA: null,
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }
  onDragEnd(result) {
    var unit_order;
    var unit_desc_order;
    var rate_order;
    var factor_order;
    var amount_order;
    var capped_amount_order;
    var days_in_week_order;
    var startDate_order;
    var endDate_order;
    var chart_sort_order;
    var chart_code_order;
    var flag_order;
    var payment_flag_order;
    var calc_days_order;
    var calc_weeks_order;
    if (!result.destination) {
      return;
    }
    var Documents = this.state.DFDocs[this.state.documentIndex];
    var Customlist = Documents.CustomList[this.state.rowIndex];
    var items;
    if (
      result.destination.droppableId === "hide_detail" &&
      result.source.droppableId === "tagsForm"
    ) {
      items = reorder(
        Customlist.CustomFields,
        result.source.index,
        result.destination.index
      );
      if (result.draggableId === "unit") {
        Customlist.CustomFields.map((obj, i) => {
          if (obj.Prompt === "unit") {
            obj.Hide = 1;
          }
        });
      }
      if (result.draggableId === "unit_desc") {
        Customlist.CustomFields.map((obj, i) => {
          if (obj.Prompt === "unit_desc") {
            obj.Hide = 1;
          }
        });
      }

      if (result.draggableId === "rate") {
        Customlist.CustomFields.map((obj, i) => {
          if (obj.Prompt === "rate") {
            obj.Hide = 1;
          }
        });
      }

      if (result.draggableId === "factor") {
        Customlist.CustomFields.map((obj, i) => {
          if (obj.Prompt === "factor") {
            obj.Hide = 1;
          }
        });
      }

      if (result.draggableId === "amount") {
        Customlist.CustomFields.map((obj, i) => {
          if (obj.Prompt === "amount") {
            obj.Hide = 1;
          }
        });
      }

      if (result.draggableId === "capped_amount") {
        Customlist.CustomFields.map((obj, i) => {
          if (obj.Prompt === "capped_amount") {
            obj.Hide = 1;
          }
        });
      }
      if (result.draggableId === "days_in_week") {
        Customlist.CustomFields.map((obj, i) => {
          if (obj.Prompt === "days_in_week") {
            obj.Hide = 1;
          }
        });
      }

      if (result.draggableId === "startDate") {
        Customlist.CustomFields.map((obj, i) => {
          if (obj.Prompt === "startDate") {
            obj.Hide = 1;
          }
        });
      }

      if (result.draggableId === "endDate") {
        Customlist.CustomFields.map((obj, i) => {
          if (obj.Prompt === "endDate") {
            obj.Hide = 1;
          }
        });
      }

      if (result.draggableId === "chart_sort") {
        Customlist.CustomFields.map((obj, i) => {
          if (obj.Prompt === "chart_sort") {
            obj.Hide = 1;
          }
        });
      }
      if (result.draggableId === "chart_code") {
        Customlist.CustomFields.map((obj, i) => {
          if (obj.Prompt === "chart_code") {
            obj.Hide = 1;
          }
        });
      }

      if (result.draggableId === "flag") {
        Customlist.CustomFields.map((obj, i) => {
          if (obj.Prompt === "flag") {
            obj.Hide = 1;
          }
        });
      }

      if (result.draggableId === "payment_flag") {
        Customlist.CustomFields.map((obj, i) => {
          if (obj.Prompt === "payment_flag") {
            obj.Hide = 1;
          }
        });
      }

      if (result.draggableId === "calc_days") {
        Customlist.CustomFields.map((obj, i) => {
          if (obj.Prompt === "calc_days") {
            obj.Hide = 1;
          }
        });
      }
      if (result.draggableId === "calc_weeks") {
        Customlist.CustomFields.map((obj, i) => {
          if (obj.Prompt === "calc_weeks") {
            obj.Hide = 1;
          }
        });
      }
    }
    if (
      result.destination.droppableId === "tagsForm" &&
      result.source.droppableId === "hide_detail"
    ) {
      items = reorder(
        Customlist.CustomFields,
        result.source.index,
        result.destination.index - 1
      );
      if (result.draggableId === "unit") {
        Customlist.CustomFields.map((obj, i) => {
          if (obj.Prompt === "unit") {
            obj.Hide = 0;
          }
        });
      }
      if (result.draggableId === "unit_desc") {
        Customlist.CustomFields.map((obj, i) => {
          if (obj.Prompt === "unit_desc") {
            obj.Hide = 0;
          }
        });
      }

      if (result.draggableId === "rate") {
        Customlist.CustomFields.map((obj, i) => {
          if (obj.Prompt === "rate") {
            obj.Hide = 0;
          }
        });
      }

      if (result.draggableId === "factor") {
        Customlist.CustomFields.map((obj, i) => {
          if (obj.Prompt === "factor") {
            obj.Hide = 0;
          }
        });
      }

      if (result.draggableId === "amount") {
        Customlist.CustomFields.map((obj, i) => {
          if (obj.Prompt === "amount") {
            obj.Hide = 0;
          }
        });
      }

      if (result.draggableId === "capped_amount") {
        Customlist.CustomFields.map((obj, i) => {
          if (obj.Prompt === "capped_amount") {
            obj.Hide = 0;
          }
        });
      }
      if (result.draggableId === "days_in_week") {
        Customlist.CustomFields.map((obj, i) => {
          if (obj.Prompt === "days_in_week") {
            obj.Hide = 0;
          }
        });
      }

      if (result.draggableId === "startDate") {
        Customlist.CustomFields.map((obj, i) => {
          if (obj.Prompt === "startDate") {
            obj.Hide = 0;
          }
        });
      }

      if (result.draggableId === "endDate") {
        Customlist.CustomFields.map((obj, i) => {
          if (obj.Prompt === "endDate") {
            obj.Hide = 0;
          }
        });
      }

      if (result.draggableId === "chart_sort") {
        Customlist.CustomFields.map((obj, i) => {
          if (obj.Prompt === "chart_sort") {
            obj.Hide = 0;
          }
        });
      }
      if (result.draggableId === "chart_code") {
        Customlist.CustomFields.map((obj, i) => {
          if (obj.Prompt === "chart_code") {
            obj.Hide = 0;
          }
        });
      }

      if (result.draggableId === "flag") {
        Customlist.CustomFields.map((obj, i) => {
          if (obj.Prompt === "flag") {
            obj.Hide = 0;
          }
        });
      }

      if (result.draggableId === "payment_flag") {
        Customlist.CustomFields.map((obj, i) => {
          if (obj.Prompt === "payment_flag") {
            obj.Hide = 0;
          }
        });
      }

      if (result.draggableId === "calc_days") {
        Customlist.CustomFields.map((obj, i) => {
          if (obj.Prompt === "calc_days") {
            obj.Hide = 0;
          }
        });
      }
      if (result.draggableId === "calc_weeks") {
        Customlist.CustomFields.map((obj, i) => {
          if (obj.Prompt === "calc_weeks") {
            obj.Hide = 0;
          }
        });
      }
    } else {
      items = reorder(
        Customlist.CustomFields,
        result.source.index,
        result.destination.index
      );
    }
    items.map((obj, index) => {
      if (obj.Prompt === "unit") {
        unit_order = index + 1;
      }
      if (obj.Prompt === "unit_desc") {
        unit_desc_order = index + 1;
      }
      if (obj.Prompt === "rate") {
        rate_order = index + 1;
      }
      if (obj.Prompt === "factor") {
        factor_order = index + 1;
      }
      if (obj.Prompt === "amount") {
        amount_order = index + 1;
      }
      if (obj.Prompt === "days_in_week") {
        days_in_week_order = index + 1;
      }
      if (obj.Prompt === "capped_amount") {
        capped_amount_order = index + 1;
      }
      if (obj.Prompt === "startDate") {
        startDate_order = index + 1;
      }
      if (obj.Prompt === "endDate") {
        endDate_order = index + 1;
      }
      if (obj.Prompt === "chart_sort") {
        chart_sort_order = index + 1;
      }
      if (obj.Prompt === "chart_code") {
        chart_code_order = index + 1;
      }

      if (obj.Prompt === "flag") {
        flag_order = index + 1;
      }
      if (obj.Prompt === "payment_flag") {
        payment_flag_order = index + 1;
      }
      if (obj.Prompt === "calc_days") {
        calc_days_order = index + 1;
      }
      if (obj.Prompt === "calc_weeks") {
        calc_weeks_order = index + 1;
      }
    });
    Documents.CustomList.map((field, j) => {
      field.CustomFields.map((obj, index) => {
        if (obj.Prompt === "unit") {
          obj.order = unit_order;
        }
        if (obj.Prompt === "unit_desc") {
          obj.order = unit_desc_order;
        }
        if (obj.Prompt === "rate") {
          obj.order = rate_order;
        }
        if (obj.Prompt === "factor") {
          obj.order = factor_order;
        }
        if (obj.Prompt === "amount") {
          obj.order = amount_order;
        }
        if (obj.Prompt === "days_in_week") {
          obj.order = days_in_week_order;
        }
        if (obj.Prompt === "capped_amount") {
          obj.order = capped_amount_order;
        }
        if (obj.Prompt === "startDate") {
          obj.order = startDate_order;
        }
        if (obj.Prompt === "endDate") {
          obj.order = endDate_order;
        }
        if (obj.Prompt === "chart_sort") {
          obj.order = chart_sort_order;
        }
        if (obj.Prompt === "chart_code") {
          obj.order = chart_code_order;
        }

        if (obj.Prompt === "flag") {
          obj.order = flag_order;
        }
        if (obj.Prompt === "payment_flag") {
          obj.order = payment_flag_order;
        }
        if (obj.Prompt === "calc_days") {
          obj.order = calc_days_order;
        }
        if (obj.Prompt === "calc_weeks") {
          obj.order = calc_weeks_order;
        }
      });
    });
    Customlist.CustomFields.sort((a, b) => (a.order > b.order ? 1 : -1));
    console.log("sortA", Customlist);
  }

  // -- -- SYSTEM LIFCYCLE -- -- //
  //-----------------------------//

  componentDidMount = async () => {
    this.setState({ isLoading: true });
    this.allFormpacks();
    try {
      var proj_response = await this.props.postRequest(
        `/projects/get-list/${localStorage.getItem("companyGuid")}`,
        {}
      );
      if (proj_response.status) {
        this.setState({ generalProjectList: proj_response.result });
      }
      console.log("/projects/get-list=>", proj_response);
    } catch (err) {
      console.log("/projects/get-list error=>", err);
    }

    try {
      var response = await this.props.postRequest(
        "/approval-setup/approver-group-list",
        { CompanyGuid: localStorage.getItem("companyGuid") }
      );
      if (response.status) {
        this.setState({ usersList: response.result });
      }
      console.log("/approval-setup/approver-group-list=>", response);
    } catch (err) {
      console.log("/approval-setup/approver-group-list error=>", err);
    }

    if (this.props.location.state !== undefined) {
      await this.setState({
        isLoading: true,
        temp_guid: this.props.location.state.edit_id,
      });

      this.set_edit_data();
    }

    try {
      var settings = await this.props.postRequest("/settings/get-list", {});
      if (settings.status) {
        this.setState({ generalSettings: settings.result });
      } else {
        this.setState({ generalSettings: "" });
      }
    } catch (err) {
      console.log("/settings/get-list error=>", err);
    }

    try {
      var response = await this.props.postRequest(
        "/email-templates/get-email-templates",
        { Type: "All", CompanyGuid: localStorage.getItem("companyGuid") }
      );
      if (response.status) {
        this.setState({ EmailTemplates: response.result });
      }
    } catch (err) {
      console.log("/email-templates/get-email-templates error=>", err);
    }

    $(".modal").css("display", "none");

    $.getJSON("https://jsonip.com?callback=?", async (jsonip) => {
      this.setState({ ipv4: jsonip.ip });
    });

    try {
      var setup = await this.props.postRequest("/setup/get-list", {});
      if (setup.status) {
        this.setState({
          setup_data: setup.result,
          setup_chartSort: setup.result.Codes.chart_sort,
          setup_chartCode: setup.result.Codes.chart_code,
          trackingList: setup.result.TrackingFields,
        });
      }
      console.log("setup=>", setup);
    } catch (err) {
      this.setState({ isLoading: false });
    }
  };

  set_edit_data = async () => {
    var that = this;
    this.setState({ isLoading: true });
    try {
      var response = await this.props.postRequest("/documents/get-document", {
        Guid: this.state.temp_guid,
      });
      if (response.status) {
        const data = response.result;

        var NewActivity = {
          Description: "Update Digital Form.",
          IPAddress: this.state.ipv4,
          Timestamp: moment().unix(),
          Type: "MoveDigitalForm",
          User: localStorage.getItem("userName"),
          profile_pic_url: localStorage.getItem("profile_pic_url"),
        };
        var Activity = data.Activity;
        Activity.push(NewActivity);

        this.setState({
          digitalForms: data,
          generalFirstName: data.FirstName,
          addFirstSignByFirstname: data.FirstName,
          generalLastName: data.LastName,
          addFirstSignByLastname: data.LastName,
          generalEmail: data.Email,
          addFirstSignByEmail: data.Email,
          ApprovalChain: data.ApprovalChain,
          AppGroup: data.ApprovalGroup,
          approvalGroupTitle: data.ApprovalGroup,
          approverGroupID: data.ApprovalGroupguid,
          displayableMobileNumber: data.Mobile?.split(data.CountryCode).pop(),
          generalMobile: data.Mobile,
          addFirstSignByMobile: data.Mobile,
          signByTable: data.ExtSigners,
          generalFormpackGuid: data.FormpackGuid,
          CreatedTS: data.CreatedTS,
          documents: data.Documents,
          customfields: data.CustomFields,
          packName: data.FormPack,
          countryCode: data.CountryCode ? `+${data.CountryCode}` : "",
          generalCountryCode: data.CountryCode,
          generalEmailValid: true,
          generalLastNameValid: true,
          generalFirstNameValid: true,
          generalProjectGuidValid: true,
          generalFormPackGuidValid: true,
          generalProjectGuid: data.ProjectGuid,
          generalProjectName: data.ProjectName,
          generalActivity: Activity,
          generalComments: data.Comments,
          generalTimestamp: data.Timestamp,
        });
        if (data.CustomFields) {
          data.CustomFields.map(function (Fields, key) {
            let value = Fields.Value;
            var generateState = "CustomField" + key;
            that.setState({
              [generateState]: value,
            });
            return null;
          });
        }

        this.set_docuemnt_detail(data.Guid);
      } else {
        this.setState({ isLoading: false });
        toast.error(response.error, { autoClose: 500 });
      }
      console.log("/documents/get-document=>", response);
    } catch (err) {
      this.setState({ isLoading: false });
      toast.error(err.message, { autoClose: 500 });
      console.log("/projects/get-list error=>", err);
    }
  };

  set_docuemnt_detail = async (guid) => {
    try {
      const response = await this.props.postRequest(
        "/documents/get-document-detail",
        { TanantGuid: guid }
      );
      if (response.status) {
        var that = this;
        let result = response.result.sort(function (a, b) {
          return a.Order - b.Order;
        });
        this.setState(
          {
            isRender: true,
            DFDocs: result,
            oldDFDocs: result,
          },
          () => {
            this.state.DFDocs.map(function (Documents, key) {
              if (Documents.DocDetails) {
                Documents.DocDetails.map(function (customField, inner) {
                  let DefaultValue = customField.DefaultValue;
                  var generateState = "ROW" + key + "DocDetails" + inner;
                  var generateStateempty =
                    "ROW" + key + "DocDetails" + inner + "Empty";

                  if (customField.Type === "Date") {
                    DefaultValue = moment(customField.startDate).format(
                      "YYYY-MM-DD"
                    );
                  }

                  that.setState({
                    [generateState]: DefaultValue,
                    [generateStateempty]: DefaultValue ? false : true,
                  });

                  return null;
                });
              }

              if (Documents.CustomList) {
                Documents.CustomList.map(function (CustomList, inner) {
                  CustomList.CustomFields.map(function (field, index) {
                    let DefaultValue = field.DefaultValue;

                    var generateState = "ROW" + inner + "CustomList" + index;

                    if (
                      field.Prompt === "startDate" ||
                      field.Prompt === "endDate"
                    ) {
                      DefaultValue = moment(field.startDate).format(
                        "YYYY-MM-DD"
                      );
                    }

                    that.setState({
                      [generateState]: DefaultValue,
                    });
                    return null;
                  });
                  return null;
                });
              }
              return null;
            });
          }
        );
        this.setState({ isLoading: false });
      } else {
        toast.error(response.message, { autoClose: 5000 });
        this.setState({ isLoading: false });
      }
      console.log("/documents/get-document-detail=>", response);
    } catch (err) {
      this.setState({ isLoading: false });
      console.log("/documents/get-document-detail=>", err);
      toast.error(err.message, { autoClose: 5000 });
    }
  };

  updateRecord = async () => {
    var FormValid = true;
    let customFormErrors = Object.assign({}, this.state.customFormErrors);
    for (var err in customFormErrors) {
      //console.log(err+"=>", this.state[err+"Valid"]);
      if (
        this.state[err + "Valid"] === false ||
        this.state[err + "Valid"] === null
      ) {
        customFormErrors[err] = "This field is required";
        FormValid = false;
      }
    }
    this.setState({ customFormErrors });
    console.log("customFormErr=>", customFormErrors);
    if (
      FormValid &&
      this.state.generalEmailValid &&
      this.state.generalLastNameValid &&
      this.state.generalFirstNameValid &&
      this.state.generalProjectGuidValid &&
      this.state.generalFormPackGuidValid
    ) {
      this.setState({ isLoading: true });
      try {
        const response = await this.props.postRequest(
          "/documents/update-document",
          {
            Guid: this.state.temp_guid,
            Activity: this.state.generalActivity
              ? this.state.generalActivity
              : [],
            ApprovalChain: this.state.ApprovalChain,
            ApprovalGroup: this.state.AppGroup,
            ApprovalGroupguid: this.state.approverGroupID,
            FormpackGuid: this.state.generalFormpackGuid,
            Comments: this.state.generalComments,
            CreatedTS: this.state.CreatedTS,
            CurrentApproval: [],
            CurrentApprovalSeq: 1,
            CustomFields: this.state.customfields,
            Documents: this.state.documents,
            Email: this.state.generalEmail,
            ExtSigners: this.state.signByTable,
            FirstName: this.state.generalFirstName,
            FormPack: this.state.packName,
            LastName: this.state.generalLastName,
            CountryCode: this.state.generalCountryCode,
            Mobile: this.state.addFirstSignByMobile,
            Timestamp: this.state.generalTimestamp,
            UserName: localStorage.getItem("userName"),
            ProjectName: this.state.generalProjectName,
            ProjectGuid: this.state.generalProjectGuid,
          }
        );

        if (response.status) {
          const createDFDocs = await this.createDFDocs(
            this.state.DFDocs,
            this.state.temp_guid
          );
          if (createDFDocs.status) {
            this.setState({
              ApprovalChain: [],
              signByTable: [],
              digitalforms: {},
              email: "",
              firstName: "",
              lastName: "",
              generalCountryCode: "",
              generalMobile: "",
              externalPartyToComplete: "",
              generalFormpackGuid: "",
              generalProjectGuid: "",
              isLoading: false,
              generalFormValid: false,
              generalFirstNameValid: false,
              generalLastNameValid: false,
              generalEmailValid: false,
              generalMobileValid: false,
              generalFormPackGuidValid: false,
              generalProjectGuidvalid: false,
            });

            if (this.state.oldDFDocsDel) {
              this.oldDFDocsDelete();
            }
            toast.success(response.message, { autoClose: 5000 });
            this.setState({ isLoading: false });

            this.props.history.push("/dashboard/documents");
          } else {
            this.setState({ isLoading: false });
            toast.error(createDFDocs.error, { autoClose: 5000 });
          }
        } else {
          toast.error(response.error, { autoClose: 5000 });
          this.setState({ isLoading: false });
        }
        console.log("/documents/update-document=>", response);
      } catch (err) {
        this.setState({ isLoading: false });
        console.log("/documents/update-document=>", err);
        toast.error(err.message, { autoClose: 5000 });
      }
      return false;
    } else {
      toast.error("Required fields are missing.", {
        autoClose: 5000,
      });
      document.getElementById("collapseOneClick").click();
      $("body").animate({ scrollTop: 0 }, "slow");
    }
  };

  oldDFDocsDelete() {
    console.log(this.state.oldDFDocs);
    this.state.oldDFDocs.map((item) => {
      this.props
        .postRequest("/documents/delete-document-detail", { Guid: item.Guid })
        .then((res) => {
          console.log("old doc deleted successfully.");

          /*var uploadPath = item.TenantGuid+"/df-docs-pdf/"+item.Guid+".pdf";
            Storage.remove(uploadPath)
                .then(result => console.log("file deleted"))
                .catch(err => console.log(err));
                console.log('deleting record');*/
        });
    });
  }

  // -- -- -- General Tab Start -- -- -- //
  //------------------------------------//
  customListCodeDateFrom = () => {
    document.getElementById("customListCodeDateFrom").click();
  };

  customListCodeDateTo = () => {
    document.getElementById("customListCodeDateTo").click();
  };

  signByFormModalOpen = () => {
    this.setState({
      signByFormModalOpen: true,
    });
  };

  signByFormModalClose = () => {
    this.setState({
      signByFormModalOpen: false,
      signByFirstName: "",
      signByLastName: "",
      signByEmail: "",
      signByMobile: "",
      signByCountryCode: "",
      signByPosition: "",
      signBySequence: "",
      signByUpdatingIndex: "",
      signByUpdating: false,
    });
  };

  customListCodeModalOpen = async (event) => {
    var that = this;
    var rowIndex;
    var documentIndex;
    var Amount;
    if (event) {
      documentIndex = $(event.currentTarget).attr("data-documentindex");
      rowIndex = $(event.currentTarget).attr("data-rowindex");
    } else {
      documentIndex = this.state.documentIndex;
      rowIndex = this.state.rowIndex;
    }

    this.setState({
      //CodeListIndex:event.currentTarget.id,
      documentIndex: documentIndex,
      rowIndex: rowIndex,
    });

    //New Drag Function
    setTimeout(function () {
      var Documents = that.state.DFDocs[that.state.documentIndex];
      var Customlist = Documents.CustomList[that.state.rowIndex];
      var value = Customlist.TBA;
      Customlist.CustomFields.sort((a, b) => (a.order > b.order ? 1 : -1));
      Customlist.CustomFields.map((field, i) => {
        if (field.Prompt === "amount") {
          Amount = field.DefaultValue;
        }
      });
      if (Amount === 0) {
        that.setState({ disableDatePicker: true });
      } else {
        that.setState({ disableDatePicker: false });
      }
      if (value === "checked") {
        that.setState({ TBA: "checked", calc_days: 0, calc_weeks: 0 });
      } else {
        that.setState({ TBA: null });
      }
    }, 200);
    that.setState({ customListCodeModalOpen: true });
  };

  createSelectItems() {
    var ApproverGroup = localStorage.getItem("ApproverGroup")
      ? localStorage.getItem("ApproverGroup").split(",")
      : "";
    //console.log('ApproverGroup: ',ApproverGroup);
    let items = [];
    let currentUsersList = this.state.usersList;
    //console.log('CurrentUsersList: ',currentUsersList);
    if (currentUsersList) {
      currentUsersList.map((results, key) => {
        //console.log('index: ',ApproverGroup.indexOf(results.ApproverGroupUserName));
        //console.log('index '+key+': ',results.FirstName);
        if (
          localStorage.getItem("userType") === "Operator/Approver" ||
          localStorage.getItem("userType") === "Operator"
        ) {
          if (ApproverGroup.indexOf(results.FirstName) < 0) {
            return null;
          } else {
            items.push(
              <option key={key} title={results.FirstName} value={results.Guid}>
                {results.FirstName}
              </option>
            );
            return null;
          }
        } else {
          items.push(
            <option key={key} title={results.FirstName} value={results.Guid}>
              {results.FirstName}
            </option>
          );
          return null;
        }
      });
    }
    return items;
  }
  //===========
  customListCodeModalClose = () => {
    this.setState({
      customListCodeModalOpen: false,
      TBA: null,
    });
  };

  validateField(fieldName, value) {
    const re = /^[0-9\b]+$/;
    let generalFieldValidationErrors = this.state.generalFormErrors;
    let generalFirstNameValid = this.state.generalFirstNameValid;
    let generalLastNameValid = this.state.generalLastNameValid;
    let generalFormPackGuidValid = this.state.generalFormPackGuidValid;
    let generalProjectGuidValid = this.state.generalProjectGuidValid;
    let generalEmailValid = this.state.generalEmailValid;
    let generalMobileValid = this.state.generalMobileValid;
    let generalDepartmentValid = this.state.generalDepartmentValid;
    let generalPositionValid = this.state.generalPositionValid;
    let generalDescriptionValid = this.state.generalDescriptionValid;
    let signByFirstNameValid = this.state.signByFirstNameValid;
    let signByLastNameValid = this.state.signByFirstNameValid;
    let signByEmailValid = this.state.signByFirstNameValid;
    let signByMobileValid = this.state.signByFirstNameValid;
    let signByCountryCodeValid = this.state.signByFirstNameValid;
    let signByPositionValid = this.state.signByFirstNameValid;
    let signBySequenceValid = this.state.signByFirstNameValid;
    switch (fieldName) {
      case "signByFirstName":
        signByFirstNameValid = value.length > 0;
        generalFieldValidationErrors.signByFirstName = signByFirstNameValid
          ? ""
          : "This field is required";
        break;
      case "signByLastName":
        signByLastNameValid = value.length > 0;
        generalFieldValidationErrors.signByLastName = signByLastNameValid
          ? ""
          : "This field is required";
        break;
      case "signByEmail":
        signByEmailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        generalFieldValidationErrors.signByEmail = signByEmailValid
          ? ""
          : "Email should be username@domain.com";
        break;
      case "signByMobile":
        signByMobileValid = value.length > 0;
        generalFieldValidationErrors.signByMobile = signByMobileValid
          ? ""
          : "This field is required";
        break;
      case "signByCountryCode":
        signByCountryCodeValid = value.length > 0;
        generalFieldValidationErrors.signByCountryCode = signByCountryCodeValid
          ? ""
          : "This field is required";
        break;
      case "signByPosition":
        signByPositionValid = re.test(value) && value > 0 && value.length > 0;
        generalFieldValidationErrors.signByPosition = signByPositionValid
          ? ""
          : "This field must be a number and greater then 0";
        break;
      case "signBySequence":
        signBySequenceValid = re.test(value) && value > 0 && value.length > 0;
        generalFieldValidationErrors.signBySequence = signBySequenceValid
          ? ""
          : "This field must be a number and greater then 0";
        break;
      case "generalFirstName":
        generalFirstNameValid = value.length > 0;
        generalFieldValidationErrors.firstName = generalFirstNameValid
          ? ""
          : "This field is required";
        break;
      case "generalLastName":
        generalLastNameValid = value.length > 0;
        generalFieldValidationErrors.lastName = generalLastNameValid
          ? ""
          : "This field is required";
        break;
      case "generalFormPackGuid":
        generalFormPackGuidValid = value.length > 0;
        generalFieldValidationErrors.formPackGuid = generalFormPackGuidValid
          ? ""
          : "This field is required";
        break;
      case "generalProjectGuid":
        generalProjectGuidValid = value.length > 0;
        generalFieldValidationErrors.projectGuid = generalProjectGuidValid
          ? ""
          : "This field is required";
        break;
      case "generalMobile":
        generalMobileValid = value.length > 0;
        generalFieldValidationErrors.mobile = generalMobileValid
          ? ""
          : "This field is required";
        break;
      case "generalEmail":
        generalEmailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        generalFieldValidationErrors.email = generalEmailValid
          ? ""
          : "Email should be username@domain.com";
        break;
      case "generalMobileNumber":
        generalMobileValid = value.length > 0;
        generalFieldValidationErrors.mobile = generalMobileValid
          ? ""
          : "This field is required";
        break;
      case "generalDepartment":
        generalDepartmentValid = value.length > 0;
        generalFieldValidationErrors.department = generalDepartmentValid
          ? ""
          : "This field is required";
        break;
      case "generalPosition":
        generalPositionValid = value.length > 0;
        generalFieldValidationErrors.position = generalPositionValid
          ? ""
          : "This field is required";
        break;
      case "generalDescription":
        generalDescriptionValid = value.length > 0;
        generalFieldValidationErrors.description = generalDescriptionValid
          ? ""
          : "This field is required";
        break;

      default:
        break;
    }

    this.setState(
      {
        generalFormErrors: generalFieldValidationErrors,
        generalFirstNameValid: generalFirstNameValid,
        generalLastNameValid: generalLastNameValid,
        generalMobileValid: generalMobileValid,
        generalDepartmentValid: generalDepartmentValid,
        generalDescriptionValid: generalDescriptionValid,
        generalPositionValid: generalPositionValid,
        generalEmailValid: generalEmailValid,
        generalFormPackGuidValid: generalFormPackGuidValid,
        generalProjectGuidValid: generalProjectGuidValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      generalFormValid:
        this.state.generalFirstNameValid &&
        this.state.generalLastNameValid &&
        this.state.generalEmailValid &&
        this.state.generalFormPackGuidValid &&
        this.state.generalProjectGuidValid,
    });
  }

  createFormPackSelectItems() {
    let items = [];
    let currentGeneralFormPack = [];
    //console.log("All Form Packs => ",this.state.generalFormPack);
    if (
      localStorage.getItem("userType") === "Admin" ||
      localStorage.getItem("userType") === "SysAdmin"
    ) {
      currentGeneralFormPack = this.state.generalFormPack;
    } else {
      this.state.generalFormPack.map((item) => {
        item.UserAccess.map((user) => {
          if (user.value === localStorage.getItem("UserLogin")) {
            currentGeneralFormPack.push(item);
          }
        });
      });
    }

    currentGeneralFormPack.map((results, key) => {
      items.push(
        <option
          key={key}
          data-packname={results.Desc}
          projectGuid={results.ProjectGuid}
          value={results.Guid}
        >
          {results.Desc}
        </option>
      );
      //  return null;
    });
    return items;
  }

  async handleFormPackSelect(event) {
    let name = event.target.name;
    let packname = $('select[name="generalFormPackGuid"] :selected').attr(
      "data-packname"
    );

    var that = this;
    this.setState({
      isLoading: false,
      packName: packname,
    });

    let Guid = event.target.value;

    const formPackResults = await this.getSingleFormpack(Guid);

    if (formPackResults?.result?.Documents) {
      this.setState({
        documents: formPackResults.result.Documents,
      });
    }

    if (Guid === "") {
      //this.state.generalFormErrors.formPackGuid = "This field is required";
      this.setState({
        isLoading: true,
      });
      alert("Select a valid option");
      return false;
    }

    const project_Guid = $('select[name="generalFormPackGuid"] :selected').attr(
      "projectGuid"
    );

    //console.log('ProjectGuid Before => ', project_Guid);
    const selectedProject = this.state.generalProjectList.find(
      (el) => el.Guid === project_Guid
    );
    if (selectedProject) {
      this.setState({
        formpackProjectDesc: selectedProject.Desc,
        generalProjectGuidValid: true,
        generalProjectGuid: project_Guid,
        generalProjectName: selectedProject.Desc,
      });
      if (selectedProject.CountryCode) {
        this.setState({
          generalCountryCode: selectedProject.CountryCode,
          countryCode: `+${selectedProject.CountryCode}`,
        });
      } else {
        this.setState({ generalCountryCode: "" });
      }
    } else {
      this.setState({
        formpackProjectDesc: "",
        generalProjectGuidValid: false,
        generalProjectGuid: "",
        generalProjectName: "",
      });
    }

    this.setState(
      {
        generalFormpackGuid: Guid,
        oldDFDocsDel: true,
        DFDocs: [],
        customFormErrors: {},
        digitalForms: false,
      },
      () => {
        //console.log("customFormErrors", this.state.customFormErrors)
        this.validateField(name, Guid);
        if (Guid) {
          const selectedFormPack = this.state.generalFormPack.find(
            (el) => el.Guid === Guid
          );
          if (selectedFormPack === undefined) {
            this.setState({ isLoading: false });
            toast.error("Selected form pack is not valid.", {
              autoClose: 5000,
            });
            console.log("selectedFormPack=>", selectedFormPack);
            return false;
          }

          console.log("selectedFormPack=>", selectedFormPack);
          selectedFormPack.CustomFields.map(function (Fields, key) {
            let value = Fields.Value;
            var generateState = "CustomField" + key;
            that.setState({
              [generateState]: value,
            });
            return null;
          });

          this.setState(
            {
              digitalForms: selectedFormPack,
              isLoading: false,
              DFDocs: selectedFormPack.Documents,
              isRender: true,
            },
            () => {
              this.onChangeSetTagValue("generalFormPackGuid", "ProdTitle");
              this.state.DFDocs.map(function (Document, key) {
                if (Document.DocDetails) {
                  Document.DocDetails.map(function (customField, inner) {
                    let DefaultValue = customField.DefaultValue;
                    var generateState = "ROW" + key + "DocDetails" + inner;
                    var generateStateempty =
                      "ROW" + key + "DocDetails" + inner + "Empty";

                    if (customField.Type === "Date") {
                      DefaultValue = moment(customField.startDate).format(
                        "YYYY-MM-DD"
                      );
                    }

                    that.setState({
                      [generateState]: DefaultValue,
                      [generateStateempty]: DefaultValue ? false : true,
                    });
                    return null;
                  });
                }

                if (Document.CustomList) {
                  Document.CustomList.map(function (CustomList, inner) {
                    CustomList.CustomFields.map(function (field, index) {
                      let DefaultValue = field.DefaultValue;

                      var generateState = "ROW" + inner + "CustomList" + index;

                      if (
                        field.Prompt === "startDate" ||
                        field.Prompt === "endDate"
                      ) {
                        DefaultValue = moment(field.startDate).format(
                          "YYYY-MM-DD"
                        );
                      }

                      that.setState({
                        [generateState]: DefaultValue,
                      });
                      return null;
                    });
                    return null;
                  });
                }
                return null;
              });
            }
          );
        }
      }
    );
  }

  allFormpacks() {
    this.getAllFormpacks()
      .then((response) => {
        console.log("formPacksList => ", response);
        if (response.status) {
          this.setState({ generalFormPack: response.result, isLoading: false });
        } else {
          toast.error(response.error, { autoClose: 5000 });
          this.setState({ isLoading: false });
        }
      })
      .catch((err) => {
        console.error("formPacksList error=> ", err);
        this.setState({ isLoading: false });
      });
  }

  async handleFieldsChange(e) {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "generalProjectGuid") {
      this.setState({
        generalProjectName: e.target.options[e.target.selectedIndex].text,
      });
      var found = this.state.generalProjectList.find(
        (obj) => obj.Guid === value
      );
      if (found.CountryCode) {
        this.setState({ generalCountryCode: found.CountryCode });
      } else {
        this.setState({ generalCountryCode: "" });
      }
    }
    await this.setState(
      {
        [name]: value,
      },
      () => {
        this.validateField(name, value);
      }
    );

    if (
      name === "generalFirstName" ||
      name === "generalLastName" ||
      name === "generalEmail" ||
      name === "generalMobile"
    ) {
      await this.addFirstSignBy(name, value);
    }
  }

  addFirstSignBy(name, value) {
    if (name === "generalFirstName") {
      this.setState({
        addFirstSignByFirstname: value,
      });
    } else if (name === "generalLastName") {
      this.setState({
        addFirstSignByLastname: value,
      });
    } else if (name === "generalEmail") {
      this.setState({
        addFirstSignByEmail: value,
      });
    } else if (name === "generalMobile") {
      this.setState({
        addFirstSignByMobile: value,
      });
    }
    var signByTable = this.state.signByTable;
    if (
      this.state.addFirstSignByFirstname !== "" &&
      this.state.addFirstSignByLastname !== "" &&
      this.state.addFirstSignByEmail !== "" &&
      this.state.addFirstSignByMobile !== ""
    ) {
      var obj = {
        Email: this.state.addFirstSignByEmail,
        FirstName: this.state.addFirstSignByFirstname,
        Lastname: this.state.addFirstSignByLastname,
        Mobile: this.state.addFirstSignByMobile,
        Sequence: signByTable[0] ? signByTable[0].Sequence : "1",
        SigPosition: signByTable[0] ? signByTable[0].SigPosition : "1",
        countryCode: this.state.generalCountryCode,
      };
      signByTable[0] = obj;

      this.setState({ signByTable: signByTable });
    }
  }

  handleCustomFieldsModalInputsCountry(event) {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({
      [name]: value,
    });
    this.setState({
      countryCode: "+" + value,
    });
  }

  handleCustomFieldsModalInputsSignByCountryCode(event) {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({
      [name]: value,
    });
    this.setState({
      signByMobile: "+" + value,
    });
  }

  onChangeCustomFieldsGeneric(event, index, tag) {
    var generateState = "CustomField" + index;
    this.state.digitalForms.CustomFields[index].Value = event.target.value
      ? event.target.value
      : null;
    this.setState(
      {
        [generateState]: event.target.value ? event.target.value : null,
        [index + "error"]: false,
      },
      () => {}
    );
  }

  onChangeSetTagValue(event, Tag) {
    if (event === "generalFormPackGuid") {
      var name = event;
      var value = $('select[name="generalProjectGuid"] :selected').html();
    } else {
      var name = event.target.name;
      var value = event.target.value;
    }
    //console.log("name=>"+name,"  value=>"+value);
    if (name === "generalProjectGuid") {
      value = event.target.options[event.target.selectedIndex].text;
    }

    if (value && value !== "- Select") {
      var that = this;

      if (this.state.digitalForms) {
        var CustomFields = this.state.digitalForms.CustomFields
          ? this.state.digitalForms.CustomFields
          : [];
        CustomFields.map(function (fieldObj, key) {
          var generateState = "CustomField" + key;
          if (fieldObj.Tag === Tag) {
            if (!fieldObj.Value) {
              fieldObj.Value = value ? value : null;
              that.setState(
                {
                  [generateState]: value ? value : null,
                  [key + "error"]: false,
                },
                () => {}
              );
            }
          }

          return null;
        });
      }

      var DFDocs = this.state.DFDocs;
      if (DFDocs) {
        this.state.DFDocs.map((templates, index) => {
          if (templates.DocDetails) {
            templates.DocDetails.map(function (template, innerIndex) {
              var generateState = "ROW" + index + "DocDetails" + innerIndex;

              if (template.Tag === Tag) {
                if (!template.DefaultValue) {
                  template.DefaultValue = value ? value : null;
                  that.setState(
                    {
                      [generateState]: value ? value : null,
                      [index + "error"]: false,
                    },
                    () => {}
                  );
                }
              }

              return null;
            });
          }
        });
      }
    }
  }

  onChangeGeneric(event, index, innerIndex, state) {
    var DFDocs = this.state.DFDocs;
    //var id=event.target.id;

    var generateState = "ROW" + index + "DocDetails" + innerIndex;
    DFDocs[index].DocDetails[innerIndex].DefaultValue =
      event.target.value !== "" ? event.target.value : null;
    this.setState(
      {
        [generateState]: event.target.value !== "" ? event.target.value : null,
        [index + "error"]: false,
      },
      () => {}
    );
    //this.onChangeSetTagValue(event, DFDocs[index].DocDetails[innerIndex].Tag);
  }

  onChangeCustomListFields(event, index, Tag, dbField = null, prompt = null) {
    var that = this;
    var templates = this.state.DFDocs[index];
    var unit;
    var factor;
    var rate;
    var amount;
    console.log("TBA==", event.target.name);
    //_ _ IF DOCS EXIST _ _//
    if (
      templates.CustomList !== null &&
      templates.CustomList.length > 0 &&
      this.state.rowIndex
    ) {
      templates.CustomList[this.state.rowIndex].TBA = that.state.TBA;
      var value = templates.CustomList[this.state.rowIndex].TBA;
      templates.CustomList[this.state.rowIndex].CustomFields.map(function (
        field,
        key2
      ) {
        var generateState = "ROW" + that.state.rowIndex + "CustomList" + key2;
        if (field.Prompt === prompt) {
          field.DefaultValue = event.target.value ? event.target.value : null;
          that.setState({
            [generateState]: event.target.value ? event.target.value : null,
          });
        }

        if (event.target.name === "TBA") {
          if (value === "checked") {
            templates.CustomList[that.state.rowIndex].TBA = "";
            value = templates.CustomList[that.state.rowIndex].TBA;
            that.setState({ TBA: "" });
          } else {
            console.log("TBA=>Check");
            templates.CustomList[that.state.rowIndex].TBA = "checked";
            value = templates.CustomList[that.state.rowIndex].TBA;
            that.setState({ TBA: "checked" });
          }
        }
        if (field.Prompt === "unit") {
          unit = field.DefaultValue ? field.DefaultValue : 0;
        }
        if (field.Prompt === "rate") {
          rate = field.DefaultValue ? field.DefaultValue : 0;
        }
        if (field.Prompt === "factor") {
          factor = field.DefaultValue ? field.DefaultValue : 0;
        }
        if (field.Prompt === "days_in_week") {
          that.handleChangeDaysInWeek();
        }
        return null;
      });
      templates.CustomList[this.state.rowIndex].CustomFields.map((test, i) => {
        if (test.Prompt === "amount") {
          test.DefaultValue = unit * rate * factor;
          amount = unit * rate * factor;
        }
      });
      if (amount === 0) {
        that.setState({ disableDatePicker: true });
      } else {
        that.setState({ disableDatePicker: false });
      }
      if (value === "checked") {
        templates.CustomList[this.state.rowIndex].CustomFields.map(
          (test, i) => {
            if (test.Prompt === "calc_days") {
              test.DefaultValue = 0;
            }
            if (test.Prompt === "calc_weeks") {
              test.DefaultValue = 0;
            }
          }
        );
      } else {
        var startDate;
        var endDate;
        var daysinweek;
        var calc_days;
        var calc_weeks;
        templates.CustomList[this.state.rowIndex].CustomFields.map(
          (test, i) => {
            if (test.Prompt === "startDate") {
              //console.log("factor DefaultValue =>",field.DefaultValue);
              startDate = test.DefaultValue;
            }
            if (test.Prompt === "endDate") {
              endDate = test.DefaultValue;
            }
            if (test.Prompt === "days_in_week") {
              daysinweek = test.DefaultValue;
            }
          }
        );
        startDate = moment(startDate);
        endDate = moment(endDate);
        startDate = moment(startDate.format("YYYY-MM-DD"));
        endDate = moment(endDate.format("YYYY-MM-DD"));
        if (moment(endDate).isSameOrAfter(startDate)) {
          calc_days = (endDate.diff(startDate, "days") + 1) % 7;
          calc_weeks = endDate.diff(startDate, "weeks");
          if (calc_days >= daysinweek) {
            calc_weeks++;
            calc_days = 0;
            calc_weeks = calc_weeks;
          } else {
            if (calc_days === 0) {
              calc_weeks++;
            }
            calc_weeks = calc_weeks;
          }
        } else {
          calc_days = 0;
          calc_weeks = 0;
        }

        templates.CustomList[this.state.rowIndex].CustomFields.map(
          (test, i) => {
            if (test.Prompt === "calc_days") {
              //console.log("factor DefaultValue =>",field.DefaultValue);
              test.DefaultValue = calc_days;
            }
            if (test.Prompt === "calc_weeks") {
              test.DefaultValue = calc_weeks;
            }
          }
        );
      }
    }
    console.log("INDX", templates.CustomList[this.state.rowIndex]);
  }

  onDrop(event) {
    //console.log("dropzone event ", event)
  }

  // -- -- -- General Tab End -- -- -- //
  //----------------------------------//

  // -- -- API CALLS -- --//
  // ---------------------//
  getAllFormpacks() {
    return this.props.postRequest(
      `/form-pack/get-list/${localStorage.getItem("companyGuid")}`,
      {}
    );
  }

  getSingleFormpack(guid) {
    return this.props.postRequest("/form-pack/get-pack", {
      Guid: guid,
    });
  }

  getSpecificFormTemplates(ids) {
    return API.post("formpack", "/formpack/get-multiple-form-templates-all", {
      body: {
        array: ids,
      },
    });
  }

  renderCustomFields = () => {
    var that = this;
    var digitalForms = this.state.digitalForms;
    const generalSettings = that.state.generalSettings;
    //   console.log('digitalForms Data=>',digitalForms)
    if (digitalForms) {
      //console.log(digitalForms.CustomFields);
      digitalForms = digitalForms.CustomFields.map(function (Fields, key) {
        let Desc = Fields.Desc;
        let Name = Fields.Desc ? Fields.Desc.replace(/ /g, "_") : " N?A";
        //let Length = Fields.Length;
        //let Required = Fields.Required;
        //let Tag = Fields.Tag;
        let DataList = Fields.DataList;
        let Type = Fields.Type;
        let Value = Fields.Value;
        let Tag = Fields.Tag;
        let Required = Fields.Required;
        var generateState = "CustomField" + key;
        var ErrorMsg = Value ? "" : that.state.customFormErrors[generateState];
        if (Required) {
          that.state[generateState + "Valid"] = Value ? true : false;
          that.state.customFormErrors[generateState] = ErrorMsg ? ErrorMsg : "";
        }
        //console.log('generateState value=>',that.state[generateState]);
        return (
          <CustomField
            key={key}
            Type={Type}
            Value={that.state[generateState]}
            Name={Name}
            Promt={Desc}
            DefaultValue={Value}
            Index={key}
            settings={generalSettings}
            DataList={DataList}
            Tag={Tag}
            Required={Required}
            onChange={that.onChangeCustomFieldsGeneric.bind(that)}
            onBlur={that.onChangeSetTagValue.bind(that)}
            customFormErrors={that.state.customFormErrors[generateState]}
          />
        );
      });
      return digitalForms;
    }
  };

  renderApprovalChain() {
    var approvalChain = this.state.ApprovalChain.map(function (approval, key) {
      //console.log(approval);
      return (
        <tr className="" key={key}>
          <td align="center">{approval.ApproverName}</td>
          <td align="center">{approval.Sequence}</td>
          <td></td>
          <td align="left">{approval.SigPosition}</td>
        </tr>
      );
    });
    return (
      <div className="col-sm-12 col-md-6  mobile_padd">
        <div className="help_body_box id_approver_table">
          <table
            width="100%"
            border="0"
            cellSpacing="2"
            cellPadding="2"
            className="table-bordered"
          >
            <thead>
              <tr>
                <th width="30%" align="center" className="text-center">
                  Approver Name
                </th>
                <th width="30%" align="center" className="text-center">
                  Seq
                </th>
                <th width="20%"></th>
                <th width="20%" align="left">
                  Signature Position
                </th>
              </tr>
            </thead>
            <tbody>{approvalChain}</tbody>
          </table>
        </div>
      </div>
    );
  }

  //add or update sign by
  addSignbyForm = async (event) => {
    event.preventDefault();
    let signByFirstName = this.state.signByFirstName;
    let signByLastName = this.state.signByLastName;
    let signByEmail = this.state.signByEmail;
    let signByMobile = this.state.signByMobile;
    let signByCountryCode = this.state.signByCountryCode;
    let signByPosition = this.state.signByPosition;
    let signBySequence = this.state.signBySequence;
    let generalFormErrors = Object.assign({}, this.state.generalFormErrors);

    if (
      signByFirstName === false ||
      signByFirstName === null ||
      signByFirstName === ""
    ) {
      generalFormErrors.signByFirstName = "This field is required";
      this.setState({
        generalFormErrors: generalFormErrors,
      });
    }

    if (
      signByLastName === false ||
      signByLastName === null ||
      signByLastName === ""
    ) {
      generalFormErrors.signByLastName = "This field is required";
      this.setState({
        generalFormErrors: generalFormErrors,
      });
    }

    if (signByEmail === false || signByEmail === null || signByEmail === "") {
      generalFormErrors.signByEmail = "This field is required";
      this.setState({
        generalFormErrors: generalFormErrors,
      });
    }

    if (
      signByMobile === false ||
      signByMobile === null ||
      signByMobile === ""
    ) {
      generalFormErrors.signByMobile = "This field is required";
      this.setState({
        generalFormErrors: generalFormErrors,
      });
    }

    if (
      signByCountryCode === false ||
      signByCountryCode === null ||
      signByCountryCode === ""
    ) {
      generalFormErrors.signByCountryCode = "This field is required";
      this.setState({
        generalFormErrors: generalFormErrors,
      });
    }

    if (
      signByPosition === false ||
      signByPosition === null ||
      signByPosition === ""
    ) {
      generalFormErrors.signByPosition = "This field is required";
      this.setState({
        generalFormErrors: generalFormErrors,
      });
    }

    if (
      signBySequence === false ||
      signBySequence === null ||
      signBySequence === ""
    ) {
      generalFormErrors.signBySequence = "This field is required";
      this.setState({
        generalFormErrors: generalFormErrors,
      });
    }

    if (
      signBySequence === false ||
      signBySequence === null ||
      signBySequence === "" ||
      signByPosition === false ||
      signByPosition === null ||
      signByPosition === "" ||
      signByCountryCode === false ||
      signByCountryCode === null ||
      signByCountryCode === "" ||
      signByMobile === false ||
      signByMobile === null ||
      signByMobile === "" ||
      signByEmail === false ||
      signByEmail === null ||
      signByEmail === "" ||
      signByLastName === false ||
      signByLastName === null ||
      signByLastName === "" ||
      signByFirstName === false ||
      signByFirstName === null ||
      signByFirstName === ""
    ) {
      return false;
    }

    await Object.keys(generalFormErrors).forEach((key) => {
      if (generalFormErrors[key] !== "") {
        return false;
      }
    });

    this.setState({
      isLoading: true,
    });

    var currentSignByFormData = {
      FirstName: this.state.signByFirstName,
      Lastname: this.state.signByLastName,
      Email: this.state.signByEmail,
      Mobile: this.state.signByMobile,
      countryCode: this.state.signByCountryCode,
      SigPosition: this.state.signByPosition,
      Sequence: this.state.signBySequence,
    };

    var signByTablearray = this.state.signByTable;
    if (this.state.signByUpdating === false) {
      signByTablearray.push(currentSignByFormData);
    } else {
      signByTablearray[this.state.signByUpdatingIndex] = currentSignByFormData;
      //console.log(signByTablearray);
    }
    this.signByFormModalClose();
    await signByTablearray.sort(function (a, b) {
      return a.Sequence - b.Sequence;
    });

    this.setState({
      signByTable: signByTablearray,
    });
    console.log("signByTablearray=>", signByTablearray);
    this.setState({
      signByFirstName: "",
      signByLastName: "",
      signByEmail: "",
      signByMobile: "",
      signByCountryCode: "",
      signByPosition: "",
      signBySequence: "",
      signByUpdatingIndex: "",
      signByUpdating: false,
    });
    this.setState({ isLoading: false });
  };

  async removeFromSignbyArray(index) {
    this.setState({
      isLoading: true,
    });
    var signByTablearray = this.state.signByTable;
    signByTablearray.splice(index, 1);
    this.setState({
      signByTable: signByTablearray,
      isLoading: false,
    });
  }

  async updateFromSignbyArray(index) {
    this.setState({ isLoading: true });
    var signByTablearray = this.state.signByTable;
    var updateableInfo = signByTablearray[index];

    this.setState({
      signByFirstName: updateableInfo.FirstName,
      signByLastName: updateableInfo.Lastname,
      signByEmail: updateableInfo.Email,
      signByMobile: updateableInfo.Mobile,
      signByCountryCode: updateableInfo.countryCode,
      signByPosition: updateableInfo.SigPosition,
      signBySequence: updateableInfo.Sequence,
      signByUpdating: true,
      signByUpdatingIndex: index,
    });
    this.signByFormModalOpen();
    this.setState({ isLoading: false });
  }

  handleChangeDaysInWeek() {
    var that = this;
    var templates = this.state.DFDocs[this.state.documentIndex];
    var startDate = moment();
    var endDate = moment().add(1, "days");
    var daysInWeek = 0;
    //_ _ IF DOCS EXIST _ _//
    if (
      templates.CustomList !== null &&
      templates.CustomList.length > 0 &&
      this.state.rowIndex
    ) {
      // console.log(templates.CustomList[this.state.rowIndex].CustomFields);
      templates.CustomList[this.state.rowIndex].CustomFields.map(function (
        field,
        key2
      ) {
        if (field.Prompt === "startDate") {
          startDate = moment(field.DefaultValue);
        }
        if (field.Prompt === "endDate") {
          endDate = moment(field.DefaultValue);
        }
        if (field.Prompt === "days_in_week") {
          daysInWeek = field.DefaultValue ? field.DefaultValue : 0;
        }
        return null;
      });
    }

    if (moment(startDate).isSameOrBefore(endDate)) {
      var duration = moment.duration(endDate.diff(startDate));
      var calc_days = (endDate.diff(startDate, "days") + 1) % 7;
      var calc_weeks = endDate.diff(startDate, "weeks");

      if (calc_days >= daysInWeek) {
        calc_weeks++;
        calc_weeks = calc_weeks;
        calc_days = 0;
      } else {
        if (calc_days === 0) {
          calc_weeks++;
        }
        calc_weeks = calc_weeks;
      }

      if (
        templates.CustomList !== null &&
        templates.CustomList.length > 0 &&
        this.state.rowIndex
      ) {
        // console.log(templates.CustomList[this.state.rowIndex].CustomFields);
        templates.CustomList[this.state.rowIndex].CustomFields.map(function (
          field,
          key2
        ) {
          if (field.Prompt === "calc_days") {
            field.DefaultValue = calc_days;
          }
          if (field.Prompt === "calc_weeks") {
            field.DefaultValue = calc_weeks;
          }
          return null;
        });
      }
    }
  }

  handleChangeFromDateInline(date, docIndex, rowIndex) {
    var templates = this.state.DFDocs[docIndex];
    // console.log("AAAAAA",date)
    var endDate = moment().add(1, "days");
    var daysInWeek = 0;
    if (
      templates.CustomList !== null &&
      templates.CustomList.length > 0 &&
      rowIndex !== null
    ) {
      // console.log(templates.CustomList[this.state.rowIndex].CustomFields);
      templates.CustomList[rowIndex].CustomFields.map(function (field, key2) {
        if (field.Prompt === "endDate") {
          endDate = moment(field.DefaultValue);
        }
        if (field.Prompt === "days_in_week") {
          daysInWeek = field.DefaultValue ? field.DefaultValue : 0;
        }
        return null;
      });
    }
    let startDate = moment(date.format("YYYY-MM-DD"));
    endDate = moment(endDate.format("YYYY-MM-DD"));

    if (moment(date).isSameOrBefore(endDate)) {
      var calc_days = (endDate.diff(startDate, "days") + 1) % 7;
      var calc_weeks = endDate.diff(startDate, "weeks");
      if (calc_days >= daysInWeek) {
        calc_weeks++;
        calc_days = 0;
        calc_weeks = calc_weeks;
      } else {
        if (calc_days === 0) {
          calc_weeks++;
        }
        calc_weeks = calc_weeks;
      }

      if (
        templates.CustomList !== null &&
        templates.CustomList.length > 0 &&
        rowIndex !== null
      ) {
        templates.CustomList[rowIndex].CustomFields.map(function (field, key2) {
          if (field.Prompt === "startDate") {
            field.DefaultValue = date;
          }
          if (field.Prompt === "calc_days") {
            field.DefaultValue = calc_days;
          }
          if (field.Prompt === "calc_weeks") {
            field.DefaultValue = calc_weeks;
          }
          return null;
        });
      }
    } else {
      if (
        templates.CustomList !== null &&
        templates.CustomList.length > 0 &&
        rowIndex !== null
      ) {
        templates.CustomList[rowIndex].CustomFields.map(function (field, key2) {
          if (field.Prompt === "startDate") {
            field.DefaultValue = date;
          }
          if (field.Prompt === "calc_days") {
            field.DefaultValue = 0;
          }
          if (field.Prompt === "calc_weeks") {
            field.DefaultValue = 0;
          }
          return null;
        });
      }
    }

    this.setState({ customListCodeModalOpen: false });
  }

  handleChangeFromDate(date) {
    // console.log("change from date=>", date);
    var that = this;
    var templates = this.state.DFDocs[this.state.documentIndex];
    var endDate = moment().add(1, "days");
    var daysInWeek = 0;
    //_ _ IF DOCS EXIST _ _//
    if (
      templates.CustomList !== null &&
      templates.CustomList.length > 0 &&
      this.state.rowIndex
    ) {
      // console.log(templates.CustomList[this.state.rowIndex].CustomFields);
      templates.CustomList[this.state.rowIndex].CustomFields.map(function (
        field,
        key2
      ) {
        if (field.Prompt === "endDate") {
          endDate = moment(field.DefaultValue);
        }
        if (field.Prompt === "days_in_week") {
          daysInWeek = field.DefaultValue ? field.DefaultValue : 0;
        }
        return null;
      });
    }
    let startDate = moment(date.format("YYYY-MM-DD"));
    endDate = moment(endDate.format("YYYY-MM-DD"));
    startDate = moment(startDate);
    // console.log("DDDDDD", startDate);
    if (moment(date).isSameOrBefore(endDate)) {
      this.setState(
        {
          startDate: date,
        },
        () => console.log("SDATE", this.state.startDate)
      );
      var calc_days = (endDate.diff(startDate, "days") + 1) % 7;
      var calc_weeks = endDate.diff(startDate, "weeks");
      if (calc_days >= daysInWeek) {
        calc_weeks++;
        calc_days = 0;
        calc_weeks = calc_weeks;
      } else {
        if (calc_days === 0) {
          calc_weeks++;
        }
        calc_weeks = calc_weeks;
      }

      if (
        templates.CustomList !== null &&
        templates.CustomList.length > 0 &&
        this.state.rowIndex
      ) {
        // console.log(templates.CustomList[this.state.rowIndex].CustomFields);
        templates.CustomList[this.state.rowIndex].CustomFields.map(function (
          field,
          key2
        ) {
          if (field.Prompt === "startDate") {
            field.DefaultValue = date;
          }
          if (field.Prompt === "calc_days") {
            field.DefaultValue = Math.floor(calc_days);
          }
          if (field.Prompt === "calc_weeks") {
            field.DefaultValue = Math.floor(calc_weeks);
          }
          return null;
        });
      }
    } else {
      this.setState({
        startDate: date,
      });

      if (
        templates.CustomList !== null &&
        templates.CustomList.length > 0 &&
        this.state.rowIndex
      ) {
        // console.log(templates.CustomList[this.state.rowIndex].CustomFields);
        templates.CustomList[this.state.rowIndex].CustomFields.map(function (
          field,
          key2
        ) {
          if (field.Prompt === "startDate") {
            field.DefaultValue = date;
          }
          if (field.Prompt === "calc_days") {
            field.DefaultValue = 0;
          }
          if (field.Prompt === "calc_weeks") {
            field.DefaultValue = 0;
          }
          return null;
        });
      }
    }
  }

  handleChangeToDateInline(date, docIndex, rowIndex) {
    var templates = this.state.DFDocs[docIndex];

    var startDate = moment();
    var daysInWeek = 0;
    if (
      templates.CustomList !== null &&
      templates.CustomList.length > 0 &&
      rowIndex !== null
    ) {
      // console.log(templates.CustomList[this.state.rowIndex].CustomFields);
      templates.CustomList[rowIndex].CustomFields.map(function (field, key2) {
        if (field.Prompt === "startDate") {
          startDate = moment(field.DefaultValue);
        }
        if (field.Prompt === "days_in_week") {
          daysInWeek = field.DefaultValue ? field.DefaultValue : 0;
        }
        return null;
      });
    }
    startDate = moment(startDate.format("YYYY-MM-DD"));
    let endDate = moment(date.format("YYYY-MM-DD"));

    if (moment(date).isSameOrAfter(startDate)) {
      var calc_days = (endDate.diff(startDate, "days") + 1) % 7;
      var calc_weeks = endDate.diff(startDate, "weeks");
      if (calc_days >= daysInWeek) {
        calc_weeks++;
        calc_days = 0;
        calc_weeks = calc_weeks;
      } else {
        if (calc_days === 0) {
          calc_weeks++;
        }
        calc_weeks = calc_weeks;
      }
      if (
        templates.CustomList !== null &&
        templates.CustomList.length > 0 &&
        rowIndex !== null
      ) {
        // console.log(templates.CustomList[this.state.rowIndex].CustomFields);
        templates.CustomList[rowIndex].CustomFields.map(function (field, key2) {
          if (field.Prompt === "endDate") {
            field.DefaultValue = date;
          }
          if (field.Prompt === "calc_days") {
            field.DefaultValue = calc_days;
          }
          if (field.Prompt === "calc_weeks") {
            field.DefaultValue = calc_weeks;
          }
          return null;
        });
      }
    } else {
      if (
        templates.CustomList !== null &&
        templates.CustomList.length > 0 &&
        rowIndex !== null
      ) {
        // console.log(templates.CustomList[this.state.rowIndex].CustomFields);
        templates.CustomList[rowIndex].CustomFields.map(function (field, key2) {
          if (field.Prompt === "endDate") {
            field.DefaultValue = date;
          }
          if (field.Prompt === "calc_days") {
            field.DefaultValue = 0;
          }
          if (field.Prompt === "calc_weeks") {
            field.DefaultValue = 0;
          }
          return null;
        });
      }
    }

    this.setState({ customListCodeModalOpen: false });
  }

  handleChangeToDate(date) {
    var that = this;
    var templates = this.state.DFDocs[this.state.documentIndex];
    var startDate;
    var daysInWeek;
    //_ _ IF DOCS EXIST _ _//

    if (
      templates.CustomList !== null &&
      templates.CustomList.length > 0 &&
      this.state.rowIndex
    ) {
      // console.log(templates.CustomList[this.state.rowIndex].CustomFields);
      templates.CustomList[this.state.rowIndex].CustomFields.map(function (
        field,
        key2
      ) {
        if (field.Prompt === "startDate") {
          startDate = moment(field.DefaultValue);
        }
        if (field.Prompt === "days_in_week") {
          daysInWeek = field.DefaultValue ? field.DefaultValue : 0;
        }
        return null;
      });
    }
    startDate = moment(startDate.format("YYYY-MM-DD"));
    let endDate = moment(date.format("YYYY-MM-DD"));
    if (moment(date).isSameOrAfter(startDate)) {
      this.setState({ endDate: date });
      var calc_days = (endDate.diff(startDate, "days") + 1) % 7;
      var calc_weeks = endDate.diff(startDate, "weeks");
      if (calc_days >= daysInWeek) {
        calc_weeks++;
        calc_days = 0;
        calc_weeks = calc_weeks;
      } else {
        if (calc_days === 0) {
          calc_weeks++;
        }
        calc_weeks = calc_weeks;
      }

      if (
        templates.CustomList !== null &&
        templates.CustomList.length > 0 &&
        this.state.rowIndex
      ) {
        // console.log(templates.CustomList[this.state.rowIndex].CustomFields);
        templates.CustomList[this.state.rowIndex].CustomFields.map(function (
          field,
          key2
        ) {
          if (field.Prompt === "endDate") {
            field.DefaultValue = date;
          }
          if (field.Prompt === "calc_days") {
            field.DefaultValue = calc_days;
          }
          if (field.Prompt === "calc_weeks") {
            field.DefaultValue = calc_weeks;
          }
          return null;
        });
      }
    } else {
      this.setState({ endDate: date });
      if (
        templates.CustomList !== null &&
        templates.CustomList.length > 0 &&
        this.state.rowIndex
      ) {
        // console.log(templates.CustomList[this.state.rowIndex].CustomFields);
        templates.CustomList[this.state.rowIndex].CustomFields.map(function (
          field,
          key2
        ) {
          if (field.Prompt === "endDate") {
            field.DefaultValue = date;
          }
          if (field.Prompt === "calc_days") {
            field.DefaultValue = 0;
          }
          if (field.Prompt === "calc_weeks") {
            field.DefaultValue = 0;
          }
          return null;
        });
      }
    }
  }

  renderCustomListCode() {
    // ___ Handle Dynamic States ___ //
    //_______________________________//

    var that = this;
    var fields;
    var hidefields;
    var generalSettings = this.state.generalSettings;
    if (this.state.documentIndex) {
      var documents = this.state.DFDocs[this.state.documentIndex];
      const rowIndex = this.state.rowIndex;
      var TBA = this.state.TBA;
      var sortData = documents.CustomList[
        this.state.rowIndex
      ].CustomFields.sort((a, b) => (a.order > b.order ? 1 : -1));

      var amount = documents.CustomList[this.state.rowIndex].CustomFields.find(
        (obj) => obj.Prompt === "amount"
      );
      var calc_days = documents.CustomList[
        this.state.rowIndex
      ].CustomFields.find((obj) => obj.Prompt === "calc_days");

      var calc_weeks = documents.CustomList[
        this.state.rowIndex
      ].CustomFields.find((obj) => obj.Prompt === "calc_weeks");

      // console.log("check=>",TBA)
      fields = sortData.map(function (field, index) {
        var generateState = "ROW" + rowIndex + "CustomList" + index;
        // console.log("CustomList" + index, field.Prompt);

        if (!field.Hide) {
          return (
            <ListInput
              key={index}
              Prompt={field.Prompt}
              Hide={field.Hide}
              TBA={TBA}
              //Value={that.state[generateState]}
              Value={field.DefaultValue}
              Length={field.Length}
              Index={that.state.documentIndex}
              RowIndex={rowIndex}
              Readonly={field.Readonly}
              order={field.order}
              generalSettings={generalSettings}
              Required={field.Required}
              Script={field.Script}
              Tag={field.Tag}
              Type={field.Type}
              Amount={amount.DefaultValue}
              disabledFromDate={amount.DefaultValue === 0 ? true : false}
              disabledEndDate={amount.DefaultValue === 0 ? true : false}
              onChange={that.onChangeCustomListFields.bind(that)}
              onChangeFromDate={that.handleChangeFromDate.bind(that)}
              onChangeToDate={that.handleChangeToDate.bind(that)}
            />
          );
        }
        return null;
      });

      hidefields = sortData.map(function (field, index) {
        //console.log('field value=>',field);
        var generateState = "ROW" + rowIndex + "CustomList" + index;
        if (field.Hide) {
          return (
            <ListInput
              key={index}
              Prompt={field.Prompt}
              Hide={field.Hide}
              TBA={TBA}
              //Value={that.state[generateState]}
              Value={field.DefaultValue}
              order={field.order}
              Length={field.Length}
              Index={that.state.documentIndex}
              RowIndex={rowIndex}
              Readonly={field.Readonly}
              Required={field.Required}
              Script={field.Script}
              Tag={field.Tag}
              Type={field.Type}
              Amount={field.Amount}
              disabledFromDate={field.Amount === 0 ? true : false}
              disabledEndDate={field.Amount === 0 ? true : false}
              onChange={that.onChangeCustomListFields.bind(that)}
              onChangeFromDate={that.handleChangeFromDate.bind(that)}
              onChangeToDate={that.handleChangeToDate.bind(that)}
            />
          );
        }
        return null;
      });
    }
    return (
      <React.Fragment>
        <div
          id="sortable"
          className="panel panel-default help_panel_group sortable"
        >
          <div id="tagsForm" className="sortable_list connectedSortable">
            {fields}
          </div>

          <div className="panel-heading panel_width exclude">
            <h4 className="panel-title">
              <a
                className="accordion-toggle collapsed text_change"
                data-toggle="collapse"
                data-parent="#sortable"
                href="#hide_detail"
              >
                More Detail
              </a>
            </h4>
          </div>
          <div id="hide_detail" className="panel-collapse collapse ">
            <div className="panel-body p0 ">
              <div className="clear30"></div>

              {hidefields}
            </div>
          </div>
        </div>

        <div className="col-sm-12 p0 mrg_bot_custom10">
          <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
            TBA:
          </div>
          <div className="col-xs-12 col-sm-8 col-md-8">
            <label className="check_box_pop">
              <input
                type="checkbox"
                name="TBA"
                value={this.state.TBA}
                checked={this.state.TBA}
                onChange={(event) =>
                  this.onChangeCustomListFields(event, 0, null, null)
                }
              />
              <span className="checkmark report_checkmark"></span>
            </label>
          </div>
        </div>
      </React.Fragment>
    );
  }
  renderDocuments() {
    // ___ Handle Dynamic States ___ //
    //_______________________________//
    //console.log('all docs: ',this.state.DFDocs);
    var that = this;
    var generalSettings = that.state.generalSettings;
    // console.log("DF", this.state.DFDocs);
    var documents = this.state.DFDocs.map(function (Documents, key) {
      if (Documents.DocDetails) {
        // ___ Custom Fields Generation ___ //
        //__________________________________//
        var customField = Documents.DocDetails.map(function (
          customField,
          inner
        ) {
          //console.log("Each Custom Fields => ",customField);
          let Type = customField.Type;
          let DBField = customField.DBField;
          let RequiredInternal = customField.ReqInt;
          let HideInternal = customField.HideInt;
          let ReadOnlyInternal = customField.ReadOnlyInt;

          //let Order      = customField.Order;
          let Promt = customField.Promt;
          let Name = customField.Promt
            ? customField.Promt.replace(/ /g, "_")
            : " N?A";
          let Tag = customField.Tag;
          //console.log('Tag',Tag);
          //let Validation = customField.Validation;
          //let Value      = customField.Value;
          let SubHeading = customField.SubHeading;
          let DataList = customField.datalist;
          let DefaultValue = "";
          if (customField.Type === "Date") {
            DefaultValue = customField.DefaultValue
              ? customField.DefaultValue
              : new Date();
          }
          // else if(customField.Type==="File")
          // {
          //  console.log("CHECKED")
          // }
          else {
            DefaultValue = customField.DefaultValue || "";
          }
          let comments = customField.Comments;
          //console.log('DefaultValue=>', DefaultValue);
          var generateState = "ROW" + key + "DocDetails" + inner;
          //ReqInt==="1"
          if (!HideInternal) {
            let Required = customField.ReqInt === "1" ? true : false;
            var ErrorMsg = DefaultValue
              ? ""
              : that.state.customFormErrors[generateState];
            if (Required) {
              that.state[generateState + "Valid"] = DefaultValue ? true : false;
              that.state.customFormErrors[generateState] = ErrorMsg
                ? ErrorMsg
                : "";
            }
            return (
              <>
                <TextInput
                  ID={"field-id-" + key + "" + inner}
                  innerIndex={inner}
                  Type={Type}
                  Value={that.state[generateState]}
                  Index={key}
                  Name={Name}
                  Promt={Promt}
                  DataList={DataList}
                  DBField={DBField + "_" + Promt}
                  SubHeading={SubHeading}
                  generalSettings={generalSettings}
                  DefaultValue={DefaultValue}
                  RequiredInternal={RequiredInternal}
                  HideInternal={HideInternal}
                  ReadOnlyInternal={ReadOnlyInternal}
                  Tag={Tag}
                  Required={Required}
                  generateState={generateState}
                  onChange={that.onChangeGeneric.bind(that)}
                  onBlur={that.onChangeSetTagValue.bind(that)}
                  previewAttachment={that.previewAttachment.bind(that)}
                  onDropZone={(files) => that.onDropZone(files, key, inner)}
                  onRemoveAttachment={() =>
                    that.onRemoveAttachment(DefaultValue, key, inner)
                  }
                  customFormErrors={that.state.customFormErrors[generateState]}
                  comments={comments}
                />
              </>
            );
          }
        });

        // ___ Custom CODE List Generation ___ //
        //_____________________________________//
        if (Documents.CustomList) {
          Documents.CustomList.map((obj, i) => {
            obj.CustomFields.sort((a, b) => (a.order > b.order ? 1 : -1));
          });
          var unit_order = 1;
          var unit_desc_order = 2;
          var rate_order = 3;
          var factor_order = 4;
          var amount_order = 5;
          var days_in_week_order = 6;
          var startDate_order = 7;
          var endDate_order = 8;
          var capped_amount_order = 9;
          var chart_sort_order = 10;
          var chart_code_order = 11;
          var flag_order = 12;
          var payment_flag_order = 13;
          var calc_days_order = 14;
          var calc_weeks_order = 15;
          if (Documents.CustomList && Documents.CustomList.length) {
            Documents.CustomList[
              Documents.CustomList.length - 1
            ].CustomFields.map((obj, i) => {
              if (obj.Prompt === "unit") {
                unit_order = obj.order;
              }
              if (obj.Prompt === "unit_desc") {
                unit_desc_order = obj.order;
              }
              if (obj.Prompt === "rate") {
                rate_order = obj.order;
              }
              if (obj.Prompt === "factor") {
                factor_order = obj.order;
              }
              if (obj.Prompt === "amount") {
                amount_order = obj.order;
              }
              if (obj.Prompt === "capped_amount") {
                capped_amount_order = obj.order;
              }
              if (obj.Prompt === "startDate") {
                startDate_order = obj.order;
              }
              if (obj.Prompt === "endDate") {
                endDate_order = obj.order;
              }
              if (obj.Prompt === "chart_sort") {
                chart_sort_order = obj.order;
              }
              if (obj.Prompt === "chart_code") {
                chart_code_order = obj.order;
              }
              if (obj.Prompt === "flag") {
                flag_order = obj.order;
              }
              if (obj.Prompt === "payment_flag") {
                payment_flag_order = obj.order;
              }
              if (obj.Prompt === "calc_days") {
                calc_days_order = obj.order;
              }
              if (obj.Prompt === "calc_weeks") {
                calc_weeks_order = obj.order;
              }
              if (obj.Prompt === "days_in_week") {
                days_in_week_order = obj.order;
              }
            });
          }
          Documents.CustomList.map((field, i) => {
            field.CustomFields.map((obj, j) => {
              if (obj.Prompt === "unit") {
                obj.order = unit_order;
              }
              if (obj.Prompt === "unit_desc") {
                obj.order = unit_desc_order;
              }
              if (obj.Prompt === "rate") {
                obj.order = rate_order;
              }
              if (obj.Prompt === "factor") {
                obj.order = factor_order;
              }
              if (obj.Prompt === "amount") {
                obj.order = amount_order;
              }
              if (obj.Prompt === "capped_amount") {
                obj.order = capped_amount_order;
              }
              if (obj.Prompt === "startDate") {
                obj.order = startDate_order;
              }
              if (obj.Prompt === "endDate") {
                obj.order = endDate_order;
              }
              if (obj.Prompt === "chart_sort") {
                obj.order = chart_sort_order;
              }
              if (obj.Prompt === "chart_code") {
                obj.order = chart_code_order;
              }
              if (obj.Prompt === "flag") {
                obj.order = flag_order;
              }
              if (obj.Prompt === "payment_flag") {
                obj.order = payment_flag_order;
              }
              if (obj.Prompt === "calc_days") {
                obj.order = calc_days_order;
              }
              if (obj.Prompt === "calc_weeks") {
                obj.order = calc_weeks_order;
              }
              if (obj.Prompt === "days_in_week") {
                obj.order = days_in_week_order;
              }
            });
          });
          var customListCode = Documents.CustomList.map(function (
            customListCode,
            inner
          ) {
            var newarray = [];
            newarray["CustomCode"] = customListCode.CustomCode;
            newarray["Desc"] = customListCode.Desc;
            newarray["TBA"] = customListCode.TBA;
            var TBA = newarray["TBA"];
            var i = 0;
            var amount_check;
            customListCode.CustomFields.map(function (array, key) {
              if (array.Prompt === "unit") {
                newarray[i] = array;
              }
              if (array.Prompt === "unit_desc") {
                newarray[i] = array;
              }
              if (array.Prompt === "rate") {
                newarray[i] = array;
              }
              if (array.Prompt === "factor") {
                newarray[i] = array;
              }
              if (array.Prompt === "amount") {
                newarray[i] = array;
                amount_check = array.DefaultValue;
              }
              if (array.Prompt === "capped_amount") {
                newarray[i] = array;
              }
              if (array.Prompt === "startDate") {
                newarray[i] = array;
              }
              if (array.Prompt === "endDate") {
                newarray[i] = array;
              }
              if (array.Prompt === "chart_sort") {
                newarray[i] = array;
              }
              if (array.Prompt === "chart_code") {
                newarray[i] = array;
              }
              if (array.Prompt === "flag") {
                newarray[i] = array;
              }
              if (array.Prompt === "payment_flag") {
                newarray[i] = array;
              }
              if (array.Prompt === "calc_days") {
                newarray[i] = array;
              }
              if (array.Prompt === "calc_weeks") {
                newarray[i] = array;
              }
              i++;
              return null;
            });
            // console.log("NEWARRAY", newarray);

            return (
              <CustomCodeList
                key={inner}
                customCode={newarray}
                TBA={TBA}
                RowIndex={inner}
                DocumentIndex={key}
                Amount={amount_check}
                handleModalOpen={that.customListCodeModalOpen}
                updateCellsValue={that.updateCellsValue}
                onChangeFromDateInline={that.handleChangeFromDateInline.bind(
                  that
                )}
                onChangeToDateInline={that.handleChangeToDateInline.bind(that)}
              />
            );
          });
        }
        // console.log("its document ", Documents);

        return (
          <div key={key} className="panel panel-default help_panel_group">
            <div className="panel-heading help_panel_heading">
              <h4 className="panel-title">
                <a
                  className="accordion-toggle collapsed"
                  data-toggle="collapse"
                  data-parent="#accordion"
                  href={"#collapse" + key}
                >
                  &nbsp; {Documents.Desc}
                </a>
              </h4>
            </div>
            <div id={"collapse" + key} className="panel-collapse collapse">
              <div className="panel-body help_body_box id_panel_side_by p0">
                <div className="clear30"></div>
                {Documents.CustomList === null ? (
                  ""
                ) : Documents.CustomList.length > 0 ? (
                  <div className="col-sm-12 col-md-12 label_attchment_form">
                    Custom List Codes: {Documents.Desc}
                  </div>
                ) : (
                  ""
                )}

                <div className="clear30"></div>
                <div className="id_custom_list_table table-responsive">
                  {Documents.CustomList === null ? (
                    ""
                  ) : Documents.CustomList.length > 0 ? (
                    <table
                      width="100%"
                      border="0"
                      cellSpacing="2"
                      cellPadding="2"
                    >
                      <thead>
                        <tr>
                          <th
                            width="13%"
                            align="center"
                            className="text-center"
                          >
                            Pmt Code
                          </th>
                          <th
                            width="13%"
                            align="center"
                            className="text-center"
                          >
                            Description
                          </th>
                          {
                            Documents.CustomList[
                              Documents.CustomList.length - 1
                            ].CustomFields.map((obj, index) => {
                              if (obj.Prompt === "amount") {
                                return (
                                  <th
                                    width="5%"
                                    align="center"
                                    className="text-center"
                                  >
                                    Amount
                                  </th>
                                );
                              }
                              if (obj.Prompt === "capped_amount") {
                                return (
                                  <th
                                    width="9%"
                                    align="center"
                                    className="text-center"
                                  >
                                    Capped
                                  </th>
                                );
                              }
                              if (obj.Prompt === "startDate") {
                                return (
                                  <th
                                    width="10%"
                                    align="center"
                                    className="text-center"
                                  >
                                    DateFrom
                                  </th>
                                );
                              }
                              if (obj.Prompt === "endDate") {
                                return (
                                  <th
                                    width="10%"
                                    align="center"
                                    className="text-center"
                                  >
                                    DateTo
                                  </th>
                                );
                              }
                              if (obj.Prompt === "calc_weeks") {
                                return (
                                  <th
                                    width="10%"
                                    align="center"
                                    className="text-center"
                                  >
                                    Weeks Calc
                                  </th>
                                );
                              }
                              if (obj.Prompt === "calc_days") {
                                return (
                                  <th
                                    width="10%"
                                    align="center"
                                    className="text-center"
                                  >
                                    Days Calc
                                  </th>
                                );
                              }
                              if (obj.Prompt === "chart_code") {
                                return (
                                  <th
                                    width="10%"
                                    align="center"
                                    className="text-center"
                                  >
                                    ChartCode
                                  </th>
                                );
                              }
                            })

                            // <th
                            //   width="10%"
                            //   align="center"
                            //   className="text-center"
                            // >
                            //   Weeks Calc
                            // </th>
                            // <th
                            //   width="10%"
                            //   align="center"
                            //   className="text-center"
                            // >
                            //   Days Calc
                            // </th>
                            // <th
                            //   width="10%"
                            //   align="center"
                            //   className="text-center"
                            // >
                            //   ChartCode
                            // </th>
                          }
                          <th
                            width="10%"
                            align="center"
                            className="text-center"
                          >
                            TBA
                          </th>
                          <th
                            width="10%"
                            align="center"
                            className="text-center"
                          ></th>
                        </tr>
                      </thead>
                      <tbody>{customListCode}</tbody>
                    </table>
                  ) : (
                    ""
                  )}
                </div>
                <div className="clear30"></div>
                {customField}
                <div className="clear30"></div>
              </div>
            </div>
          </div>
        );
      }
      return null;
    });

    return documents;
  }

  updateCellsValue = (event, index, key) => {
    if (key === "TBA") {
      var CustomListData = this.state.DFDocs[0].CustomList[index];
      if (CustomListData.TBA === "checked") {
        CustomListData.TBA = "";
        this.setState({ TBA: "" });
        var value;
        var templates = this.state.DFDocs[0];
        console.log("DOCSSS", this.state.DFDocs);
        templates.CustomList[index].CustomFields.map((obj, i) => {
          if (obj.Prompt === "endDate") {
            value = obj.DefaultValue;
          }
        });
        value = moment(value);
        this.handleChangeToDateInline(value, 0, index);
      } else {
        CustomListData.TBA = "checked";
        this.setState({ TBA: "checked" });
        var templates = this.state.DFDocs[0];
        templates.CustomList[index].CustomFields.map((obj, i) => {
          if (obj.Prompt === "calc_days") {
            obj.DefaultValue = 0;
          }
          if (obj.Prompt === "calc_weeks") {
            obj.DefaultValue = 0;
          }
        });
        this.setState({ calc_weeks: 0, calc_days: 0 });
      }
      console.log("TESTING", CustomListData);
    }
    if (key === "cell_code") {
      var value = event.target.innerHTML;
      var CustomListData = this.state.DFDocs[0].CustomList[index];
      console.log(CustomListData);
      CustomListData.CustomCode = value;
      console.log(CustomListData);
      console.log(this.state.DFDocs);
    }
    if (key === "cell_description") {
      var value = event.target.innerHTML;
      var CustomListData = this.state.DFDocs[0].CustomList[index];
      console.log(CustomListData);
      CustomListData.Desc = value;
      console.log(CustomListData);
      console.log(this.state.DFDocs);
    }
    if (key === "capped_amount") {
      var value = event.target.innerHTML;
      this.state.DFDocs[0].CustomList[index].CustomFields.map((obj, r) => {
        if (obj.Prompt === "capped_amount") {
          this.state.DFDocs[0].CustomList[index].CustomFields[r].DefaultValue =
            value;
          console.log("=", this.state.DFDocs);
        }
      });
    }
    if (key === "chart_code") {
      var value = event.target.innerHTML;
      this.state.DFDocs[0].CustomList[index].CustomFields.map((obj, r) => {
        if (obj.Prompt === "chart_code") {
          this.state.DFDocs[0].CustomList[index].CustomFields[r].DefaultValue =
            value;
          console.log("=", this.state.DFDocs);
        }
      });
    }
  };
  handleApproverGroupSelect = async (event) => {
    let index = event.target.selectedIndex;
    let element = event.target.childNodes[index];
    let approvalGroupTitle = element.getAttribute("title");

    this.setState({
      approvalGroupTitle: approvalGroupTitle,
      generalFormErrors: {
        ...this.state.generalFormErrors,
        approvalGroupTitle: "",
      },
    });

    if (event.target.value === "-1") {
      this.setState({
        ApprovalChain: [],
        approverGroup: "",
        approverGroupID: "",
        generalFormErrors: {
          ...this.state.generalFormErrors,
          approvalGroupTitle: "",
        },
      });
      return;
    }
    let name = event.target.name;
    var approverName = $('select[name="approverGroup"] :selected').attr(
      "class"
    );
    let value = event.target.value;
    const selectedAproval = this.state.usersList.find(
      (el) => el.Guid === value
    );
    this.setState({ isLoading: true });
    const aprovalChain = selectedAproval.approvalData.Approvers;
    console.log("aprovalChain=>", aprovalChain);
    this.setState(
      {
        ApprovalChain: aprovalChain,
        approverGroup: approverName,
        approverGroupID: value,
        AppGroup: approvalGroupTitle,
        isLoading: false,
      },
      () => {
        this.validateField(name, value);
      }
    );
  };

  sendDocument = async (formData) => {
    this.setState({ isLoading: true });
    var formData = formData;

    var CurrentApprovalSeq =
      formData.ApprovalChain.length > 0
        ? formData.ApprovalChain[0].Sequence
        : "1";
    //console.log('CurrentApprovalSeq: ',CurrentApprovalSeq);
    var CurrentApproval = [];
    var externalSigner = false;

    formData.ApprovalChain.map(function (approver, index) {
      /*if(approver.userGuid === 'external-signer'){
               externalSigner = true;
          }*/
      if (approver.Sequence === CurrentApprovalSeq) {
        CurrentApproval.push(approver);
      }
    });

    //console.log('CurrentApproval: ',CurrentApproval); return false;

    var NewActivity = {
      Description: "Sent Digital Form to Approver.",
      IPAddress: this.state.ipv4,
      Timestamp: moment().unix(),
      Type: "SendDigitalForm",
      User: localStorage.getItem("userName"),
      profile_pic_url: localStorage.getItem("profile_pic_url"),
    };
    var Activity = formData.Activity;
    Activity.push(NewActivity);

    var body = {
      Guid: formData.Guid,
      updateAction: "SendDocument",
      Activity: Activity,
      ApprovalChain: formData.ApprovalChain,
      ApprovalGroup: formData.ApprovalGroup,
      ApprovalGroupguid: formData.ApprovalGroupguid,
      FormpackGuid: formData.FormpackGuid,
      Comments: formData.Comments,
      CreatedTS: formData.CreatedTS,
      CurrentApproval: CurrentApproval,
      CurrentApprovalSeq: parseInt(CurrentApprovalSeq),
      CustomFields: formData.CustomFields,
      Documents: formData.Documents,
      Email: formData.Email,
      ExtSigners: formData.ExtSigners,
      FirstName: formData.FirstName,
      FormPack: formData.FormPack,
      LastName: formData.LastName,
      CountryCode: formData.CountryCode,
      Mobile: formData.Mobile,
      Timestamp: formData.Timestamp,
      UserName: formData.UserName,
      ProjectName: formData.ProjectName ? formData.ProjectName : "",
      ProjectGuid: formData.ProjectGuid ? formData.ProjectGuid : "",
    };

    if (
      CurrentApproval.length > 0 &&
      CurrentApproval[0].userGuid === "external-signer"
    ) {
      var emailTemplate = this.getDefaultEmailContent("New Contract");
      if (emailTemplate) {
        this.sendLinkToSigner(body, emailTemplate);
      } else {
        toast.error("Default email template does not exist.", {
          autoClose: 5000,
        });
      }
    } else {
      var emailTemplate = this.getDefaultEmailContent("Forms submitted");
      if (emailTemplate) {
        this.sendLinkToApprovers(body, emailTemplate);
      } else {
        toast.error("Default email template does not exist.", {
          autoClose: 5000,
        });
      }

      this.updateDigitlForms(body)
        .then((res) => {
          if (res.status) {
            toast.success("Email has been sent to Aprover", {
              autoClose: 5000,
            });
          } else {
            toast.error("Error in sending, please try again later", {
              autoClose: 5000,
            });
          }
          //this.setState({ isLoading:false });
        })
        .catch((err) => {
          toast.error("Error in sending, please try again later", {
            autoClose: 5000,
          });
          //this.setState({ isLoading:false });
        });

      this.props.history.push({
        pathname: "/dashboard/documents",
        state: { successMessage: true },
      });
    }

    //console.log(body);
  };

  getDefaultEmailContent(type) {
    var emailTemplateDate = false;
    this.state.EmailTemplates.filter((item) => {
      if (item.Default === 1 && item.Type === type) {
        emailTemplateDate = item;
      }
    });
    return emailTemplateDate;
  }

  updateDigitlForms(body) {
    return this.props.postRequest("/documents/update-document", body);
  }

  sendLinkToApprovers = async (formData, emailTemplate) => {
    //console.log('emailTemplate=>', emailTemplate);
    var subject = emailTemplate.Subject;
    var BodyText = emailTemplate.BodyText;
    var message = emailTemplate.BodyText;
    var returned_data = {
      to: "",
      message: message,
    };

    formData.CurrentApproval.map(async (approver) => {
      //console.log('approver guid=>', approver);
      returned_data = await this.replacePlaceHodler(
        message,
        approver.userGuid,
        formData.Guid
      );
      //console.log('returned_data=>', returned_data);
      this.sendEmailToSigner(returned_data.to, subject, returned_data.message)
        .then((data) => {
          if (data.status === true) {
            toast.success("Email has been sent to approvers successfully", {
              autoClose: 5000,
            });
          } else {
            toast.error("Error in sending email, please try again later", {
              autoClose: 5000,
            });
            this.setState({ isLoading: false });
          }
        })
        .catch((err) => {
          console.error(err, err.stack);
          this.setState({ isLoading: false });
        });
    });
  };

  sendLinkToSigner = async (formData, emailTemplate) => {
    var ExtSignerData = formData.ExtSigners[0];
    var ExtSignerName = ExtSignerData.FirstName + " " + ExtSignerData.Lastname;
    var ccEmail = null;
    var ExText = "";

    var subject = emailTemplate.Subject;
    var BodyText = emailTemplate.BodyText;
    var message = emailTemplate.BodyText;

    try {
      let user_data = await this.props.postRequest("/users/get-by-email", {
        userEmail: this.state.generalEmail,
        companyGuid: localStorage.getItem("companyGuid"),
      });
      if (user_data.status) {
        this.setState({ Rguid_user: user_data.guid });
      } else {
        this.setState({ Rguid_user: null });
      }
    } catch (err) {
      this.setState({ Rguid_user: null });
    }

    await this.recordOnlineFormData(
      this.state.Rguid_user,
      ExtSignerName,
      ExtSignerData.Email,
      ExtSignerData.Mobile,
      formData.Guid,
      "SendableUrl",
      formData.UserName
    )
      .then(async (data) => {
        if (data.status === true) {
          var SendableUrl = this.LinkOfProjectedUser(data.guid);
          //console.log('SendableUrl: ', SendableUrl);
          message = await this.replaceExternalPartyPlaceHodler(
            message,
            data.guid,
            formData.Guid
          );
          this.sendEmailToSigner(ExtSignerData.Email, subject, message)
            .then((data) => {
              if (data.status === true) {
                //toast.success("Email has been sent successfully", { autoClose: 5000 });

                this.updateDigitlForms(formData)
                  .then((res) => {
                    if (res.status) {
                      toast.success("Email Sent to Aprover", {
                        autoClose: 5000,
                      });
                    } else {
                      toast.error("Error in sending, please Try again later", {
                        autoClose: 5000,
                      });
                    }
                    //this.setState({ isLoading:false });
                  })
                  .catch((err) => {
                    //console.log('Error in Email: ',err);
                    toast.error("Error in sending, please Try again later", {
                      autoClose: 5000,
                    });
                    //this.setState({ isLoading:false });
                  });
              } else {
                toast.error("Error in sending Email , Please Try again later", {
                  autoClose: 5000,
                });
              }
            })
            .catch((err) => {
              console.error(err, err.stack);
            });
        } else {
          toast.error("Error in Recording Link , Please Try again later", {
            autoClose: 5000,
          });
        }
      })
      .catch((err) => {
        toast.error("Error in Recording Link , Please Try again later", {
          autoClose: 5000,
        });
      });

    setTimeout(
      function () {
        this.props.history.push({
          pathname: "/dashboard/documents",
          state: { successMessage: true },
        });
      }.bind(this),
      3000
    );
  };

  replaceExternalPartyPlaceHodler = async (
    message,
    linkGuid,
    formRecordGuid
  ) => {
    let word = /\{{(.*?)\}}/g;
    let tempArray = [];
    let responceplaceHolder = "";
    let placeHolderArray = [];

    for (let m = word.exec(message); m; m = word.exec(message)) {
      placeHolderArray.push(m[1]);
    }

    try {
      var response = await this.props.postRequest(
        "/email-templates/get-placeholder-data",
        {
          placeholderNames: [...placeHolderArray, "ContractLink"],
          companyGuid: localStorage.getItem("companyGuid"),
          formRecordGuid: formRecordGuid,
          ofLinkGuid: linkGuid || undefined,
        }
      );
      if (response.status) {
        responceplaceHolder = response.placeholders;
        responceplaceHolder.map((items) => {
          const replaceKey = "{{" + items.name + "}}";
          message = message.split(replaceKey).join(items.value);
        });
        return message;
      } else {
        return message;
      }
    } catch (err) {
      console.log("email-placeholders/get-email-placeholders Error=>", err);
      return message;
    }
  };

  replacePlaceHodler = async (message, guid, formRecordGuid) => {
    let word = /\{{(.*?)\}}/g;
    let tempArray = [];
    let responceplaceHolder = "";
    let userData = {};
    var output = {
      to: "",
      message: "",
    };

    for (let m = word.exec(message); m; m = word.exec(message)) {
      this.state.placeHolderArray.push(m[1]);
    }

    try {
      let user_response = await this.props.postRequest(
        "/users/getsingleuserguid",
        { Guid: guid }
      );
      if (user_response.status) {
        userData = {
          ...user_response.result,
          CompanyID: localStorage.getItem("companyID"),
        };
        output.to = user_response.result.EmailAddress;
      }

      var response = await this.props.postRequest(
        "/email-templates/get-placeholder-data",
        {
          placeholderNames: this.state.placeHolderArray,
          companyGuid: localStorage.getItem("companyGuid"),
          formRecordGuid: formRecordGuid,
        }
      );
      if (response.status) {
        responceplaceHolder = response.placeholders;
        responceplaceHolder.map((item) => {
          const replaceKey = "{{" + item.name + "}}";
          message = message.split(replaceKey).join(item.value);
        });

        output.message = message;
        return output;
      } else {
        output.message = message;
        return output;
      }
    } catch (err) {
      console.log("email-placeholders/get-email-placeholders Error=>", err);
      output.message = message;
      return output;
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    //console.log("generalFormErrors=>", this.state.generalFormErrors);
    //console.log("customFormErrors=>", this.state.customFormErrors);
    var submit_type = event.target.attributes.getNamedItem("data-type").value;
    var FormValid = true;
    let generalEmail = this.state.generalEmailValid;
    let generalLastName = this.state.generalLastNameValid;
    let generalFirstName = this.state.generalFirstNameValid;
    let generalProjectGuid = this.state.generalProjectGuidValid;
    let generalFormPackGuid = this.state.generalFormPackGuidValid;
    let generalFormErrors = Object.assign({}, this.state.generalFormErrors);
    let generalFormValid = true;

    if (generalEmail === false || generalEmail === null) {
      generalFormErrors.email = "Email should be username@domain.com";
      generalFormValid = false;
    }
    if (generalFirstName === false || generalFirstName === null) {
      generalFormErrors.firstName = "This field is required";
      generalFormValid = false;
    }
    if (generalLastName === false || generalLastName === null) {
      generalFormErrors.lastName = "This field is required";
      generalFormValid = false;
    }
    if (generalProjectGuid === false || generalProjectGuid === null) {
      generalFormErrors.projectGuid = "This field is required";
      generalFormValid = false;
    }
    if (generalFormPackGuid === false || generalFormPackGuid === null) {
      generalFormErrors.formPackGuid = "This field is required";
      generalFormValid = false;
    }
    if (
      this.state.generalCountryCode === "" ||
      this.state.generalCountryCode === null
    ) {
      generalFormErrors.countryCode = "This field is required";
      generalFormValid = false;
    }
    if (this.state.generalMobile === "" || this.state.generalMobile === null) {
      generalFormErrors.mobile = "This field is required";
      generalFormValid = false;
    }

    if (!this.state.ApprovalChain || this.state.ApprovalChain.length === 0) {
      generalFormErrors.approvalGroupTitle = "This field is required";
      generalFormValid = false;
    }

    this.setState({ generalFormErrors, generalFormValid });

    let customFormErrors = Object.assign({}, this.state.customFormErrors);
    for (var err in customFormErrors) {
      //console.log(err+"=>", this.state[err+"Valid"]);
      if (
        this.state[err + "Valid"] === false ||
        this.state[err + "Valid"] === null
      ) {
        customFormErrors[err] = "This field is required";
        FormValid = false;
      }
    }
    this.setState({ customFormErrors });

    if (generalFormValid && FormValid) {
      this.setState({ isLoading: true });
      try {
        var customFields;
        if (this.state.digitalForms) {
          customFields = this.state.digitalForms.CustomFields;
        } else {
          customFields = [];
        }
        //console.log(this.state.DFDocs); return false;
        const response = await this.createDigitlForms(
          this.state.ApprovalChain,
          customFields,
          this.state.signByTable,
          this.state.generalFirstName,
          this.state.generalLastName,
          this.state.generalEmail,
          this.state.generalCountryCode,
          this.state.generalMobile,
          this.state.approverGroupID,
          this.state.AppGroup,
          this.state.generalFormpackGuid,
          this.state.packName,
          this.state.documents,
          moment()
        );
        if (response.status) {
          const createDFDocs = await this.createDFDocs(
            this.state.DFDocs,
            response.result.Guid
          );
          if (createDFDocs.status) {
            if (submit_type === "saveSend") {
              this.sendDocument(response.result);
            } else {
              this.props.history.push({
                pathname: "/dashboard/documents",
                state: { successMessage: true },
              });
            }

            this.setState({
              ApprovalChain: [],
              signByTable: [],
              digitalforms: {},
              email: "",
              firstName: "",
              lastName: "",
              generalCountryCode: "",
              generalMobile: "",
              externalPartyToComplete: "",
              generalFormpackGuid: "",
              generalProjectGuid: "",
              isLoading: false,
              generalFormValid: false,
              generalFirstNameValid: false,
              generalLastNameValid: false,
              generalEmailValid: false,
              generalMobileValid: false,
              generalFormPackGuidValid: false,
              generalProjectGuidvalid: false,
            });
          } else {
            this.setState({ isLoading: false });
            toast.error(createDFDocs.error, { autoClose: 5000 });
          }
        } else {
          this.setState({ isLoading: false });
          toast.error(response.error, { autoClose: 5000 });
        }
      } catch (err) {
        this.setState({ isLoading: false });
        toast.error(err.message, { autoClose: 5000 });
      }
    } else {
      // $("#collapseOne").addClass("in");
      toast.error("Required fields are missing.", { autoClose: 5000 });
      document.getElementById("collapseOneClick").click();
      $("body").animate({ scrollTop: 0 }, "slow");
    }
  };

  createDigitlForms(
    ApprovalChain,
    CustomFields,
    ExtSigners,
    FirstName,
    LastName,
    Email,
    CountryCode,
    Mobile,
    approverGroupID,
    AppGroup,
    FormpackGuid,
    FormPack,
    Documents,
    Timestamp
  ) {
    console.log("documents-------------------", Documents);
    return this.props.postRequest("/documents/create-document", {
      Activity: [
        {
          Description:
            "Created new Digital Form. " + FormPack + " Pack selected",
          IPAddress: this.state.ipv4,
          Timestamp: moment().unix(),
          Type: "NEWDigitalForm",
          User: localStorage.getItem("userName"),
          profile_pic_url: localStorage.getItem("profile_pic_url"),
        },
      ],
      ApprovalChain: ApprovalChain,
      ApprovalGroupguid: approverGroupID,
      ApprovalGroup: AppGroup,
      CreatedTS: moment().unix(),
      CustomFields: CustomFields,
      Documents: Documents,
      Email: Email,
      ExtSigners: ExtSigners,
      FirstName: FirstName,
      FormpackGuid: FormpackGuid,
      FormPack: FormPack,
      LastName: LastName,
      CountryCode: CountryCode,
      Mobile: Mobile,
      Timestamp: Timestamp,
      UserName: localStorage.getItem("userName"),
      ProjectName: this.state.generalProjectName,
      ProjectGuid: this.state.generalProjectGuid,
      CompanyID: localStorage.getItem("companyID"),
      CompanyGuid: localStorage.getItem("companyGuid"),
      TenantGuid: localStorage.getItem("currentTenantGuid"),
    });
  }

  backToDocumentDashboard() {
    this.props.history.push({ pathname: "/dashboard/documents" });
  }

  createDFDocs(form, TanantGuid) {
    console.log("form--------------------------------", form);
    const updatedForm = [
      {
        Confidential: true,
        CustomList: [
          {
            CustomCode: "string",
            CustomFields: [
              {
                DBField: "string",
                DefaultValue: "string",
                Hide: true,
                Length: "string",
                Prompt: "string",
                Readonly: true,
                Required: true,
                Script: "string",
                Tag: "string",
                Type: "string",
                Validation: "string",
                order: 0,
              },
            ],
            Desc: "string",
            TBA: "string",
          },
        ],
        Desc: "string",
        DigitalDoc: "string",
        DocDetails: [
          {
            Comments: "string",
            DBField: "string",
            DefaultValue: "string",
            HideExt: true,
            HideInt: true,
            Internal: true,
            Length: "string",
            Order: 0,
            Promt: "string",
            ReadOnlyExt: true,
            ReadOnlyInt: true,
            ReqExt: true,
            ReqInt: true,
            Script: "string",
            SubHeading: true,
            Tag: "string",
            Type: "string",
            Validation: "string",
            Value: "string",
            datalist: [
              {
                option: "string",
                value: 0,
              },
            ],
          },
        ],
        DocPassword: "string",
        DocumentAtt: [
          {
            Confidential: "string",
            ContentType: "string",
            DocDesc: "string",
            DocType: "string",
            Encrypted: "string",
            PWHash: "string",
            Salt: "string",
            Timestamp: "string",
            filename: "string",
            url: "string",
          },
        ],
        EmailDist: ["string"],
        ExtParty: true,
        Guid: "string",
        TemplateCode: "string",
        filename: "string",
        TanantGuid: "string",
      },
    ];

    return this.props.postRequest("/documents/create-document-detail", {
      form: form,
      TanantGuid: TanantGuid,
      addNewDFDocs: this.state.oldDFDocsDel,
    });
  }

  async createPdfDocs(docs) {
    const options = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    docs.map((doc) => {
      var Document = doc.DigitalDoc;
      var DocDetails = doc.DocDetails;
      var CustomList = doc.CustomList ? doc.CustomList : [];

      var jsObj = {};
      DocDetails.map((detail) => {
        jsObj[detail.Tag] = detail.DefaultValue ? detail.DefaultValue : "";
      });

      if (CustomList.length > 0) {
        CustomList.map((list) => {
          //jsObj[detail.Tag] = detail.DefaultValue? detail.DefaultValue : "";
          list.CustomFields.map((field) => {
            if (field.Tag) {
              jsObj[field.Tag] = field.DefaultValue ? field.DefaultValue : "";
            }
          });
        });
      }

      var pdfData = {
        Document: "digital-forms/public/" + Document,
        ApiKey: "027823aaiv0fvy0s987dfv34nhpgfi7v74871669390",
        Data: jsObj,
        Signatures: {
          "BucketName/Path_To_Default_Signature/Extblank1.jpg":
            "BucketName/Path_To_User_Signatures/Users_Signature.jpg",
          "BucketName/Path_To_Default_Signature/blank1.jpg":
            "BucketName/Path_To_User_Signatures/User_Signature.jpg",
        },
      };

      axios
        .post(window.config.API_URL + "/pdf", pdfData, options)
        .then((res) => {
          //console.log('axios result=>',res);
          if (res.data.PDFDocument !== undefined) {
            console.log("axios result data=>", res.data.PDFDocument);

            var file_name = doc.Guid + ".pdf";
            var key = "documents/" + file_name;
            const base64Data = new Buffer(res.data.PDFDocument, "base64");

            Storage.put(key, base64Data, { contentType: "application/pdf" })
              .then((result) => {
                try {
                  console.log("Save Document " + result);
                } catch (e) {
                  console.log("Save Document " + e);
                }
              })
              .catch((err) => console.log(err));
          } else {
            console.log("axios error by API=>", res.data.Reason);
          }
        })
        .catch((error) => {
          console.log("axios error in request=>", error);
        });

      console.log("doc detail obj=>", jsObj);
    });
  }

  recordOnlineFormData(
    RGuid,
    RName,
    REmail,
    Rphone,
    formpackID,
    SendableUrl,
    SName
  ) {
    return this.props.postRequest("/online-forms/create", {
      RGuid: RGuid,
      RName: RName,
      REmail: REmail,
      Rphone: Rphone,
      formpackID: formpackID,
      SendableUrl: SendableUrl,
      SName: SName,
    });
  }

  updateDate = (Guid) => {
    API.post("updateemaildate", "/updateemaildate", {
      body: {
        guid: Guid,
        Timestamp: moment(),
      },
    })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  LinkOfProjectedUser(OFlinkGuid) {
    var ExternelUri =
      "&SenderName=" + encodeURIComponent(localStorage.getItem("userName"));
    var ExternelLink =
      window.config.OF_SITE_URL +
      "?536dac2bc9ba290eeb81d87ee43b3faf=" +
      OFlinkGuid +
      ExternelUri;
    //ExternelLink +='&receiverEmail='+REmail+'';
    //    ExternelLink +='&formPackId='+formpackID+'';
    //    ExternelLink +=(ccEmail !== '' ? '&ccEmail='+ccEmail+'':'');
    //    ExternelLink +=(ExText !== '' ? '&extraText='+ExText+'':'');
    return ExternelLink;
  }

  sendEmailToSigner = async (to, subject, msg) => {
    var sendTo = to;
    return this.props.postRequest("/users/send-mail", {
      TO: sendTo,
      CC: "",
      MSG: msg,
      SUBJECT: subject,
    });
  };

  async sendEmail(to, msg, subject, userName) {
    var sendTo = to;
    msg =
      "<p><strong>Hello " +
      userName +
      "</strong>,&nbsp;<br />" +
      "We are pleased to inform you that now you can save your forms once and use it whenever you want " +
      'Please <a href="' +
      msg +
      '">Click here</a>' +
      " to save your information.</p>" +
      "<p>&nbsp;</p>";
    return API.post("send-mail", "/allusers/send-mail", {
      body: {
        TO: sendTo,
        CC: "",
        MSG: msg,
        SUBJECT: subject,
        ExText: "",
      },
    });
  }

  getTextOfCountryCode(code) {
    if (code && code !== "N/A") {
      var txt = $("#selectCountryCode option[value=" + code + "]").text();
      return txt.split(" ")[0];
    } else {
      return "N/A";
    }
  }

  async uploadPDFDocs(docData) {
    const options = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    console.log("upload formData=>", docData);
    var that = this;
    var signaturs = {};
    docData.map(async (doc) => {
      var uploadPath = doc.TenantGuid + "/df-docs-pdf/" + doc.Guid + ".pdf";
      var Document = doc.DigitalDoc;
      var DocDetails = doc.DocDetails;
      var CustomList = doc.CustomList ? doc.CustomList : [];

      var jsObj = {};
      DocDetails.map((detail) => {
        jsObj[detail.Tag] = detail.DefaultValue ? detail.DefaultValue : "";
      });

      if (CustomList.length > 0) {
        CustomList.map((list) => {
          //jsObj[detail.Tag] = detail.DefaultValue? detail.DefaultValue : "";
          list.CustomFields.map((field) => {
            if (field.Tag) {
              jsObj[field.Tag] = field.DefaultValue ? field.DefaultValue : "";
            }
          });
        });
      }

      var pdfData = {
        Document: window.config.S3_BUCKET + "/public/" + Document,
        ApiKey: window.config.PDF_API_KEY,
        Data: jsObj,
        Signatures: signaturs,
      };
      console.log("pdfData=>", pdfData);
      await axios
        .post(window.config.API_URL + "/pdf", pdfData, options)
        .then((res) => {
          if (res.data.PDFDocument !== undefined) {
            this.SavePdfIntoBucket(res.data.PDFDocument, uploadPath);
          }
          //console.log('axios result data=>',res.data);
        })
        .catch((error) => {
          console.log("pdf preview result error=>", error);
        });
    });
  }

  SavePdfIntoBucket = (base64, uploadPath) => {
    //this.props.handleUpdateConfig(1);
    var that = this;
    var base64Data = new Buffer(
      base64.replace(/^data:image\/\w+;base64,/, ""),
      "base64"
    );
    //console.log(base64Data); return false;
    Storage.put(uploadPath, base64Data, { contentType: "application/pdf" })
      .then((result) => {
        Storage.get(uploadPath)
          .then((result) => {
            console.log("succesfully uploaded the image!");
            console.log("result data: ", result);
            that.setState({ pdfbase64: result, isLoading: false });
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        console.log(err);
        console.log("Error uploading data: ", err);
      });
  };
  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });

  onRemoveAttachment = async (file_path, index, innerIndex, State) => {
    this.setState({ isLoading: true });
    try {
      var response = await this.props.postRequest("/attachments/remove", {
        upload_path: file_path,
        companyGuid: localStorage.getItem("companyGuid"),
        tenantGuid: localStorage.getItem("currentTenantGuid"),
      });

      var DFDocs = this.state.DFDocs;

      var generateState = "ROW" + index + "DocDetails" + innerIndex;
      DFDocs[index].DocDetails[innerIndex].DefaultValue = null;
      DFDocs[index].DocDetails[innerIndex].Value = null;
      this.setState(
        {
          [generateState]: null,
          [index + "error"]: false,
          isLoading: false,
        },
        () => {}
      );
    } catch (err) {
      this.setState({ isLoading: false });
      console.log("upload attachment Error=>", err);
      toast.error(err.message, { autoClose: 5000 });
    }
  };

  previewAttachment = async (upload_path) => {
    try {
      this.setState({ isLoading: false });

      const response = await this.props.postRequest("/attachments/fetch", {
        upload_path,
        CompanyGuid: localStorage.getItem("companyGuid"),
        TenantGuid: localStorage.getItem("currentTenantGuid"),
      });
      console.log("response", response);
      window.open(response.attachment);
    } catch (error) {
      toast.error("Failed to fetch the attachment preview");
    } finally {
      this.setState({ isLoading: false });
    }
  };

  formatMobileNumber = () => {
    if (this.state.displayableMobileNumber) {
      let mobileNumber = this.state.displayableMobileNumber;
      if (mobileNumber.startsWith(this.state.countryCode)) {
        mobileNumber = mobileNumber.split(this.state.countryCode).pop();
      }
      mobileNumber = mobileNumber
        .replaceAll(" ", "")
        .replaceAll("(", "")
        .replaceAll(")", "");
      if (mobileNumber.startsWith("0")) {
        mobileNumber = mobileNumber.slice(1);
      }
      this.setState({ displayableMobileNumber: mobileNumber }, () => {
        this.handleFieldsChange({
          target: {
            name: "generalMobile",
            value: `${this.state.countryCode}${mobileNumber}`,
          },
        });
      });
    }
  };

  onDropZone = async (files, key, index) => {
    console.log("files=>", files);
    this.setState({ isLoading: true });
    var selected_files = this.state.FileAttachments;
    var selected_files_urls = this.state.FileAttachments_urls;
    //var file = files[0];

    files.forEach(async (file) => {
      try {
        const base64Data = await this.toBase64(file);

        const content_type = file.type;
        const upload_path =
          "formTemplate-documents/" +
          +new Date().getTime() +
          "-" +
          file.name.replace(/\s+/g, "-").toLowerCase();
        //var response = await this.props.postRequest("/attachments/fetch", {upload_path: "form-templates/1605114548056-edits_24-oct-20(a).docx", content_type: content_type, base64Data: base64Data});
        var response = await this.props.postRequest("/attachments/upload", {
          upload_path,
          companyGuid: localStorage.getItem("companyGuid"),
          tenantGuid: localStorage.getItem("currentTenantGuid"),
          content_type: content_type,
          base64Data: base64Data,
        });
        if (response.status) {
          var uploadedObj = {
            Confidential: false,
            ContentType: content_type,
            DocDesc: "Form Template Document",
            DocType: "Document",
            Encrypted: "No",
            filename: file.name,
            PWHash: "a7e7ef%^%*&(7ke834",
            Salt: "HFHHGVHJBJB",
            Timestamp: new Date().getTime(),
            url: upload_path,
          };
          selected_files.push(uploadedObj);

          this.setState({
            FileAttachments_urls: selected_files_urls,
            FileAttachments: selected_files,
            isLoading: false,
            imageUploadModalOpen: true,
          });
          this.onChangeGeneric({ target: { value: upload_path } }, key, index);
        } else {
          toast.error("Failed to upload file.", { autoClose: 5000 });
          this.setState({ isLoading: false });
        }
        console.log("/attachments/upload=>", response);
      } catch (err) {
        this.setState({ isLoading: false });
        console.log("upload attachment Error=>", err);
        toast.error(err.message, { autoClose: 5000 });
      }
    });
  };
  handleUserInputCheckbox(event) {
    let name = event.target.name;
    var value = event.target.value;
    if (value === "") {
      this.setState(
        {
          [name]: "checked",
        },
        () => console.log("TBA=>", this.state.TBA)
      );
    } else {
      this.setState(
        {
          [name]: "",
        },
        () => console.log("TBA=>", this.state.TBA)
      );
    }
  }

  render() {
    return (
      <div className="InsertDocument">
        <Header
          pageHeading="Draft Document"
          isLoading={this.state.isLoading}
          props={this.props}
        />
        <div className="col-xs-12 col-sm-9 col-md-10 pull-right p0 top_mrg_general top_mrg_general_custom">
          <div className="">
            <div className="col-sm-12 col-md-12 col-lg-10 col-lg-offset-1 p0">
              <div className="col-xs-12 col-sm-12 col-md-12">
                <LinkContainer to="/dashboard/Documents">
                  <a href={null} className="form_temp_detail">
                    <img src={arrow_back_from} width="20" height="20" alt="" />{" "}
                    Documents
                  </a>
                </LinkContainer>

                <div className="col-sm-12 col-md-12 col-lg-11 col-lg-offset-1 mobile_heading_font">
                  <div className="clear40"></div>
                  <div className="panel-group" id="accordion">
                    <label className="id_main_label">Details</label>
                    <div className="panel panel-default help_panel_group">
                      <div className="panel-heading help_panel_heading">
                        <h4 className="panel-title">
                          <a
                            className="accordion-toggle collapsed"
                            id="collapseOneClick"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseOne"
                          >
                            &nbsp; General
                          </a>
                        </h4>
                      </div>
                      <div id="collapseOne" className="panel-collapse collapse">
                        <div className="panel-body help_body_box">
                          <div className="clear10"></div>
                          <div className="col-sm-12 col-md-12 label_attchment_form">
                            Type
                          </div>
                          <div className="col-sm-4 col-md-3 label_dataentry label_form_margin">
                            Form Pack:
                          </div>
                          <div className="col-sm-7 col-md-8">
                            <div className="col-xs-12 p0">
                              <div className="col-xs-12 col-sm-6 p0">
                                <select
                                  name="generalFormPackGuid"
                                  style={
                                    this.state.generalSettings !== ""
                                      ? {
                                          textTransform:
                                            this.state.generalSettings,
                                        }
                                      : { textTransform: "capitalize" }
                                  }
                                  value={this.state.generalFormpackGuid}
                                  onChange={(event) =>
                                    this.handleFormPackSelect(event)
                                  }
                                  className="id_form_detail_select form_margin_zero"
                                >
                                  <option value="">- Select</option>
                                  {this.createFormPackSelectItems()}
                                </select>
                                <p
                                  className={
                                    "error validation " +
                                    (this.state.generalFormErrors.formPackGuid
                                      ? ""
                                      : "displayNone")
                                  }
                                >
                                  {this.state.generalFormErrors.formPackGuid}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="clearfix"></div>
                          <div className="col-sm-4 col-md-3  label_dataentry label_form_margin">
                            Project:
                          </div>
                          <div className="col-sm-7 col-md-8">
                            <div className="col-xs-12 p0">
                              <div className="col-xs-12 col-sm-6 p0">
                                <select
                                  name="generalProjectGuid"
                                  style={
                                    this.state.generalSettings !== ""
                                      ? {
                                          textTransform:
                                            this.state.generalSettings,
                                        }
                                      : { textTransform: "capitalize" }
                                  }
                                  value={this.state.generalProjectGuid}
                                  onChange={(e) => this.handleFieldsChange(e)}
                                  onBlur={(e) =>
                                    this.onChangeSetTagValue(e, "ProdTitle")
                                  }
                                  className="id_form_detail_select form_margin_zero"
                                >
                                  <option value="">- Select</option>
                                  {this.state.generalProjectList.length > 0 ? (
                                    <React.Fragment>
                                      {this.state.generalProjectList.map(
                                        (val, key) => {
                                          return (
                                            <option key={key} value={val.Guid}>
                                              {val.Desc}
                                            </option>
                                          );
                                        }
                                      )}
                                    </React.Fragment>
                                  ) : (
                                    ""
                                  )}
                                  {/* <option value="">- Select</option>
                                                                    <option value="1">0093</option>
                                                                    <option value="1">0094</option>
                                                                    <option value="1">0095</option>
                                                                    <option value="1">0096</option>
                                                                    <option value="1">0097</option> */}
                                </select>
                                <p
                                  className={
                                    "error validation " +
                                    (this.state.generalFormErrors.projectGuid
                                      ? ""
                                      : "displayNone")
                                  }
                                >
                                  {this.state.generalFormErrors.projectGuid}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="clearfix"></div>
                          <div className="col-sm-4 col-md-3  label_dataentry label_form_margin">
                            First Name:
                          </div>
                          <div className="col-sm-7 col-md-8">
                            <div className="col-xs-12 p0">
                              <div className="col-xs-12 col-sm-6 p0">
                                <input
                                  name="generalFirstName"
                                  style={
                                    this.state.generalSettings !== ""
                                      ? {
                                          textTransform:
                                            this.state.generalSettings,
                                        }
                                      : { textTransform: "capitalize" }
                                  }
                                  onChange={(e) => this.handleFieldsChange(e)}
                                  onBlur={(e) =>
                                    this.onChangeSetTagValue(e, "UserFirstName")
                                  }
                                  value={this.state.generalFirstName}
                                  className="form_detail_iput form_margin_zero"
                                  type="text"
                                />
                                <p
                                  className={
                                    "error validation " +
                                    (this.state.generalFormErrors.firstName
                                      ? ""
                                      : "displayNone")
                                  }
                                >
                                  {this.state.generalFormErrors.firstName}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="clearfix"></div>
                          <div className="col-sm-4 col-md-3  label_dataentry label_form_margin">
                            Last Name:
                          </div>
                          <div className="col-sm-7 col-md-8">
                            <div className="col-xs-12 p0">
                              <div className="col-xs-12 col-sm-6 p0">
                                <input
                                  name="generalLastName"
                                  style={
                                    this.state.generalSettings !== ""
                                      ? {
                                          textTransform:
                                            this.state.generalSettings,
                                        }
                                      : { textTransform: "capitalize" }
                                  }
                                  onChange={(e) => this.handleFieldsChange(e)}
                                  onBlur={(e) =>
                                    this.onChangeSetTagValue(e, "UserLastName")
                                  }
                                  value={this.state.generalLastName}
                                  className="form_detail_iput form_margin_zero"
                                  type="text"
                                />
                                <p
                                  className={
                                    "error validation " +
                                    (this.state.generalFormErrors.lastName
                                      ? ""
                                      : "displayNone")
                                  }
                                >
                                  {this.state.generalFormErrors.lastName}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="clearfix"></div>
                          <div className="col-sm-4 col-md-3  label_dataentry label_form_margin">
                            Email:
                          </div>
                          <div className="col-sm-7 col-md-8">
                            <div className="col-xs-12 p0">
                              <div className="col-xs-12 col-sm-6 p0">
                                <input
                                  name="generalEmail"
                                  onChange={(e) => this.handleFieldsChange(e)}
                                  onBlur={(e) =>
                                    this.onChangeSetTagValue(e, "UserEmail")
                                  }
                                  value={this.state.generalEmail}
                                  className="form_detail_iput form_margin_zero"
                                  type="Email"
                                />
                                <p
                                  className={
                                    "error validation " +
                                    (this.state.generalFormErrors.email
                                      ? ""
                                      : "displayNone")
                                  }
                                >
                                  {this.state.generalFormErrors.email}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="clearfix"></div>
                          <div className="col-sm-4 col-md-3  label_dataentry label_form_margin">
                            Country Code:
                          </div>
                          <div className="col-sm-7 col-md-8">
                            <div className="col-xs-12 p0">
                              <div className="col-xs-12 col-sm-6 p0">
                                <select
                                  name="generalCountryCode"
                                  id="selectCountryCode"
                                  className="id_form_detail_select form_margin_zero"
                                  onChange={(event) =>
                                    this.handleCustomFieldsModalInputsCountry(
                                      event
                                    )
                                  }
                                  style={
                                    this.state.generalSettings !== ""
                                      ? {
                                          textTransform:
                                            this.state.generalSettings,
                                        }
                                      : { textTransform: "capitalize" }
                                  }
                                  value={this.state.generalCountryCode}
                                >
                                  <option value="">Select</option>
                                  <option value="61">Australia (+61)</option>
                                  <option value="44">UK (+44)</option>
                                  <option value="1">USA (+1)</option>
                                  <option value="213">Algeria (+213)</option>
                                  <option value="376">Andorra (+376)</option>
                                  <option value="244">Angola (+244)</option>
                                  <option value="1264">Anguilla (+1264)</option>
                                  <option value="1268">
                                    Antigua &amp; Barbuda (+1268)
                                  </option>
                                  <option value="54">Argentina (+54)</option>
                                  <option value="374">Armenia (+374)</option>
                                  <option value="297">Aruba (+297)</option>
                                  <option value="43">Austria (+43)</option>
                                  <option value="994">Azerbaijan (+994)</option>
                                  <option value="1242">Bahamas (+1242)</option>
                                  <option value="973">Bahrain (+973)</option>
                                  <option value="880">Bangladesh (+880)</option>
                                  <option value="1246">Barbados (+1246)</option>
                                  <option value="375">Belarus (+375)</option>
                                  <option value="32">Belgium (+32)</option>
                                  <option value="501">Belize (+501)</option>
                                  <option value="229">Benin (+229)</option>
                                  <option value="1441">Bermuda (+1441)</option>
                                  <option value="975">Bhutan (+975)</option>
                                  <option value="591">Bolivia (+591)</option>
                                  <option value="387">
                                    Bosnia Herzegovina (+387)
                                  </option>
                                  <option value="267">Botswana (+267)</option>
                                  <option value="55">Brazil (+55)</option>
                                  <option value="673">Brunei (+673)</option>
                                  <option value="359">Bulgaria (+359)</option>
                                  <option value="226">
                                    Burkina Faso (+226)
                                  </option>
                                  <option value="257">Burundi (+257)</option>
                                  <option value="855">Cambodia (+855)</option>
                                  <option value="237">Cameroon (+237)</option>
                                  <option value="1">Canada (+1)</option>
                                  <option value="238">
                                    Cape Verde Islands (+238)
                                  </option>
                                  <option value="1345">
                                    Cayman Islands (+1345)
                                  </option>
                                  <option value="236">
                                    Central African Republic (+236)
                                  </option>
                                  <option value="56">Chile (+56)</option>
                                  <option value="86">China (+86)</option>
                                  <option value="57">Colombia (+57)</option>
                                  <option value="269">Comoros (+269)</option>
                                  <option value="242">Congo (+242)</option>
                                  <option value="682">
                                    Cook Islands (+682)
                                  </option>
                                  <option value="506">Costa Rica (+506)</option>
                                  <option value="385">Croatia (+385)</option>
                                  <option value="53">Cuba (+53)</option>
                                  <option value="90392">
                                    Cyprus North (+90392)
                                  </option>
                                  <option value="357">
                                    Cyprus South (+357)
                                  </option>
                                  <option value="42">
                                    Czech Republic (+42)
                                  </option>
                                  <option value="45">Denmark (+45)</option>
                                  <option value="253">Djibouti (+253)</option>
                                  <option value="1809">Dominica (+1809)</option>
                                  <option value="1809">
                                    Dominican Republic (+1809)
                                  </option>
                                  <option value="593">Ecuador (+593)</option>
                                  <option value="20">Egypt (+20)</option>
                                  <option value="503">
                                    El Salvador (+503)
                                  </option>
                                  <option value="240">
                                    Equatorial Guinea (+240)
                                  </option>
                                  <option value="291">Eritrea (+291)</option>
                                  <option value="372">Estonia (+372)</option>
                                  <option value="251">Ethiopia (+251)</option>
                                  <option value="500">
                                    Falkland Islands (+500)
                                  </option>
                                  <option value="298">
                                    Faroe Islands (+298)
                                  </option>
                                  <option value="679">Fiji (+679)</option>
                                  <option value="358">Finland (+358)</option>
                                  <option value="33">France (+33)</option>
                                  <option value="594">
                                    French Guiana (+594)
                                  </option>
                                  <option value="689">
                                    French Polynesia (+689)
                                  </option>
                                  <option value="241">Gabon (+241)</option>
                                  <option value="220">Gambia (+220)</option>
                                  <option value="7880">Georgia (+7880)</option>
                                  <option value="49">Germany (+49)</option>
                                  <option value="233">Ghana (+233)</option>
                                  <option value="350">Gibraltar (+350)</option>
                                  <option value="30">Greece (+30)</option>
                                  <option value="299">Greenland (+299)</option>
                                  <option value="1473">Grenada (+1473)</option>
                                  <option value="590">Guadeloupe (+590)</option>
                                  <option value="671">Guam (+671)</option>
                                  <option value="502">Guatemala (+502)</option>
                                  <option value="224">Guinea (+224)</option>
                                  <option value="245">
                                    Guinea - Bissau (+245)
                                  </option>
                                  <option value="592">Guyana (+592)</option>
                                  <option value="509">Haiti (+509)</option>
                                  <option value="504">Honduras (+504)</option>
                                  <option value="852">Hong Kong (+852)</option>
                                  <option value="36">Hungary (+36)</option>
                                  <option value="354">Iceland (+354)</option>
                                  <option value="91">India (+91)</option>
                                  <option value="62">Indonesia (+62)</option>
                                  <option value="98">Iran (+98)</option>
                                  <option value="964">Iraq (+964)</option>
                                  <option value="353">Ireland (+353)</option>
                                  <option value="972">Israel (+972)</option>
                                  <option value="39">Italy (+39)</option>
                                  <option value="1876">Jamaica (+1876)</option>
                                  <option value="81">Japan (+81)</option>
                                  <option value="962">Jordan (+962)</option>
                                  <option value="7">Kazakhstan (+7)</option>
                                  <option value="254">Kenya (+254)</option>
                                  <option value="686">Kiribati (+686)</option>
                                  <option value="850">
                                    Korea North (+850)
                                  </option>
                                  <option value="82">Korea South (+82)</option>
                                  <option value="965">Kuwait (+965)</option>
                                  <option value="996">Kyrgyzstan (+996)</option>
                                  <option value="856">Laos (+856)</option>
                                  <option value="371">Latvia (+371)</option>
                                  <option value="961">Lebanon (+961)</option>
                                  <option value="266">Lesotho (+266)</option>
                                  <option value="231">Liberia (+231)</option>
                                  <option value="218">Libya (+218)</option>
                                  <option value="417">
                                    Liechtenstein (+417)
                                  </option>
                                  <option value="370">Lithuania (+370)</option>
                                  <option value="352">Luxembourg (+352)</option>
                                  <option value="853">Macao (+853)</option>
                                  <option value="389">Macedonia (+389)</option>
                                  <option value="261">Madagascar (+261)</option>
                                  <option value="265">Malawi (+265)</option>
                                  <option value="60">Malaysia (+60)</option>
                                  <option value="960">Maldives (+960)</option>
                                  <option value="223">Mali (+223)</option>
                                  <option value="356">Malta (+356)</option>
                                  <option value="692">
                                    Marshall Islands (+692)
                                  </option>
                                  <option value="596">Martinique (+596)</option>
                                  <option value="222">Mauritania (+222)</option>
                                  <option value="269">Mayotte (+269)</option>
                                  <option value="52">Mexico (+52)</option>
                                  <option value="691">Micronesia (+691)</option>
                                  <option value="373">Moldova (+373)</option>
                                  <option value="377">Monaco (+377)</option>
                                  <option value="976">Mongolia (+976)</option>
                                  <option value="1664">
                                    Montserrat (+1664)
                                  </option>
                                  <option value="212">Morocco (+212)</option>
                                  <option value="258">Mozambique (+258)</option>
                                  <option value="95">Myanmar (+95)</option>
                                  <option value="264">Namibia (+264)</option>
                                  <option value="674">Nauru (+674)</option>
                                  <option value="977">Nepal (+977)</option>
                                  <option value="31">Netherlands (+31)</option>
                                  <option value="687">
                                    New Caledonia (+687)
                                  </option>
                                  <option value="64">New Zealand (+64)</option>
                                  <option value="505">Nicaragua (+505)</option>
                                  <option value="227">Niger (+227)</option>
                                  <option value="234">Nigeria (+234)</option>
                                  <option value="683">Niue (+683)</option>
                                  <option value="672">
                                    Norfolk Islands (+672)
                                  </option>
                                  <option value="670">
                                    Northern Marianas (+670)
                                  </option>
                                  <option value="47">Norway (+47)</option>
                                  <option value="968">Oman (+968)</option>
                                  <option value="92">Pakistan (+92)</option>
                                  <option value="680">Palau (+680)</option>
                                  <option value="507">Panama (+507)</option>
                                  <option value="675">
                                    Papua New Guinea (+675)
                                  </option>
                                  <option value="595">Paraguay (+595)</option>
                                  <option value="51">Peru (+51)</option>
                                  <option value="63">Philippines (+63)</option>
                                  <option value="48">Poland (+48)</option>
                                  <option value="351">Portugal (+351)</option>
                                  <option value="1787">
                                    Puerto Rico (+1787)
                                  </option>
                                  <option value="974">Qatar (+974)</option>
                                  <option value="262">Reunion (+262)</option>
                                  <option value="40">Romania (+40)</option>
                                  <option value="7">Russia (+7)</option>
                                  <option value="250">Rwanda (+250)</option>
                                  <option value="378">San Marino (+378)</option>
                                  <option value="239">
                                    Sao Tome &amp; Principe (+239)
                                  </option>
                                  <option value="966">
                                    Saudi Arabia (+966)
                                  </option>
                                  <option value="221">Senegal (+221)</option>
                                  <option value="381">Serbia (+381)</option>
                                  <option value="248">Seychelles (+248)</option>
                                  <option value="232">
                                    Sierra Leone (+232)
                                  </option>
                                  <option value="65">Singapore (+65)</option>
                                  <option value="421">
                                    Slovak Republic (+421)
                                  </option>
                                  <option value="386">Slovenia (+386)</option>
                                  <option value="677">
                                    Solomon Islands (+677)
                                  </option>
                                  <option value="252">Somalia (+252)</option>
                                  <option value="27">South Africa (+27)</option>
                                  <option value="34">Spain (+34)</option>
                                  <option value="94">Sri Lanka (+94)</option>
                                  <option value="290">St. Helena (+290)</option>
                                  <option value="1869">
                                    St. Kitts (+1869)
                                  </option>
                                  <option value="1758">
                                    St. Lucia (+1758)
                                  </option>
                                  <option value="249">Sudan (+249)</option>
                                  <option value="597">Suriname (+597)</option>
                                  <option value="268">Swaziland (+268)</option>
                                  <option value="46">Sweden (+46)</option>
                                  <option value="41">Switzerland (+41)</option>
                                  <option value="963">Syria (+963)</option>
                                  <option value="886">Taiwan (+886)</option>
                                  <option value="7">Tajikstan (+7)</option>
                                  <option value="66">Thailand (+66)</option>
                                  <option value="228">Togo (+228)</option>
                                  <option value="676">Tonga (+676)</option>
                                  <option value="1868">
                                    Trinidad &amp; Tobago (+1868)
                                  </option>
                                  <option value="216">Tunisia (+216)</option>
                                  <option value="90">Turkey (+90)</option>
                                  <option value="7">Turkmenistan (+7)</option>
                                  <option value="993">
                                    Turkmenistan (+993)
                                  </option>
                                  <option value="1649">
                                    Turks & Caicos Islands (+1649)
                                  </option>
                                  <option value="688">Tuvalu (+688)</option>
                                  <option value="256">Uganda (+256)</option>
                                  <option value="44">UK (+44)</option>
                                  <option value="380">Ukraine (+380)</option>
                                  <option value="971">
                                    United Arab Emirates (+971)
                                  </option>
                                  <option value="598">Uruguay (+598)</option>
                                  <option value="1">USA (+1)</option>
                                  <option value="7">Uzbekistan (+7)</option>
                                  <option value="678">Vanuatu (+678)</option>
                                  <option value="379">
                                    Vatican City (+379)
                                  </option>
                                  <option value="58">Venezuela (+58)</option>
                                  <option value="84">Vietnam (+84)</option>
                                  <option value="84">
                                    Virgin Islands - British (+1284)
                                  </option>
                                  <option value="84">
                                    Virgin Islands - US (+1340)
                                  </option>
                                  <option value="681">
                                    Wallis &amp; Futuna (+681)
                                  </option>
                                  <option value="969">
                                    Yemen (North)(+969)
                                  </option>
                                  <option value="967">
                                    Yemen (South)(+967)
                                  </option>
                                  <option value="260">Zmbaia (+260)</option>
                                  <option value="263">Zimbabwe (+263)</option>
                                </select>
                                <p
                                  className={
                                    "error validation " +
                                    (this.state.generalFormErrors.countryCode
                                      ? ""
                                      : "displayNone")
                                  }
                                >
                                  {this.state.generalFormErrors.countryCode}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="clearfix"></div>
                          <div className="col-sm-4 col-md-3  label_dataentry label_form_margin">
                            Mobile:
                          </div>
                          <div className="col-sm-7 col-md-8">
                            <div className="col-xs-12 p0">
                              <div className="col-xs-12 col-sm-6 p0">
                                <div
                                  className="input-group"
                                  style={{ marginTop: "20px" }}
                                >
                                  <span class="input-group-addon">
                                    {this.state.countryCode ?? "___"}
                                  </span>
                                  <input
                                    name="displayableMobileNumber"
                                    style={
                                      this.state.generalSettings !== ""
                                        ? {
                                            textTransform:
                                              this.state.generalSettings,
                                          }
                                        : { textTransform: "capitalize" }
                                    }
                                    value={this.state.displayableMobileNumber}
                                    onChange={(e) => {
                                      this.handleFieldsChange(e);
                                    }}
                                    onBlur={(e) => {
                                      this.onChangeSetTagValue(e, "UserMobile");
                                      this.formatMobileNumber();
                                    }}
                                    className="form_margin_zero form-control"
                                    type="text"
                                  />
                                </div>
                                <p
                                  className={
                                    "error validation " +
                                    (this.state.generalFormErrors.mobile
                                      ? ""
                                      : "displayNone")
                                  }
                                >
                                  {this.state.generalFormErrors.mobile}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="clearfix"></div>
                          <div className="clear20"></div>
                          {this.state.isRender ? (
                            <div className="col-sm-12 col-md-12 label_attchment_form">
                              Custom fields
                            </div>
                          ) : (
                            ""
                          )}

                          <div className="col-sm-12 attchment_bottom_label p0">
                            {this.state.isRender
                              ? this.renderCustomFields()
                              : ""}
                          </div>
                          <div className="clear10"></div>
                          <div className="btn_cance_save idNoDisplay">
                            <div className="col-sm-12">
                              <button
                                type="button"
                                className="btn_general_report"
                              >
                                Generate Report
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="panel panel-default help_panel_group">
                      <div className="panel-heading help_panel_heading">
                        <h4 className="panel-title">
                          <a
                            className="accordion-toggle collapsed"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseTen"
                          >
                            &nbsp; Sign By
                          </a>
                        </h4>
                      </div>
                      <div
                        id="collapseTen"
                        className="panel-collapse  col-sm-12 collapse p0"
                      >
                        <div className="panel-body p0 id_panel_side_by">
                          <div className="clear20"></div>
                          <div>
                            <a href={null}>
                              <button
                                type="button"
                                className="btn btn-primary pull-right plus_icon_table btn_plus_cusotm id_btn_plus_cusotm"
                                onClick={this.signByFormModalOpen}
                                data-toggle="modal"
                              >
                                +
                              </button>
                            </a>
                          </div>
                          <div className="clear10"></div>
                          <div
                            className="help_body_box approvel_new_open_table"
                            style={{ border: "none" }}
                          >
                            <div
                              className="table-responsive"
                              id="table_mobile "
                            >
                              <table
                                width="100%"
                                border="0"
                                cellSpacing="2"
                                cellPadding="2"
                                className="table table-bordered"
                              >
                                <thead>
                                  <tr>
                                    <th
                                      width="5%"
                                      align="center"
                                      className="text-center"
                                    >
                                      Seq
                                    </th>
                                    <th width="15%" align="left">
                                      First Name
                                    </th>
                                    <th width="15%" align="left">
                                      Last Name
                                    </th>
                                    <th
                                      width="20%"
                                      align="center"
                                      className="text-center"
                                    >
                                      Email
                                    </th>
                                    <th width="10%" align="left">
                                      Mobile
                                    </th>
                                    <th
                                      width="15%"
                                      align="center"
                                      className="text-center"
                                    >
                                      Country Code
                                    </th>
                                    <th
                                      width="10%"
                                      align="center"
                                      className="text-center"
                                    >
                                      App
                                    </th>
                                    <th
                                      width="10%"
                                      align="center"
                                      className="text-center"
                                    ></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.signByTable.length > 0 ? (
                                    this.state.signByTable.map((val, key) => {
                                      var country_name =
                                        this.getTextOfCountryCode(
                                          val.countryCode
                                        );
                                      return (
                                        <tr
                                          className="custom_field_tab"
                                          key={key}
                                        >
                                          <td align="center">{val.Sequence}</td>
                                          <td align="left">{val.FirstName}</td>
                                          <td align="left">{val.Lastname}</td>
                                          <td
                                            align="center"
                                            className="mobile_num_padd"
                                          >
                                            {val.Email}
                                          </td>
                                          <td
                                            align="left"
                                            className="mobile_num_padd"
                                          >
                                            {val.Mobile}
                                          </td>
                                          <td align="center">{country_name}</td>
                                          <td align="center">
                                            {val.SigPosition}
                                          </td>
                                          <td>
                                            <div className="col-sm-3  col-xs-6 p0 pull-right text-center timecard_dele pull-right">
                                              <a
                                                href={null}
                                                onClick={(event) =>
                                                  this.removeFromSignbyArray(
                                                    key
                                                  )
                                                }
                                                className="cursorPointer"
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="1700 296 15 19.286"
                                                >
                                                  <path
                                                    id="ic_delete_24px"
                                                    className="cls-1"
                                                    d="M6.071,20.143a2.149,2.149,0,0,0,2.143,2.143h8.571a2.149,2.149,0,0,0,2.143-2.143V7.286H6.071ZM20,4.071H16.25L15.179,3H9.821L8.75,4.071H5V6.214H20Z"
                                                    transform="translate(1695 293)"
                                                  ></path>
                                                </svg>
                                              </a>
                                            </div>
                                            <div className="col-sm-3  col-xs-6 p0 text-center timecard_edit6 pull-right">
                                              <a
                                                href={null}
                                                onClick={(event) =>
                                                  this.updateFromSignbyArray(
                                                    key
                                                  )
                                                }
                                                className="cursorPointer"
                                                data-toggle="modal"
                                                data-target="#myModal_sign_by_form"
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="1656.776 299 17.515 18.003"
                                                >
                                                  <path
                                                    id="ic_create_24px"
                                                    className="cls-1"
                                                    d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                                                    transform="translate(1653.776 296.002)"
                                                  ></path>
                                                </svg>
                                              </a>
                                            </div>
                                          </td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <tr>
                                      <td
                                        colSpan={8}
                                        style={{ textAlign: "center" }}
                                      >
                                        {" "}
                                        No Record Found{" "}
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="clear10"></div>
                        </div>
                      </div>
                    </div>
                    <div className="clear10"></div>
                    <div className="panel panel-default help_panel_group">
                      <div className="panel-heading help_panel_heading">
                        <h4 className="panel-title">
                          <a
                            className="accordion-toggle collapsed"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseEleven"
                          >
                            &nbsp; Approver Group{" "}
                            {this.state.approvalGroupTitle
                              ? this.state.approvalGroupTitle
                              : ""}
                          </a>
                        </h4>
                      </div>
                      <div
                        id="collapseEleven"
                        className="panel-collapse collapse"
                      >
                        <div className="panel-body help_body_box id_panel_side_by p0">
                          <div className="clear30"></div>
                          <div className="col-sm-5 col-md-3 id_label_select_option">
                            Choose the Approver Group
                          </div>
                          <div className="col-sm-7 col-md-9">
                            <div className="col-xs-12 p0">
                              <div className="col-xs-12 col-sm-12 col-md-4 p0">
                                <select
                                  name="approverGroup"
                                  style={
                                    this.state.generalSettings !== ""
                                      ? {
                                          textTransform:
                                            this.state.generalSettings,
                                        }
                                      : { textTransform: "capitalize" }
                                  }
                                  value={this.state.approverGroupID}
                                  onChange={(event) =>
                                    this.handleApproverGroupSelect(event)
                                  }
                                  className="id_form_detail_select construction_dropdown"
                                >
                                  <option value={-1}> - Select</option>
                                  {this.createSelectItems()}
                                </select>
                                <p
                                  className={
                                    "error validation " +
                                    (this.state.generalFormErrors
                                      .approvalGroupTitle
                                      ? ""
                                      : "displayNone")
                                  }
                                >
                                  {
                                    this.state.generalFormErrors
                                      .approvalGroupTitle
                                  }
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="clear20"></div>
                          {this.state.ApprovalChain.length > 0
                            ? this.renderApprovalChain()
                            : "no approver list found"}
                          <div className="clear30"></div>
                        </div>
                      </div>
                    </div>
                    <div className="clear50"></div>
                    {this.state.isRender ? (
                      <label className="id_main_label">Documents</label>
                    ) : (
                      ""
                    )}

                    <div className="clear20"></div>
                    {this.state.isRender ? this.renderDocuments() : ""}
                    <div className="clear30"></div>
                    <div className="col-sm-6 p0">
                      <div
                        className="btn_cance_save"
                        id="insert_document_dropdown_save_btn"
                      >
                        {this.state.temp_guid !== "" ? (
                          <button
                            className="btn_save_pro dropdown-toggle"
                            type="button"
                            onClick={this.updateRecord}
                          >
                            Update
                          </button>
                        ) : (
                          <div className="dropdown">
                            <button
                              className="btn_save_pro dropdown-toggle"
                              data-dismiss="modal"
                              type="button"
                              data-toggle="dropdown"
                            >
                              Save
                              <span className="caret"></span>
                            </button>
                            <ul className="dropdown-menu">
                              <li>
                                <a
                                  href={null}
                                  className="CursorPointer"
                                  onClick={(event) => this.handleSubmit(event)}
                                  data-type="save"
                                >
                                  <span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 19.122 14.559"
                                    >
                                      <path
                                        id="ic_done_24px"
                                        className="cls-1"
                                        d="M9.484,17.117,4.921,12.553,3.4,14.074l6.084,6.084L22.522,7.121,21,5.6Z"
                                        transform="translate(-3.4 -5.6)"
                                      ></path>
                                    </svg>
                                  </span>
                                  Save
                                </a>
                              </li>
                              <li>
                                <a
                                  href={null}
                                  className="CursorPointer"
                                  onClick={(event) => this.handleSubmit(event)}
                                  data-type="saveSend"
                                >
                                  <span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 16"
                                    >
                                      <path
                                        id="ic_mail_outline_24px"
                                        className="cls-1"
                                        d="M20,4H4A2,2,0,0,0,2.01,6L2,18a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V6A2.006,2.006,0,0,0,20,4Zm0,14H4V8l8,5,8-5Zm-8-7L4,6H20Z"
                                        transform="translate(-2 -4)"
                                      />
                                    </svg>
                                  </span>
                                  Save and Send
                                </a>
                              </li>
                            </ul>
                          </div>
                        )}
                        <button
                          type="button"
                          className="btn_cancel_pro"
                          onClick={(event) => this.backToDocumentDashboard()}
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="clear30"></div>
                <div className="clear30"></div>
                <div className="clearfix"></div>
              </div>
            </div>
            <div className="clear10"></div>
          </div>
        </div>
        <div className="clear10"></div>
        <div id="myModal" className="modal fade" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close OutlineNone"
                  data-dismiss="modal"
                >
                  <img
                    className="mt15"
                    src={ic_clear_24px}
                    width="18"
                    height="18"
                    alt=""
                  />
                </button>
                <h4 className="modal-title modal_customefield">
                  Custom Fields
                </h4>
              </div>
              <div className="modal-body label_modal_custom">
                <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-sm-3 col-md-3 label_csutom_pop">
                    Description:
                  </div>
                  <div className="col-sm-9 col-md-9">
                    <select
                      style={
                        this.state.generalSettings !== ""
                          ? { textTransform: this.state.generalSettings }
                          : { textTransform: "capitalize" }
                      }
                      name=""
                    ></select>
                  </div>
                </div>
                <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-sm-3 col-md-3 label_csutom_pop">
                    Default Value:
                  </div>
                  <div className="col-sm-9 col-md-9">
                    <input
                      style={
                        this.state.generalSettings !== ""
                          ? { textTransform: this.state.generalSettings }
                          : { textTransform: "capitalize" }
                      }
                      name=""
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-sm-3 col-md-3 label_csutom_pop">Tag:</div>
                  <div className="col-sm-9 col-md-9">
                    <input
                      style={
                        this.state.generalSettings !== ""
                          ? { textTransform: this.state.generalSettings }
                          : { textTransform: "capitalize" }
                      }
                      name=""
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-sm-3 col-md-3 label_csutom_pop">
                    Validations:
                  </div>
                  <div className="col-sm-9 col-md-9">
                    <input
                      style={
                        this.state.generalSettings !== ""
                          ? { textTransform: this.state.generalSettings }
                          : { textTransform: "capitalize" }
                      }
                      name=""
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="btn_cance_save">
                    <button
                      type="button"
                      className="btn_save_pro"
                      data-dismiss="modal"
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="btn_cancel_pro"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
                <div className="clear20"></div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          id="myModal_sign_by_form"
          show={this.state.signByFormModalOpen}
          onHide={this.signByFormModalClose}
          className="modal modal2 fade"
          role="dialog"
        >
          <div className="modal-header">
            <button
              type="button"
              className="close OutlineNone"
              data-dismiss="modal"
              onClick={this.signByFormModalClose}
            >
              <img
                className="mt22"
                src={ic_clear_24px}
                width="18"
                height="18"
                alt=""
              />
            </button>
            <h4 className="modal-title modal_customefield">Sign By Form</h4>
          </div>
          <div className="modal-body label_modal_custom">
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-sm-3 col-md-3 label_csutom_pop">
                First Name:
              </div>
              <div className="col-sm-9 col-md-9">
                <input
                  name="signByFirstName"
                  style={
                    this.state.generalSettings !== ""
                      ? { textTransform: this.state.generalSettings }
                      : { textTransform: "capitalize" }
                  }
                  value={this.state.signByFirstName}
                  onChange={(e) => this.handleFieldsChange(e)}
                  type="text"
                />
                <p
                  className={
                    "error validation " +
                    (this.state.generalFormErrors.signByFirstName
                      ? ""
                      : "displayNone")
                  }
                >
                  {this.state.generalFormErrors.signByFirstName}
                </p>
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-sm-3 col-md-3 label_csutom_pop">
                Last Name:
              </div>
              <div className="col-sm-9 col-md-9">
                <input
                  name="signByLastName"
                  style={
                    this.state.generalSettings !== ""
                      ? { textTransform: this.state.generalSettings }
                      : { textTransform: "capitalize" }
                  }
                  value={this.state.signByLastName}
                  onChange={(e) => this.handleFieldsChange(e)}
                  type="text"
                />
                <p
                  className={
                    "error validation " +
                    (this.state.generalFormErrors.signByLastName
                      ? ""
                      : "displayNone")
                  }
                >
                  {this.state.generalFormErrors.signByLastName}
                </p>
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-sm-3 col-md-3 label_csutom_pop">Email:</div>
              <div className="col-sm-9 col-md-9">
                <input
                  name="signByEmail"
                  style={
                    this.state.generalSettings !== ""
                      ? { textTransform: this.state.generalSettings }
                      : { textTransform: "capitalize" }
                  }
                  value={this.state.signByEmail}
                  onChange={(e) => this.handleFieldsChange(e)}
                  type="email"
                />
                <p
                  className={
                    "error validation " +
                    (this.state.generalFormErrors.signByEmail
                      ? ""
                      : "displayNone")
                  }
                >
                  {this.state.generalFormErrors.signByEmail}
                </p>
              </div>
            </div>

            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-sm-4 col-md-4 label_csutom_pop">
                Country Code:
              </div>
              <div className="col-sm-6 col-md-6">
                <select
                  name="signByCountryCode"
                  style={
                    this.state.generalSettings !== ""
                      ? { textTransform: this.state.generalSettings }
                      : { textTransform: "capitalize" }
                  }
                  value={this.state.signByCountryCode}
                  onChange={(event) =>
                    this.handleCustomFieldsModalInputsSignByCountryCode(event)
                  }
                  className="bill"
                  style={{ marginBottom: 0 }}
                >
                  <option value="">Select</option>
                  <option value="61">Australia (+61)</option>
                  <option value="44">UK (+44)</option>
                  <option value="1">USA (+1)</option>
                  <option value="213">Algeria (+213)</option>
                  <option value="376">Andorra (+376)</option>
                  <option value="244">Angola (+244)</option>
                  <option value="1264">Anguilla (+1264)</option>
                  <option value="1268">Antigua &amp; Barbuda (+1268)</option>
                  <option value="54">Argentina (+54)</option>
                  <option value="374">Armenia (+374)</option>
                  <option value="297">Aruba (+297)</option>
                  <option value="43">Austria (+43)</option>
                  <option value="994">Azerbaijan (+994)</option>
                  <option value="1242">Bahamas (+1242)</option>
                  <option value="973">Bahrain (+973)</option>
                  <option value="880">Bangladesh (+880)</option>
                  <option value="1246">Barbados (+1246)</option>
                  <option value="375">Belarus (+375)</option>
                  <option value="32">Belgium (+32)</option>
                  <option value="501">Belize (+501)</option>
                  <option value="229">Benin (+229)</option>
                  <option value="1441">Bermuda (+1441)</option>
                  <option value="975">Bhutan (+975)</option>
                  <option value="591">Bolivia (+591)</option>
                  <option value="387">Bosnia Herzegovina (+387)</option>
                  <option value="267">Botswana (+267)</option>
                  <option value="55">Brazil (+55)</option>
                  <option value="673">Brunei (+673)</option>
                  <option value="359">Bulgaria (+359)</option>
                  <option value="226">Burkina Faso (+226)</option>
                  <option value="257">Burundi (+257)</option>
                  <option value="855">Cambodia (+855)</option>
                  <option value="237">Cameroon (+237)</option>
                  <option value="1">Canada (+1)</option>
                  <option value="238">Cape Verde Islands (+238)</option>
                  <option value="1345">Cayman Islands (+1345)</option>
                  <option value="236">Central African Republic (+236)</option>
                  <option value="56">Chile (+56)</option>
                  <option value="86">China (+86)</option>
                  <option value="57">Colombia (+57)</option>
                  <option value="269">Comoros (+269)</option>
                  <option value="242">Congo (+242)</option>
                  <option value="682">Cook Islands (+682)</option>
                  <option value="506">Costa Rica (+506)</option>
                  <option value="385">Croatia (+385)</option>
                  <option value="53">Cuba (+53)</option>
                  <option value="90392">Cyprus North (+90392)</option>
                  <option value="357">Cyprus South (+357)</option>
                  <option value="42">Czech Republic (+42)</option>
                  <option value="45">Denmark (+45)</option>
                  <option value="253">Djibouti (+253)</option>
                  <option value="1809">Dominica (+1809)</option>
                  <option value="1809">Dominican Republic (+1809)</option>
                  <option value="593">Ecuador (+593)</option>
                  <option value="20">Egypt (+20)</option>
                  <option value="503">El Salvador (+503)</option>
                  <option value="240">Equatorial Guinea (+240)</option>
                  <option value="291">Eritrea (+291)</option>
                  <option value="372">Estonia (+372)</option>
                  <option value="251">Ethiopia (+251)</option>
                  <option value="500">Falkland Islands (+500)</option>
                  <option value="298">Faroe Islands (+298)</option>
                  <option value="679">Fiji (+679)</option>
                  <option value="358">Finland (+358)</option>
                  <option value="33">France (+33)</option>
                  <option value="594">French Guiana (+594)</option>
                  <option value="689">French Polynesia (+689)</option>
                  <option value="241">Gabon (+241)</option>
                  <option value="220">Gambia (+220)</option>
                  <option value="7880">Georgia (+7880)</option>
                  <option value="49">Germany (+49)</option>
                  <option value="233">Ghana (+233)</option>
                  <option value="350">Gibraltar (+350)</option>
                  <option value="30">Greece (+30)</option>
                  <option value="299">Greenland (+299)</option>
                  <option value="1473">Grenada (+1473)</option>
                  <option value="590">Guadeloupe (+590)</option>
                  <option value="671">Guam (+671)</option>
                  <option value="502">Guatemala (+502)</option>
                  <option value="224">Guinea (+224)</option>
                  <option value="245">Guinea - Bissau (+245)</option>
                  <option value="592">Guyana (+592)</option>
                  <option value="509">Haiti (+509)</option>
                  <option value="504">Honduras (+504)</option>
                  <option value="852">Hong Kong (+852)</option>
                  <option value="36">Hungary (+36)</option>
                  <option value="354">Iceland (+354)</option>
                  <option value="91">India (+91)</option>
                  <option value="62">Indonesia (+62)</option>
                  <option value="98">Iran (+98)</option>
                  <option value="964">Iraq (+964)</option>
                  <option value="353">Ireland (+353)</option>
                  <option value="972">Israel (+972)</option>
                  <option value="39">Italy (+39)</option>
                  <option value="1876">Jamaica (+1876)</option>
                  <option value="81">Japan (+81)</option>
                  <option value="962">Jordan (+962)</option>
                  <option value="7">Kazakhstan (+7)</option>
                  <option value="254">Kenya (+254)</option>
                  <option value="686">Kiribati (+686)</option>
                  <option value="850">Korea North (+850)</option>
                  <option value="82">Korea South (+82)</option>
                  <option value="965">Kuwait (+965)</option>
                  <option value="996">Kyrgyzstan (+996)</option>
                  <option value="856">Laos (+856)</option>
                  <option value="371">Latvia (+371)</option>
                  <option value="961">Lebanon (+961)</option>
                  <option value="266">Lesotho (+266)</option>
                  <option value="231">Liberia (+231)</option>
                  <option value="218">Libya (+218)</option>
                  <option value="417">Liechtenstein (+417)</option>
                  <option value="370">Lithuania (+370)</option>
                  <option value="352">Luxembourg (+352)</option>
                  <option value="853">Macao (+853)</option>
                  <option value="389">Macedonia (+389)</option>
                  <option value="261">Madagascar (+261)</option>
                  <option value="265">Malawi (+265)</option>
                  <option value="60">Malaysia (+60)</option>
                  <option value="960">Maldives (+960)</option>
                  <option value="223">Mali (+223)</option>
                  <option value="356">Malta (+356)</option>
                  <option value="692">Marshall Islands (+692)</option>
                  <option value="596">Martinique (+596)</option>
                  <option value="222">Mauritania (+222)</option>
                  <option value="269">Mayotte (+269)</option>
                  <option value="52">Mexico (+52)</option>
                  <option value="691">Micronesia (+691)</option>
                  <option value="373">Moldova (+373)</option>
                  <option value="377">Monaco (+377)</option>
                  <option value="976">Mongolia (+976)</option>
                  <option value="1664">Montserrat (+1664)</option>
                  <option value="212">Morocco (+212)</option>
                  <option value="258">Mozambique (+258)</option>
                  <option value="95">Myanmar (+95)</option>
                  <option value="264">Namibia (+264)</option>
                  <option value="674">Nauru (+674)</option>
                  <option value="977">Nepal (+977)</option>
                  <option value="31">Netherlands (+31)</option>
                  <option value="687">New Caledonia (+687)</option>
                  <option value="64">New Zealand (+64)</option>
                  <option value="505">Nicaragua (+505)</option>
                  <option value="227">Niger (+227)</option>
                  <option value="234">Nigeria (+234)</option>
                  <option value="683">Niue (+683)</option>
                  <option value="672">Norfolk Islands (+672)</option>
                  <option value="670">Northern Marianas (+670)</option>
                  <option value="47">Norway (+47)</option>
                  <option value="968">Oman (+968)</option>
                  <option value="92">Pakistan (+92)</option>
                  <option value="680">Palau (+680)</option>
                  <option value="507">Panama (+507)</option>
                  <option value="675">Papua New Guinea (+675)</option>
                  <option value="595">Paraguay (+595)</option>
                  <option value="51">Peru (+51)</option>
                  <option value="63">Philippines (+63)</option>
                  <option value="48">Poland (+48)</option>
                  <option value="351">Portugal (+351)</option>
                  <option value="1787">Puerto Rico (+1787)</option>
                  <option value="974">Qatar (+974)</option>
                  <option value="262">Reunion (+262)</option>
                  <option value="40">Romania (+40)</option>
                  <option value="7">Russia (+7)</option>
                  <option value="250">Rwanda (+250)</option>
                  <option value="378">San Marino (+378)</option>
                  <option value="239">Sao Tome &amp; Principe (+239)</option>
                  <option value="966">Saudi Arabia (+966)</option>
                  <option value="221">Senegal (+221)</option>
                  <option value="381">Serbia (+381)</option>
                  <option value="248">Seychelles (+248)</option>
                  <option value="232">Sierra Leone (+232)</option>
                  <option value="65">Singapore (+65)</option>
                  <option value="421">Slovak Republic (+421)</option>
                  <option value="386">Slovenia (+386)</option>
                  <option value="677">Solomon Islands (+677)</option>
                  <option value="252">Somalia (+252)</option>
                  <option value="27">South Africa (+27)</option>
                  <option value="34">Spain (+34)</option>
                  <option value="94">Sri Lanka (+94)</option>
                  <option value="290">St. Helena (+290)</option>
                  <option value="1869">St. Kitts (+1869)</option>
                  <option value="1758">St. Lucia (+1758)</option>
                  <option value="249">Sudan (+249)</option>
                  <option value="597">Suriname (+597)</option>
                  <option value="268">Swaziland (+268)</option>
                  <option value="46">Sweden (+46)</option>
                  <option value="41">Switzerland (+41)</option>
                  <option value="963">Syria (+963)</option>
                  <option value="886">Taiwan (+886)</option>
                  <option value="7">Tajikstan (+7)</option>
                  <option value="66">Thailand (+66)</option>
                  <option value="228">Togo (+228)</option>
                  <option value="676">Tonga (+676)</option>
                  <option value="1868">Trinidad &amp; Tobago (+1868)</option>
                  <option value="216">Tunisia (+216)</option>
                  <option value="90">Turkey (+90)</option>
                  <option value="7">Turkmenistan (+7)</option>
                  <option value="993">Turkmenistan (+993)</option>
                  <option value="1649">Turks & Caicos Islands (+1649)</option>
                  <option value="688">Tuvalu (+688)</option>
                  <option value="256">Uganda (+256)</option>
                  <option value="44">UK (+44)</option>
                  <option value="380">Ukraine (+380)</option>
                  <option value="971">United Arab Emirates (+971)</option>
                  <option value="598">Uruguay (+598)</option>
                  <option value="1">USA (+1)</option>
                  <option value="7">Uzbekistan (+7)</option>
                  <option value="678">Vanuatu (+678)</option>
                  <option value="379">Vatican City (+379)</option>
                  <option value="58">Venezuela (+58)</option>
                  <option value="84">Vietnam (+84)</option>
                  <option value="84">Virgin Islands - British (+1284)</option>
                  <option value="84">Virgin Islands - US (+1340)</option>
                  <option value="681">Wallis &amp; Futuna (+681)</option>
                  <option value="969">Yemen (North)(+969)</option>
                  <option value="967">Yemen (South)(+967)</option>
                  <option value="260">Zmbaia (+260)</option>
                  <option value="263">Zimbabwe (+263)</option>
                </select>
                <p
                  className={
                    "error validation " +
                    (this.state.generalFormErrors.signByCountryCode
                      ? ""
                      : "displayNone")
                  }
                >
                  {this.state.generalFormErrors.signByCountryCode}
                </p>
              </div>
            </div>

            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-sm-4 col-md-4 label_csutom_pop">Mobile:</div>
              <div className="col-sm-9 col-md-6">
                <input
                  name="signByMobile"
                  style={
                    this.state.generalSettings !== ""
                      ? { textTransform: this.state.generalSettings }
                      : { textTransform: "capitalize" }
                  }
                  value={this.state.signByMobile}
                  onChange={(e) => this.handleFieldsChange(e)}
                  type="text"
                />
                <p
                  className={
                    "error validation " +
                    (this.state.generalFormErrors.signByMobile
                      ? ""
                      : "displayNone")
                  }
                >
                  {this.state.generalFormErrors.signByMobile}
                </p>
              </div>
            </div>

            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-sm-4 col-md-4 label_csutom_pop">
                Signature Position:
              </div>
              <div className="col-sm-6 col-md-6">
                <input
                  name="signByPosition"
                  style={
                    this.state.generalSettings !== ""
                      ? { textTransform: this.state.generalSettings }
                      : { textTransform: "capitalize" }
                  }
                  value={this.state.signByPosition}
                  onChange={(e) => this.handleFieldsChange(e)}
                  type="text"
                />
                <p
                  className={
                    "error validation " +
                    (this.state.generalFormErrors.signByPosition
                      ? ""
                      : "displayNone")
                  }
                >
                  {this.state.generalFormErrors.signByPosition}
                </p>
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-sm-4 col-md-4 label_csutom_pop">
                Signing Order:
              </div>
              <div className="col-sm-6 col-md-6">
                <input
                  name="signBySequence"
                  style={
                    this.state.generalSettings !== ""
                      ? { textTransform: this.state.generalSettings }
                      : { textTransform: "capitalize" }
                  }
                  value={this.state.signBySequence}
                  onChange={(e) => this.handleFieldsChange(e)}
                  type="text"
                />
                <p
                  className={
                    "error validation " +
                    (this.state.generalFormErrors.signBySequence
                      ? ""
                      : "displayNone")
                  }
                >
                  {this.state.generalFormErrors.signBySequence}
                </p>
              </div>
            </div>
            <div className="col-sm-6 p0">
              <div className="btn_cance_save">
                <button
                  type="button"
                  className="btn_save_pro"
                  onClick={(event) => this.addSignbyForm(event)}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="btn_cancel_pro"
                  data-dismiss="modal"
                  onClick={this.signByFormModalClose}
                >
                  Cancel
                </button>
              </div>
            </div>
            <div className="clear20"></div>
          </div>
        </Modal>
        <div id="myModal2" className="modal fade" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close OutlineNone"
                  data-dismiss="modal"
                >
                  <img
                    className="mt15"
                    src={ic_clear_24px}
                    width="18"
                    height="18"
                    alt=""
                  />
                </button>
                <h4 className="modal-title modal_customefield">
                  Custom List Code
                </h4>
              </div>
              <div className="modal-body label_modal_custom pdg_modal_csutom_leist modal_pop_list_scroll">
                <div className="col-sm-12">
                  NOTE: Drag fields to change order in form and list.
                  <div className="clear20"></div>
                </div>
                <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                    Pmt Code:
                  </div>
                  <div className="col-xs-10 col-sm-7 col-md-7">
                    <input
                      style={
                        this.state.generalSettings !== ""
                          ? { textTransform: this.state.generalSettings }
                          : { textTransform: "capitalize" }
                      }
                      name=""
                      type="text"
                    />
                  </div>
                  <div className="col-xs-2 col-sm-1 col-md-1">
                    <button
                      type="button"
                      className="pen_custom_list_pop OutlineNone"
                      data-toggle="modal"
                      data-target="#myModal4"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="1656.776 299 17.515 18.003"
                      >
                        <path
                          id="ic_create_24px"
                          className="cls-1"
                          d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                          transform="translate(1653.776 296.002)"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                    Description:
                  </div>
                  <div className="col-xs-10 col-sm-7 col-md-7">
                    <input
                      style={
                        this.state.generalSettings !== ""
                          ? { textTransform: this.state.generalSettings }
                          : { textTransform: "capitalize" }
                      }
                      name=""
                      type="text"
                    />
                  </div>
                  <div className="col-xs-2 col-sm-1 col-md-1">
                    <button
                      type="button"
                      className="pen_custom_list_pop OutlineNone"
                      data-toggle="modal"
                      data-target="#myModal4"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="1656.776 299 17.515 18.003"
                      >
                        <path
                          id="ic_create_24px"
                          className="cls-1"
                          d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                          transform="translate(1653.776 296.002)"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                    Unit:
                  </div>
                  <div className="col-xs-10 col-sm-7 col-md-7">
                    <input
                      style={
                        this.state.generalSettings !== ""
                          ? { textTransform: this.state.generalSettings }
                          : { textTransform: "capitalize" }
                      }
                      name=""
                      type="text"
                    />
                  </div>
                  <div className="col-xs-2 col-sm-1 col-md-1">
                    <button
                      type="button"
                      className="pen_custom_list_pop OutlineNone"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="1656.776 299 17.515 18.003"
                      >
                        <path
                          id="ic_create_24px"
                          className="cls-1"
                          d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                          transform="translate(1653.776 296.002)"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                    Unit Desc:
                  </div>
                  <div className="col-xs-10 col-sm-7 col-md-7">
                    <select
                      style={
                        this.state.generalSettings !== ""
                          ? { textTransform: this.state.generalSettings }
                          : { textTransform: "capitalize" }
                      }
                      name=""
                    ></select>
                  </div>
                  <div className="col-xs-2 col-sm-1 col-md-1">
                    <button
                      type="button"
                      className="pen_custom_list_pop OutlineNone"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="1656.776 299 17.515 18.003"
                      >
                        <path
                          id="ic_create_24px"
                          className="cls-1"
                          d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                          transform="translate(1653.776 296.002)"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                    Rate:
                  </div>
                  <div className="col-xs-10 col-sm-7 col-md-7">
                    <input
                      style={
                        this.state.generalSettings !== ""
                          ? { textTransform: this.state.generalSettings }
                          : { textTransform: "capitalize" }
                      }
                      name=""
                      type="text"
                    />
                  </div>
                  <div className="col-xs-2 col-sm-1 col-md-1">
                    <button
                      type="button"
                      className="pen_custom_list_pop OutlineNone"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="1656.776 299 17.515 18.003"
                      >
                        <path
                          id="ic_create_24px"
                          className="cls-1"
                          d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                          transform="translate(1653.776 296.002)"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                    Factor:
                  </div>
                  <div className="col-xs-10 col-sm-7 col-md-7">
                    <input
                      style={
                        this.state.generalSettings !== ""
                          ? { textTransform: this.state.generalSettings }
                          : { textTransform: "capitalize" }
                      }
                      name=""
                      type="text"
                    />
                  </div>
                  <div className="col-xs-2 col-sm-1 col-md-1">
                    <button
                      type="button"
                      className="pen_custom_list_pop OutlineNone"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="1656.776 299 17.515 18.003"
                      >
                        <path
                          id="ic_create_24px"
                          className="cls-1"
                          d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                          transform="translate(1653.776 296.002)"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                    Amount:
                  </div>
                  <div className="col-xs-10 col-sm-7 col-md-7">
                    <input
                      style={
                        this.state.generalSettings !== ""
                          ? { textTransform: this.state.generalSettings }
                          : { textTransform: "capitalize" }
                      }
                      name=""
                      type="text"
                    />
                  </div>
                  <div className="col-xs-2 col-sm-1 col-md-1">
                    <button
                      type="button"
                      className="pen_custom_list_pop OutlineNone"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="1656.776 299 17.515 18.003"
                      >
                        <path
                          id="ic_create_24px"
                          className="cls-1"
                          d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                          transform="translate(1653.776 296.002)"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                    Capped Amount:
                  </div>
                  <div className="col-xs-10 col-sm-7 col-md-7">
                    <input
                      style={
                        this.state.generalSettings !== ""
                          ? { textTransform: this.state.generalSettings }
                          : { textTransform: "capitalize" }
                      }
                      name=""
                      type="text"
                    />
                  </div>
                  <div className="col-xs-2 col-sm-1 col-md-1">
                    <button
                      type="button"
                      className="pen_custom_list_pop OutlineNone"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="1656.776 299 17.515 18.003"
                      >
                        <path
                          id="ic_create_24px"
                          className="cls-1"
                          d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                          transform="translate(1653.776 296.002)"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                    Days in Week:
                  </div>
                  <div className="col-xs-10 col-sm-7 col-md-7">
                    <input
                      style={
                        this.state.generalSettings !== ""
                          ? { textTransform: this.state.generalSettings }
                          : { textTransform: "capitalize" }
                      }
                      name=""
                      type="text"
                    />
                  </div>
                  <div className="col-xs-2 col-sm-1 col-md-1">
                    <button
                      type="button"
                      className="pen_custom_list_pop OutlineNone"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="1656.776 299 17.515 18.003"
                      >
                        <path
                          id="ic_create_24px"
                          className="cls-1"
                          d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                          transform="translate(1653.776 296.002)"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                    Date From:
                  </div>
                  <div className="col-xs-10 col-sm-7 col-md-7">
                    <div className="col-xs-6 col-sm-6 p0">
                      <input
                        style={
                          this.state.generalSettings !== ""
                            ? { textTransform: this.state.generalSettings }
                            : { textTransform: "capitalize" }
                        }
                        name=""
                        type="text"
                      />
                    </div>
                    <div className="col-xs-2 col-sm-2 calendar_time2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="2936.352 349.176 18.501 23.145"
                      >
                        <a href={null}>
                          <path
                            id="ic_date_range_24px"
                            className="cls-1"
                            d="M9.167,12.415H7.111V14.73H9.167Zm4.111,0H11.223V14.73h2.056Zm4.111,0H15.334V14.73H17.39Zm2.056-8.1H18.418V2H16.362V4.314H8.139V2H6.084V4.314H5.056A2.188,2.188,0,0,0,3.01,6.629L3,22.83a2.2,2.2,0,0,0,2.056,2.314h14.39A2.2,2.2,0,0,0,21.5,22.83V6.629A2.2,2.2,0,0,0,19.446,4.314Zm0,18.516H5.056V10.1h14.39Z"
                            transform="translate(2933.352 347.176)"
                          ></path>
                        </a>
                      </svg>
                    </div>
                  </div>
                  <div className="col-xs-2 col-sm-1 col-md-1">
                    <button
                      type="button"
                      className="pen_custom_list_pop OutlineNone"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="1656.776 299 17.515 18.003"
                      >
                        <path
                          id="ic_create_24px"
                          className="cls-1"
                          d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                          transform="translate(1653.776 296.002)"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                    Date To:
                  </div>
                  <div className="col-xs-10 col-sm-7 col-md-7">
                    <div className="col-xs-6 col-sm-6 p0">
                      <input
                        style={
                          this.state.generalSettings !== ""
                            ? { textTransform: this.state.generalSettings }
                            : { textTransform: "capitalize" }
                        }
                        name=""
                        type="text"
                      />
                    </div>
                    <div className="col-xs-2 col-sm-2 calendar_time2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="2936.352 349.176 18.501 23.145"
                      >
                        <a href={null}>
                          <path
                            id="ic_date_range_24px"
                            className="cls-1"
                            d="M9.167,12.415H7.111V14.73H9.167Zm4.111,0H11.223V14.73h2.056Zm4.111,0H15.334V14.73H17.39Zm2.056-8.1H18.418V2H16.362V4.314H8.139V2H6.084V4.314H5.056A2.188,2.188,0,0,0,3.01,6.629L3,22.83a2.2,2.2,0,0,0,2.056,2.314h14.39A2.2,2.2,0,0,0,21.5,22.83V6.629A2.2,2.2,0,0,0,19.446,4.314Zm0,18.516H5.056V10.1h14.39Z"
                            transform="translate(2933.352 347.176)"
                          ></path>
                        </a>
                      </svg>
                    </div>
                  </div>
                  <div className="col-xs-2 col-sm-1 col-md-1">
                    <button
                      type="button"
                      className="pen_custom_list_pop OutlineNone"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="1656.776 299 17.515 18.003"
                      >
                        <path
                          id="ic_create_24px"
                          className="cls-1"
                          d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                          transform="translate(1653.776 296.002)"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                    Chart Sort:
                  </div>
                  <div className="col-xs-10 col-sm-7 col-md-7">
                    <input
                      style={
                        this.state.generalSettings !== ""
                          ? { textTransform: this.state.generalSettings }
                          : { textTransform: "capitalize" }
                      }
                      name=""
                      type="text"
                    />
                  </div>
                  <div className="col-xs-2 col-sm-1 col-md-1">
                    <button
                      type="button"
                      className="pen_custom_list_pop OutlineNone"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="1656.776 299 17.515 18.003"
                      >
                        <path
                          id="ic_create_24px"
                          className="cls-1"
                          d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                          transform="translate(1653.776 296.002)"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                    Chart Code:
                  </div>
                  <div className="col-xs-10 col-sm-7 col-md-7">
                    <input
                      style={
                        this.state.generalSettings !== ""
                          ? { textTransform: this.state.generalSettings }
                          : { textTransform: "capitalize" }
                      }
                      name=""
                      type="text"
                    />
                  </div>
                  <div className="col-xs-2 col-sm-1 col-md-1">
                    <button
                      type="button"
                      className="pen_custom_list_pop OutlineNone"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="1656.776 299 17.515 18.003"
                      >
                        <path
                          id="ic_create_24px"
                          className="cls-1"
                          d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                          transform="translate(1653.776 296.002)"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                    Flag:
                  </div>
                  <div className="col-xs-10 col-sm-7 col-md-7">
                    <input
                      style={
                        this.state.generalSettings !== ""
                          ? { textTransform: this.state.generalSettings }
                          : { textTransform: "capitalize" }
                      }
                      name=""
                      type="text"
                    />
                  </div>
                  <div className="col-xs-2 col-sm-1 col-md-1">
                    <button
                      type="button"
                      className="pen_custom_list_pop OutlineNone"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="1656.776 299 17.515 18.003"
                      >
                        <path
                          id="ic_create_24px"
                          className="cls-1"
                          d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                          transform="translate(1653.776 296.002)"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                    Payment Flag:
                  </div>
                  <div className="col-xs-10 col-sm-7 col-md-7">
                    <input
                      style={
                        this.state.generalSettings !== ""
                          ? { textTransform: this.state.generalSettings }
                          : { textTransform: "capitalize" }
                      }
                      name=""
                      type="text"
                    />
                  </div>
                  <div className="col-xs-2 col-sm-1 col-md-1">
                    <button
                      type="button"
                      className="pen_custom_list_pop OutlineNone"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="1656.776 299 17.515 18.003"
                      >
                        <path
                          id="ic_create_24px"
                          className="cls-1"
                          d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                          transform="translate(1653.776 296.002)"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                    Calc Days:
                  </div>
                  <div className="col-xs-2 col-sm-1 col-md-1 pull-right">
                    <button
                      type="button"
                      className="pen_custom_list_pop OutlineNone"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="1656.776 299 17.515 18.003"
                      >
                        <path
                          id="ic_create_24px"
                          className="cls-1"
                          d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                          transform="translate(1653.776 296.002)"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                    Calc Weeks:
                  </div>
                  <div className="col-xs-2 col-sm-1 col-md-1 pull-right">
                    <button
                      type="button"
                      className="pen_custom_list_pop OutlineNone"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="1656.776 299 17.515 18.003"
                      >
                        <path
                          id="ic_create_24px"
                          className="cls-1"
                          d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                          transform="translate(1653.776 296.002)"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="clear30"></div>
                <div className="col-sm-6">
                  <div className="btn_cance_save">
                    <button
                      type="button"
                      className="btn_save_pro"
                      data-dismiss="modal"
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="btn_cancel_pro"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
                <div className="clear20"></div>
              </div>
            </div>
          </div>
        </div>
        <div id="myModal4" className="modal fade" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close OutlineNone"
                  data-dismiss="modal"
                >
                  <img
                    className="mt15"
                    src={ic_clear_24px}
                    width="18"
                    height="18"
                    alt=""
                  />
                </button>
                <h4 className="modal-title modal_customefield">Setting</h4>
              </div>
              <div className="modal-body label_modal_custom">
                <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-sm-3 col-md-3 label_csutom_pop">
                    DB Field:
                  </div>
                  <div className="col-sm-9 col-md-9">
                    <input
                      style={
                        this.state.generalSettings !== ""
                          ? { textTransform: this.state.generalSettings }
                          : { textTransform: "capitalize" }
                      }
                      name=""
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-sm-3 col-md-3 label_csutom_pop">
                    Validate:
                  </div>
                  <div className="col-sm-9 col-md-9">
                    <input
                      style={
                        this.state.generalSettings !== ""
                          ? { textTransform: this.state.generalSettings }
                          : { textTransform: "capitalize" }
                      }
                      name=""
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-sm-3 col-md-3 label_csutom_pop">
                    Hide:
                  </div>
                  <div className="col-sm-9 col-md-9">
                    <label className="check_box_pop">
                      <input
                        style={
                          this.state.generalSettings !== ""
                            ? { textTransform: this.state.generalSettings }
                            : { textTransform: "capitalize" }
                        }
                        type="checkbox"
                      />
                      <span className="checkmark left0_check"></span>
                    </label>
                  </div>
                </div>
                <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-sm-3 col-md-3 label_csutom_pop">
                    Read Only:
                  </div>
                  <div className="col-sm-9 col-md-9">
                    <label className="check_box_pop">
                      <input
                        style={
                          this.state.generalSettings !== ""
                            ? { textTransform: this.state.generalSettings }
                            : { textTransform: "capitalize" }
                        }
                        type="checkbox"
                      />
                      <span className="checkmark left0_check"></span>
                    </label>
                  </div>
                </div>
                <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-sm-3 col-md-3 label_csutom_pop">
                    Required:
                  </div>
                  <div className="col-sm-9 col-md-9">
                    <label className="check_box_pop">
                      <input
                        style={
                          this.state.generalSettings !== ""
                            ? { textTransform: this.state.generalSettings }
                            : { textTransform: "capitalize" }
                        }
                        type="checkbox"
                      />
                      <span className="checkmark left0_check"></span>
                    </label>
                  </div>
                </div>
                <div className="clear20"></div>
                <div className="col-sm-6">
                  <div className="btn_cance_save">
                    <button
                      type="button"
                      className="btn_save_pro"
                      data-dismiss="modal"
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="btn_cancel_pro"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
                <div className="clear20"></div>
              </div>
            </div>
          </div>
        </div>
        <div id="myModal3" className="modal fade" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close OutlineNone"
                  data-dismiss="modal"
                >
                  <img
                    className="mt15"
                    src={ic_clear_24px}
                    width="18"
                    height="18"
                    alt=""
                  />
                </button>
                <h4 className="modal-title modal_customefield">
                  Form Entry Fields
                </h4>
              </div>
              <div className="modal-body label_modal_custom pdg_modal_csutom_leist modal_pop_list_scroll">
                <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                    Group Heading:
                  </div>
                  <div className="col-xs-10 col-sm-8 col-md-8">
                    <input
                      style={
                        this.state.generalSettings !== ""
                          ? { textTransform: this.state.generalSettings }
                          : { textTransform: "capitalize" }
                      }
                      name=""
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                    Description:
                  </div>
                  <div className="col-xs-10 col-sm-8 col-md-8">
                    <input
                      style={
                        this.state.generalSettings !== ""
                          ? { textTransform: this.state.generalSettings }
                          : { textTransform: "capitalize" }
                      }
                      name=""
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                    Default Value:
                  </div>
                  <div className="col-xs-10 col-sm-8 col-md-8">
                    <input
                      style={
                        this.state.generalSettings !== ""
                          ? { textTransform: this.state.generalSettings }
                          : { textTransform: "capitalize" }
                      }
                      name=""
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                    Comments:
                  </div>
                  <div className="col-xs-10 col-sm-8 col-md-8">
                    <input
                      style={
                        this.state.generalSettings !== ""
                          ? { textTransform: this.state.generalSettings }
                          : { textTransform: "capitalize" }
                      }
                      name=""
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                    Tag:
                  </div>
                  <div className="col-xs-10 col-sm-8 col-md-8">
                    <input
                      style={
                        this.state.generalSettings !== ""
                          ? { textTransform: this.state.generalSettings }
                          : { textTransform: "capitalize" }
                      }
                      name=""
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                    DBField:
                  </div>
                  <div className="col-xs-10 col-sm-8 col-md-8">
                    <input
                      style={
                        this.state.generalSettings !== ""
                          ? { textTransform: this.state.generalSettings }
                          : { textTransform: "capitalize" }
                      }
                      name=""
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                    Company:
                  </div>
                  <div className="col-xs-10 col-sm-8 col-md-8 mrg_top_from8">
                    <div className="width_check_form_dtl_pop">
                      <label className="check_box_pop">
                        <input type="checkbox" />
                        <span className="checkmark"></span> Internal:
                      </label>
                    </div>
                    <div className="width_check_form_dtl_pop2">
                      <label className="check_box_pop">
                        <input type="checkbox" />
                        <span className="checkmark"></span> Hide:
                      </label>
                    </div>
                    <div className="width_check_form_dtl_pop3">
                      <label className="check_box_pop">
                        <input type="checkbox" />
                        <span className="checkmark"></span> Read Only:
                      </label>
                    </div>
                    <div className="width_check_form_dtl_pop4">
                      <label className="check_box_pop">
                        <input type="checkbox" />
                        <span className="checkmark"></span> Required:
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                    External:
                  </div>
                  <div className="col-xs-10 col-sm-8 col-md-8 mrg_top_from8">
                    <div className="width_check_form_dtl_pop5">
                      <label className="check_box_pop">
                        <input type="checkbox" />
                        <span className="checkmark"></span> Hide:
                      </label>
                    </div>
                    <div className="width_check_form_dtl_pop6">
                      <label className="check_box_pop">
                        <input type="checkbox" />
                        <span className="checkmark"></span> Read Only:
                      </label>
                    </div>
                    <div className="width_check_form_dtl_pop7">
                      <label className="check_box_pop">
                        <input type="checkbox" />
                        <span className="checkmark"></span> Required:
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                    Field Type:
                  </div>
                  <div className="col-xs-10 col-sm-8 col-md-8">
                    <select
                      style={
                        this.state.generalSettings !== ""
                          ? { textTransform: this.state.generalSettings }
                          : { textTransform: "capitalize" }
                      }
                      name=""
                    ></select>
                  </div>
                </div>
                <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                    Field Length:
                  </div>
                  <div className="col-xs-10 col-sm-8 col-md-8">
                    <input
                      style={
                        this.state.generalSettings !== ""
                          ? { textTransform: this.state.generalSettings }
                          : { textTransform: "capitalize" }
                      }
                      name=""
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                    Validation:
                  </div>
                  <div className="col-xs-10 col-sm-8 col-md-8">
                    <input
                      style={
                        this.state.generalSettings !== ""
                          ? { textTransform: this.state.generalSettings }
                          : { textTransform: "capitalize" }
                      }
                      name=""
                      type="text"
                    />
                  </div>
                </div>
                <div className="clear30"></div>
                <div className="col-sm-6">
                  <div className="btn_cance_save">
                    <button
                      type="button"
                      className="btn_save_pro"
                      data-dismiss="modal"
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="btn_cancel_pro"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
                <div className="clear20"></div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          id="custom_list_pencil"
          show={this.state.customListCodeModalOpen}
          onHide={this.customListCodeModalClose}
          className="modal modal2 fade"
          role="dialog"
        >
          <div className="modal-header">
            <button
              type="button"
              className="close OutlineNone"
              data-dismiss="modal"
              onClick={this.customListCodeModalClose}
            >
              <img
                className="mt22"
                src={ic_clear_24px}
                width="18"
                height="18"
                alt=""
              />
            </button>
            <h4 className="modal-title modal_customefield custom_field_code">
              Custom List Codes:{" "}
              {this.state.documentIndex
                ? this.state.DFDocs[this.state.documentIndex].Desc
                : ""}
            </h4>
          </div>
          <div className="col-sm-12 drag_files_text">
            <label>NOTE: Drag fields to change order in form and list.</label>
          </div>
          <div className="modal-body label_modal_custom pdg_modal_csutom_leist modal_pop_list_scroll">
            {/* {this.renderCustomListCode()} */}

            <div
              id="sortable"
              className="panel panel-default help_panel_group sortable"
            >
              <div className="col-sm-12 p0 mrg_bot_custom10">
                <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                  TBA:
                </div>
                <div className="col-xs-12 col-sm-8 col-md-8">
                  <label className="check_box_pop">
                    <input
                      type="checkbox"
                      name="TBA"
                      value={this.state.TBA}
                      checked={this.state.TBA}
                      onChange={(event) =>
                        this.onChangeCustomListFields(
                          event,
                          0,
                          null,
                          null,
                          null
                        )
                      }
                    />
                    <span className="checkmark report_checkmark"></span>
                  </label>
                </div>
              </div>
              <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="tagsForm">
                  {(provided, snapshot) => (
                    <div
                      id="tagsForm"
                      className="sortable_list connectedSortable"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {this.state.documentIndex && this.state.rowIndex
                        ? this.state.DFDocs[
                            this.state.documentIndex
                          ].CustomList[this.state.rowIndex].CustomFields.map(
                            (obj, index) => {
                              if (!obj.Hide) {
                                if (obj.Prompt === "unit_desc") {
                                  return (
                                    <Draggable
                                      key={obj.Prompt}
                                      draggableId={obj.Prompt}
                                      index={index}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          className="form__display--props form__display--between"
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                          )}
                                        >
                                          <div
                                            className="col-xs-12 col-sm-4 col-md-4"
                                            id="unit_desc"
                                          >
                                            Unit Desc:
                                          </div>
                                          <div className="col-xs-10 col-sm-7 col-md-7">
                                            <input
                                              name="unit_desc"
                                              value={obj.DefaultValue}
                                              type="text"
                                              onChange={(event) =>
                                                this.onChangeCustomListFields(
                                                  event,
                                                  0,
                                                  null,
                                                  null,
                                                  obj.Prompt
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                }

                                if (obj.Prompt === "unit") {
                                  return (
                                    <Draggable
                                      key={obj.Prompt}
                                      draggableId={obj.Prompt}
                                      index={index}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          className="form__display--props form__display--between"
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                          )}
                                        >
                                          <div
                                            className="col-xs-12 col-sm-4 col-md-4"
                                            id="unit"
                                          >
                                            Unit:
                                          </div>
                                          <div className="col-xs-10 col-sm-7 col-md-7">
                                            <input
                                              name="unit"
                                              type="number"
                                              min="0"
                                              value={obj.DefaultValue}
                                              onChange={(event) =>
                                                this.onChangeCustomListFields(
                                                  event,
                                                  0,
                                                  null,
                                                  null,
                                                  obj.Prompt
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                }

                                if (obj.Prompt === "rate") {
                                  return (
                                    <Draggable
                                      key={obj.Prompt}
                                      draggableId={obj.Prompt}
                                      index={index}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          className="form__display--props form__display--between"
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                          )}
                                        >
                                          <div
                                            className="col-xs-12 col-sm-4 col-md-4"
                                            id="rate"
                                          >
                                            Rate:
                                          </div>
                                          <div className="col-xs-10 col-sm-7 col-md-7">
                                            <input
                                              name="rate"
                                              type="number"
                                              min="0"
                                              value={obj.DefaultValue}
                                              onChange={(event) =>
                                                this.onChangeCustomListFields(
                                                  event,
                                                  0,
                                                  null,
                                                  null,
                                                  obj.Prompt
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                }

                                if (obj.Prompt === "factor") {
                                  return (
                                    <Draggable
                                      key={obj.Prompt}
                                      draggableId={obj.Prompt}
                                      index={index}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          className="form__display--props form__display--between"
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                          )}
                                        >
                                          <div
                                            className="col-xs-12 col-sm-4 col-md-4"
                                            id="factor"
                                          >
                                            Factor:
                                          </div>
                                          <div className="col-xs-10 col-sm-7 col-md-7">
                                            <input
                                              name="factor"
                                              type="number"
                                              min="0"
                                              value={obj.DefaultValue}
                                              onChange={(event) =>
                                                this.onChangeCustomListFields(
                                                  event,
                                                  0,
                                                  null,
                                                  null,
                                                  obj.Prompt
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                }

                                if (obj.Prompt === "days_in_week") {
                                  return (
                                    <Draggable
                                      key={obj.Prompt}
                                      draggableId={obj.Prompt}
                                      index={index}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          className="form__display--props form__display--between"
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                          )}
                                        >
                                          <div
                                            className="col-xs-12 col-sm-4 col-md-4"
                                            id="days_in_week"
                                          >
                                            Days In Week:
                                          </div>
                                          <div className="col-xs-10 col-sm-7 col-md-7">
                                            <input
                                              name="days_in_week"
                                              type="number"
                                              min="0"
                                              value={obj.DefaultValue}
                                              onChange={(event) =>
                                                this.onChangeCustomListFields(
                                                  event,
                                                  0,
                                                  null,
                                                  null,
                                                  obj.Prompt
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                }

                                if (obj.Prompt === "amount") {
                                  return (
                                    <Draggable
                                      key={obj.Prompt}
                                      draggableId={obj.Prompt}
                                      index={index}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          className="form__display--props form__display--between"
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                          )}
                                        >
                                          <div
                                            className="col-xs-12 col-sm-4 col-md-4"
                                            id="amount"
                                          >
                                            Amount:
                                          </div>
                                          <div className="col-xs-10 col-sm-7 col-md-7">
                                            <input
                                              name="amount"
                                              type="number"
                                              min="0"
                                              value={obj.DefaultValue}
                                            />
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                }
                                if (obj.Prompt === "startDate") {
                                  var startDate = moment(obj.DefaultValue);
                                  return (
                                    <Draggable
                                      key={obj.Prompt}
                                      draggableId={obj.Prompt}
                                      index={index}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          className="form__display--props form__display--between"
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                          )}
                                          id="startDate"
                                        >
                                          <div className="col-xs-12 col-sm-4 col-md-4">
                                            Date From:
                                          </div>
                                          <div className="form__date--picker col-xs-10 col-sm-7 col-md-7 ">
                                            <div className="col-xs-8 col-sm-8 p0">
                                              <DatePicker
                                                id="showDateFrom"
                                                dateFormat="DD/MM/YYYY"
                                                selected={
                                                  this.state.disableDatePicker
                                                    ? null
                                                    : startDate
                                                }
                                                onChange={(date) =>
                                                  this.handleChangeFromDate(
                                                    date
                                                  )
                                                }
                                                disabled={
                                                  this.state.disableDatePicker
                                                }
                                              />
                                            </div>
                                            <div className="Svg--icons--picker">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="2936.352 349.176 18.501 23.145"
                                                onClick={showDateFrom}
                                              >
                                                <a
                                                  href={null}
                                                  className="svg__color--blue"
                                                >
                                                  <path
                                                    id="ic_date_range_24px"
                                                    className="cls-1"
                                                    d="M9.167,12.415H7.111V14.73H9.167Zm4.111,0H11.223V14.73h2.056Zm4.111,0H15.334V14.73H17.39Zm2.056-8.1H18.418V2H16.362V4.314H8.139V2H6.084V4.314H5.056A2.188,2.188,0,0,0,3.01,6.629L3,22.83a2.2,2.2,0,0,0,2.056,2.314h14.39A2.2,2.2,0,0,0,21.5,22.83V6.629A2.2,2.2,0,0,0,19.446,4.314Zm0,18.516H5.056V10.1h14.39Z"
                                                    transform="translate(2933.352 347.176)"
                                                  ></path>
                                                </a>
                                              </svg>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                }

                                if (obj.Prompt === "endDate") {
                                  var endDate = moment(obj.DefaultValue);
                                  return (
                                    <Draggable
                                      key={obj.Prompt}
                                      draggableId={obj.Prompt}
                                      index={index}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          className="form__display--props form__display--between "
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                          )}
                                        >
                                          <div className="col-xs-12 col-sm-4 col-md-4">
                                            Date To:
                                          </div>
                                          {this.state.TBA === "checked" ? (
                                            <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop cursorMove tba-box">
                                              TBA
                                            </div>
                                          ) : (
                                            <div className="form__date--picker col-xs-10 col-sm-7 col-md-7">
                                              <div className="col-xs-8 col-sm-8 p0">
                                                <DatePicker
                                                  id="showDateTo"
                                                  dateFormat="DD/MM/YYYY"
                                                  selected={
                                                    this.state.disableDatePicker
                                                      ? null
                                                      : endDate
                                                  }
                                                  onChange={(date) =>
                                                    this.handleChangeToDate(
                                                      date
                                                    )
                                                  }
                                                  disabled={
                                                    this.state.disableDatePicker
                                                  }
                                                />
                                              </div>
                                              <div className="Svg--icons--picker">
                                                <svg
                                                  onClick={showDateTo}
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="2936.352 349.176 18.501 23.145"
                                                >
                                                  <a
                                                    href={null}
                                                    className="svg__color--blue"
                                                  >
                                                    <path
                                                      id="ic_date_range_24px"
                                                      className="cls-1"
                                                      d="M9.167,12.415H7.111V14.73H9.167Zm4.111,0H11.223V14.73h2.056Zm4.111,0H15.334V14.73H17.39Zm2.056-8.1H18.418V2H16.362V4.314H8.139V2H6.084V4.314H5.056A2.188,2.188,0,0,0,3.01,6.629L3,22.83a2.2,2.2,0,0,0,2.056,2.314h14.39A2.2,2.2,0,0,0,21.5,22.83V6.629A2.2,2.2,0,0,0,19.446,4.314Zm0,18.516H5.056V10.1h14.39Z"
                                                      transform="translate(2933.352 347.176)"
                                                    ></path>
                                                  </a>
                                                </svg>
                                              </div>
                                            </div>
                                          )}{" "}
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                }

                                if (obj.Prompt === "capped_amount") {
                                  return (
                                    <Draggable
                                      key={obj.Prompt}
                                      draggableId={obj.Prompt}
                                      index={index}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          className="form__display--props form__display--between"
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                          )}
                                        >
                                          <div
                                            className="col-xs-12 col-sm-4 col-md-4"
                                            id="unit_desc"
                                          >
                                            Capped Amount:
                                          </div>
                                          <div className="col-xs-10 col-sm-7 col-md-7">
                                            <input
                                              name="capped_amount"
                                              value={obj.DefaultValue}
                                              type="text"
                                              onChange={(event) =>
                                                this.onChangeCustomListFields(
                                                  event,
                                                  0,
                                                  null,
                                                  null,
                                                  obj.Prompt
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                }

                                if (obj.Prompt === "chart_sort") {
                                  return (
                                    <Draggable
                                      key={obj.Prompt}
                                      draggableId={obj.Prompt}
                                      index={index}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          className="form__display--props form__display--between"
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                          )}
                                        >
                                          <div
                                            className="col-xs-12 col-sm-4 col-md-4"
                                            id="chart_sort"
                                          >
                                            Chart Sort:
                                          </div>
                                          <div className="col-xs-10 col-sm-7 col-md-7">
                                            {/* <input
                                          name="chart_sort"
                                          value={obj.DefaultValue}
                                          type="text"
                                          onChange={(event) =>
                                            this.onChangeCustomListFields(
                                              event,
                                              0,
                                              null,
                                              null,
                                              obj.Prompt
                                            )
                                          }
                                        /> */}

                                            <InputMask
                                              mask={this.state.setup_chartSort.replace(
                                                /x/g,
                                                "*"
                                              )}
                                              maskChar={null}
                                              type="text"
                                              name="chart_sort"
                                              value={obj.DefaultValue}
                                              onChange={(event) =>
                                                this.onChangeCustomListFields(
                                                  event,
                                                  0,
                                                  null,
                                                  null,
                                                  obj.Prompt
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                }

                                if (obj.Prompt === "chart_code") {
                                  return (
                                    <Draggable
                                      key={obj.Prompt}
                                      draggableId={obj.Prompt}
                                      index={index}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          className="form__display--props form__display--between"
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                          )}
                                        >
                                          <div
                                            className="col-xs-12 col-sm-4 col-md-4"
                                            id="chart_code"
                                          >
                                            Chart Code:
                                          </div>
                                          <div className="col-xs-10 col-sm-7 col-md-7">
                                            {/* <input
                                              name="chart_code"
                                              value={obj.DefaultValue}
                                              type="text"
                                              onChange={(event) =>
                                                this.onChangeCustomListFields(
                                                  event,
                                                  0,
                                                  null,
                                                  null,
                                                  obj.Prompt
                                                )
                                              }
                                            /> */}
                                            <InputMask
                                              mask={this.state.setup_chartCode.replace(
                                                /x/g,
                                                "*"
                                              )}
                                              maskChar={null}
                                              type="text"
                                              name="chart_code"
                                              value={obj.DefaultValue}
                                              onChange={(event) =>
                                                this.onChangeCustomListFields(
                                                  event,
                                                  0,
                                                  null,
                                                  null,
                                                  obj.Prompt
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                }

                                if (obj.Prompt === "flag") {
                                  return (
                                    <Draggable
                                      key={obj.Prompt}
                                      draggableId={obj.Prompt}
                                      index={index}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          className="form__display--props form__display--between"
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                          )}
                                        >
                                          <div
                                            className="col-xs-12 col-sm-4 col-md-4"
                                            id="flag"
                                          >
                                            Tracking Field:
                                          </div>
                                          <div className="col-xs-10 col-sm-7 col-md-7">
                                            {/* <input
                                              name="flag"
                                              value={obj.DefaultValue}
                                              type="text"
                                              onChange={(event) =>
                                                this.onChangeCustomListFields(
                                                  event,
                                                  0,
                                                  null,
                                                  null,
                                                  obj.Prompt
                                                )
                                              }
                                            /> */}
                                            <select
                                              name="flag"
                                              value={this.state.flag}
                                              onChange={(event) =>
                                                this.onChangeCustomListFields(
                                                  event,
                                                  0,
                                                  null,
                                                  null,
                                                  obj.Prompt
                                                )
                                              }
                                            >
                                              <option value="">Select</option>
                                              {this.state.trackingList.map(
                                                (obj, i) => {
                                                  return (
                                                    <option
                                                      value={obj.defaultValue}
                                                    >
                                                      {obj.Prompt}
                                                    </option>
                                                  );
                                                }
                                              )}
                                            </select>
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                }

                                if (obj.Prompt === "payment_flag") {
                                  return (
                                    <Draggable
                                      key={obj.Prompt}
                                      draggableId={obj.Prompt}
                                      index={index}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          className="form__display--props form__display--between"
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                          )}
                                        >
                                          <div
                                            className="col-xs-12 col-sm-4 col-md-4"
                                            id="payment_flag"
                                          >
                                            Payment Flag:
                                          </div>
                                          <div className="col-xs-10 col-sm-7 col-md-7">
                                            <input
                                              name="payment_flag"
                                              value={obj.DefaultValue}
                                              type="text"
                                              onChange={(event) =>
                                                this.onChangeCustomListFields(
                                                  event,
                                                  0,
                                                  null,
                                                  null,
                                                  obj.Prompt
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                }

                                if (obj.Prompt === "calc_days") {
                                  return (
                                    <Draggable
                                      key={obj.Prompt}
                                      draggableId={obj.Prompt}
                                      index={index}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          className="form__display--props form__display--between"
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                          )}
                                        >
                                          <div
                                            className="col-xs-12 col-sm-4 col-md-4"
                                            id="calc_days"
                                          >
                                            Calc days:
                                          </div>
                                          <div className="col-xs-10 col-sm-7 col-md-7">
                                            <input
                                              name="calc_days"
                                              type="number"
                                              min="0"
                                              value={
                                                obj.DefaultValue
                                                  ? obj.DefaultValue
                                                  : 0
                                              }
                                            />
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                }

                                if (obj.Prompt === "calc_weeks") {
                                  return (
                                    <Draggable
                                      key={obj.Prompt}
                                      draggableId={obj.Prompt}
                                      index={index}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          className="form__display--props form__display--between"
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                          )}
                                        >
                                          <div
                                            className="col-xs-12 col-sm-4 col-md-4"
                                            id="calc_weeks"
                                          >
                                            Calc Weeks:
                                          </div>
                                          <div className="col-xs-10 col-sm-7 col-md-7">
                                            <input
                                              name="calc_weeks"
                                              type="number"
                                              min="0"
                                              value={
                                                obj.DefaultValue
                                                  ? obj.DefaultValue
                                                  : 0
                                              }
                                            />
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                }
                              }
                            }
                          )
                        : console.log("NOT FOUND")}
                      <div className="clear5"></div>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
                <div className="panel-heading panel_width exclude">
                  <h4 className="panel-title">
                    <a
                      className="accordion-toggle collapsed text_change"
                      data-toggle="collapse"
                      data-parent=".sortable_list"
                      href="#hide_detail"
                    >
                      More Detail
                    </a>
                  </h4>
                </div>
                <Droppable droppableId="hide_detail">
                  {(provided, snapshot) => (
                    <div
                      id="hide_detail"
                      className="panel-collapse collapse "
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      <div className="clear10"></div>
                      <div className="panel-body p0 ">
                        {this.state.documentIndex && this.state.rowIndex
                          ? this.state.DFDocs[
                              this.state.documentIndex
                            ].CustomList[this.state.rowIndex].CustomFields.map(
                              (obj, index) => {
                                if (obj.Hide) {
                                  if (obj.Prompt === "unit_desc") {
                                    return (
                                      <Draggable
                                        key={obj.Prompt}
                                        draggableId={obj.Prompt}
                                        index={index}
                                      >
                                        {(provided, snapshot) => (
                                          <div
                                            className="form__display--props form__display--between"
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                              snapshot.isDragging,
                                              provided.draggableProps.style
                                            )}
                                          >
                                            <div
                                              className="col-xs-12 col-sm-4 col-md-4"
                                              id="unit_desc"
                                            >
                                              Unit Desc:
                                            </div>
                                            <div className="col-xs-10 col-sm-7 col-md-7">
                                              <input
                                                name="unit_desc"
                                                value={obj.DefaultValue}
                                                type="text"
                                                onChange={(event) =>
                                                  this.onChangeCustomListFields(
                                                    event,
                                                    0,
                                                    null,
                                                    null,
                                                    obj.Prompt
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                        )}
                                      </Draggable>
                                    );
                                  }

                                  if (obj.Prompt === "unit") {
                                    return (
                                      <Draggable
                                        key={obj.Prompt}
                                        draggableId={obj.Prompt}
                                        index={index}
                                      >
                                        {(provided, snapshot) => (
                                          <div
                                            className="form__display--props form__display--between"
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                              snapshot.isDragging,
                                              provided.draggableProps.style
                                            )}
                                          >
                                            <div
                                              className="col-xs-12 col-sm-4 col-md-4"
                                              id="unit"
                                            >
                                              Unit:
                                            </div>
                                            <div className="col-xs-10 col-sm-7 col-md-7">
                                              <input
                                                name="unit"
                                                type="number"
                                                min="0"
                                                value={obj.DefaultValue}
                                                onChange={(event) =>
                                                  this.onChangeCustomListFields(
                                                    event,
                                                    0,
                                                    null,
                                                    null,
                                                    obj.Prompt
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                        )}
                                      </Draggable>
                                    );
                                  }

                                  if (obj.Prompt === "rate") {
                                    return (
                                      <Draggable
                                        key={obj.Prompt}
                                        draggableId={obj.Prompt}
                                        index={index}
                                      >
                                        {(provided, snapshot) => (
                                          <div
                                            className="form__display--props form__display--between"
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                              snapshot.isDragging,
                                              provided.draggableProps.style
                                            )}
                                          >
                                            <div
                                              className="col-xs-12 col-sm-4 col-md-4"
                                              id="rate"
                                            >
                                              Rate:
                                            </div>
                                            <div className="col-xs-10 col-sm-7 col-md-7">
                                              <input
                                                name="rate"
                                                type="number"
                                                min="0"
                                                value={obj.DefaultValue}
                                                onChange={(event) =>
                                                  this.onChangeCustomListFields(
                                                    event,
                                                    0,
                                                    null,
                                                    null,
                                                    obj.Prompt
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                        )}
                                      </Draggable>
                                    );
                                  }

                                  if (obj.Prompt === "factor") {
                                    return (
                                      <Draggable
                                        key={obj.Prompt}
                                        draggableId={obj.Prompt}
                                        index={index}
                                      >
                                        {(provided, snapshot) => (
                                          <div
                                            className="form__display--props form__display--between"
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                              snapshot.isDragging,
                                              provided.draggableProps.style
                                            )}
                                          >
                                            <div
                                              className="col-xs-12 col-sm-4 col-md-4"
                                              id="factor"
                                            >
                                              Factor:
                                            </div>
                                            <div className="col-xs-10 col-sm-7 col-md-7">
                                              <input
                                                name="factor"
                                                type="number"
                                                min="0"
                                                value={obj.DefaultValue}
                                                onChange={(event) =>
                                                  this.onChangeCustomListFields(
                                                    event,
                                                    0,
                                                    null,
                                                    null,
                                                    obj.Prompt
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                        )}
                                      </Draggable>
                                    );
                                  }

                                  if (obj.Prompt === "days_in_week") {
                                    return (
                                      <Draggable
                                        key={obj.Prompt}
                                        draggableId={obj.Prompt}
                                        index={index}
                                      >
                                        {(provided, snapshot) => (
                                          <div
                                            className="form__display--props form__display--between"
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                              snapshot.isDragging,
                                              provided.draggableProps.style
                                            )}
                                          >
                                            <div
                                              className="col-xs-12 col-sm-4 col-md-4"
                                              id="days_in_week"
                                            >
                                              Days In Week:
                                            </div>
                                            <div className="col-xs-10 col-sm-7 col-md-7">
                                              <input
                                                name="days_in_week"
                                                type="number"
                                                min="0"
                                                value={obj.DefaultValue}
                                                onChange={(event) =>
                                                  this.onChangeCustomListFields(
                                                    event,
                                                    0,
                                                    null,
                                                    null,
                                                    obj.Prompt
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                        )}
                                      </Draggable>
                                    );
                                  }

                                  if (obj.Prompt === "amount") {
                                    return (
                                      <Draggable
                                        key={obj.Prompt}
                                        draggableId={obj.Prompt}
                                        index={index}
                                      >
                                        {(provided, snapshot) => (
                                          <div
                                            className="form__display--props form__display--between"
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                              snapshot.isDragging,
                                              provided.draggableProps.style
                                            )}
                                          >
                                            <div
                                              className="col-xs-12 col-sm-4 col-md-4"
                                              id="amount"
                                            >
                                              Amount:
                                            </div>
                                            <div className="col-xs-10 col-sm-7 col-md-7">
                                              <input
                                                name="amount"
                                                type="number"
                                                min="0"
                                                value={obj.DefaultValue}
                                              />
                                            </div>
                                          </div>
                                        )}
                                      </Draggable>
                                    );
                                  }
                                  if (obj.Prompt === "startDate") {
                                    var startDate = moment(obj.DefaultValue);
                                    return (
                                      <Draggable
                                        key={obj.Prompt}
                                        draggableId={obj.Prompt}
                                        index={index}
                                      >
                                        {(provided, snapshot) => (
                                          <div
                                            className="form__display--props form__display--between"
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                              snapshot.isDragging,
                                              provided.draggableProps.style
                                            )}
                                            id="startDate"
                                          >
                                            <div className="col-xs-12 col-sm-4 col-md-4">
                                              Date From:
                                            </div>
                                            <div className="form__date--picker col-xs-10 col-sm-7 col-md-7 ">
                                              <div className="col-xs-8 col-sm-8 p0">
                                                <DatePicker
                                                  id="showDateFrom"
                                                  dateFormat="DD/MM/YYYY"
                                                  selected={
                                                    this.state.disableDatePicker
                                                      ? null
                                                      : startDate
                                                  }
                                                  onChange={(date) =>
                                                    this.handleChangeFromDate(
                                                      date
                                                    )
                                                  }
                                                  disabled={
                                                    this.state.disableDatePicker
                                                  }
                                                />
                                              </div>
                                              <div className="Svg--icons--picker">
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="2936.352 349.176 18.501 23.145"
                                                  onClick={showDateFrom}
                                                >
                                                  <a
                                                    href={null}
                                                    className="svg__color--blue"
                                                  >
                                                    <path
                                                      id="ic_date_range_24px"
                                                      className="cls-1"
                                                      d="M9.167,12.415H7.111V14.73H9.167Zm4.111,0H11.223V14.73h2.056Zm4.111,0H15.334V14.73H17.39Zm2.056-8.1H18.418V2H16.362V4.314H8.139V2H6.084V4.314H5.056A2.188,2.188,0,0,0,3.01,6.629L3,22.83a2.2,2.2,0,0,0,2.056,2.314h14.39A2.2,2.2,0,0,0,21.5,22.83V6.629A2.2,2.2,0,0,0,19.446,4.314Zm0,18.516H5.056V10.1h14.39Z"
                                                      transform="translate(2933.352 347.176)"
                                                    ></path>
                                                  </a>
                                                </svg>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </Draggable>
                                    );
                                  }

                                  if (obj.Prompt === "endDate") {
                                    var endDate = moment(obj.DefaultValue);
                                    return (
                                      <Draggable
                                        key={obj.Prompt}
                                        draggableId={obj.Prompt}
                                        index={index}
                                      >
                                        {(provided, snapshot) => (
                                          <div
                                            className="form__display--props form__display--between"
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                              snapshot.isDragging,
                                              provided.draggableProps.style
                                            )}
                                          >
                                            <div className="col-xs-12 col-sm-4 col-md-4">
                                              Date To:
                                            </div>
                                            {this.state.TBA === "checked" ? (
                                              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop cursorMove tba-box">
                                                TBA
                                              </div>
                                            ) : (
                                              <div className="form__date--picker col-xs-10 col-sm-7 col-md-7">
                                                <div className="col-xs-8 col-sm-8 p0">
                                                  <DatePicker
                                                    id="showDateTo"
                                                    dateFormat="DD/MM/YYYY"
                                                    selected={
                                                      this.state
                                                        .disableDatePicker
                                                        ? null
                                                        : endDate
                                                    }
                                                    onChange={(date) =>
                                                      this.handleChangeToDate(
                                                        date
                                                      )
                                                    }
                                                    disabled={
                                                      this.state
                                                        .disableDatePicker
                                                    }
                                                  />
                                                </div>
                                                <div className="Svg--icons--picker">
                                                  <svg
                                                    onClick={showDateTo}
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="2936.352 349.176 18.501 23.145"
                                                  >
                                                    <a
                                                      href={null}
                                                      className="svg__color--blue"
                                                    >
                                                      <path
                                                        id="ic_date_range_24px"
                                                        className="cls-1"
                                                        d="M9.167,12.415H7.111V14.73H9.167Zm4.111,0H11.223V14.73h2.056Zm4.111,0H15.334V14.73H17.39Zm2.056-8.1H18.418V2H16.362V4.314H8.139V2H6.084V4.314H5.056A2.188,2.188,0,0,0,3.01,6.629L3,22.83a2.2,2.2,0,0,0,2.056,2.314h14.39A2.2,2.2,0,0,0,21.5,22.83V6.629A2.2,2.2,0,0,0,19.446,4.314Zm0,18.516H5.056V10.1h14.39Z"
                                                        transform="translate(2933.352 347.176)"
                                                      ></path>
                                                    </a>
                                                  </svg>
                                                </div>
                                              </div>
                                            )}{" "}
                                          </div>
                                        )}
                                      </Draggable>
                                    );
                                  }

                                  if (obj.Prompt === "capped_amount") {
                                    return (
                                      <Draggable
                                        key={obj.Prompt}
                                        draggableId={obj.Prompt}
                                        index={index}
                                      >
                                        {(provided, snapshot) => (
                                          <div
                                            className="form__display--props form__display--between"
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                              snapshot.isDragging,
                                              provided.draggableProps.style
                                            )}
                                          >
                                            <div
                                              className="col-xs-12 col-sm-4 col-md-4"
                                              id="unit_desc"
                                            >
                                              Capped Amount:
                                            </div>
                                            <div className="col-xs-10 col-sm-7 col-md-7">
                                              <input
                                                name="capped_amount"
                                                value={obj.DefaultValue}
                                                type="text"
                                                onChange={(event) =>
                                                  this.onChangeCustomListFields(
                                                    event,
                                                    0,
                                                    null,
                                                    null,
                                                    obj.Prompt
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                        )}
                                      </Draggable>
                                    );
                                  }

                                  if (obj.Prompt === "chart_sort") {
                                    return (
                                      <Draggable
                                        key={obj.Prompt}
                                        draggableId={obj.Prompt}
                                        index={index}
                                      >
                                        {(provided, snapshot) => (
                                          <div
                                            className="form__display--props form__display--between"
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                              snapshot.isDragging,
                                              provided.draggableProps.style
                                            )}
                                          >
                                            <div
                                              className="col-xs-12 col-sm-4 col-md-4"
                                              id="chart_sort"
                                            >
                                              Chart Sort:
                                            </div>
                                            <div className="col-xs-10 col-sm-7 col-md-7">
                                              {/* <input
                                                name="chart_sort"
                                                value={obj.DefaultValue}
                                                type="text"
                                                onChange={(event) =>
                                                  this.onChangeCustomListFields(
                                                    event,
                                                    0,
                                                    null,
                                                    null,
                                                    obj.Prompt
                                                  )
                                                }
                                              /> */}
                                              <InputMask
                                                mask={this.state.setup_chartSort.replace(
                                                  /x/g,
                                                  "*"
                                                )}
                                                maskChar={null}
                                                type="text"
                                                name="chart_sort"
                                                value={obj.DefaultValue}
                                                onChange={(event) =>
                                                  this.onChangeCustomListFields(
                                                    event,
                                                    0,
                                                    null,
                                                    null,
                                                    obj.Prompt
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                        )}
                                      </Draggable>
                                    );
                                  }

                                  if (obj.Prompt === "chart_code") {
                                    return (
                                      <Draggable
                                        key={obj.Prompt}
                                        draggableId={obj.Prompt}
                                        index={index}
                                      >
                                        {(provided, snapshot) => (
                                          <div
                                            className="form__display--props form__display--between"
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                              snapshot.isDragging,
                                              provided.draggableProps.style
                                            )}
                                          >
                                            <div
                                              className="col-xs-12 col-sm-4 col-md-4"
                                              id="chart_code"
                                            >
                                              Chart Code:
                                            </div>
                                            <div className="col-xs-10 col-sm-7 col-md-7">
                                              {/* <input
                                                name="chart_code"
                                                value={obj.DefaultValue}
                                                type="text"
                                                onChange={(event) =>
                                                  this.onChangeCustomListFields(
                                                    event,
                                                    0,
                                                    null,
                                                    null,
                                                    obj.Prompt
                                                  )
                                                }
                                              /> */}
                                              <InputMask
                                                mask={this.state.setup_chartCode.replace(
                                                  /x/g,
                                                  "*"
                                                )}
                                                maskChar={null}
                                                type="text"
                                                name="chart_code"
                                                value={obj.DefaultValue}
                                                onChange={(event) =>
                                                  this.onChangeCustomListFields(
                                                    event,
                                                    0,
                                                    null,
                                                    null,
                                                    obj.Prompt
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                        )}
                                      </Draggable>
                                    );
                                  }

                                  if (obj.Prompt === "flag") {
                                    return (
                                      <Draggable
                                        key={obj.Prompt}
                                        draggableId={obj.Prompt}
                                        index={index}
                                      >
                                        {(provided, snapshot) => (
                                          <div
                                            className="form__display--props form__display--between"
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                              snapshot.isDragging,
                                              provided.draggableProps.style
                                            )}
                                          >
                                            <div
                                              className="col-xs-12 col-sm-4 col-md-4"
                                              id="flag"
                                            >
                                              Tracking Field:
                                            </div>
                                            <div className="col-xs-10 col-sm-7 col-md-7">
                                              {/* <input
                                                name="flag"
                                                value={obj.DefaultValue}
                                                type="text"
                                                onChange={(event) =>
                                                  this.onChangeCustomListFields(
                                                    event,
                                                    0,
                                                    null,
                                                    null,
                                                    obj.Prompt
                                                  )
                                                }
                                              /> */}
                                              <select
                                                name="flag"
                                                value={this.state.flag}
                                                onChange={(event) =>
                                                  this.onChangeCustomListFields(
                                                    event,
                                                    0,
                                                    null,
                                                    null,
                                                    obj.Prompt
                                                  )
                                                }
                                              >
                                                <option value="">Select</option>
                                                {this.state.trackingList.map(
                                                  (obj, i) => {
                                                    return (
                                                      <option
                                                        value={obj.defaultValue}
                                                      >
                                                        {obj.Prompt}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                              </select>
                                            </div>
                                          </div>
                                        )}
                                      </Draggable>
                                    );
                                  }

                                  if (obj.Prompt === "payment_flag") {
                                    return (
                                      <Draggable
                                        key={obj.Prompt}
                                        draggableId={obj.Prompt}
                                        index={index}
                                      >
                                        {(provided, snapshot) => (
                                          <div
                                            className="form__display--props form__display--between"
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                              snapshot.isDragging,
                                              provided.draggableProps.style
                                            )}
                                          >
                                            <div
                                              className="col-xs-12 col-sm-4 col-md-4"
                                              id="payment_flag"
                                            >
                                              Payment Flag:
                                            </div>
                                            <div className="col-xs-10 col-sm-7 col-md-7">
                                              <input
                                                name="payment_flag"
                                                value={obj.DefaultValue}
                                                type="text"
                                                onChange={(event) =>
                                                  this.onChangeCustomListFields(
                                                    event,
                                                    0,
                                                    null,
                                                    null,
                                                    obj.Prompt
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                        )}
                                      </Draggable>
                                    );
                                  }

                                  if (obj.Prompt === "calc_days") {
                                    return (
                                      <Draggable
                                        key={obj.Prompt}
                                        draggableId={obj.Prompt}
                                        index={index}
                                      >
                                        {(provided, snapshot) => (
                                          <div
                                            className="form__display--props form__display--between"
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                              snapshot.isDragging,
                                              provided.draggableProps.style
                                            )}
                                          >
                                            <div
                                              className="col-xs-12 col-sm-4 col-md-4"
                                              id="calc_days"
                                            >
                                              Calc days:
                                            </div>
                                            <div className="col-xs-10 col-sm-7 col-md-7">
                                              <input
                                                name="calc_days"
                                                type="number"
                                                min="0"
                                                value={
                                                  obj.DefaultValue
                                                    ? obj.DefaultValue
                                                    : 0
                                                }
                                              />
                                            </div>
                                          </div>
                                        )}
                                      </Draggable>
                                    );
                                  }

                                  if (obj.Prompt === "calc_weeks") {
                                    return (
                                      <Draggable
                                        key={obj.Prompt}
                                        draggableId={obj.Prompt}
                                        index={index}
                                      >
                                        {(provided, snapshot) => (
                                          <div
                                            className="form__display--props form__display--between"
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                              snapshot.isDragging,
                                              provided.draggableProps.style
                                            )}
                                          >
                                            <div
                                              className="col-xs-12 col-sm-4 col-md-4"
                                              id="calc_weeks"
                                            >
                                              Calc Weeks:
                                            </div>
                                            <div className="col-xs-10 col-sm-7 col-md-7">
                                              <input
                                                name="calc_weeks"
                                                type="number"
                                                min="0"
                                                value={
                                                  obj.DefaultValue
                                                    ? obj.DefaultValue
                                                    : 0
                                                }
                                              />
                                            </div>
                                          </div>
                                        )}
                                      </Draggable>
                                    );
                                  }
                                }
                              }
                            )
                          : console.log("NOT FOUND")}
                      </div>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>

            <div className="clear30"></div>
            <div className="col-sm-6">
              <div className="btn_cance_save">
                <button
                  type="button"
                  className="btn_save_pro"
                  data-dismiss="modal"
                  onClick={this.customListCodeModalClose}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="btn_cancel_pro"
                  data-dismiss="modal"
                  onClick={this.customListCodeModalClose}
                >
                  Cancel
                </button>
              </div>
            </div>
            <div className="clear20"></div>
          </div>
        </Modal>
      </div>
    );
  }
}
export default InsertDocument;
