import React, { Component } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Auth } from "aws-amplify";
import "./CpStepFirst.css";
import "../../assets/css/bootstrap.css";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/style.css";
import LOGO from "../../assets/images/reset_logo.jpg";
import { toast } from 'react-toastify';

// - -   jQUARY INSTANCE  - -//
// - - - - - - - - - - - - - //
import $ from 'jquery';
window.jQuery = $;
window.$ = $;
global.jQuery = $;

class CpStepFirst extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phone_number:'',
            code:''
        };
     }
     
    componentDidMount(){
        this.setState({phone_number: localStorage.getItem('MobileNumber')});
        this.setState({reset_email: localStorage.getItem('userEmail')});
        this.setState({code: Math.round(Math.random() * 100000)});
        $('.modal-backdrop').hide();
    }

    inputChangeHandler = (e) =>{
        
    }

    onProceed = async () =>{

        await Auth.forgotPassword(this.state.reset_email).then(data => {
            toast.success("Sms sent successfully to " + data.CodeDeliveryDetails.Destination, { autoClose: 5000});
            this.props.history.push({
                pathname: '/change-password-step-2',
                state: { code: this.state.code , phone_number: this.state.phone_number, reset_email: this.state.reset_email}
            });
        }).catch(err => {
            toast.error(err.message);
        });
    }
    

    render() {
        return (
                <div className="CpStepFirst">
                    <div className="col-sm-12">
                        <div className="container p0">
                            <div className="forgot_box1">
                                <div className="forgot_box bdr_change_pass">

                                    <div className="col-sm-12 reset_password"> <img src={LOGO} alt="" /> Reset Password</div>

                                    <div className="reset_under_box">
                                        <div className="clear50"></div>

                                        Enter your phone number below to reset your password

                                        <div className="clear20"></div>

                                        <input name="phone_number" onChange={this.inputChangeHandler}  value={this.state.phone_number} className="frogot_input" type="text" placeholder="Phone Number" />

                                        <div className="clear50"></div>

                                        <LinkContainer to="/"><a href={null} className="btn_frogot_back">Back</a></LinkContainer>
                                        <a href={null} onClick={this.onProceed} className="btn_frogot_blue">Proceed</a>

                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="clearfix"></div>

                                </div>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
                );
    }
};
export default CpStepFirst;
