import React, { Component } from "react";
import "./Report.css";
import Header from "../Common/Header/Header";
import { API } from "aws-amplify";
import DatePicker from "react-datepicker";
import moment from "moment";
import "moment-timezone";
import "react-datepicker/dist/react-datepicker.css";
// import jsonfile from 'jsonfile';

class Report extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            projectList: [],
            startDate: moment.tz(moment(), "Australia/Canberra"),
            endDate: moment.tz(moment().add(1, "days"), "Australia/Canberra"),
            ReportValue: "1",
            projectName: "",
            position: "",
            department: "",
            report: [],
            reportName: "",
            projectGuid: "",
            reportGuid: "",
            formPack: [],
        };
    }

    async componentDidMount() {
        await this.getAllProjects();

        this.setState({
            projectName:
                this.state.projectList.length > 0 ? this.state.projectList[0].Desc : 0,
            projectGuid:
                this.state.projectList.length > 0 ? this.state.projectList[0].Guid : 0,
        });
        await this.getAllReposrts();
        this.setState({
            isLoading: false,
        });
    }

    getAllReposrtsAPI() {
        // return API.post("getconfig", "/getconfig", {
        //     body: {
        //         TenantGuid: localStorage.getItem("useableGuid"),
        //         Type: "Mrt",
        //     },
        // });
        return this.props.postRequest("/config/get-config", { Type: "Mrt" });
    }

    getFormPack(id) {
        //console.log(id)
        // return API.post("formpackgetreport", "/formpackgetreport", {
        //     body: {
        //         gid: id,
        //     },
        // });
        return this.props.postRequest("/form-pack/get-pack", { Guid: id });
    }

    getAllReposrts() {
        this.getAllReposrtsAPI().then((data) => {
            console.log("getAllReposrtsAPI=>", data);
            if (data.status) {
                this.setState({
                    report: data.result,
                });
            }

        });
    }

    async getAllProjects() {
        // await API.post("getallprojects", "/getallprojects", {
        //     body: {
        //         TenantGuid: localStorage.getItem("useableGuid"),
        //     },
        // })
        //     .then((data) => {
        //         console.log("data--------------------", data);
        //         this.setState({
        //             projectList: data,
        //         });
        //     })
        //     .catch((err) => {
        //         console.log(err);
        //     });
        try {
            let response = await this.props.postRequest(
                `/projects/get-list/${localStorage.getItem("companyGuid")}`,
                {}
            );

            if (response.status) {
                this.setState({
                    projectList: response.result,
                });
            }
            console.log("/projects/get-list=>", response);
        } catch (err) {
            console.log("/projects/get-list error=>", err);
        }
    }

    showDateFrom() {
        document.getElementById("showDateFrom").click();
    }

    showDateTo() {
        document.getElementById("showDateTo").click();
    }

    reportsChange = (event) => {
        if (event.target.name === "projectName") {
            this.setState({
                projectName: event.target.value,
            });
        }

        if (event.target.name === "ReportValue") {
            this.setState({
                ReportValue: event.target.value,
            });
        }
        if (event.target.name === "position") {
            this.setState({
                position: event.target.value,
            });
        }
        if (event.target.name === "department") {
            this.setState({
                department: event.target.value,
            });
        }
    };

    setCustomFields(fields) {
        var field = [];
        fields.map((item) => {
            field.push({
                description: item.Desc,
                value: item.Value,
            });
        });
        return field;
    }

    setExtSigners(ExtSigners) {
        var externalSigners = [];
        ExtSigners.map((item) => {
            //console.log('ExtSigner=>',item);
            externalSigners.push({
                firstName: item.FirstName,
                lastName: item.Lastname,
                email: item.Email,
                countryCode: "+" + item.countryCode,
                mobile: item.Mobile.replace("+" + item.countryCode, ""),
                sequence: parseInt(item.Sequence),
                signaturePosition: parseInt(item.SigPosition),
            });
        });
        return externalSigners;
    }

    setApprovers(Approvers) {
        var ApproversGroup = [];
        Approvers.map((item) => {
            ApproversGroup.push({
                name: item.ApproverName,
                sequence: item.Sequence,
                signaturePosition: item.SigPosition,
                signed: item.Signed,
            });
        });
        return ApproversGroup;
    }

    setCustomListCodeValues(CustomFields, pmtCode, Desc) {
        var pmtCode = pmtCode;
        var description = Desc;
        var unit = 0;
        var unitDesc = "";
        var rate = 0;
        var factor = 0;
        var amount = 0;
        var cappedAmount = 0;
        var daysInWeek = 0;
        var dateFrom = "2019-02-04T00:00:00.000Z";
        var dateTo = "2019-04-16T00:00:00.000Z";
        var chartSort = "";
        var chartCode = "";
        var flag = "";
        var paymentFlag = "";
        var calcDays = 0;
        var calcWeeks = 0;
        CustomFields.map((item) => {
            if (item.Promt === "unit") {
                unit = parseInt(item.DefaultValue);
            }

            if (item.Promt === "unit_desc") {
                unitDesc = item.DefaultValue;
            }

            if (item.Promt === "rate") {
                rate = parseInt(item.DefaultValue);
            }

            if (item.Promt === "factor") {
                factor = parseInt(item.DefaultValue);
            }

            if (item.Promt === "amount") {
                amount = parseInt(item.DefaultValue);
            }

            if (item.Promt === "capped_amount") {
                cappedAmount = parseInt(item.DefaultValue);
            }

            if (item.Promt === "startDate") {
                dateFrom = item.DefaultValue;
            }

            if (item.Promt === "endDate") {
                dateTo = item.DefaultValue;
            }

            if (item.Promt === "chart_sort") {
                chartSort = item.DefaultValue;
            }

            if (item.Promt === "chart_code") {
                chartCode = item.DefaultValue;
            }

            if (item.Promt === "flag") {
                flag = item.DefaultValue;
            }

            if (item.Promt === "payment_flag") {
                paymentFlag = item.DefaultValue;
            }

            if (item.Promt === "calc_days") {
                calcDays = parseInt(item.DefaultValue);
            }

            if (item.Promt === "calc_weeks") {
                calcWeeks = parseInt(item.DefaultValue);
            }
        });
        return {
            pmtCode: pmtCode,
            description: description,
            unit: unit,
            unitDesc: unitDesc,
            rate: rate,
            factor: factor,
            amount: amount,
            cappedAmount: cappedAmount,
            daysInWeek: daysInWeek,
            dateFrom: dateFrom,
            dateTo: dateTo,
            chartSort: chartSort,
            chartCode: chartCode,
            flag: flag,
            paymentFlag: paymentFlag,
            calcDays: calcDays,
            calcWeeks: calcWeeks,
        };
    }

    setCustomListCodes(customList) {
        var customListCodes = [];
        customList.map((item) => {
            customListCodes.push(
                this.setCustomListCodeValues(
                    item.CustomFields,
                    item.CustomCode,
                    item.Desc
                )
            );
        });

        return customListCodes;
    }

    setFormEntryFields(DocDetails) {
        var formEntryFields = [];
        DocDetails.map((item) => {
            formEntryFields.push({
                groupHeading: item.SubHeading,
                description: item.Promt,
                value: item.DefaultValue,
            });
        });

        return formEntryFields;
    }

    async setDocuments(id) {
        var documentDetail = [];
        await API.post("getdocumentdetail", "/getdocumentdetail", {
            body: {
                TanantGuid: id,
            },
        }).then((data) => {
            //documentDetail[id] = data;
            data.docs.map((item) => {
                //console.log('item=>',item);
                documentDetail.push({
                    templateCode: item.TemplateCode,
                    templateDescription: item.Desc,
                    confidential: parseInt(item.Confidential) === 1 ? true : false,
                    extParty: item.ExtParty ? item.ExtParty : false,
                    emailDist: item.EmailDist.length ? item.EmailDist[0] : "",
                    customListCodes: this.setCustomListCodes(item.CustomList),
                    formEntryFields: this.setFormEntryFields(item.DocDetails),
                });
            });
            this.setState({ [id]: documentDetail });
            //console.log('doc data=>', documentDetail)
        });
    }

    dropDown(e) {
        let index = e.target.selectedIndex;
        let element = e.target.childNodes[index];
        let selectedid = element.getAttribute("id");
        this.setState({
            [e.target.name]: e.target.value,
            [e.target.id]: selectedid,
        });

        if (e.target.id === "projectGuid") {
            this.setState({
                isLoading: true,
            });
            this.getFormPack(selectedid).then((data) => {
                console.log('Report pack Data: ', data.Items);
                if (data?.Items?.length > 0) {
                    var formPacks = [];
                    data.Items.map((item) => {
                        this.setDocuments(item.guid);
                        formPacks.push({
                            guid: item.guid,
                            code: "PRFT01",
                            description: item.FormPack,
                            project: item.ProjectName,
                            firstName: item.FirstName,
                            lastName: item.LastName,
                            email: item.Email,
                            countryCode: "+" + item.CountryCode,
                            mobile: item.Mobile.replace("+" + item.CountryCode, ""),
                            createdDate: item.CreatedTS,
                            emailSent: item.EmailDate,
                            status: item.Status,
                            operator: item.UserName,
                            type: item.Status,
                            customFields: this.setCustomFields(item.CustomFields),
                            approvalGroup: {
                                userGroup: "Production Approval Group",
                                userName: item.ApprovalGroup,
                                initials: "PAG",
                                department: "Production",
                                approvers: this.setApprovers(item.ApprovalChain),
                            },
                            externalSigners: this.setExtSigners(item.ExtSigners),
                            documents: [],
                        });
                    });
                    //console.log('formPacks=>',formPacks);
                    this.setState({
                        isLoading: false,
                        formPack: formPacks,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                        formPack: [],
                    });
                }
            });
        }
    }
    handleChange(date) {
        this.setState({
            startDate: date,
        });
    }

    handleChangeTo(date) {
        this.setState({
            endDate: date,
        });
    }

    generateReport() {
        var formPack = this.state.formPack;
        formPack.map((pack) => {
            //console.log('pack=>',this.state[pack.guid]);
            pack.documents = this.state[pack.guid];
        });
        //console.log('this.state.formPack=>', this.state.formPack); return false;
        let summary_only = document.getElementById("summary_only").checked;
        let obj = {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            position: this.state.position,
            department: this.state.department,
            projectName: this.state.projectName,
            projectGuid: this.state.projectGuid,
            reportName: summary_only ? "Summary Report" : "Detailed Report",
            reportGuid: this.state.reportGuid,
            reportResults: {
                formPacks: this.state.formPack,
            },
        };
        //console.log('Object data: ',obj); return false;
        let data = JSON.stringify(obj);
        //console.log(data)
        localStorage.setItem("getreport", data);
        /* this.props.history.push({
                            pathname: '/report/print',
                            state: { data:data  }
                          });

        */
        if (summary_only) {
            var path =
                window.location.protocol +
                "//" +
                window.location.host +
                "/report/print";
            window.open(path, "_blank");
        } else {
            var path =
                window.location.protocol +
                "//" +
                window.location.host +
                "/report/print_detail";
            window.open(path, "_blank");
        }
    }
    render() {
        return (
            <div className="Report">
                <Header
                    pageHeading=""
                    isLoading={this.state.isLoading}
                    props={this.props}
                />
                <div className="clearfix"></div>
                <div className="col-xs-12 col-sm-9 col-md-10 pull-right p0 top_mrg_general">
                    <div className="">
                        <div className="col-sm-9 col-sm-offset-2 general_setting_hd report_input">
                            <h2>Approved Report</h2>
                            <div className="col-sm-3 col-md-3 report_label">Report:</div>
                            <div className="col-sm-6 col-md-5">
                                <div className="select-style report-select-style">
                                    <select
                                        name="reportName"
                                        id="reportGuid"
                                        onChange={(e) => this.dropDown(e)}
                                    >
                                        {this.state.report.length > 0
                                            ? this.state.report.map((val, key) => {
                                                return (
                                                    <option key={key} value={val.Desc} id={val.Guid}>
                                                        {val.Desc}
                                                    </option>
                                                );
                                            })
                                            : ""}
                                    </select>
                                </div>
                            </div>
                            <div className="clearfix"></div>

                            <div className="col-sm-3 col-md-3 report_label">Position:</div>
                            <div className="col-sm-6 col-md-5">
                                <input
                                    name="position"
                                    onChange={this.reportsChange}
                                    value={this.state.position}
                                    type="text"
                                />
                            </div>
                            <div className="clearfix"></div>
                            <div className="col-sm-3 col-md-3 report_label">
                                Department:
                            </div>
                            <div className="col-sm-6 col-md-5">
                                <input
                                    name="department"
                                    onChange={this.reportsChange}
                                    value={this.state.department}
                                    type="text"
                                />
                            </div>
                            <div className="clearfix"></div>
                            <div className="col-sm-3 col-md-3 report_label">
                                Start Date:
                            </div>
                            <div className="col-sm-6 col-md-5">
                                <div className="col-xs-12 p0">
                                    <div className="col-xs-6 col-sm-6 p0">
                                        <DatePicker
                                            id="showDateFrom"
                                            onChange={this.handleChange.bind(this)}
                                            selected={this.state.startDate}
                                            dateFormat="DD/MM/YYYY"
                                        />
                                    </div>
                                    <div className="col-xs-2 col-sm-2 calendar_time2">
                                        <svg
                                            onClick={(event) => this.showDateFrom()}
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="2936.352 349.176 18.501 23.145"
                                        >
                                            <a href={null}>
                                                <path
                                                    id="ic_date_range_24px"
                                                    className="cls-1"
                                                    d="M9.167,12.415H7.111V14.73H9.167Zm4.111,0H11.223V14.73h2.056Zm4.111,0H15.334V14.73H17.39Zm2.056-8.1H18.418V2H16.362V4.314H8.139V2H6.084V4.314H5.056A2.188,2.188,0,0,0,3.01,6.629L3,22.83a2.2,2.2,0,0,0,2.056,2.314h14.39A2.2,2.2,0,0,0,21.5,22.83V6.629A2.2,2.2,0,0,0,19.446,4.314Zm0,18.516H5.056V10.1h14.39Z"
                                                    transform="translate(2933.352 347.176)"
                                                ></path>
                                            </a>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                            <div className="col-sm-3 col-md-3 report_label">
                                Finish Date:
                            </div>
                            <div className="col-sm-6 col-md-5">
                                <div className="col-xs-12 p0">
                                    <div className="col-xs-6 col-sm-6 p0">
                                        <DatePicker
                                            onChange={this.handleChangeTo.bind(this)}
                                            selected={this.state.endDate}
                                            id="showDateTo"
                                            dateFormat="DD/MM/YYYY"
                                        />
                                    </div>
                                    <div className="col-xs-2 col-sm-2 calendar_time2">
                                        <svg
                                            onClick={(event) => this.showDateTo()}
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="2936.352 349.176 18.501 23.145"
                                        >
                                            <a href={null}>
                                                <path
                                                    id="ic_date_range_24px"
                                                    className="cls-1"
                                                    d="M9.167,12.415H7.111V14.73H9.167Zm4.111,0H11.223V14.73h2.056Zm4.111,0H15.334V14.73H17.39Zm2.056-8.1H18.418V2H16.362V4.314H8.139V2H6.084V4.314H5.056A2.188,2.188,0,0,0,3.01,6.629L3,22.83a2.2,2.2,0,0,0,2.056,2.314h14.39A2.2,2.2,0,0,0,21.5,22.83V6.629A2.2,2.2,0,0,0,19.446,4.314Zm0,18.516H5.056V10.1h14.39Z"
                                                    transform="translate(2933.352 347.176)"
                                                ></path>
                                            </a>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                            <div className="col-sm-3 col-md-3 report_label">Project:</div>
                            <div className="col-sm-6 col-md-5">
                                <div className="select-style report-select-style">
                                    <select
                                        name="projectName"
                                        defaultValue={this.state.projectName}
                                        id="projectGuid"
                                        onChange={(e) => this.dropDown(e)}
                                    >
                                        <option> Select Project</option>
                                        {this.state.projectList.length > 0
                                            ? this.state.projectList.map((project, key) => {
                                                return (
                                                    <option
                                                        key={key}
                                                        value={project.Desc}
                                                        id={project.Guid}
                                                    >
                                                        {project.Desc}
                                                    </option>
                                                );
                                            })
                                            : ""}
                                    </select>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                            <div className="col-sm-3 col-md-3 report_label">
                                Report Summary Only:
                            </div>
                            <div className="col-sm-6 col-md-5 report_check_box_container">
                                <label className="check_box_pop ">
                                    <input type="checkbox" id="summary_only" />
                                    <span className="checkmark report_checkmark"></span>
                                </label>
                            </div>
                            <div className="clear40"></div>
                            <div className="btn_cance_save">
                                <div className="col-sm-12">
                                    <button
                                        type="button"
                                        className="btn_general_report"
                                        onClick={() => this.generateReport()}
                                    >
                                        Generate Report
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="clear40"></div>
                    </div>
                </div>
                <div className="clear40"></div>
            </div>
        );
    }
}
export default Report;
