import React, { Component } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import "./AddCompany.css";
import "../../assets/css/bootstrap.css";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/style.css";
import LOGO from "../../assets/images/logo.png";
import moment from 'moment';
import 'moment-timezone';
import slider_img1 from "../../assets/images/slider_img1.jpg";
import slider_img2 from "../../assets/images/slider_img2.jpg";
import slider_img3 from "../../assets/images/slider_img3.jpg";
import { Auth } from "aws-amplify";
import ip from "ip";
import $ from 'jquery';

import { getCurrentYearForCopyRight } from "../../utils/helpers";

class AddCompany extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
          isLoading: true,
          ipv4:null
        };

        /*if (this.props.isAuthenticated === false) {
            this.props.history.push("/");
        }*/
    }

    async componentDidMount() {
        // console.log(this.props.location.state.state.resultCompanies);
        try {
          await Auth.currentSession();
          var user = await Auth.currentUserInfo();
          const {attributes} = user;
          console.log("Yes logedin....");
          if(!attributes['custom:userGuid']){
            console.log("user id not found.");
          }
          //this.props.userHasAuthenticated(true);
        }catch(e) {
          console.log("catch session errr=>", e);
          this.props.history.push('/');
        }
        $.getJSON("https://jsonip.com?callback=?", async(jsonip) => {
          this.setState({ ipv4: jsonip.ip});
        });
        this.setState({ isLoading: false });
    }

    checkCompanyWithUser= async(event)=>{
        //console.log("Required at that moment",this.props.location.state.state.userTypeCheck);
        //return false;
        //var userTenant=localStorage.getItem('TenantGuid');
        const userData = this.props.location.state.state.userData;
        const last = userData.LastName === null? "" : userData.LastName;
        const userName = userData.FirstName + " " + last;
        const userEmail = userData.EmailAddress;
        const userType = userData.UserType;
        const userLastLogin = userData.LastLogin;
        const MobileNumber = userData.MobileNumber;
        const countryCode = userData.countryCode;
        const Guid = userData.Guid;
        const SignatureTyped = userData.SignatureType;
        const currentIpAddress = this.state.ipv4;
        const ApproverGroup = userData.ApproverGroup;
      const CurrentTenantGuid = this.props.location.state.state.tenantGuid;
      const CurrentTenantID = this.props.location.state.state.tenantID;
      const UserLogin = userData.UserLogin;
      const companyGuid = event.target.id;
      const company = this.props.location.state.state.resultCompanies?.find(company => company.Guid === companyGuid);
      const companyID = company?.CompanyId;
      const companyName = company?.CompanyName;
      
        const userCompanyGuidList = userData.CompanyGuidList;

        if(this.props.location.state.state.userTypeCheck === false){
          
          // SysAdmin and Admin can access all the project within the tenant
          // for other users the companyGuid should be listed in the UserCompanyGuidList
          if(['SysAdmin', 'Admin'].includes(userType) || userCompanyGuidList.includes(companyGuid)){
            //console.log("type check fail"); return false;
            this.setState({isLoading:true});
            await localStorage.setItem('useableGuid', event.target.id);
            await this.props.userHasAuthenticated(true);
            if (typeof Storage !== "undefined") {
                  localStorage.setItem("loggedIn", true);
                  localStorage.setItem("userName", userName);
                  localStorage.setItem("userEmail", userEmail);
                  localStorage.setItem("userLastLogin", userLastLogin);
                  localStorage.setItem("SignatureTyped", SignatureTyped);
                  localStorage.setItem("Guid", Guid);
                  localStorage.setItem("currentIpAddress", currentIpAddress);
                  localStorage.setItem("MobileNumber", MobileNumber);
                  localStorage.setItem("countryCode", countryCode);
                  localStorage.setItem("userType", userType);
                  localStorage.setItem("ApproverGroup", ApproverGroup);
                  localStorage.setItem("currentTenantGuid", CurrentTenantGuid);
                  localStorage.setItem("currentTenantID", CurrentTenantID);
                  localStorage.setItem("currentCompanyName", companyName);
                  localStorage.setItem('companyID', companyID);
                  localStorage.setItem('companyGuid', companyGuid);

                  localStorage.setItem('UserLogin', UserLogin);
            }
            await this.props.postRequest("/users/update-login-count", {email: userData.EmailAddress});
            this.props.history.push("/dashboard",{
                state :{
                   tenantAuthenticated: true
                }
            });
          }else{
            toast.error("Your user is not found in the selected company");
          }
        }else{
          this.setState({isLoading:true});
          await localStorage.setItem('useableGuid', event.target.id);
          this.props.history.push("/dashboard",{
              state :{
                 tenantAuthenticated: true
              }
          });
        }
      }


    cancelAddCompany = async () =>{
       await Auth.signOut();
       localStorage.removeItem('useableGuid');
       this.props.history.push('/');
    }
    
  render() {
    return (
        <div className="Login">
       
          {this.state.isLoading ? <div className="se-pre-con"></div> : ''}
            <div className="col-sm-12 p0">
              <div className="col-sm-6 p0">
                 <div className="col-sm-12 logo_login">
                    <LinkContainer to="/">
                       <img href={null} className="login_CursorPointer" src={LOGO} alt="LOGO" />
                    </LinkContainer>
                 </div>
                 <div className="col-sm-12 login_h2">
                    <h2>Welcome to Digital-Forms</h2>
                 </div>
                 <div className="col-sm-12 noPadding">
                    <div className="login_box">
                    <div className="verif_table_box">

<table width="99%" className="table zeroMarginBottom">
  <thead className="thead-dark">
    <tr>
      <th scope="col">Select a Company</th>
    </tr>
  </thead>
  <tbody>
{(!this.props.location ? ''  : this.props.location.state.state.resultCompanies.map((value,key)=>{
return(<tr key={key} onClick={this.checkCompanyWithUser} className="cursorPointer companyList">
      <td id={value.Guid}>{value.CompanyName}</td>
    </tr>);
}))}
  </tbody>
</table>
</div>

<div className="clear20"></div>
<div className="col-md-12 text-center">
<a href={null} className="verify_btn_cancel cursorPointer" onClick={this.cancelAddCompany}>Cancel</a>
</div>
                        <div className="clearfix"></div>
                       
                    </div>
                 </div>
              </div>
              <div className="col-sm-6 noPadding">
                 <div className="login_right_area">
                    <div id="myCarousel" className="carousel slide" data-ride="carousel">
                       <ol className="carousel-indicators ">
                          <li data-target="#myCarousel" data-slide-to="0" className="active"></li>
                          <li data-target="#myCarousel" data-slide-to="1"></li>
                          <li data-target="#myCarousel" data-slide-to="2"></li>
                       </ol>
                       <div className="carousel-inner">
                          <div className="item active">
                             <img src={slider_img1} className="img-responsive" alt="" />
                             <div className="clear20"></div>
                             <h2>Welcome to Digital-Forms</h2>
                             <label>
                             </label>
                        </div>
                          <div className="item">
                             <img src={slider_img2} className="img-responsive" alt="" />
                             <div className="clear20"></div>
                             <h2>Welcome to Digital-Forms</h2>
                             <label>
                             </label>                          
                          </div>
                          <div className="item">
                             <img src={slider_img3} className="img-responsive" alt="" />
                             <div className="clear20"></div>
                             <h2>Welcome to Digital-Forms</h2>
                             <label>
                             </label>
                       </div>
                       </div>
                    </div>
                    <div className="clearfix"></div>
                    <div className="login_terms login_terms_extended">
                      <span>@ Copyright TPH Technologies {getCurrentYearForCopyRight()}</span>
                      <span>Version {}</span> 
                    </div>
                 </div>
              </div>
              <div className="clearfix"></div>
            </div>
        </div>
    );
  }
};
export default AddCompany;
