import React, { Component } from "react";
import { LinkContainer } from "react-router-bootstrap";
//======================================//
// ListItem AS A CHILD COMPONENT DEFINE //
// - - - - -  IN THIS SCOPE - - - - - - //
//--------------------------------------//

export default class ProjectListItem extends Component
{
	constructor(props)
	{
	    super(props);
		this.state = {
            projectList: props.projectList
        };
  	}
        componentDidMount()
	{
	  	console.log(this.props);
	}

	componentWillReceiveProps(nextProps)
	{
	  	this.setState({ projectList: nextProps.projectList });
	}


	//=======================================//
	//  - - - RENDER LISTITEM COMPONENT - -  //
	//---------------------------------------//
	render()
	{
        if(typeof this.props.projectList !== 'undefined' && this.props.projectList.length > 0)
        {
            let currentProjectList= this.props.projectList;
            var projectList = currentProjectList.map((project, key) => {
                return (
                        <tr key={key}>
                        <LinkContainer className="cursorPointer" to ={'/dashboard/edit-project/'+btoa(project.Guid)}>
                        <td width="80%" align="left" valign="middle"><a href="company_detail.html">{project.Desc}</a></td>
                        </LinkContainer>
                        <td align="center">
                            <div className="col-sm-4  col-xs-6 p0 pull-right text-center timecard_dele pull-right">
                                <a className="cursorPointer" href={null} onClick={this.props.handleDelete(project.Guid)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="1700 296 15 19.286">
                                        <path id="ic_delete_24px" className="cls-1" d="M6.071,20.143a2.149,2.149,0,0,0,2.143,2.143h8.571a2.149,2.149,0,0,0,2.143-2.143V7.286H6.071ZM20,4.071H16.25L15.179,3H9.821L8.75,4.071H5V6.214H20Z" transform="translate(1695 293)"></path>
                                    </svg>
                                </a>
                            </div>
                            <div className="col-sm-4  col-xs-6 p0 text-center timecard_edit6 pull-right">
                            <LinkContainer to ={'/dashboard/edit-project/'+btoa(project.Guid)}>
                                <a className="cursorPointer" href={null}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="1656.776 299 17.515 18.003">
                                        <path id="ic_create_24px" className="cls-1" d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z" transform="translate(1653.776 296.002)"></path>
                                    </svg>
                                </a>
                                </LinkContainer>
                            </div>
                        </td>
                    </tr>
                )
            });
            if(projectList.length === 0)
            {

                 projectList = <tr><td  colSpan="2">Record not found</td></tr>;
            }
        }
        else
        {
             projectList = <tr><td  colSpan="2">Record not found</td></tr>;
        }


        return(
            <tbody>
            		{
            			projectList
            		}
            </tbody>
        );
	}
};
