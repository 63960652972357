import React, { Component } from "react";
import { LinkContainer } from "react-router-bootstrap";
//  __  DEFINE COMMON STYLE  __ //
//------------------------------//
import "../../../assets/css/bootstrap.css";
import "../../../assets/css/bootstrap.min.css";
import "../../../assets/css/style.css";
import "../../../assets/css/home.css";
import "../../../assets/css/ie_only.css";
import "./Header.css";
//  ____  DEFINE IMAGES  ____ //
//----------------------------//
import LOGO from "../../../assets/images/logo.png";
import LOGOLOGOUT from "../../../assets/images/logout_icon.png";
import helpIcon from "../../../assets/images/help_icon.png";
import settingIcon from "../../../assets/images/setting_icon.png";
import ic_dehaze from "../../../assets/images/ic_dehaze_-19@2x.png";
import ip from 'ip';
import $ from 'jquery';
window.jQuery = $;
window.$ = $;
global.jQuery = $;

//=======================================//
//      HEADER AS A COMMON COMPONENT     //
//---------------------------------------//
export default class Header extends Component {
    constructor(props) {
         super(props);
         this.state = {
            isLoading: true
        };
        if (typeof(Storage) !== "undefined")
                        {
        if(localStorage.getItem('loggedIn') !== null && localStorage.getItem('loggedIn') !== ''&& localStorage.getItem('loggedIn') !== true){
        if(localStorage.getItem('currentIpAddress') === ip.address()){
            console.log('same Ip address');
        }
        else{
            console.log('Changed Ip address');
        }
    }    
                        }
    };
    componentDidMount() {
        if (this.props.props.isAuthenticated === false) {
            this.props.props.history.push("/");
        }
        if(localStorage.getItem('useableGuid') === null || localStorage.getItem('useableGuid') === '' || localStorage.getItem('useableGuid') === undefined ){
                this.props.props.handleLogout();
        }
    }
    render() {
        let hideHomeButton = this.props.hideHomeButton;
        let showMobileMenu = this.props.showMobileMenu;
            const homeButton = hideHomeButton === "1" ? (
            null
        ) : (
            <LinkContainer to="/dashboard">
                <a href={null} className="btn_homer_hdr">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5378 759 13.333 11.333">
                        <path id="ic_home_24px" className="cls-1" d="M7.333,14.333v-4H10v4h3.333V9h2L8.667,3,2,9H4v5.333Z" transform="translate(-5380 756)"/>
                    </svg>
                    Home
                </a>
            </LinkContainer>
        );
        const mobileMenuButton = showMobileMenu === "1" ? (
            <a href={null} className="menu_toggle_mrg display_none_sm_menu header_mobileMenuStylingRe" id="menu-toggle_mobile">
                        <img src={ic_dehaze} className="header_mobileMenuStyling" alt="" />
                    </a>
        ) : (
            null
        );
        return (
            <div className="col-sm-12 dashboard_main_shadow">
                {this.props.isLoading ? <div className="se-pre-con"></div> : ''}
                
                <div className="col-sm-5 col-md-5 col-xs-12 p0">
                    {mobileMenuButton}
                    <LinkContainer to="/dashboard">
                        <a href={null} className="pull-left">
                            <img src={LOGO} className="header_CursorPointer" width="200" height="85" alt="" /> </a>
                    </LinkContainer>
                    {homeButton}
                </div>
                <div className="col-xs-12 col-sm-2 col-md-2 text-center custome_header_label">
                    { this.props.pageHeading }
                </div>
                <div className="col-sm-4 col-xs-12 p0 header_top_right">
               
                    <a href={null} className="header_CursorPointer" onClick={this.props.props.handleLogout}>Logout <img src={LOGOLOGOUT} alt="" />
                    </a>

                    <LinkContainer to="/dashboard/help">
                        <a href={null} className="header_CursorPointer">
                     Help <img src={helpIcon} alt="" /></a>
                    </LinkContainer>
                    <div className="header_relitivePosition">
                        <a href="sign_setting.html" type="button" data-toggle="dropdown" className="logoutAcnhortag header_bL0px">Setting <img src={settingIcon} alt="" /></a>
                        <ul className="dropdown-menu dropleft header_dropdown_menu_dashset_down header_dropdown_menu_dashset3">
                            <li>
                                <LinkContainer to="/dashboard/signature-settings">
                                    <a href={null} className="header_CursorPointer">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                            <path id="ic_create_24px" className="cls-1" d="M3,18.831V23H7.166L19.453,10.71,15.287,6.544ZM22.675,7.489a1.106,1.106,0,0,0,0-1.566l-2.6-2.6a1.106,1.106,0,0,0-1.566,0L16.476,5.356l4.166,4.166,2.033-2.033Z" transform="translate(-3 -2.998)" />
                                        </svg>
                                        Signature
                                    </a>
                                </LinkContainer>
                            </li>
                            <li>
                                <LinkContainer to="/dashboard/general_settings">
                                    <a href={null} className="header_CursorPointer">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 16">
                                            <path id="ic_mail_outline_24px" className="cls-1" d="M20,4H4A2,2,0,0,0,2.01,6L2,18a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V6A2.006,2.006,0,0,0,20,4Zm0,14H4V8l8,5,8-5Zm-8-7L4,6H20Z" transform="translate(-2 -4)" />
                                        </svg>
                                        General
                                    </a>
                                </LinkContainer>
                            </li>
                        </ul>
                    </div>
                    <span className="company-name">{ localStorage.getItem('currentCompanyName') ?? ''}</span>
                </div>
                <div className="clearfix"></div>
            </div>
        );
    }
};
