import React, { Component } from "react";
import { LinkContainer } from "react-router-bootstrap";
import "./VerifyCode.css";
import "../../assets/css/bootstrap.css";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/style.css";
import LOGO from "../../assets/images/reset_logo.jpg";
import { toast } from 'react-toastify';
import 'moment-timezone';
import { Auth} from "aws-amplify";
import $ from 'jquery';
//import ip from 'ip';
//const publicIp = require("react-public-ip");
class VerifyCode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            continueDashboard:false,
            isActive: false,
            verification_code:'',
            VerificationCodeError:'',
            email:(typeof(this.props.location.state) !== "undefined"?this.props.location.state.email:''),
            tenantID:(typeof(this.props.location.state) !== "undefined"?this.props.location.state.tenantID:''),
            UserTypeAdmin:(typeof(this.props.location.state) !== "undefined"?this.props.location.state.UserTypeAdmin:''),
            ipv4: null
        };
    }
    async componentDidMount() {

        Auth.verifyCurrentUserAttribute('phone_number').then(() => {
            console.log('a verification code has been sent');
            toast.success("a verification code has been sent." , { autoClose: 5000 });
            this.setState({isLoading: false});
        }).catch((e) => {
             console.log('failed with error', e);
             toast.error(e.message, { autoClose: 5000 });
             this.setState({isLoading: false});
        });
        $.getJSON("https://jsonip.com?callback=?", async(jsonip) => {
          this.setState({ ipv4: jsonip.ip});
        });
    }


    handleUserInputCode(event) {
        //let name = event.target.name;
        let value = event.target.value;
        this.setState({
          'verification_code' : value,
          'VerificationCodeError':(value.length>0?'':'Please Enter Verification Code.')
        }); 
    }

    handleSubmitVerification = async event => {
      event.preventDefault();
      if(this.state.verification_code.length>0){
        console.log("this.state.verification_code", this.state.verification_code);
        this.setState({isLoading: true});
        try{
          const verify_response = await Auth.verifyCurrentUserAttributeSubmit('phone_number', this.state.verification_code);
          console.log('phone_number verified', verify_response);
          toast.success("Successfully Verified." , { autoClose: 5000 });
          var response = await this.props.postRequest("/users/validate-user-company", {email: this.state.email, tenantID: this.state.tenantID});
          //console.log("validate-user-company response=>", response);
          if(response.status){
              this.props.history.push("/add-company", {
                  state: {
                    resultCompanies: response.companies_list,
                    userData: response.user_data,
                    userTypeCheck: false,
                    tenantID: this.state.tenantID,
                    tenantGuid: response.tenant_guid,
                  }
              });
          }else{
              await this.signOut();
              this.setState({isLoading: false});
              toast.error(response.error, { autoClose: 5000 });
          }
        
        }catch(err){
          console.log('failed with error', err);
          if(err.code==='CodeMismatchException'){
            toast.error("Incorrect Verification Code" , { autoClose: 5000 });
            this.setState({isLoading: false}); 
          }else{
            toast.error(err.message, { autoClose: 5000 });
            this.setState({isLoading: false}); 
          }
        }

      }else{
           this.setState({VerificationCodeError: 'Please Enter Verification Code.'}); 
           return false;
      }
    }

    handleResendConfirmationCode = async ()=> {
        this.setState({ isLoading: true });
  
        Auth.verifyCurrentUserAttribute('phone_number').then(() => {
            console.log('a verification code has been resent');
            toast.success("a verification code has been resent." , { autoClose: 5000 });
            this.setState({isLoading: false});
        }).catch((e) => {
             console.log('handleResendConfirmationCode error', e);
             toast.error(e.message, { autoClose: 5000 });
             this.setState({isLoading: false});
        });
    }

    signOut = async event => {
      Auth.signOut({global: true}).then((data) => {
          console.log("SignOut succsessfully.");
          localStorage.setItem('loggedIn', false);
          localStorage.removeItem('userName');
          localStorage.removeItem('userEmail');
          localStorage.removeItem('userLastLogin');
          localStorage.removeItem('SignatureTyped');
          localStorage.removeItem('Guid');
          localStorage.removeItem('currentIpAddress');
          localStorage.removeItem('MobileNumber');
          localStorage.removeItem('countryCode');
          localStorage.removeItem('userType');
          localStorage.removeItem('ApproverGroup');
          localStorage.removeItem('TenantGuid');
          localStorage.removeItem('companyID');
          localStorage.removeItem('UserLogin');
          this.userHasAuthenticated(false);
      }).catch((error) => {
          console.log("SignOut Error=>",error);
      });
    }

    render() {
        return (
                <div className="FpStepFirst">
          
          {this.state.isLoading ? <div className="se-pre-con"></div> : ''}
   <div className="forgetpassword_forgot_bg">
      <div className="col-sm-12">
         <div className="container p0">
            <div className="forgot_box1">
               <div className="forgot_box">
                  <div className="col-sm-12 reset_password">
                     <LinkContainer to="/">
                        <img src={LOGO} alt="LOGO" id="forgetpassword_marginRight10"/>
                     </LinkContainer>
                     Verify Code
                  </div>
                  <div className="reset_under_box">
                     <div className="clear50"></div>
                     Enter your code received via SMS
                     <div className="clear20"></div>
                     <form onSubmit={this.handleSubmitVerification}>
                     <div className="clear20"></div>
                     <input name="verification_code" onChange={(event) => this.handleUserInputCode(event)}  value={this.state.verification_code} className="frogot_input" type="text" placeholder="Code" />
                    <p className={"error vc_validation " + (this.state.VerificationCodeError ? '': 'displayNone')}>{this.state.VerificationCodeError}</p>
                    <a href={null} className="resendCodeVerifyCode" onClick={this.handleResendConfirmationCode}>Resend Code</a>             
                    <div className="clear50"></div>
                     <LinkContainer to="/">
                        <a href={null} className="btn_frogot_back">Back</a>
                     </LinkContainer>
                        <button href={null} type="submit" className={"btn_frogot_blue"+ (this.state.isActive ? '': ' ')}>Proceed</button>
                     </form>
                     <div className="clearfix"></div>
                  </div>
                  <div className="clearfix"></div>
               </div>
            </div>
            <div className="clearfix"></div>
         </div>
         <div className="clearfix"></div>
      </div>
   </div>
</div>
                );
    }
}
;
export default VerifyCode;
