import React, { Component } from "react";
import { toast } from "react-toastify";
import { API, Storage } from "aws-amplify";
import "./Users.css";
// - -   Custom components  - -//
// - - - - - - - - - - - - - - //
import Header from "../Common/Header/Header";
// - -   Image constants  - -//
// - - - - - - - - - - - - - //
import user_down_icon from "../../assets/images/user_down_icon.png";
import ic_clear_24px from "../../assets/images/ic_clear_24px@2x.png";
import Preloader from "../../assets/images/Preloader.gif";
import DatePicker from "react-datepicker";
import moment from "moment";
import "moment-timezone";
import "react-datepicker/dist/react-datepicker.css";
import { Auth } from "aws-amplify";
//import ip from 'ip';
import SignaturePad from "./index";
// - -   jQUARY INSTANCE  - -//
// - - - - - - - - - - - - - //
import $ from "jquery";
window.jQuery = $;
window.$ = $;
global.jQuery = $;
var Modal = require("react-bootstrap-modal");
require("react-bootstrap-modal/lib/css/rbm-patch.css");
//const publicIp = require("react-public-ip");
//=========================================//
// ADDPROJECT AS A PARENT COMPONENT DEFINE //
// - - - - - - IN THIS SCOPE - - - - - - - //
//-----------------------------------------//
export default class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userTypeForRoleManagement: localStorage.getItem("userType"),
            currentTenantGuid: localStorage.getItem("currentTenantGuid"),
            currentTenantId: localStorage.getItem("currentTenantID"),
            currentUserEmail: localStorage.getItem("userEmail"),
            companyId: "",
            userType: "",
            updateableGuid: "",
            AGuserName: "",
            AGinitials: "",
            AGdepartment: "",
            handlingEditUser: false,
            isLoading: true,
            InvitSendModal: false,
            userCreating: false,
            updateableData: "",
            newUserPassword: "",
            userLogin: "",
            userName: "",
            initials: "",
            userFormsWorking: false,
            approverGroupOfUser: "",
            deleteableEmail: "",
            deleteablePassword: "",
            emailAddress: "",
            prevEmailAddress:"",
            countryCode: "61",
            mobileNumber: "+61",
            department: "",
            teamGroup: "",
            sendPasswordToUser: false,
            teamGroupToView: "",
            sendReminderEvery: "0 Hr",
            passwordStrength: "Medium",
            pwExpireDay: 60,
            mustChange: "",
            cannotChange: "",
            neverExpires: "",
            accountDisabled: "",
            lockOut: "",
            factorAuthentication: "",
            inactivityLockoutDays: 60,
            lastLogin: "NA",
            lastLogOff: "NA",
            pwLastChange: "NA",
            badPwCount: 0,
            loginCount: 0,
            enterinDynamo: false,
            userSub: "",
            wait: "Loading ...",
            accessDate: moment.tz(moment(), "Australia/Canberra"),
            lockoutDate: moment.tz(moment().add(3, "years"), "Australia/Canberra"),
            modalOpen: false,
            formValid: false,
            iaActive: false,
            userTypeValid: false,
            userLoginValid: false,
            userNameValid: false,
            emailAddressValid: false,
            mobileNumberValid: false,
            initialsValid: false,
            departmentValid: false,
            approverGroupName: "",
            approverGroupNameValid: false,
            //===============new states=====================
            SignatureImage: "",
            SignatureText: "",
            SignatureTyped: "",
            DisplayPad: "block",
            SignatureInput: "block",
            CurrentTab: "Draw",
            CurrentTabMobile: "DrawMobile",
            SignaturePad: "",
            signaturemodal: false,
            email: "",
            Signature_key: "",
            Signature_url: "",
            isSaveSignature: false,

            //===============Send Email states=====================
            userEmail: "",
            emailtemplates: [],
            emailCC: "",
            emailnotes: "",
            emailSubject: "",
            emailMsg: "",
            selectUsertoEmail: false,

            //===============Approver Group Modal=====================
            userAccess: "",
            addApproverGroupModalOpen: false,
            checkboxArray: [],
            checkedGroupsList: [],
            approvalGroupList: [],

            //______ Erros ______//
            //___________________//
            formErrors: {
                userType: "",
                userLogin: "",
                userName: "",
                emailAddress: "",
                initials: "",
                mobileNumber: "",
                department: "",
                teamGroup: "",
                teamGroupToView: "",
                pwExpireDay: "",
                inactivityLockoutDays: "",
                approverGroupName: "",
                companyId: "",
            },

            usersList: [],
            companyList: [],
            placeHolderArray: [],
            emailAlreadyRegistered: false,
            EmailTemplateList: "",
            TemplatetoEmailDefaultValue: "",
            ipv4: null,
        };

        this.handleDropdownChange = this.handleDropdownChange.bind(this);
        this.onAddApproverGroupModalOpen = this.onAddApproverGroupModalOpen.bind(this);
        this.handleApproverGroupInputCheckbox = this.handleApproverGroupInputCheckbox.bind(this);
        this.addGroup = this.addGroup.bind(this);

        if (
            this.state.userTypeForRoleManagement === "Approver" ||
            this.state.userTypeForRoleManagement === "Operator" ||
            this.state.userTypeForRoleManagement === "Operator/Approver"
        ) {
            this.props.history.push("/dashboard");
        }
    }

    async componentDidMount() {
        try {
            var response = await this.props.postRequest(
                `/users/getallusers/${localStorage.getItem("currentTenantGuid")}`,
                {}
            );
            if (response.length > 0) {
                this.setState({ isLoading: false, usersList: response });
            } else {
                this.setState({ isLoading: false, wait: "No Record Found" });
            }
            //console.log("/users/getallusers=>", response);
        } catch (err) {
            console.log("/users/getallusers error=>", err);
        }
        //const usersList = this.getAllUsers();
        $(".modal").css("display", "none");
        /*usersList.then(
                    data => {
                        if (data.length > 0) {
                            this.setState({
                                wait: "No Record Found",
                            })
                        }
                        this.setState({
                            usersList: data,
                            isLoading: false,
                        })
                    }
                ).catch(
                    err => {
                        console.error(err, err.stack);
                });*/

        this.getTemplates();

        $.getJSON("https://jsonip.com?callback=?", async (jsonip) => {
            this.setState({ ipv4: jsonip.ip });
        });

        // fetch company list
        try {
            const response = await this.props.postRequest(
                "/users/validate-user-company",
                {
                    email: this.state.currentUserEmail,
                    tenantID: this.state.currentTenantId,
                }
            );
            if (response.companies_list.length > 0) {
                this.setState({
                    isLoading: false,
                    companyList: response.companies_list,
                });
            } else {
                this.setState({ isLoading: false, wait: "No Record Found" });
            }
        } catch (err) {
            console.log("/users/validate-user-company error=>", err);
        }

        // fetch approval group list
        try {
            var response = await this.props.postRequest(
                "/approval-setup/approver-group-list",
                { CompanyGuid: localStorage.getItem("companyGuid") }
            );
            if (response.status) {
                this.setState({ approvalGroupList: response.result, isLoading: false });
            } else {
                this.setState({ isLoading: false });
                toast.error(response.error, { autoClose: 5000 });
            }
            console.log("/approval-setup/approver-group-list=>", response);
        } catch (err) {
            console.log("/approval-setup/approver-group-list error=>", err);
            toast.error(err.message, { autoClose: 5000 });
            this.setState({ isLoading: false });
        }
    }

    componentWillUnmount() {
        this.setState({
            checkedGroupsList: [],
            checkboxArray: []
        })
    }

    handleChange(date) {
        if (date) {
            if (moment(date).isBefore(this.state.lockoutDate)) {
                this.setState({
                    accessDate: date,
                });
            } else {
                alert(
                    "Please Select date before " +
                    moment.utc(this.state.lockoutDate).format("LL") +
                    ""
                );
            }
        }
    }
    //=======================sohail functions=======================================
    // sohail add functions

    signatureTextHandle = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({
            [name]: value,
        });
    };

    get_image = () => {
        this.setState({
            userFormsWorking: true,
        });
        let email = this.state.emailAddress;
        let filename = email + "_SignatureFile.jpg";
        this.setState({
            filename: filename,
            email: email,
        });
        if (email) {
            Storage.vault
                .get(filename, { level: "private" })
                .then((result) => {
                    this.setState({
                        SignatureImage: result,
                        DisplayPad: "none",
                        Signature_url: result,
                    });
                })
                .catch((err) => console.log(err));
        } else {
            this.setState({
                Signature_url: "",
            });
        }
        this.setState({
            userFormsWorking: false,
        });
    };

    signaturemodal_open = (e) => {
        let emailAddress = this.state.emailAddress;

        let formErrors = Object.assign({}, this.state.formErrors);
        if (
            emailAddress === false ||
            emailAddress === null ||
            emailAddress === ""
        ) {
            formErrors.emailAddress = "This field is required";
            $("#myModal_user_edit .modal-body").animate(
                {
                    scrollTop: 0,
                },
                "slow"
            );
            this.setState({
                formErrors: formErrors,
            });
        } else {
            this.get_image();

            this.setState({
                signaturemodal: true,
            });
        }
    };

    signaturemodal_close = (e) => {
        this.setState({
            CurrentTab: "Draw",
            signaturemodal: false,
            isSaveSignature: false,
        });
    };

    InvitSendModalClose = (e) => {
        this.setState({ InvitSendModal: false });
    };

    clearSignature = async (event) => {
        console.log(this.state.CurrentTab);
        if (this.state.CurrentTab === "Draw") {
            this.setState({ SignatureImage: "", DisplayPad: "block" });
            const signature = this.refs.Signature;

            signature.clear();
        }

        if (this.state.CurrentTab === "Typed") {
            this.setState({
                SignatureTyped: "",
                SignatureText: "",
                SignatureInput: "block",
            });
        }
    };

    saveSignature = async (event) => {
        this.setState({ isSaveSignature: true });
        //console.log(this.state.email);
        //return false;
        if (this.state.CurrentTab === "Draw") {
            const signature = this.refs.Signature;
            let email = this.state.email;
            let filename = email + "_SignatureFile.jpg";

            if (signature.isEmpty()) {
                Storage.vault
                    .remove(filename, { level: "private" })
                    .then((result) => {
                        this.setState({
                            isSaveSignature: false,
                            Signature_key: result.key,
                        });
                        this.get_image();
                        this.signaturemodal_close();
                    })
                    .catch((err) => {
                        console.log(err);
                        this.setState({ isSaveSignature: false });
                    });
            } else {
                const base64 = signature.toDataURL();

                const base64Data = new Buffer(
                    base64.replace(/^data:image\/\w+;base64,/, ""),
                    "base64"
                );

                Storage.vault
                    .put(filename, base64Data, { contentType: "image/jpeg" })
                    .then((result) => {
                        $("#HomeBtn").click();
                        console.log("Successfully saved.");

                        this.setState({
                            isSaveSignature: false,
                            SignatureImage: base64,
                            DisplayPad: "none",
                            Signature_key: result.key,
                        });
                        this.get_image();
                        this.signaturemodal_close();
                    })
                    .catch((err) => {
                        console.log(err);
                        this.setState({ isSaveSignature: false });
                    });
            }
        }
        if (this.state.CurrentTab === "Typed") {
            let Signaturetype = this.state.SignatureText;
            if (Signaturetype) {
                if (Signaturetype) {
                    this.setState({
                        SignatureTyped: Signaturetype,
                        SignatureInput: "none",
                        isSaveSignature: false,
                    });
                } else {
                    this.setState({
                        SignatureTyped: "",
                        SignatureInput: "block",
                        isSaveSignature: false,
                    });
                }
            }

            this.signaturemodal_close();
        }
    };

    clearSignatureMobile = async (event) => {
        if (this.state.CurrentTabMobile === "DrawMobile") {
            const signatureMobile = this.refs.SignatureMobile;
            signatureMobile.clear();
            this.setState({ SignatureImage: "", DisplayPad: "block" });
        }

        if (this.state.CurrentTabMobile === "TypedMobile") {
            this.setState({ SignatureTyped: "", SignatureInput: "block" });
        }
    };

    saveSignatureMobile = async (event) => {
        this.setState({ isSaving: true });
        let filename = this.state.filename;
        if (this.state.CurrentTabMobile === "DrawMobile") {
            const signatureMobile = this.refs.SignatureMobile;
            if (signatureMobile.isEmpty()) {
                Storage.vault
                    .remove(filename, { level: "private" })
                    .then((result) => {
                        console.log(result);
                        this.setState({ isSaving: false });
                    })
                    .catch((err) => {
                        console.log(err);
                        this.setState({ isSaving: false });
                    });
            } else {
                const base64 = signatureMobile.toDataURL();

                const base64Data = new Buffer(
                    base64.replace(/^data:image\/\w+;base64,/, ""),
                    "base64"
                );

                Storage.vault
                    .put(filename, base64Data, { contentType: "image/jpeg" })
                    .then((result) => {
                        console.log("Successfully saved.");
                        $("#HomeBtn").click();
                        this.setState({
                            isSaving: false,
                            SignatureImage: base64,
                            DisplayPad: "none",
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                        this.setState({ isSaving: false });
                    });
            }
        }

        if (this.state.CurrentTabMobile === "TypedMobile") {
            try {
                const response = await this.updateUser({
                    SignatureTyped: this.state.SignatureText,
                });
                if (response.status) {
                    console.log("Successfully saved.");
                    $("#HomeBtn").click();
                    this.setState({
                        isSaving: false,
                        SignatureTyped: this.state.SignatureText,
                        SignatureInput: "none",
                    });
                }
            } catch (e) {
                console.log(e);
                this.setState({ isSaving: false });
            }
        }
    };

    handleTab = (element) => async (event) => {
        event.preventDefault();
        this.setState({ CurrentTab: element });
    };

    handleTabMobile = (element) => async (event) => {
        event.preventDefault();
        this.setState({ CurrentTabMobile: element });
    };

    handleImageError = async (event) => {
        this.setState({ SignatureImage: "", DisplayPad: "block" });
    };

    //sohail functions end
    //==============================================================================
    handleNumberInput(e) {
        if (e.target.name === "pwExpireDay") {
            let name = e.target.name;
            let value = e.target.value;
            let check = value.match(/^\d+$/);
            if (!check) {
                let fieldValidationErrors = this.state.formErrors;
                fieldValidationErrors.pwExpireDay = check ? "" : "Only Number Allowed";
                this.setState({
                    fieldValidationErrors,
                });
            }
            this.setState(
                {
                    [name]: value.replace(/\D/, ""),
                },
                () => {
                    let fieldValidationErrors = this.state.formErrors;
                    fieldValidationErrors.pwExpireDay = check
                        ? ""
                        : "Only Number Allowed";
                    this.setState({
                        fieldValidationErrors,
                    });
                }
            );
        }
        if (e.target.name === "inactivityLockoutDays") {
            let name = e.target.name;
            let value = e.target.value;
            let check = value.match(/^\d+$/);

            if (!check) {
                let fieldValidationErrors = this.state.formErrors;
                fieldValidationErrors.inactivityLockoutDays = check
                    ? ""
                    : "Only Number Allowed";
                this.setState({
                    fieldValidationErrors,
                });
            }
            this.setState(
                {
                    [name]: value.replace(/\D/, ""),
                },
                () => {
                    let fieldValidationErrors = this.state.formErrors;
                    fieldValidationErrors.inactivityLockoutDays = check
                        ? ""
                        : "Only Number Allowed";
                    this.setState({
                        fieldValidationErrors,
                    });
                }
            );
        }
        var thisobj = this;
        setTimeout(function () {
            let fieldValidationErrors = thisobj.state.formErrors;
            fieldValidationErrors.pwExpireDay = "";
            fieldValidationErrors.inactivityLockoutDays = "";
            thisobj.setState({
                fieldValidationErrors,
            });
        }, 1000);
    }
    handleInputCheckbox(event) {
        let name = event.target.name;
        var value = event.target.value;
        if (
            event.target.name !== "mustChange" &&
            event.target.name !== "cannotChange" &&
            event.target.name !== "neverExpires"
        ) {
            if (value === "") {
                this.setState({
                    [name]: "checked",
                });
            } else {
                this.setState({
                    [name]: "",
                });
            }
        }
        if (event.target.name === "mustChange") {
            if (value === "") {
                this.setState({
                    [name]: "checked",
                    cannotChange: "",
                    neverExpires: "",
                });
            } else {
                this.setState({
                    [name]: "",
                });
            }
        }
        if (event.target.name === "cannotChange") {
            if (value === "") {
                this.setState({
                    [name]: "checked",
                    mustChange: "",
                    neverExpires: "",
                });
            } else {
                this.setState({
                    [name]: "",
                });
            }
        }
        if (event.target.name === "neverExpires") {
            if (value === "") {
                this.setState({
                    [name]: "checked",
                    cannotChange: "",
                    mustChange: "",
                });
            } else {
                this.setState({
                    [name]: "",
                });
            }
        }
    }
    //==============================================================================
    handleCustomFieldsModalInputs(event) {
        let name = event.target.name;
        let value = event.target.value;
        this.setState(
            {
                [name]: value,
            },
            () => {
                this.validateField(name, value);
            }
        );
    }

    handleCustomFieldsModalInputsCountry(event) {
        let name = event.target.name;
        let value = event.target.value;
        this.setState(
            {
                [name]: value,
            },
            () => {
                this.validateField(name, value);
            }
        );
        this.setState({
            mobileNumber: "+" + value,
        });
    }
    handleCustomFieldsModalInputsMultiSelect(event) {
        let name = event.target.name;
        let value = $("#approverGroupOfUser").val();
        this.setState(
            {
                [name]: value,
            },
            () => {
                this.validateField(name, value);
            }
        );
    }

    handleSelectRminderEvery(event) {
        let name = event.target.name;
        let value = event.target.value;
        this.setState(
            {
                [name]: value,
            },
            () => {
                this.validateField(name, value);
            }
        );
    }

    handleInputs(event) {
        let name = event.target.name;
        let value = event.target.value;
        this.setState(
            {
                [name]: value,
            },
            () => {
                this.validateField(name, value);
            }
        );
    }
    handleInputsApproverGroup(event) {
        let name = event.target.name;
        let value = event.target.value;
        this.setState(
            {
                [name]: value,
            },
            () => {
                this.validateFieldApproverGroup(name, value);
            }
        );
    }

    validateFieldApproverGroup(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let approverGroupName = this.state.approverGroupName;
        let valid = value.length > 0 ? true : false;
        switch (fieldName) {
            case "approverGroupName":
                fieldValidationErrors.approverGroupName = valid
                    ? ""
                    : "This field is required";
                break;
            default:
                break;
        }
        this.setState({
            formErrors: fieldValidationErrors,
            approverGroupName: approverGroupName,
        });
    }
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let userTypeValid = this.state.userTypeValid;
        let userLoginValid = this.state.userLoginValid;
        let userNameValid = this.state.userNameValid;
        let emailAddressValid = this.state.emailAddressValid;
        let mobileNumberValid = this.state.mobileNumberValid;
        let initialsValid = this.state.initialsValid;
        let departmentValid = this.state.departmentValid;

        switch (fieldName) {
            case "userType":
                userTypeValid = value.length > 0;
                fieldValidationErrors.userType = userTypeValid
                    ? ""
                    : "This field is required";
                break;
            case "userLogin":
                userLoginValid = value.length > 0;
                fieldValidationErrors.userLogin = userLoginValid
                    ? ""
                    : "This field is required";
                break;
            case "userName":
                userNameValid = value.length > 0;
                fieldValidationErrors.userName = userNameValid
                    ? ""
                    : "This field is required";
                break;
            case "initials":
                initialsValid = value.length > 0;
                fieldValidationErrors.initials = initialsValid
                    ? ""
                    : "This field is required";
                break;
            case "emailAddress":
                emailAddressValid = value.match(
                    /^\s*([\w.%+-]+)@([\w-]+\.)+([\w]{2,})\s*$/i
                );
                fieldValidationErrors.emailAddress = emailAddressValid
                    ? ""
                    : "Email should be username@domain.com";
                this.setState({
                    email: emailAddressValid ? value.trim() : "",
                });
                break;
            case "mobileNumber":
                mobileNumberValid = value.match(/^\+?\d+$/);
                fieldValidationErrors.mobileNumber = mobileNumberValid
                    ? ""
                    : "Format should be (+)3234567891";
                break;
            case "department":
                departmentValid = value.length > 0;
                fieldValidationErrors.department = departmentValid
                    ? ""
                    : "This field is required";
                break;
            default:
                break;
        }
        this.setState(
            {
                formErrors: fieldValidationErrors,
                userTypeValid: userTypeValid,
                userLoginValid: userLoginValid,
                userNameValid: userNameValid,
                emailAddressValid: emailAddressValid,
                mobileNumberValid: mobileNumberValid,
                departmentValid: departmentValid,
            },
            this.validateForm
        );
    }

    validateForm() {
        this.setState({
            formValid:
                this.state.userTypeValid &&
                this.state.userLoginValid &&
                this.state.userNameValid &&
                this.state.emailAddressValid &&
                this.state.mobileNumberValid &&
                this.state.departmentValid,
            isActive:
                this.state.userTypeValid &&
                this.state.userLoginValid &&
                this.state.userNameValid &&
                this.state.emailAddressValid &&
                this.state.mobileNumberValid &&
                this.state.departmentValid,
        });
    }
    onModalOpen(action) {
        if (action === "newUser") {
            this.resetStates();
        }
        this.setState({
            modalOpen: true,
        });
        this.get_image();
    }
    onModalClose() {
        this.setState({
            modalOpen: false,
            userAccess: "",
        });
        if (this.state.handlingEditUser === true) {
            this.resetStates();
        }
    }
    resetStates() {
        this.setState({
            userType: "",
            approverGroupOfUser: "",
            approverGroupName: "",
            approverGroupNameValid: false,
            updateableGuid: "",
            AGuserName: "",
            AGinitials: "",
            AGdepartment: "",
            handlingEditUser: false,
            updateableData: "",
            newUserPassword: "",
            userLogin: "",
            userName: "",
            initials: "",
            userFormsWorking: false,
            deleteableEmail: "",
            deleteablePassword: "",
            emailAddress: "",
            countryCode: "61",
            mobileNumber: "+61",
            department: "",
            teamGroup: "",
            sendPasswordToUser: false,
            teamGroupToView: "",
            sendReminderEvery: "0 Hr",
            passwordStrength: "Medium",
            pwExpireDay: 60,
            mustChange: "",
            cannotChange: "",
            neverExpires: "",
            accountDisabled: "",
            lockOut: "",
            factorAuthentication: "",
            inactivityLockoutDays: 60,
            lastLogin: "NA",
            lastLogOff: "NA",
            pwLastChange: "NA",
            badPwCount: 0,
            loginCount: 0,
            enterinDynamo: false,
            userSub: "",
            wait: "Loading ...",
            accessDate: moment.tz(moment(), "Australia/Canberra"),
            lockoutDate: moment.tz(moment().add(3, "years"), "Australia/Canberra"),
            modalOpen: false,
            formValid: false,
            iaActive: false,
            userTypeValid: false,
            userLoginValid: false,
            userNameValid: false,
            emailAddressValid: false,
            mobileNumberValid: false,
            initialsValid: false,
            departmentValid: false,
            //===============new states=====================
            SignatureImage: "",
            SignatureText: "",
            SignatureTyped: "",
            DisplayPad: "block",
            SignatureInput: "block",
            CurrentTab: "Draw",
            CurrentTabMobile: "DrawMobile",
            SignaturePad: "",
            signaturemodal: false,
            email: "",
            Signature_key: "",
            Signature_url: "",
            isSaveSignature: false,
            checkedGroupsList: [],
            checkboxArray: [],

            //______ Erros ______//
            //___________________//
            formErrors: {
                userType: "",
                userLogin: "",
                userName: "",
                emailAddress: "",
                initials: "",
                mobileNumber: "",
                approverGroupName: "",
                department: "",
                teamGroup: "",
                teamGroupToView: "",
                pwExpireDay: "",
                inactivityLockoutDays: "",
            },
        });
    }
    lockoutDateHandle(date) {
        if (date) {
            if (moment(date).isAfter(this.state.accessDate)) {
                this.setState({
                    lockoutDate: date,
                });
            } else {
                alert(
                    "Please Select date after " +
                    moment.utc(this.state.accessDate).format("LL") +
                    ""
                );
            }
        }
    }

    showAccessDate() {
        document.getElementById("showAccessDate").click();
    }

    showLockDate() {
        document.getElementById("showLockDate").click();
    }

    randomNumber() {
        var generator = require("generate-password");
        return generator.generate({
            length: 10,
            numbers: true,
            symbols: true,
            excludeSimilarCharacters: true,
            exclude: '"+-%[[]]{{}}{J;<>*()^=,.:;`¬',
            strict: true,
        });
    }

    addApproverGroup = async (event) => {
        event.preventDefault();
        let updateableGuid =
            this.state.updateableGuid !== "" ? this.state.updateableGuid : null;
        let approverGroupName = this.state.approverGroupName;
        let AGuserName = this.state.AGuserName;
        let AGinitials = this.state.AGinitials;
        let AGdepartment = this.state.AGdepartment;
        let formErrors = Object.assign({}, this.state.formErrors);
        var thisobj = this;
        if (
            approverGroupName === false ||
            approverGroupName === null ||
            approverGroupName === ""
        ) {
            formErrors.approverGroupName = "This field is required";
            this.setState({
                formErrors: formErrors,
                approverGroupNameValid: false,
            });
            return false;
        }

        const approverGroupData = {
            approverGroupName: approverGroupName,
            updateableGuid: updateableGuid !== "" ? updateableGuid : null,
            AGuserName: AGuserName !== "" ? AGuserName : null,
            AGinitials: AGinitials !== "" ? AGinitials : null,
            AGdepartment: AGdepartment !== "" ? AGdepartment : null,
            CompanyGuid: this.state.companyList.find(
                (c) => c.CompanyId === this.state.companyId
            )?.Guid,
        };
        this.setState({ userCreating: true });
        try {
            var response = await this.props.postRequest(
                "/approval-setup/create-approver-group",
                approverGroupData
            );
            if (response.status) {
                thisobj.onModalClose();
                toast.success(response.message, { autoClose: 5000 });
                this.setState({ isLoading: true, userCreating: false });
                this.reloadUsersList();

                console.log("user_response=>", response);
            } else {
                toast.error(response.error, { autoClose: 5000 });
                this.setState({ userCreating: false });
            }
        } catch (err) {
            toast.error(err.message, { autoClose: 5000 });
            this.setState({ userCreating: false });
        }
    };

    addUser = async (event) => {
        event.preventDefault();

        var thisobj = this;
        let userType = this.state.userType;
        let userLogin = this.state.userLogin;
        let userName = this.state.userName;
        let emailAddress = this.state.emailAddress;
        let emailAddressValid = emailAddress.match(
            /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
        );
        let initials = this.state.initials;
        let mobileNumber = this.state.mobileNumber;
        let department = this.state.department;
        const companyId = this.state.companyId;

        let formErrors = Object.assign({}, this.state.formErrors);
        //let password = this.randomNumber();
        let myIp = this.state.ipv4;
        if (userType === false || userType === null || userType === "") {
            formErrors.userType = "This field is required";
            $("#myModal_user_edit .modal-body").animate(
                {
                    scrollTop: 0,
                },
                "slow"
            );
        }
        if (userLogin === false || userLogin === null || userLogin === "") {
            formErrors.userLogin = "This field is required";
            $("#myModal_user_edit .modal-body").animate(
                {
                    scrollTop: 0,
                },
                "slow"
            );
        }
        if (userName === false || userName === null || userName === "") {
            formErrors.userName = "This field is required";
            $("#myModal_user_edit .modal-body").animate(
                {
                    scrollTop: 0,
                },
                "slow"
            );
        }
        if (
            emailAddress === false ||
            emailAddress === null ||
            emailAddress === ""
        ) {
            formErrors.emailAddress = "This field is required";
            $("#myModal_user_edit .modal-body").animate(
                {
                    scrollTop: 0,
                },
                "slow"
            );
        }
        if (emailAddressValid === false) {
            formErrors.emailAddress = "Email should be username@domain.com";
            $("#myModal_user_edit .modal-body").animate(
                {
                    scrollTop: 0,
                },
                "slow"
            );
        }
        if (initials === false || initials === null || initials === "") {
            formErrors.initials = "This field is required";
            $("#myModal_user_edit .modal-body").animate(
                {
                    scrollTop: 0,
                },
                "slow"
            );
        }
        if (
            mobileNumber === false ||
            mobileNumber === null ||
            mobileNumber === ""
        ) {
            formErrors.mobileNumber = "This field is required";
            $("#myModal_user_edit .modal-body").animate(
                {
                    scrollTop: 0,
                },
                "slow"
            );
        }
        if (mobileNumber.length < 7) {
            formErrors.mobileNumber = "Please Write Proper number.";
            $("#myModal_user_edit .modal-body").animate(
                {
                    scrollTop: 0,
                },
                "slow"
            );
        }
        if (department === false || department === null || department === "") {
            formErrors.department = "This field is required";
            $("#myModal_user_edit .modal-body").animate(
                {
                    scrollTop: 0,
                },
                "slow"
            );
        }
        if (department === false || department === null || department === "") {
            formErrors.department = "This field is required";
            $("#myModal_user_edit .modal-body").animate(
                {
                    scrollTop: 0,
                },
                "slow"
            );
        }
        if (
            (companyId === false || companyId === null || companyId === "") &&
            this.state.userType !== "Admin" &&
            this.state.userType !== "SysAdmin"
        ) {
            formErrors.companyId = "This field is required";
            $("#myModal_user_edit .modal-body").animate(
                {
                    scrollTop: 0,
                },
                "slow"
            );
        }

        this.setState({
            formErrors: formErrors,
            formValid:
                this.state.userTypeValid &&
                this.state.userLoginValid &&
                this.state.userNameValid &&
                this.state.emailAddressValid &&
                this.state.mobileNumberValid &&
                this.state.departmentValid,
            isActive:
                this.state.userTypeValid &&
                this.state.userLoginValid &&
                this.state.userNameValid &&
                this.state.emailAddressValid &&
                this.state.mobileNumberValid &&
                this.state.departmentValid,
        });

        if (this.state.formValid) {
            const { checkedGroupsList } = this.state;
            let fullusername = this.state.userName;
            let LastName;
            let FirstName;
            let fullusername_array = fullusername.split(" ");
            if (fullusername_array.length > 1) {
                FirstName = fullusername_array[0];
                LastName = "";
                for (let index = 1; index < fullusername_array.length; index++) {
                    LastName += fullusername_array[index] + " ";
                }
            } else {
                FirstName = fullusername_array[0];
                LastName = null;
            }

            if (checkedGroupsList && checkedGroupsList.length > 0) {
                var filteredApproverGroups = checkedGroupsList.map(group => group.value);
            }

            let dynamoDbDFUsersData = {
                prevEmailAddress:this.state.prevEmailAddress,
                userSub: false,
                FirstName: FirstName,
                LastName: LastName,
                EmailAddress: this.state.emailAddress, //
                countryCode: this.state.countryCode,
                teamGroupToView: this.state.teamGroupToView
                    ? this.state.teamGroupToView
                    : null,
                FAuth: this.state.factorAuthentication === "checked" ? 1 : 0,
                AcctDisabled: this.state.accountDisabled === "checked" ? 1 : 0,
                AcctLockedout: this.state.lockOut === "checked" ? 1 : 0,
                BadPWCount: this.state.badPwCount > 0 ? this.state.badPwCount : 0,
                Department: this.state.department,
                EndAccess: this.state.lockoutDate ? this.state.lockoutDate : null,
                InactivityLockoutDays: this.state.inactivityLockoutDays
                    ? this.state.inactivityLockoutDays
                    : null,
                Initials: this.state.initials,
                LastLogin: this.state.lastLogin ? this.state.lastLogin : null,
                LoginCount: this.state.loginCount ? this.state.loginCount : 0,
                MobileNumber: this.state.mobileNumber, //
                PWCannotChange: this.state.cannotChange === "checked" ? 1 : 0,
                PWExpireDays: this.state.pwExpireDay,
                PWLastChanged: this.state.pwLastChange ? this.state.pwLastChange : null,
                PWMustChange: this.state.mustChange === "checked" ? 1 : 0,
                PWNeverExp: this.state.neverExpires === "checked" ? 1 : 0,
                PWStrength: this.state.passwordStrength
                    ? this.state.passwordStrength
                    : null,
                lastLogOff: this.state.lastLogOff ? this.state.lastLogOff : null,
                Reminder: "1",
                sendReminderEvery: this.state.sendReminderEvery
                    ? this.state.sendReminderEvery
                    : null,
                SignatureFile: null,
                StartAccess: this.state.accessDate ? this.state.accessDate : null,
                TeamGroup: this.state.teamGroup ? this.state.teamGroup : null, //
                UserLogin: this.state.userLogin, //
                UserType: this.state.userType, //
                Activity: [],
                ApproverGroup: filteredApproverGroups
                    ? filteredApproverGroups
                    : null,
                Avatar: null,
                EULA: 0,
                InviteDate: moment.tz(moment(), "Australia/Canberra"),
                InviteStatus: "Pending",
                IPList: [
                    {
                        myIp,
                    },
                ],
                MoileDeviceTokens: "NA",
                PWHistory: [],
                Salt: "JVUYVGUVUYVBUBV",
                SignatureType:
                    this.state.SignatureText !== "" ? this.state.SignatureText : "NA",
                CompanyID: this.state.companyId,
                TenantID: this.state.currentTenantId,
                TeamList: ["Production", "Accounts", "Costume"],
            };

            if (this.state.handlingEditUser === false) {
                this.setState({ userCreating: true });
                try {
                    var response = await this.props.postRequest(
                        "/users/email-already-exist",
                        { email: emailAddress, user_id: false }
                    );
                    if (response.status) {
                        var user_response = await this.props.postRequest(
                            "/users/create-user",
                            dynamoDbDFUsersData
                        );
                        if (user_response.status) {
                            thisobj.onModalClose();
                            toast.success(user_response.message, { autoClose: 5000 });
                            this.setState({ isLoading: true, userCreating: false });
                            this.reloadUsersList();
                            // this.reloadApprovalGroupList();
                        } else {
                            toast.error(user_response.error, { autoClose: 5000 });
                            this.setState({ userCreating: false });
                        }
                        console.log("user_response=>", user_response);
                    } else {
                        toast.error(response.error, { autoClose: 5000 });
                        this.setState({ userCreating: false });
                    }
                } catch (err) {
                    toast.error(err.message, { autoClose: 5000 });
                    this.setState({ userCreating: false });
                }
            } else if (this.state.handlingEditUser === true) {
                dynamoDbDFUsersData.handlingEditUser = this.state.handlingEditUser;
                dynamoDbDFUsersData.updateableGuid = this.state.updateableGuid;
                this.setState({ userCreating: true });
                try {
                    var exist_response = await this.props.postRequest(
                        "/users/email-already-exist",
                        { email: emailAddress, user_id: this.state.updateableGuid }
                    );
                    if (exist_response.status) {
                        var update_user_response = await this.props.postRequest(
                            "/users/update-user",
                            dynamoDbDFUsersData
                        );
                        if (update_user_response.status) {
                            thisobj.onModalClose();
                            toast.success(update_user_response.message, { autoClose: 5000 });
                            this.setState({ isLoading: true, userCreating: false });
                            this.reloadUsersList();
                        } else {
                            toast.error(update_user_response.error, { autoClose: 5000 });
                            this.setState({ userCreating: false });
                        }
                        console.log("update_user_response=>", update_user_response);
                    } else {
                        toast.error(exist_response.error, { autoClose: 5000 });
                        this.setState({ userCreating: false });
                    }
                } catch (err) {
                    toast.error(err.message, { autoClose: 5000 });
                    this.setState({ userCreating: false });
                }
            }
        }
    };

    reloadUsersList = async (event) => {
        try {
            var response = await this.props.postRequest(
                `/users/getallusers/${localStorage.getItem("currentTenantGuid")}`,
                {}
            );
            if (response.length > 0) {
                this.setState({ isLoading: false, usersList: response });
            } else {
                this.setState({ isLoading: false, wait: "No Record Found" });
            }
            console.log("/users/getallusers=>", response);
        } catch (err) {
            console.log("/users/getallusers error=>", err);
        }
    };

    reloadApprovalGroupList = async () => {
        try {
            var response = await this.props.postRequest(
                "/approval-setup/approver-group-list",
                { CompanyGuid: localStorage.getItem("companyGuid") }
            );
            if (response.status) {
                this.setState({ approvalGroupList: response.result, isLoading: false });
            } else {
                this.setState({ isLoading: false });
                toast.error(response.error, { autoClose: 5000 });
            }
            console.log("/approval-setup/approver-group-list=>", response);
        } catch (err) {
            console.log("/approval-setup/approver-group-list error=>", err);
            this.setState({ isLoading: false });
        }
    };

    handleEditUser = (Guid) => async (event) => {
        this.setState({ isLoading: true });
        try {
            var response = await this.props.postRequest("/users/getsingleuserguid", {
                Guid: Guid,
            });
            if (response.status) {
                this.setState(
                    { isLoading: false, updateableData: response.result },
                    async () => {
                        const companyGuid = this.state.updateableData.CompanyGuidList
                            ?.length
                            ? this.state.updateableData.CompanyGuidList[0]
                            : "";
                        const companyId = this.state.companyList.find(
                            (company) => company.Guid === companyGuid
                        )?.CompanyId;
                        if (this.state.updateableData.UserType === "Approver Group") {
                            this.setState({
                                handlingEditUser: true,
                                approverGroupName: this.state.updateableData.FirstName,
                                AGdepartment: this.state.updateableData.ApproverGroupDepartment,
                                AGinitials: this.state.updateableData.ApproverGroupinitials,
                                AGuserName: this.state.updateableData.FirstName,
                                updateableGuid: this.state.updateableData.Guid,
                                userType: this.state.updateableData.UserType,
                                companyId: companyId,
                            });
                            this.setState({ isLoading: false });
                            this.onModalOpen("updateUser");
                        } else {
                            //=================validations of update ===============================
                            await this.validateField(
                                "emailAddress",
                                this.state.updateableData.EmailAddress
                            );
                            await this.validateField(
                                "userType",
                                this.state.updateableData.UserType
                            );
                            await this.validateField(
                                "userLogin",
                                this.state.updateableData.UserLogin
                            );
                            await this.validateField(
                                "userName",
                                this.state.updateableData.FirstName +
                                (this.state.updateableData.LastName !== null
                                    ? " " + this.state.updateableData.LastName
                                    : "")
                            );
                            await this.validateField(
                                "teamGroupToView",
                                this.state.updateableData.teamGroupToView
                            );
                            await this.validateField(
                                "department",
                                this.state.updateableData.Department
                            );
                            await this.validateField(
                                "mobileNumber",
                                this.state.updateableData.MobileNumber
                            );
                            await this.validateField(
                                "teamGroup",
                                this.state.updateableData.TeamGroup
                            );
                            await this.validateField(
                                "initials",
                                this.state.updateableData.Initials
                            );


                            if (this.state.updateableData.ApproverGroup && this.state.updateableData.ApproverGroup.length > 0) {
                                var inputArray = this.state.updateableData.ApproverGroup; // ["test", "Automation Approver Group"]
                                var outputArray = [];
                                // format of the outputArray
                                /*  [
                                     {
                                         "value": "test",
                                         "key": 1
                                     },
                                     {
                                         "value": "Automation Approver Group",
                                         "key": 2
                                     }
                                 ] */

                                inputArray.forEach((value, index) => {
                                    outputArray.push({
                                        value: value,
                                        key: index + 1
                                    });
                                });

                                this.state.approvalGroupList.map((approvalGroup, index) => {
                                    outputArray.map((obj) => {
                                        if (approvalGroup.FirstName === obj.value) {
                                            obj.key = index;
                                        }
                                    })
                                })

                                var updatedUserAccess = inputArray.join(" ");
                            }
                            //============== Set Updateable Data in States =============================
                            this.setState({
                                handlingEditUser: true,
                                emailAddress: this.state.updateableData.EmailAddress,
                                prevEmailAddress:this.state.updateableData.EmailAddress,
                                updateableGuid: this.state.updateableData.Guid,
                                countryCode: this.state.updateableData.countryCode,
                                teamGroupToView: this.state.updateableData.teamGroupToView,
                                factorAuthentication:
                                    this.state.updateableData.twoFAuth === 0 ? "" : "checked",
                                userName:
                                    this.state.updateableData.FirstName +
                                    (this.state.updateableData.LastName !== null
                                        ? " " + this.state.updateableData.LastName
                                        : ""),
                                accountDisabled:
                                    this.state.updateableData.AcctDisabled === 0 ? "" : "checked",
                                lockOut:
                                    this.state.updateableData.AcctLockedout === 0
                                        ? ""
                                        : "checked",
                                department: this.state.updateableData.Department,
                                sendReminderEvery: this.state.updateableData.sendReminderEvery,
                                lastLogOff:
                                    this.state.updateableData.lastLogOff &&
                                        this.state.updateableData.lastLogOff !== "NA" &&
                                        this.state.updateableData.lastLogOff !== ""
                                        ? moment(this.state.updateableData.lastLogOff).format(
                                            "YYYY-MM-DD HH:mm:ss"
                                        )
                                        : "NA",
                                lockoutDate: moment(this.state.updateableData.EndAccess),
                                mobileNumber: this.state.updateableData.MobileNumber,
                                approverGroupOfUser: this.state.updateableData.ApproverGroup,
                                checkedGroupsList: outputArray ? outputArray : [],
                                checkboxArray: outputArray ? outputArray : [],
                                userAccess: updatedUserAccess ? updatedUserAccess : "",
                                cannotChange:
                                    this.state.updateableData.PWCannotChange === 0
                                        ? ""
                                        : "checked",
                                pwExpireDay: this.state.updateableData.PWExpireDays,
                                mustChange:
                                    this.state.updateableData.PWMustChange === 0 ? "" : "checked",
                                neverExpires:
                                    this.state.updateableData.PWNeverExp === 0 ? "" : "checked",
                                passwordStrength: this.state.updateableData.PWStrength,
                                accessDate: moment(this.state.updateableData.StartAccess),
                                teamGroup: this.state.updateableData.TeamGroup,
                                userLogin: this.state.updateableData.UserLogin,
                                userType: this.state.updateableData.UserType,
                                inactivityLockoutDays: this.state.updateableData
                                    .InactivityLockoutDays
                                    ? this.state.updateableData.InactivityLockoutDays
                                    : 0,
                                lastLogin:
                                    this.state.updateableData.LastLogin !== "NA" &&
                                        this.state.updateableData.LastLogin !== "" &&
                                        this.state.updateableData.LastLogin
                                        ? moment(this.state.updateableData.LastLogin).format(
                                            "YYYY-MM-DD HH:mm:ss"
                                        )
                                        : "NA",
                                pwLastChange:
                                    this.state.updateableData.PWLastChanged !== "NA" &&
                                        this.state.updateableData.PWLastChanged !== "" &&
                                        this.state.updateableData.PWLastChanged
                                        ? moment(this.state.updateableData.PWLastChanged).format(
                                            "YYYY-MM-DD HH:mm:ss"
                                        )
                                        : "NA",
                                badPwCount: this.state.updateableData.BadPWCount
                                    ? this.state.updateableData.BadPWCount
                                    : 0,
                                loginCount: this.state.updateableData.LoginCount,
                                initials: this.state.updateableData.Initials,
                                SignatureType: this.state.updateableData.SignatureType,
                                companyId: companyId,
                            });
                            //============== Set Updateable Data in States =============================
                            /*let SignatureTyped = (this.state.updateableData.SignatureType)?this.state.updateableData.SignatureType:"";
                                                    if(SignatureTyped){
                                                        if(SignatureTyped){
                                                            this.setState({ SignatureTyped: SignatureTyped, SignatureInput: "none" });
                                                        }else{
                                                            this.setState({ SignatureTyped: "", SignatureInput: "block" });
                                                        }
                                                    }*/
                            this.setState({ isLoading: false });
                            this.onModalOpen("updateUser");
                            //this.get_image();
                        }
                    }
                );
            } else {
                toast.error("User not found.", { autoClose: 5000 });
                this.setState({ isLoading: false });
            }
        } catch (err) {
            this.setState({ isLoading: false });
            toast.error("User not found.", { autoClose: 5000 });
        }
    };

    handleDeleteUser = (user_id) => async (event) => {
        var SessionGuid = localStorage.getItem("Guid");
        if (SessionGuid === user_id) {
            toast.error("You cannot delete your own account.", { autoClose: 5000 });
            this.setState({ isLoading: false });
            return false;
        }
        this.setState({ isLoading: true });
        try {
            var response = await this.props.postRequest("/users/delete-user", {
                user_id: user_id,
            });
            if (response.status) {
                toast.success(response.message, { autoClose: 5000 });
                this.reloadUsersList();
            } else {
                toast.error("Invalid User id.", { autoClose: 5000 });
                this.setState({ isLoading: false });
            }
        } catch (err) {
            this.setState({ isLoading: false });
            toast.error(err.message, { autoClose: 5000 });
        }
    };

    // - - - Sending Email - - -//
    selectUsertoEmail = async (e, useremail, userGuid) => {
        e.preventDefault();

        this.setState({ isLoading: true, selectUsertoEmail: false });
        try {
            var response = await this.props.postRequest("/users/getsingleuserguid", {
                Guid: userGuid,
            });
            if (response.status) {
                const userObj = {
                    ...response.result,
                    CompanyID: localStorage.getItem("companyID"),
                };
                this.setState({
                    isLoading: false,
                    userEmail: useremail,
                    selectUsertoEmail: userObj,
                    InvitSendModal: true,
                });
            } else {
                this.setState({ isLoading: false });
                toast.error("Selected user not found.", { autoClose: 5000 });
            }
        } catch (err) {
            this.setState({ isLoading: false });
            toast.error(err.message, { autoClose: 5000 });
        }
    };

    async getTemplates() {
        try {
            var response = await this.props.postRequest(
                "/email-templates/get-email-templates",
                {
                    Type: "User Invite",
                    CompanyGuid: localStorage.getItem("companyGuid"),
                }
            );
            if (response.status) {
                this.setState(
                    { isLoading: false, emailtemplates: response.result },
                    () => {
                        this.renderEmailTemplateDropdown();
                    }
                );
            }
        } catch (err) {
            console.log("/email-templates/get-email-templates error=>", err);
        }
    }

    emailchange(e) {
        let name = e.target.name;
        let value = e.target.value;
        this.setState({
            [name]: value,
        });
    }

    replacePlaceHodler = async (message) => {
        let word = /\{{(.*?)\}}/g;
        let tempArray = [];
        let responceplaceHolder = "";
        let userData = this.state.selectUsertoEmail;

        for (let m = word.exec(message); m; m = word.exec(message)) {
            this.state.placeHolderArray.push(m[1]);
        }
        //console.log("placeHolderArray=>", this.state.placeHolderArray); return false;
        try {
            var response = await this.props.postRequest(
                "/email-placeholders/get-email-placeholders",
                {
                    placeholder: this.state.placeHolderArray,
                    CompanyGuid: localStorage.getItem("companyGuid"),
                }
            );
            if (response.status) {
                responceplaceHolder = response.result;
                console.log("responceplaceHolder=>", responceplaceHolder);
                responceplaceHolder.map((items) => {
                    if (items.Text && items.Text !== "N?A" && items.Text !== "N/A") {
                        var replaceKey = "{{" + items.Name + "}}";
                        message = message.split(replaceKey).join(items.Text);
                    }
                    if (items.DBValue === "{System.TempPassword}") {
                        //tempArray.push({"dbname":"Password","placeholdername":items.Name});
                        var replaceKey = "{{" + items.Name + "}}";
                        message = message.split(replaceKey).join("{{Password}}");
                    }
                    if (
                        items.DBValue &&
                        items.DBValue !== "N?A" &&
                        items.DBValue !== "N/A"
                    ) {
                        var tmp = items.DBValue.split("_");
                        if (typeof tmp[1] !== "undefined") {
                            tempArray.push({ dbname: tmp[1], placeholdername: items.Name });
                        }
                    }
                });

                //console.log("tempArray=>", tempArray);
                tempArray.map((tempItem, index) => {
                    var replaceKey = "{{" + tempItem.placeholdername + "}}";
                    //message=message.replace(replaceKey,user[0][tempItem.dbname]);
                    message = message.split(replaceKey).join(userData[tempItem.dbname]);
                });
                return message;
            } else {
                return message;
            }
        } catch (err) {
            console.log("email-placeholders/get-email-placeholders Error=>", err);
            return message;
        }
    };

    UserInviteUserSendHandler = async () => {
        if (this.state.selectUsertoEmail) {
            //console.log("selectUsertoEmail=>", this.state.selectUsertoEmail); return false;
            if (this.state.emailMsg === "") {
                toast.error("Please Select Email Template", { autoClose: 5000 });
                return false;
            }
            //this.setState({isLoading:true});

            var to = this.state.selectUsertoEmail.EmailAddress;
            var message = this.state.emailMsg;

            if (this.state.emailCC !== "") {
                to = to + ";" + this.state.emailCC;
            }
            if (this.state.emailnotes !== "") {
                message = message + " Additional Notes: " + this.state.emailnotes;
            }

            this.setState({ userCreating: true });

            message = await this.replacePlaceHodler(message);
            //console.log("message=>", message); return false;
            if (message) {
                try {
                    var response = await this.props.postRequest(
                        "/users/send-invitation",
                        {
                            TO: to,
                            SUBJECT: "Invite from " + this.state.emailSubject,
                            message: message,
                            userData: this.state.selectUsertoEmail,
                        }
                    );
                    if (response.status) {
                        toast.success("Invitation sent successfully", { autoClose: 5000 });
                        this.InvitSendModalClose();
                        this.setState({ userCreating: false });
                    } else {
                        toast.error("Invitation sending failed", { autoClose: 5000 });
                        this.setState({ userCreating: false });
                    }
                    console.log("users/send-invitation=>", response);
                } catch (err) {
                    console.log("users/send-invitation Error=>", err);
                    toast.error(err.message, { autoClose: 5000 });
                    this.setState({ userCreating: false });
                }
            } else {
                toast.error("Unable to send invitation.");
                this.setState({ userCreating: false });
            }
        } else {
            toast.error("Unable to send invitation, selected user not found.", {
                autoClose: 1000,
            });
        }
    };

    sendEmailtoUser(e) {
        e.preventDefault();
        this.setState({ isLoading: true });
        if (this.state.emailnotes !== "") {
            console.log("Notes: ", this.state.emailnotes);
        }
        const emailsent = this.sendEmail(
            this.state.userEmail,
            this.state.emailMsg,
            this.state.emailSubject,
            this.state.emailCC
        );
        emailsent
            .then((data) => {
                if (data.status === true) {
                    this.setState({
                        isLoading: false,
                    });
                    toast.success("Email has sent successfully", {
                        autoClose: 1000,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    toast.error("Something went wrong, failed to send email", {
                        autoClose: 1000,
                    });
                }
            })
            .catch((err) => {
                console.error(err, err.stack);
                setTimeout(function () {
                    this.setState({
                        isLoading: false,
                    });
                    toast.error("Failed!", {
                        autoClose: 5000,
                    });
                }, 1000);
            });
    }

    selectTemplatetoEmail(e) {
        let index = e.target.selectedIndex;
        let element = e.target.childNodes[index];
        let subject = element.getAttribute("subject");
        let msg = element.getAttribute("msg");
        if (e.target.selectedIndex !== 0) {
            this.setState({ emailSubject: subject });
            this.setState({ emailMsg: msg });
        }
        //console.log(this.state.emailSubject);
        //console.log(this.state.emailMsg);
    }

    // - - - - - - - - - - - - - - //

    listApproverGroups() {
        return (
            <div className="col-sm-12 p0 mrg_bot_custom10">
                <div className="col-sm-3 col-md-4 label_csutom_pop">
                    Approver Group:
                </div>
                <div className="col-sm-9 col-md-8">
                    {/* <select
                        id="approverGroupOfUser"
                        name="approverGroupOfUser"
                        onChange={(event) =>
                            this.handleCustomFieldsModalInputsMultiSelect(event)
                        }
                        value={this.state.approverGroupOfUser}
                        multiple={true}
                    >
                        {this.state.usersList.map((field, key) => {
                            return (
                                <option value={field.FirstName}>{field.FirstName}</option>
                            );

                        })}
                    </select> */}
                    <input
                        name="approverGroupOfUser"
                        type="text"
                        value={this.state.userAccess}
                        readOnly
                    />
                    <div className="btn_addgroup_pop">
                        <a
                            href={null}
                            data-toggle="modal"
                            onClick={() => this.onAddApproverGroupModalOpen()}
                            className="btn_adduser_pck_pop CursorPointer"
                        >
                            Add Approver Groups
                        </a>
                    </div>
                </div>
            </div>
        );
    }

    // - - - - - - - - - - - - - - //
    renderEmailTemplateDropdown() {
        //console.log("emailSubject")
        var that = this;
        var emailtemplates = this.state.emailtemplates;
        var content = "";
        var emailSubject = "";
        var emailMsg = "";
        var TemplatetoEmailDefaultValue = "";

        content = emailtemplates.map(function (emailtemplate, index) {
            if (emailtemplate.Type === "User Invite") {
                if (emailtemplate.Default === 1) {
                    that.setState({
                        emailSubject: emailtemplate.Subject,
                        emailMsg: emailtemplate.BodyText,
                        TemplatetoEmailDefaultValue: emailtemplate.Guid,
                    });
                    return (
                        <option
                            value={emailtemplate.Guid}
                            id={emailtemplate.Guid}
                            key={index}
                            type={emailtemplate.Type}
                            subject={emailtemplate.Subject}
                            msg={emailtemplate.BodyText}
                        >
                            {emailtemplate.TName}
                        </option>
                    );
                } else {
                    return (
                        <option
                            value={emailtemplate.Guid}
                            id={emailtemplate.Guid}
                            key={index}
                            type={emailtemplate.Type}
                            subject={emailtemplate.Subject}
                            msg={emailtemplate.BodyText}
                        >
                            {emailtemplate.TName}
                        </option>
                    );
                }
            }
        });

        this.setState({ EmailTemplateList: content });
        //return content;
    }

    handleDropdownChange(e) {
        this.setState({ TemplatetoEmailDefaultValue: e.target.value });
    }

    approvalDetailsDOM() {
        return (
            <div>
                <h2>Approver Details </h2>
                <div className="clear10"></div>

                <div className="col-sm-12 p0 mrg_bot_custom10">
                    <div className="col-sm-3 col-md-4 label_csutom_pop">
                        Send Reminder every:
                    </div>
                    <div className="col-sm-5 col-md-5">
                        <select
                            name="sendReminderEvery"
                            onChange={(event) => this.handleSelectRminderEvery(event)}
                            value={this.state.sendReminderEvery}
                        >
                            <option value="0 Hr">0 Hr</option>
                            <option value="1 Hr">1 Hr</option>
                            <option value="4 Hr">4 Hr</option>
                            <option value="8 Hr">8 Hr</option>
                            <option value="24 Hr">24 Hr</option>
                        </select>
                    </div>
                </div>
                <div className="col-sm-12 p0 mrg_bot_custom10">
                    <div className="col-sm-3 col-md-4 label_csutom_pop">
                        Signature File:
                    </div>
                    <div className="col-sm-5 col-md-5">
                        <div className="col-xs-5 col-sm-7">
                            {this.state.Signature_url ? (
                                <img
                                    src={this.state.Signature_url}
                                    className="img-responsive user_sign_img"
                                    alt={this.state.SignatureText}
                                />
                            ) : (
                                <React.Fragment>
                                    {this.state.SignatureText ? this.state.SignatureText : ""}
                                </React.Fragment>
                            )}
                        </div>

                        <div className="col-xs-7 col-sm-5 mrg_top5">
                            <a
                                href={null}
                                className="btn_adduser_pck_pop cursorPointer"
                                onClick={(e) => this.signaturemodal_open(e)}
                            >
                                Edit
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    // Add approver group
    addGroup(event) {
        event.preventDefault();
        var array = this.state.checkboxArray;

        var string = "";
        array.map(function (value, key) {
            if (value.value !== null) {
                string += value.value + " ";
            } else {
                string = "";
            }
            return null;
        });
        this.setState({
            checkedGroupsList: array,
            checkboxArray: [],
            userAccess: string,
        });
        this.onAddApproverGroupModalClose();
    }

    // Handle checking approver groups
    handleApproverGroupInputCheckbox() {
        var checkedGroupArray = [];
        var i;
        var length = this.state.approvalGroupList.length;
        for (i = 0; i < length; i++) {
            var checked = $("input[name=select" + i + "]:checked").length;
            if (checked != 0) {
                checkedGroupArray.push({ value: $("input[name=select" + i + "]").data('userlogin'), key: i });
            }
        }
        this.setState({
            checkboxArray: checkedGroupArray,
        });
    }

    // Open add approver group modal
    onAddApproverGroupModalOpen() {
        var checkedGroups = this.state.checkedGroupsList.slice();
        this.setState({ addApproverGroupModalOpen: true, checkboxArray: checkedGroups });
        if (checkedGroups) {
            setTimeout(function () {
                console.log("checked Groups => ", checkedGroups);
                checkedGroups.map(function (user) {
                    if (user.value != null) {
                        var cls = "input.checkbox" + user.key;
                        $(cls).prop('checked', true);
                    }
                    return null;
                });
            }, 100);

        }
    }

    // Close add approver group modal
    onAddApproverGroupModalClose() {
        this.setState({ addApproverGroupModalOpen: false, checkboxArray: [] });
    }

    // - - - render component - - -//
    // - - - - - - - - - - - - - - //
    render() {

        if (typeof this.state.approvalGroupList !== 'undefined' && this.state.approvalGroupList.length > 0) {
            let currentApproverGroupList = this.state.approvalGroupList;
            var approvalGroupList = currentApproverGroupList.map((field, key) => {
                return (
                    <tr key={key}>
                        <td align="left" valign="middle">
                            <label className="check_box_pop">
                                <input className={"checkbox" + key} id={+key} type="checkbox" name={"select" + key} data-userlogin={field.FirstName} data-emailaddress={field.EmailAddress} onChange={(event) => this.handleApproverGroupInputCheckbox(event)} />
                                <span className="checkmark left0_check checkmark_form_pck_pop"></span>
                            </label>
                        </td>
                        {/* <td align="left" valign="middle">{field.ApproverGroupUserName ? field.ApproverGroupUserName : 'N/A'}</td> */}
                        <td align="left" valign="middle">{field.FirstName}</td>
                    </tr>
                )
            });
            if (approvalGroupList.length === 0) {

                approvalGroupList = <tr><td colSpan="2">Record not found</td><td></td></tr>;
            }
        }
        else {
            approvalGroupList = <tr><td colSpan="2">Record not found</td><td></td></tr>;
        }
        return (
            <div className="CustomFields">
                <Header
                    pageHeading="Users"
                    hideHomeButton="0"
                    props={this.props}
                    isLoading={this.state.isLoading}
                />
                <div className="clearfix"></div>
                <div className="col-xs-12 col-sm-9 col-md-10 pull-right p0 top_mrg_general top_mrg_general_custom">
                    <div className="">
                        <div className="col-sm-12 col-md-12 col-lg-10 col-lg-offset-1 general_setting_hd">
                            <div className="col-xs-12 col-sm-12 col-md-12">
                                <div className="clear10"></div>
                                <a href={null}>
                                    <button
                                        type="button"
                                        className="btn btn-primary pull-right plus_icon_table btn_plus_cusotm users_plus_icon_table"
                                        data-toggle="modal"
                                        onClick={(event) => this.onModalOpen("newUser")}
                                    >
                                        +
                                    </button>
                                </a>
                                <div className="clearfix"></div>
                                <div className="overflow_scroll_sm">
                                    <table
                                        width="100%"
                                        className="table table-bordered timecard_table custome_field_table"
                                    >
                                        <thead>
                                            <tr>
                                                <th width="15%" align="center">
                                                    User Name
                                                </th>
                                                <th width="12%" align="center">
                                                    Type
                                                </th>
                                                <th width="20%" align="center">
                                                    Department
                                                </th>
                                                <th width="25%" align="center">
                                                    Email Address
                                                </th>
                                                <th width="15%" align="center">
                                                    Status
                                                </th>
                                                <th width="5%" align="center">
                                                    &nbsp;
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.usersList.length > 0 ? (
                                                this.state.usersList.map((field, key) => {
                                                    if (field.UserType !== "Approver Group") {
                                                        return (
                                                            <tr key={key}>
                                                                <td width="11%" align="center">
                                                                    {field.FirstName +
                                                                        " " +
                                                                        (field.LastName !== null
                                                                            ? field.LastName
                                                                            : "")}
                                                                </td>
                                                                <td align="center">{field.UserType}</td>
                                                                <td width="20%" align="center">
                                                                    {field.Department}
                                                                </td>
                                                                <td align="center">{field.EmailAddress}</td>
                                                                <td align="center">
                                                                    {field.AcctLockedout > 0
                                                                        ? " Locked "
                                                                        : field.AcctDisabled > 0
                                                                            ? " Disabled "
                                                                            : field.LoginCount < 1
                                                                                ? "Pending"
                                                                                : field.LoginCount > 0
                                                                                    ? "Accepted "
                                                                                    : ""}
                                                                </td>
                                                                <td align="center">
                                                                    <div className="dropdown">
                                                                        <button
                                                                            className="dropdown-toggle btn_user_down_icon"
                                                                            type="button"
                                                                            data-toggle="dropdown"
                                                                        >
                                                                            <img
                                                                                src={user_down_icon}
                                                                                width="4"
                                                                                height="16"
                                                                                alt=""
                                                                            />
                                                                        </button>
                                                                        <ul className="dropdown-menu dropleft dropdown_menu_users_down dropdown_menu_user3">
                                                                            {this.state.userTypeForRoleManagement ===
                                                                                "SysAdmin" ? (
                                                                                <li>
                                                                                    <a
                                                                                        href={null}
                                                                                        onClick={this.handleEditUser(
                                                                                            field.Guid
                                                                                        )}
                                                                                        className="users_cursorpointer"
                                                                                        data-toggle="modal"
                                                                                        data-target="#myModal_user_edit"
                                                                                    >
                                                                                        <svg
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                            viewBox="0 0 20 20"
                                                                                        >
                                                                                            <path
                                                                                                id="ic_create_24px"
                                                                                                className="cls-1"
                                                                                                d="M3,18.831V23H7.166L19.453,10.71,15.287,6.544ZM22.675,7.489a1.106,1.106,0,0,0,0-1.566l-2.6-2.6a1.106,1.106,0,0,0-1.566,0L16.476,5.356l4.166,4.166,2.033-2.033Z"
                                                                                                transform="translate(-3 -2.998)"
                                                                                            />
                                                                                        </svg>
                                                                                        Edit
                                                                                    </a>
                                                                                </li>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                            {this.state.userTypeForRoleManagement ===
                                                                                "Admin" &&
                                                                                field.UserType !== "SysAdmin" ? (
                                                                                <li>
                                                                                    <a
                                                                                        href={null}
                                                                                        onClick={this.handleEditUser(
                                                                                            field.Guid
                                                                                        )}
                                                                                        className="users_cursorpointer"
                                                                                        data-toggle="modal"
                                                                                        data-target="#myModal_user_edit"
                                                                                    >
                                                                                        <svg
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                            viewBox="0 0 20 20"
                                                                                        >
                                                                                            <path
                                                                                                id="ic_create_24px"
                                                                                                className="cls-1"
                                                                                                d="M3,18.831V23H7.166L19.453,10.71,15.287,6.544ZM22.675,7.489a1.106,1.106,0,0,0,0-1.566l-2.6-2.6a1.106,1.106,0,0,0-1.566,0L16.476,5.356l4.166,4.166,2.033-2.033Z"
                                                                                                transform="translate(-3 -2.998)"
                                                                                            />
                                                                                        </svg>
                                                                                        Edit
                                                                                    </a>
                                                                                </li>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                            {field.LoginCount < 1 ? (
                                                                                <li>
                                                                                    <a
                                                                                        href={null}
                                                                                        className="users_cursorpointer"
                                                                                        onClick={(e) =>
                                                                                            this.selectUsertoEmail(
                                                                                                e,
                                                                                                field.EmailAddress,
                                                                                                field.Guid
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <svg
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                            viewBox="0 0 20 16"
                                                                                        >
                                                                                            <path
                                                                                                id="ic_mail_outline_24px"
                                                                                                className="cls-1"
                                                                                                d="M20,4H4A2,2,0,0,0,2.01,6L2,18a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V6A2.006,2.006,0,0,0,20,4Zm0,14H4V8l8,5,8-5Zm-8-7L4,6H20Z"
                                                                                                transform="translate(-2 -4)"
                                                                                            />
                                                                                        </svg>
                                                                                        Send
                                                                                    </a>
                                                                                </li>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                            {this.state.userTypeForRoleManagement ===
                                                                                "SysAdmin" ? (
                                                                                <li>
                                                                                    <a
                                                                                        href={null}
                                                                                        onClick={this.handleDeleteUser(
                                                                                            field.Guid
                                                                                        )}
                                                                                        className="users_cursorpointer"
                                                                                    >
                                                                                        <svg
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                            viewBox="1700 296 15 19.286"
                                                                                        >
                                                                                            <path
                                                                                                id="ic_delete_24px"
                                                                                                className="cls-1"
                                                                                                d="M6.071,20.143a2.149,2.149,0,0,0,2.143,2.143h8.571a2.149,2.149,0,0,0,2.143-2.143V7.286H6.071ZM20,4.071H16.25L15.179,3H9.821L8.75,4.071H5V6.214H20Z"
                                                                                                transform="translate(1695 293)"
                                                                                            ></path>
                                                                                        </svg>
                                                                                        Delete
                                                                                    </a>
                                                                                </li>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                            {this.state.userTypeForRoleManagement ===
                                                                                "Admin" &&
                                                                                field.UserType !== "SysAdmin" ? (
                                                                                <li>
                                                                                    <a
                                                                                        href={null}
                                                                                        onClick={this.handleDeleteUser(
                                                                                            field.Guid
                                                                                        )}
                                                                                        className="users_cursorpointer"
                                                                                    >
                                                                                        <svg
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                            viewBox="1700 296 15 19.286"
                                                                                        >
                                                                                            <path
                                                                                                id="ic_delete_24px"
                                                                                                className="cls-1"
                                                                                                d="M6.071,20.143a2.149,2.149,0,0,0,2.143,2.143h8.571a2.149,2.149,0,0,0,2.143-2.143V7.286H6.071ZM20,4.071H16.25L15.179,3H9.821L8.75,4.071H5V6.214H20Z"
                                                                                                transform="translate(1695 293)"
                                                                                            ></path>
                                                                                        </svg>
                                                                                        Delete
                                                                                    </a>
                                                                                </li>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                        </ul>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    } else {
                                                        return null;
                                                    }
                                                })
                                            ) : (
                                                <tr>
                                                    <td colSpan="5">{this.state.wait}</td>
                                                    <td></td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="clear10"></div>
                    </div>
                    {this.state.userTypeForRoleManagement === "Approver" ||
                        this.state.userTypeForRoleManagement === "Operator/Approver" ? (
                        <div className="">
                            <div className="col-sm-12 col-md-12 col-lg-10 col-lg-offset-1 general_setting_hd">
                                <div className="col-xs-12 col-sm-12 col-md-12">
                                    <div className="clear10"></div>
                                    <div className="col-xs-12 col-sm-12 col-md-12 text-center custome_header_label">
                                        Approver Group
                                    </div>
                                    <div className="clear10"></div>
                                    <div className="clearfix"></div>
                                    <div className="overflow_scroll_sm">
                                        <table
                                            width="100%"
                                            className="table table-bordered timecard_table custome_field_table"
                                        >
                                            <thead>
                                                <tr>
                                                    <th width="15%" align="center">
                                                        Approver Group Name
                                                    </th>
                                                    <th width="12%" align="center">
                                                        Type
                                                    </th>
                                                    <th width="20%" align="center">
                                                        Approver Group Department
                                                    </th>
                                                    <th width="25%" align="center">
                                                        Approver Group Initials
                                                    </th>
                                                    <th width="5%" align="center">
                                                        &nbsp;
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.usersList.length > 0 ? (
                                                    this.state.usersList.map((field, key) => {
                                                        if (field.UserType === "Approver Group") {
                                                            return (
                                                                <tr key={key}>
                                                                    <td width="11%" align="center">
                                                                        {field.FirstName}
                                                                    </td>
                                                                    <td align="center">{field.UserType}</td>
                                                                    <td width="20%" align="center">
                                                                        {field.ApproverGroupDepartment}
                                                                    </td>
                                                                    <td align="center">
                                                                        {field.ApproverGroupinitials}
                                                                    </td>
                                                                    <td align="center">
                                                                        <div className="dropdown">
                                                                            <button
                                                                                className="dropdown-toggle btn_user_down_icon"
                                                                                type="button"
                                                                                data-toggle="dropdown"
                                                                            >
                                                                                <img
                                                                                    src={user_down_icon}
                                                                                    width="4"
                                                                                    height="16"
                                                                                    alt=""
                                                                                />
                                                                            </button>
                                                                            <ul className="dropdown-menu dropleft dropdown_menu_users_down dropdown_menu_user3">
                                                                                <li>
                                                                                    <a
                                                                                        href={null}
                                                                                        onClick={this.handleDeleteUser(
                                                                                            field.Guid
                                                                                        )}
                                                                                        className="users_cursorpointer"
                                                                                    >
                                                                                        <svg
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                            viewBox="1700 296 15 19.286"
                                                                                        >
                                                                                            <path
                                                                                                id="ic_delete_24px"
                                                                                                className="cls-1"
                                                                                                d="M6.071,20.143a2.149,2.149,0,0,0,2.143,2.143h8.571a2.149,2.149,0,0,0,2.143-2.143V7.286H6.071ZM20,4.071H16.25L15.179,3H9.821L8.75,4.071H5V6.214H20Z"
                                                                                                transform="translate(1695 293)"
                                                                                            ></path>
                                                                                        </svg>
                                                                                        Delete
                                                                                    </a>
                                                                                </li>
                                                                                <li>
                                                                                    <a
                                                                                        href={null}
                                                                                        onClick={this.handleEditUser(
                                                                                            field.Guid
                                                                                        )}
                                                                                        className="users_cursorpointer"
                                                                                        data-toggle="modal"
                                                                                        data-target="#myModal_user_edit"
                                                                                    >
                                                                                        <svg
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                            viewBox="0 0 20 20"
                                                                                        >
                                                                                            <path
                                                                                                id="ic_create_24px"
                                                                                                className="cls-1"
                                                                                                d="M3,18.831V23H7.166L19.453,10.71,15.287,6.544ZM22.675,7.489a1.106,1.106,0,0,0,0-1.566l-2.6-2.6a1.106,1.106,0,0,0-1.566,0L16.476,5.356l4.166,4.166,2.033-2.033Z"
                                                                                                transform="translate(-3 -2.998)"
                                                                                            />
                                                                                        </svg>
                                                                                        Edit
                                                                                    </a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        } else {
                                                            return null;
                                                        }
                                                    })
                                                ) : (
                                                    <tr>
                                                        <td colSpan="5">{this.state.wait}</td>
                                                        <td></td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="clear10"></div>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
                <div className="clear10"></div>
                <Modal
                    id="myModal_user_edit"
                    show={this.state.modalOpen}
                    onHide={(event) => this.onModalClose(event)}
                    className="modal modal2 fade"
                    role="dialog"
                >
                    {this.state.userCreating ? <div className="se-pre-con"></div> : ""}
                    <div className="modal-header">
                        <button
                            type="button"
                            className="close users_OutlineNone"
                            onClick={(event) => this.onModalClose()}
                        >
                            <img
                                className="mt22"
                                src={ic_clear_24px}
                                width="18"
                                height="18"
                                alt=""
                            />
                        </button>
                        <h4 className="modal-title modal_customefield">
                            {this.state.userType !== "Approver Group"
                                ? "User"
                                : "Approver Group"}{" "}
                            Form
                        </h4>
                    </div>
                    <div className="modal-body label_modal_custom user_pop_1">
                        <div className="clear10"></div>
                        <h2>
                            {this.state.userType !== "Approver Group"
                                ? "User"
                                : "Approver Group"}{" "}
                            Details{" "}
                        </h2>
                        {this.state.userFormsWorking === false ? (
                            <form
                                onSubmit={
                                    this.state.userType !== "Approver Group"
                                        ? (event) => this.addUser(event)
                                        : (event) => this.addApproverGroup(event)
                                }
                            >
                                <div className="col-sm-12 p0 mrg_bot_custom10">
                                    <div className="col-sm-3 col-md-4 label_csutom_pop">
                                        User Type:
                                    </div>
                                    <div className="col-sm-5 col-md-5">
                                        <select
                                            name="userType"
                                            onChange={(event) =>
                                                this.handleCustomFieldsModalInputs(event)
                                            }
                                            value={this.state.userType}
                                        >
                                            <option value="">Select</option>
                                            <option value="Admin">Admin</option>
                                            {/* COMMENTED DUE TO TPHP-1699 (https://tphglobal.atlassian.net/browse/TPHP-1699) */}
                                            {/* {this.state.userTypeForRoleManagement === 'SysAdmin' ?
                                                <option value="SysAdmin">SysAdmin</option>
                                                : null} */}
                                            <option value="Operator">Operator</option>
                                            <option value="Approver">Approver</option>
                                            <option value="Approver Group">Approver Group</option>
                                            <option value="Operator/Approver">
                                                Operator/Approver
                                            </option>
                                        </select>
                                        <p
                                            className={
                                                "error user_validation " +
                                                (this.state.formErrors.userType ? "" : "displayNone")
                                            }
                                        >
                                            {this.state.formErrors.userType}
                                        </p>
                                    </div>
                                </div>

                                {this.state.userType !== "" &&
                                    this.state.userType !== "Admin" &&
                                    this.state.userType !== "SysAdmin" ? (
                                    <div className="col-sm-12 p0 mrg_bot_custom10">
                                        <div className="col-sm-3 col-md-4 label_csutom_pop">
                                            Company:
                                        </div>
                                        <div className="col-sm-5 col-md-5">
                                            <select
                                                name="companyId"
                                                onChange={(event) =>
                                                    this.handleCustomFieldsModalInputs(event)
                                                }
                                                value={this.state.companyId}
                                            >
                                                <option value="">Select</option>
                                                {this.state.companyList?.map((company) => (
                                                    <option
                                                        key={company.CompanyId}
                                                        value={company.CompanyId}
                                                    >
                                                        {company.CompanyName}
                                                    </option>
                                                ))}
                                            </select>
                                            <p
                                                className={
                                                    "error user_validation " +
                                                    (this.state.formErrors.companyId ? "" : "displayNone")
                                                }
                                            >
                                                {this.state.formErrors.companyId}
                                            </p>
                                        </div>
                                    </div>
                                ) : null}

                                <div
                                    className={
                                        this.state.userType !== "Approver Group"
                                            ? ""
                                            : "displayNone"
                                    }
                                >
                                    <div className="col-sm-12 p0 mrg_bot_custom10">
                                        <div className="col-sm-3 col-md-4 label_csutom_pop">
                                            User Login:
                                        </div>
                                        <div className="col-sm-9 col-md-8">
                                            <input
                                                name="userLogin"
                                                value={this.state.userLogin}
                                                onChange={(event) => this.handleInputs(event)}
                                                type="text"
                                            />
                                        </div>
                                        <p
                                            className={
                                                "error user_validation " +
                                                (this.state.formErrors.userLogin ? "" : "displayNone")
                                            }
                                        >
                                            {this.state.formErrors.userLogin}
                                        </p>
                                    </div>
                                    <div className="col-sm-12 p0 mrg_bot_custom10">
                                        <div className="col-sm-3 col-md-4 label_csutom_pop">
                                            User Name:
                                        </div>
                                        <div className="col-sm-9 col-md-8">
                                            <input
                                                name="userName"
                                                type="text"
                                                value={this.state.userName}
                                                onChange={(event) => this.handleInputs(event)}
                                            />
                                        </div>
                                        <p
                                            className={
                                                "error user_validation " +
                                                (this.state.formErrors.userName ? "" : "displayNone")
                                            }
                                        >
                                            {this.state.formErrors.userName}
                                        </p>
                                    </div>
                                    <div className="col-sm-12 p0 mrg_bot_custom10">
                                        <div className="col-sm-3 col-md-4 label_csutom_pop">
                                            Initials:
                                        </div>
                                        <div className="col-sm-9 col-md-8">
                                            <input
                                                name="initials"
                                                value={this.state.initials}
                                                onChange={(event) => this.handleInputs(event)}
                                                type="text"
                                            />
                                            <p
                                                className={
                                                    "error user_validation " +
                                                    (this.state.formErrors.initials ? "" : "displayNone")
                                                }
                                            >
                                                {this.state.formErrors.initials}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 p0 mrg_bot_custom10">
                                        <div className="col-sm-3 col-md-4 label_csutom_pop">
                                            Email Address:
                                        </div>
                                        <div className="col-sm-9 col-md-8">
                                            <input
                                                name="emailAddress"
                                                type="text"
                                                readOnly={
                                                    this.state.handlingEditUser === true ? "readOnly" : ""
                                                }
                                                value={this.state.emailAddress}
                                                onChange={(event) => this.handleInputs(event)}
                                            />
                                        </div>
                                        <p
                                            className={
                                                "error user_validation " +
                                                (this.state.formErrors.emailAddress
                                                    ? ""
                                                    : "displayNone")
                                            }
                                        >
                                            {this.state.formErrors.emailAddress}
                                        </p>
                                    </div>
                                    <div className="col-sm-12 p0 mrg_bot_custom10">
                                        <div className="col-sm-3 col-md-4 label_csutom_pop">
                                            Country Code:
                                        </div>
                                        <div className="col-sm-5 col-md-5">
                                            <select
                                                name="countryCode"
                                                onChange={(event) =>
                                                    this.handleCustomFieldsModalInputsCountry(event)
                                                }
                                                value={this.state.countryCode}
                                            >
                                                <option value="61">Australia (+61)</option>
                                                <option value="44">UK (+44)</option>
                                                <option value="1">USA (+1)</option>
                                                <option value="213">Algeria (+213)</option>
                                                <option value="376">Andorra (+376)</option>
                                                <option value="244">Angola (+244)</option>
                                                <option value="1264">Anguilla (+1264)</option>
                                                <option value="1268">
                                                    Antigua &amp; Barbuda (+1268)
                                                </option>
                                                <option value="54">Argentina (+54)</option>
                                                <option value="374">Armenia (+374)</option>
                                                <option value="297">Aruba (+297)</option>
                                                <option value="43">Austria (+43)</option>
                                                <option value="994">Azerbaijan (+994)</option>
                                                <option value="1242">Bahamas (+1242)</option>
                                                <option value="973">Bahrain (+973)</option>
                                                <option value="880">Bangladesh (+880)</option>
                                                <option value="1246">Barbados (+1246)</option>
                                                <option value="375">Belarus (+375)</option>
                                                <option value="32">Belgium (+32)</option>
                                                <option value="501">Belize (+501)</option>
                                                <option value="229">Benin (+229)</option>
                                                <option value="1441">Bermuda (+1441)</option>
                                                <option value="975">Bhutan (+975)</option>
                                                <option value="591">Bolivia (+591)</option>
                                                <option value="387">Bosnia Herzegovina (+387)</option>
                                                <option value="267">Botswana (+267)</option>
                                                <option value="55">Brazil (+55)</option>
                                                <option value="673">Brunei (+673)</option>
                                                <option value="359">Bulgaria (+359)</option>
                                                <option value="226">Burkina Faso (+226)</option>
                                                <option value="257">Burundi (+257)</option>
                                                <option value="855">Cambodia (+855)</option>
                                                <option value="237">Cameroon (+237)</option>
                                                <option value="1">Canada (+1)</option>
                                                <option value="238">Cape Verde Islands (+238)</option>
                                                <option value="1345">Cayman Islands (+1345)</option>
                                                <option value="236">
                                                    Central African Republic (+236)
                                                </option>
                                                <option value="56">Chile (+56)</option>
                                                <option value="86">China (+86)</option>
                                                <option value="57">Colombia (+57)</option>
                                                <option value="269">Comoros (+269)</option>
                                                <option value="242">Congo (+242)</option>
                                                <option value="682">Cook Islands (+682)</option>
                                                <option value="506">Costa Rica (+506)</option>
                                                <option value="385">Croatia (+385)</option>
                                                <option value="53">Cuba (+53)</option>
                                                <option value="90392">Cyprus North (+90392)</option>
                                                <option value="357">Cyprus South (+357)</option>
                                                <option value="42">Czech Republic (+42)</option>
                                                <option value="45">Denmark (+45)</option>
                                                <option value="253">Djibouti (+253)</option>
                                                <option value="1809">Dominica (+1809)</option>
                                                <option value="1809">Dominican Republic (+1809)</option>
                                                <option value="593">Ecuador (+593)</option>
                                                <option value="20">Egypt (+20)</option>
                                                <option value="503">El Salvador (+503)</option>
                                                <option value="240">Equatorial Guinea (+240)</option>
                                                <option value="291">Eritrea (+291)</option>
                                                <option value="372">Estonia (+372)</option>
                                                <option value="251">Ethiopia (+251)</option>
                                                <option value="500">Falkland Islands (+500)</option>
                                                <option value="298">Faroe Islands (+298)</option>
                                                <option value="679">Fiji (+679)</option>
                                                <option value="358">Finland (+358)</option>
                                                <option value="33">France (+33)</option>
                                                <option value="594">French Guiana (+594)</option>
                                                <option value="689">French Polynesia (+689)</option>
                                                <option value="241">Gabon (+241)</option>
                                                <option value="220">Gambia (+220)</option>
                                                <option value="7880">Georgia (+7880)</option>
                                                <option value="49">Germany (+49)</option>
                                                <option value="233">Ghana (+233)</option>
                                                <option value="350">Gibraltar (+350)</option>
                                                <option value="30">Greece (+30)</option>
                                                <option value="299">Greenland (+299)</option>
                                                <option value="1473">Grenada (+1473)</option>
                                                <option value="590">Guadeloupe (+590)</option>
                                                <option value="671">Guam (+671)</option>
                                                <option value="502">Guatemala (+502)</option>
                                                <option value="224">Guinea (+224)</option>
                                                <option value="245">Guinea - Bissau (+245)</option>
                                                <option value="592">Guyana (+592)</option>
                                                <option value="509">Haiti (+509)</option>
                                                <option value="504">Honduras (+504)</option>
                                                <option value="852">Hong Kong (+852)</option>
                                                <option value="36">Hungary (+36)</option>
                                                <option value="354">Iceland (+354)</option>
                                                <option value="91">India (+91)</option>
                                                <option value="62">Indonesia (+62)</option>
                                                <option value="98">Iran (+98)</option>
                                                <option value="964">Iraq (+964)</option>
                                                <option value="353">Ireland (+353)</option>
                                                <option value="972">Israel (+972)</option>
                                                <option value="39">Italy (+39)</option>
                                                <option value="1876">Jamaica (+1876)</option>
                                                <option value="81">Japan (+81)</option>
                                                <option value="962">Jordan (+962)</option>
                                                <option value="7">Kazakhstan (+7)</option>
                                                <option value="254">Kenya (+254)</option>
                                                <option value="686">Kiribati (+686)</option>
                                                <option value="850">Korea North (+850)</option>
                                                <option value="82">Korea South (+82)</option>
                                                <option value="965">Kuwait (+965)</option>
                                                <option value="996">Kyrgyzstan (+996)</option>
                                                <option value="856">Laos (+856)</option>
                                                <option value="371">Latvia (+371)</option>
                                                <option value="961">Lebanon (+961)</option>
                                                <option value="266">Lesotho (+266)</option>
                                                <option value="231">Liberia (+231)</option>
                                                <option value="218">Libya (+218)</option>
                                                <option value="417">Liechtenstein (+417)</option>
                                                <option value="370">Lithuania (+370)</option>
                                                <option value="352">Luxembourg (+352)</option>
                                                <option value="853">Macao (+853)</option>
                                                <option value="389">Macedonia (+389)</option>
                                                <option value="261">Madagascar (+261)</option>
                                                <option value="265">Malawi (+265)</option>
                                                <option value="60">Malaysia (+60)</option>
                                                <option value="960">Maldives (+960)</option>
                                                <option value="223">Mali (+223)</option>
                                                <option value="356">Malta (+356)</option>
                                                <option value="692">Marshall Islands (+692)</option>
                                                <option value="596">Martinique (+596)</option>
                                                <option value="222">Mauritania (+222)</option>
                                                <option value="269">Mayotte (+269)</option>
                                                <option value="52">Mexico (+52)</option>
                                                <option value="691">Micronesia (+691)</option>
                                                <option value="373">Moldova (+373)</option>
                                                <option value="377">Monaco (+377)</option>
                                                <option value="976">Mongolia (+976)</option>
                                                <option value="1664">Montserrat (+1664)</option>
                                                <option value="212">Morocco (+212)</option>
                                                <option value="258">Mozambique (+258)</option>
                                                <option value="95">Myanmar (+95)</option>
                                                <option value="264">Namibia (+264)</option>
                                                <option value="674">Nauru (+674)</option>
                                                <option value="977">Nepal (+977)</option>
                                                <option value="31">Netherlands (+31)</option>
                                                <option value="687">New Caledonia (+687)</option>
                                                <option value="64">New Zealand (+64)</option>
                                                <option value="505">Nicaragua (+505)</option>
                                                <option value="227">Niger (+227)</option>
                                                <option value="234">Nigeria (+234)</option>
                                                <option value="683">Niue (+683)</option>
                                                <option value="672">Norfolk Islands (+672)</option>
                                                <option value="670">Northern Marianas (+670)</option>
                                                <option value="47">Norway (+47)</option>
                                                <option value="968">Oman (+968)</option>
                                                <option value="92">Pakistan (+92)</option>
                                                <option value="680">Palau (+680)</option>
                                                <option value="507">Panama (+507)</option>
                                                <option value="675">Papua New Guinea (+675)</option>
                                                <option value="595">Paraguay (+595)</option>
                                                <option value="51">Peru (+51)</option>
                                                <option value="63">Philippines (+63)</option>
                                                <option value="48">Poland (+48)</option>
                                                <option value="351">Portugal (+351)</option>
                                                <option value="1787">Puerto Rico (+1787)</option>
                                                <option value="974">Qatar (+974)</option>
                                                <option value="262">Reunion (+262)</option>
                                                <option value="40">Romania (+40)</option>
                                                <option value="7">Russia (+7)</option>
                                                <option value="250">Rwanda (+250)</option>
                                                <option value="378">San Marino (+378)</option>
                                                <option value="239">
                                                    Sao Tome &amp; Principe (+239)
                                                </option>
                                                <option value="966">Saudi Arabia (+966)</option>
                                                <option value="221">Senegal (+221)</option>
                                                <option value="381">Serbia (+381)</option>
                                                <option value="248">Seychelles (+248)</option>
                                                <option value="232">Sierra Leone (+232)</option>
                                                <option value="65">Singapore (+65)</option>
                                                <option value="421">Slovak Republic (+421)</option>
                                                <option value="386">Slovenia (+386)</option>
                                                <option value="677">Solomon Islands (+677)</option>
                                                <option value="252">Somalia (+252)</option>
                                                <option value="27">South Africa (+27)</option>
                                                <option value="34">Spain (+34)</option>
                                                <option value="94">Sri Lanka (+94)</option>
                                                <option value="290">St. Helena (+290)</option>
                                                <option value="1869">St. Kitts (+1869)</option>
                                                <option value="1758">St. Lucia (+1758)</option>
                                                <option value="249">Sudan (+249)</option>
                                                <option value="597">Suriname (+597)</option>
                                                <option value="268">Swaziland (+268)</option>
                                                <option value="46">Sweden (+46)</option>
                                                <option value="41">Switzerland (+41)</option>
                                                <option value="963">Syria (+963)</option>
                                                <option value="886">Taiwan (+886)</option>
                                                <option value="7">Tajikstan (+7)</option>
                                                <option value="66">Thailand (+66)</option>
                                                <option value="228">Togo (+228)</option>
                                                <option value="676">Tonga (+676)</option>
                                                <option value="1868">
                                                    Trinidad &amp; Tobago (+1868)
                                                </option>
                                                <option value="216">Tunisia (+216)</option>
                                                <option value="90">Turkey (+90)</option>
                                                <option value="7">Turkmenistan (+7)</option>
                                                <option value="993">Turkmenistan (+993)</option>
                                                <option value="1649">
                                                    Turks & Caicos Islands (+1649)
                                                </option>
                                                <option value="688">Tuvalu (+688)</option>
                                                <option value="256">Uganda (+256)</option>
                                                <option value="44">UK (+44)</option>
                                                <option value="380">Ukraine (+380)</option>
                                                <option value="971">United Arab Emirates (+971)</option>
                                                <option value="598">Uruguay (+598)</option>
                                                <option value="1">USA (+1)</option>
                                                <option value="7">Uzbekistan (+7)</option>
                                                <option value="678">Vanuatu (+678)</option>
                                                <option value="379">Vatican City (+379)</option>
                                                <option value="58">Venezuela (+58)</option>
                                                <option value="84">Vietnam (+84)</option>
                                                <option value="84">
                                                    Virgin Islands - British (+1284)
                                                </option>
                                                <option value="84">Virgin Islands - US (+1340)</option>
                                                <option value="681">Wallis &amp; Futuna (+681)</option>
                                                <option value="969">Yemen (North)(+969)</option>
                                                <option value="967">Yemen (South)(+967)</option>
                                                <option value="260">Zmbaia (+260)</option>
                                                <option value="263">Zimbabwe (+263)</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 p0 mrg_bot_custom10">
                                        <div className="col-sm-3 col-md-4 label_csutom_pop">
                                            Mobile Number:
                                        </div>
                                        <div className="col-sm-9 col-md-8">
                                            <input
                                                name="mobileNumber"
                                                type="text"
                                                value={this.state.mobileNumber}
                                                onChange={(event) => this.handleInputs(event)}
                                            />
                                        </div>
                                        <p
                                            className={
                                                "error user_validation " +
                                                (this.state.formErrors.mobileNumber
                                                    ? ""
                                                    : "displayNone")
                                            }
                                        >
                                            {this.state.formErrors.mobileNumber}
                                        </p>
                                    </div>
                                    <div className="col-sm-12 p0 mrg_bot_custom10">
                                        <div className="col-sm-3 col-md-4 label_csutom_pop">
                                            Department:
                                        </div>
                                        <div className="col-sm-9 col-md-8">
                                            <input
                                                name="department"
                                                type="text"
                                                value={this.state.department}
                                                onChange={(event) => this.handleInputs(event)}
                                            />
                                            <p
                                                className={
                                                    "error user_validation " +
                                                    (this.state.formErrors.department
                                                        ? ""
                                                        : "displayNone")
                                                }
                                            >
                                                {this.state.formErrors.department}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 p0 mrg_bot_custom10">
                                        <div className="col-sm-3 col-md-4 label_csutom_pop">
                                            Team Group:
                                        </div>
                                        <div className="col-sm-9 col-md-8">
                                            <input
                                                name="teamGroup"
                                                type="text"
                                                value={this.state.teamGroup}
                                                onChange={(event) => this.handleInputs(event)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 p0 mrg_bot_custom10">
                                        <div className="col-sm-3 col-md-4 label_csutom_pop">
                                            Team Groups to View:
                                        </div>
                                        <div className="col-sm-9 col-md-8">
                                            <input
                                                name="teamGroupToView"
                                                type="text"
                                                value={this.state.teamGroupToView}
                                                onChange={(event) => this.handleInputs(event)}
                                            />
                                        </div>
                                    </div>
                                    {this.state.userType === "Operator" ||
                                        this.state.userType === "Operator/Approver"
                                        ? this.listApproverGroups()
                                        : ""}
                                    <div className="clear10"></div>
                                    {this.state.userType === "Approver" ||
                                        this.state.userType === "Operator/Approver"
                                        ? this.approvalDetailsDOM()
                                        : ""}

                                    <div className="clear10"></div>
                                    <h2>Security </h2>
                                    <div className="clear10"></div>
                                    <div className="col-sm-12 p0 mrg_bot_custom10">
                                        <div className="col-sm-3 col-md-4 label_csutom_pop">
                                            Password Strength:
                                        </div>
                                        <div className="col-sm-9 col-md-8">
                                            <select
                                                name="passwordStrength"
                                                onChange={(event) =>
                                                    this.handleCustomFieldsModalInputs(event)
                                                }
                                                value={this.state.passwordStrength}
                                            >
                                                <option value="Basic">Basic</option>
                                                <option value="Medium">Medium</option>
                                                <option value="Advanced">Advanced</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 p0 mrg_bot_custom10">
                                        <div className="col-sm-3 col-md-4 label_csutom_pop">
                                            PW Expire Day:
                                        </div>
                                        <div className="col-sm-9 col-md-8">
                                            <input
                                                name="pwExpireDay"
                                                type="text"
                                                value={this.state.pwExpireDay}
                                                onChange={(event) => this.handleNumberInput(event)}
                                            />
                                            <p
                                                className={
                                                    "error user_validation " +
                                                    (this.state.formErrors.pwExpireDay
                                                        ? ""
                                                        : "displayNone")
                                                }
                                            >
                                                {this.state.formErrors.pwExpireDay}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 p0 mrg_bot_custom10">
                                        <div className="col-xs-12 col-sm-3 col-md-4 label_csutom_pop">
                                            Password:
                                        </div>
                                        <div className="col-xs-12 col-sm-9 col-md-8 mrg_top5 p0">
                                            <div className="width_check_form_dtl_pop users_width_check_form_dtl_pop2">
                                                <label className="check_box_pop">
                                                    <input
                                                        name="mustChange"
                                                        type="checkbox"
                                                        value={this.state.mustChange}
                                                        checked={this.state.mustChange}
                                                        onChange={(event) =>
                                                            this.handleInputCheckbox(event)
                                                        }
                                                    />
                                                    <span className="checkmark users_checkmark"></span>
                                                    Must Change:
                                                </label>
                                            </div>
                                            <div className="width_check_form_dtl_pop2 users_width_check_form_dtl_pop2">
                                                <label className="check_box_pop">
                                                    <input
                                                        name="cannotChange"
                                                        type="checkbox"
                                                        value={this.state.cannotChange}
                                                        checked={this.state.cannotChange}
                                                        onChange={(event) =>
                                                            this.handleInputCheckbox(event)
                                                        }
                                                    />
                                                    <span className="checkmark users_checkmark"></span>
                                                    Cannot Change:
                                                </label>
                                            </div>
                                            <div className="width_check_form_dtl_pop3 users_width_check_form_dtl_pop2">
                                                <label className="check_box_pop">
                                                    <input
                                                        name="neverExpires"
                                                        type="checkbox"
                                                        value={this.state.neverExpires}
                                                        checked={this.state.neverExpires}
                                                        onChange={(event) =>
                                                            this.handleInputCheckbox(event)
                                                        }
                                                    />
                                                    <span className="checkmark users_checkmark"></span>
                                                    &nbsp; Never Expires:
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 p0 mrg_bot_custom10">
                                        <div className="col-xs-12 col-sm-3 col-md-4 label_csutom_pop">
                                            Account Disabled:
                                        </div>
                                        <div className="col-xs-12 col-sm-9 col-md-8">
                                            <label className="check_box_pop">
                                                <input
                                                    name="accountDisabled"
                                                    type="checkbox"
                                                    value={this.state.accountDisabled}
                                                    checked={this.state.accountDisabled}
                                                    onChange={(event) => this.handleInputCheckbox(event)}
                                                />
                                                <span className="checkmark checkmark_box left0_check"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 p0 mrg_bot_custom10">
                                        <div className="col-xs-12 col-sm-3 col-md-4 label_csutom_pop">
                                            Lock Out:
                                        </div>
                                        <div className="col-sm-9 col-md-8">
                                            <label className="check_box_pop">
                                                <input
                                                    name="lockOut"
                                                    type="checkbox"
                                                    value={this.state.lockOut}
                                                    checked={this.state.lockOut}
                                                    onChange={(event) => this.handleInputCheckbox(event)}
                                                />
                                                <span className="checkmark checkmark_box left0_check"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 p0 mrg_bot_custom10">
                                        <div className="col-xs-12 col-sm-3 col-md-4 label_csutom_pop">
                                            Factor Authentication:
                                        </div>
                                        <div className="col-sm-9 col-md-8">
                                            <label className="check_box_pop">
                                                <input
                                                    name="factorAuthentication"
                                                    type="checkbox"
                                                    value={this.state.factorAuthentication}
                                                    checked={this.state.factorAuthentication}
                                                    onChange={(event) => this.handleInputCheckbox(event)}
                                                />
                                                <span className="checkmark checkmark_box_auth left0_check"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 p0 mrg_bot_custom10">
                                        <div className="col-sm-3 col-md-4 label_csutom_pop">
                                            Inactivity Lockout Days:
                                        </div>
                                        <div className="col-sm-9 col-md-8">
                                            <input
                                                name="inactivityLockoutDays"
                                                type="text"
                                                value={this.state.inactivityLockoutDays}
                                                onChange={(event) => this.handleNumberInput(event)}
                                            />
                                            <p
                                                className={
                                                    "error user_validation " +
                                                    (this.state.formErrors.inactivityLockoutDays
                                                        ? ""
                                                        : "displayNone")
                                                }
                                            >
                                                {this.state.formErrors.inactivityLockoutDays}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 p0 mrg_bot_custom10">
                                        <div className="col-sm-3 col-md-4 label_csutom_pop">
                                            Access Date:
                                        </div>
                                        <div className="col-sm-9 col-md-8">
                                            <div className="col-xs-6 col-sm-6 p0">
                                                <DatePicker
                                                    id="showAccessDate"
                                                    dateFormat="DD/MM/YYYY"
                                                    selected={this.state.accessDate}
                                                    onChange={this.handleChange.bind(this)}
                                                />
                                            </div>
                                            <div className="col-xs-2 col-sm-2 calendar_time2">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    onClick={(e) => this.showAccessDate()}
                                                    viewBox="2936.352 349.176 18.501 23.145"
                                                >
                                                    <a href={null}>
                                                        <path
                                                            id="ic_date_range_24px"
                                                            className="cls-1"
                                                            d="M9.167,12.415H7.111V14.73H9.167Zm4.111,0H11.223V14.73h2.056Zm4.111,0H15.334V14.73H17.39Zm2.056-8.1H18.418V2H16.362V4.314H8.139V2H6.084V4.314H5.056A2.188,2.188,0,0,0,3.01,6.629L3,22.83a2.2,2.2,0,0,0,2.056,2.314h14.39A2.2,2.2,0,0,0,21.5,22.83V6.629A2.2,2.2,0,0,0,19.446,4.314Zm0,18.516H5.056V10.1h14.39Z"
                                                            transform="translate(2933.352 347.176)"
                                                        ></path>
                                                    </a>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 p0 mrg_bot_custom10">
                                        <div className="col-sm-3 col-md-4 label_csutom_pop">
                                            Lockout Date:
                                        </div>
                                        <div className="col-sm-9 col-md-8">
                                            <div className="col-xs-6 col-sm-6 p0">
                                                <DatePicker
                                                    id="showLockDate"
                                                    dateFormat="DD/MM/YYYY"
                                                    selected={this.state.lockoutDate}
                                                    onChange={this.lockoutDateHandle.bind(this)}
                                                />
                                            </div>
                                            <div className="col-xs-2 col-sm-2 calendar_time2">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    onClick={(e) => this.showLockDate()}
                                                    viewBox="2936.352 349.176 18.501 23.145"
                                                >
                                                    <a href={null}>
                                                        <path
                                                            id="ic_date_range_24px"
                                                            className="cls-1"
                                                            d="M9.167,12.415H7.111V14.73H9.167Zm4.111,0H11.223V14.73h2.056Zm4.111,0H15.334V14.73H17.39Zm2.056-8.1H18.418V2H16.362V4.314H8.139V2H6.084V4.314H5.056A2.188,2.188,0,0,0,3.01,6.629L3,22.83a2.2,2.2,0,0,0,2.056,2.314h14.39A2.2,2.2,0,0,0,21.5,22.83V6.629A2.2,2.2,0,0,0,19.446,4.314Zm0,18.516H5.056V10.1h14.39Z"
                                                            transform="translate(2933.352 347.176)"
                                                        ></path>
                                                    </a>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 p0 mrg_bot_custom10">
                                        <div className="col-sm-3 col-md-4 label_csutom_pop">
                                            Last Login:
                                        </div>
                                        <div className="col-sm-9 col-md-8">
                                            <input
                                                name="lastLogin"
                                                type="text"
                                                readOnly="readOnly"
                                                value={this.state.lastLogin}
                                                onChange={(event) => this.handleInputs(event)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 p0 mrg_bot_custom10">
                                        <div className="col-sm-3 col-md-4 label_csutom_pop">
                                            Last Log Off:
                                        </div>
                                        <div className="col-sm-9 col-md-8">
                                            <input
                                                name="lastLogOff"
                                                readOnly="readOnly"
                                                type="text"
                                                value={this.state.lastLogOff}
                                                onChange={(event) => this.handleInputs(event)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 p0 mrg_bot_custom10">
                                        <div className="col-sm-3 col-md-4 label_csutom_pop">
                                            PW Last Changed:
                                        </div>
                                        <div className="col-sm-9 col-md-8">
                                            <input
                                                name="pwLastChange"
                                                readOnly="readOnly"
                                                type="text"
                                                value={this.state.pwLastChange}
                                                onChange={(event) => this.handleInputs(event)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 p0 mrg_bot_custom10">
                                        <div className="col-sm-3 col-md-4 label_csutom_pop">
                                            Bad PW Count:
                                        </div>
                                        <div className="col-sm-9 col-md-8">
                                            <input
                                                name="badPwCount"
                                                readOnly="readOnly"
                                                type="text"
                                                value={this.state.badPwCount}
                                                onChange={(event) => this.handleInputs(event)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 p0 mrg_bot_custom10">
                                        <div className="col-sm-3 col-md-4 label_csutom_pop">
                                            Login Count:
                                        </div>
                                        <div className="col-sm-9 col-md-8">
                                            <input
                                                name="loginCount"
                                                type="text"
                                                value={this.state.loginCount}
                                                onChange={(event) => this.handleInputs(event)}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={
                                        this.state.userType === "Approver Group"
                                            ? ""
                                            : "displayNone"
                                    }
                                >
                                    <div className="col-sm-12 p0 mrg_bot_custom10">
                                        <div className="col-sm-3 col-md-4 label_csutom_pop">
                                            Group Name:
                                        </div>
                                        <div className="col-sm-9 col-md-8">
                                            <input
                                                name="approverGroupName"
                                                value={this.state.approverGroupName}
                                                onChange={(event) =>
                                                    this.handleInputsApproverGroup(event)
                                                }
                                                type="text"
                                            />
                                        </div>
                                        <p
                                            className={
                                                "error user_validation " +
                                                (this.state.formErrors.approverGroupName
                                                    ? ""
                                                    : "displayNone")
                                            }
                                        >
                                            {this.state.formErrors.approverGroupName}
                                        </p>
                                    </div>
                                    <div className="col-sm-12 p0 mrg_bot_custom10">
                                        <div className="col-sm-3 col-md-4 label_csutom_pop">
                                            User Name:
                                        </div>
                                        <div className="col-sm-9 col-md-8">
                                            <input
                                                name="AGuserName"
                                                type="text"
                                                value={this.state.AGuserName}
                                                onChange={(event) => this.handleInputs(event)}
                                            />
                                        </div>
                                        <p
                                            className={
                                                "error user_validation " +
                                                (this.state.formErrors.AGuserName ? "" : "displayNone")
                                            }
                                        >
                                            {this.state.formErrors.AGuserName}
                                        </p>
                                    </div>
                                    <div className="col-sm-12 p0 mrg_bot_custom10">
                                        <div className="col-sm-3 col-md-4 label_csutom_pop">
                                            Initials:
                                        </div>
                                        <div className="col-sm-9 col-md-8">
                                            <input
                                                name="AGinitials"
                                                value={this.state.AGinitials}
                                                onChange={(event) => this.handleInputs(event)}
                                                type="text"
                                            />
                                            <p
                                                className={
                                                    "error user_validation " +
                                                    (this.state.formErrors.AGinitials
                                                        ? ""
                                                        : "displayNone")
                                                }
                                            >
                                                {this.state.formErrors.AGinitials}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 p0 mrg_bot_custom10">
                                        <div className="col-sm-3 col-md-4 label_csutom_pop">
                                            Department:
                                        </div>
                                        <div className="col-sm-9 col-md-8">
                                            <input
                                                name="AGdepartment"
                                                type="text"
                                                value={this.state.AGdepartment}
                                                onChange={(event) => this.handleInputs(event)}
                                            />
                                            <p
                                                className={
                                                    "error user_validation " +
                                                    (this.state.formErrors.AGdepartment
                                                        ? ""
                                                        : "displayNone")
                                                }
                                            >
                                                {this.state.formErrors.AGdepartment}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="btn_cance_save">
                                        <button
                                            type="submit"
                                            className="btn_save_pro"
                                            data-dismiss="modal"
                                        >
                                            Save
                                        </button>
                                        <button
                                            type="button"
                                            className="btn_cancel_pro"
                                            data-dismiss="modal"
                                            onClick={(event) => this.onModalClose()}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </form>
                        ) : (
                            <img
                                className="img-responsive users_sign_Loading"
                                src={Preloader}
                                alt=""
                                onError={this.handleImageError}
                            />
                        )}
                        <div className="clear20"></div>
                    </div>
                </Modal>
                <Modal
                    id="myModal_user_edit"
                    show={this.state.signaturemodal}
                    onHide={(event) => this.onModalClose(event)}
                    className="modal modal2 fade"
                    role="dialog"
                >
                    <div className="modal-header">
                        <button
                            type="button"
                            className="close users_OutlineNone"
                            onClick={(event) => this.signaturemodal_close()}
                        >
                            <img
                                className="mt22"
                                src={ic_clear_24px}
                                width="18"
                                height="18"
                                alt=""
                            />
                        </button>
                        <h4 className="modal-title modal_customefield">User Signature</h4>
                    </div>
                    <div className="modal-body label_modal_custom user_pop_1">
                        <div className="col-xs-12 col-sm-12">
                            <div className="tabbable-panel sign-tabbable-panel">
                                <div className="tabbable-line_ss">
                                    <ul className="nav nav-tabs ss_nav_tabs">
                                        <li className="">
                                            <a
                                                href="#tab_default_1"
                                                data-toggle="tab"
                                                className="text_right_tab1"
                                                onClick={this.handleTab("Typed")}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="-2112 7612 20 14"
                                                >
                                                    <path
                                                        id="ic_keyboard_24px"
                                                        className="cls-1"
                                                        d="M20,5H4A2,2,0,0,0,2.01,7L2,17a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V7A2.006,2.006,0,0,0,20,5ZM11,8h2v2H11Zm0,3h2v2H11ZM8,8h2v2H8Zm0,3h2v2H8ZM7,13H5V11H7Zm0-3H5V8H7Zm9,7H8V15h8Zm0-4H14V11h2Zm0-3H14V8h2Zm3,3H17V11h2Zm0-3H17V8h2Z"
                                                        transform="translate(-2114 7607)"
                                                    />
                                                </svg>
                                                &nbsp;&nbsp; Type
                                            </a>
                                        </li>
                                        <li className="svg_down_pencil active">
                                            <a
                                                href="#tab_default_21"
                                                data-toggle="tab"
                                                className="text_left_tab2"
                                                onClick={this.handleTab("Draw")}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="-3800 7611 15 15"
                                                >
                                                    <path
                                                        id="ic_mode_edit_24px"
                                                        className="cls-1"
                                                        d="M3,14.873V18H6.125L15.34,8.782,12.215,5.658ZM17.756,6.366a.83.83,0,0,0,0-1.175l-1.95-1.95a.83.83,0,0,0-1.175,0L13.107,4.766l3.125,3.125Z"
                                                        transform="translate(-3803 7608.002)"
                                                    />
                                                </svg>
                                                &nbsp;&nbsp; Draw
                                            </a>
                                        </li>
                                    </ul>
                                    <div className="tab-content tab-content-signsetting">
                                        <div className="tab-pane ss_tab_pane" id="tab_default_1">
                                            {this.state.SignatureTyped}
                                            <p></p>
                                            <p> </p>
                                            <p style={{ display: this.state.SignatureInput }}>
                                                <input
                                                    name="SignatureText"
                                                    id="SignatureText"
                                                    className=""
                                                    value={this.state.SignatureText}
                                                    onChange={this.signatureTextHandle}
                                                    type="text"
                                                    placeholder="Type Here"
                                                    defaultValue=""
                                                />
                                            </p>
                                            <p></p>
                                        </div>
                                        <div
                                            className="tab-pane ss_tab_pane active"
                                            id="tab_default_21"
                                        >
                                            <React.Fragment>
                                                {this.state.isSaveSignature ? (
                                                    <React.Fragment>
                                                        <img
                                                            className="img-responsive users_sign_Loading"
                                                            src={Preloader}
                                                            alt=""
                                                            onError={this.handleImageError}
                                                        />
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                        {this.state.SignatureImage ? (
                                                            <React.Fragment>
                                                                <img
                                                                    className="img-responsive"
                                                                    src={this.state.SignatureImage}
                                                                    alt=""
                                                                    onError={this.handleImageError}
                                                                />
                                                                <SignaturePad
                                                                    SignaturePad={this.state}
                                                                    ref="Signature"
                                                                />
                                                            </React.Fragment>
                                                        ) : (
                                                            <SignaturePad
                                                                SignaturePad={this.state}
                                                                ref="Signature"
                                                            />
                                                        )}
                                                    </React.Fragment>
                                                )}
                                            </React.Fragment>
                                        </div>
                                        <div className="clearfix"></div>
                                        <div className="btn_cance_save mL50px">
                                            <button
                                                name=""
                                                className="btn_save_pro"
                                                value="Save"
                                                type="button"
                                                onClick={this.saveSignature}
                                            >
                                                Save
                                            </button>
                                            <button
                                                name=""
                                                className="btn_cancel_pro"
                                                value="Clear"
                                                type="button"
                                                onClick={this.clearSignature}
                                            >
                                                Clear
                                            </button>
                                        </div>
                                        <div className="clear20"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="clear20"></div>
                    </div>
                </Modal>

                <Modal
                    id="myModal_user_send"
                    show={this.state.InvitSendModal}
                    className="modal fade modal2"
                    role="dialog"
                >
                    {this.state.userCreating ? <div className="se-pre-con"></div> : ""}
                    <div className="modal-header">
                        <button
                            type="button"
                            className="close users_OutlineNone"
                            onClick={(event) => this.InvitSendModalClose()}
                        >
                            <img
                                className="mt22"
                                src={ic_clear_24px}
                                width="18"
                                height="18"
                                alt=""
                            />
                        </button>
                        <h4 className="modal-title modal_customefield">
                            Invite User Email
                        </h4>
                    </div>
                    <div className="modal-body label_modal_custom">
                        <div className="clear10"></div>
                        <div className="col-sm-12 p0 mrg_bot_custom10">
                            <div className="col-sm-3 col-md-2 label_csutom_pop">
                                Templates:
                            </div>
                            <div className="col-sm-9 col-md-6">
                                <select
                                    name=""
                                    onClick={(e) => this.selectTemplatetoEmail(e)}
                                    onChange={this.handleDropdownChange}
                                    value={this.state.TemplatetoEmailDefaultValue}
                                >
                                    <option value="">Select Template</option>
                                    {this.state.EmailTemplateList}
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-12 p0 mrg_bot_custom10 user_label_email">
                            Please enter any CC Emails and Notes to the user below. When you
                            press send, an email will be sent to the user containing their
                            login details, instructions on how to login, and help
                            documentation.
                        </div>
                        <div className="col-sm-12 p0 mrg_bot_custom10">
                            <div className="col-sm-3 col-md-2 label_csutom_pop">CC:</div>
                            <div className="col-sm-9 col-md-10">
                                <input
                                    name="emailCC"
                                    type="text"
                                    value={this.state.emailCC}
                                    onChange={(e) => this.emailchange(e)}
                                />
                            </div>
                        </div>
                        <div className="col-sm-12 p0 mrg_bot_custom10">
                            <div className="col-sm-3 col-md-2 label_csutom_pop">Notes:</div>
                            <div className="col-sm-9 col-md-10">
                                <textarea
                                    name="emailnotes"
                                    className="text_area_user"
                                    cols=""
                                    rows=""
                                    value={this.state.emailnotes}
                                    onChange={(e) => this.emailchange(e)}
                                ></textarea>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="btn_cance_save">
                                <button
                                    type="button"
                                    className="btn_save_pro"
                                    data-dismiss="modal"
                                    onClick={(e) => this.UserInviteUserSendHandler(e)}
                                >
                                    Save
                                </button>
                                <button
                                    type="button"
                                    className="btn_cancel_pro"
                                    onClick={(event) => this.InvitSendModalClose()}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                        <div className="clear20"></div>
                    </div>
                </Modal>

                <Modal
                    id="addApproverGroupModal"
                    show={this.state.addApproverGroupModalOpen}
                    onHide={(event) => this.onAddApproverGroupModalClose(event)}
                    className="modal modal2 fade"
                    role="dialog"
                >
                    <div className="modal-header">
                        <button
                            type="button"
                            className="close OutlineNone"
                            data-dismiss="modal"
                            onClick={(event) => this.onAddApproverGroupModalClose(event)}
                        >
                            <img
                                src={ic_clear_24px}
                                className="mt22"
                                width="18"
                                height="18"
                                alt=""
                            />
                        </button>
                        <h4 className="modal-title modal_customefield">Approver Group</h4>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={(event) => this.addGroup(event)}>
                            <div className="col-sm-12 p0 mrg_bot_custom10">
                                <div className="table_formpck_pop_1">
                                    <table
                                        width="100%"
                                        border="0"
                                        cellSpacing="2"
                                        cellPadding="2"
                                    >
                                        <thead>
                                            <tr>
                                                <th width="20%" align="left" valign="middle">
                                                    Select
                                                </th>
                                                <th width="40%" align="left" valign="middle">
                                                    User Group
                                                </th>
                                                {/* <th width="40%" align="left" valign="middle">
                                                    User Name
                                                </th> */}
                                            </tr>
                                        </thead>

                                        <tbody>{approvalGroupList}</tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="clear10"></div>
                            <div className="col-sm-6 p0">
                                <div className="btn_cance_save">
                                    <button
                                        type="submit"
                                        className="btn_save_pro"
                                        data-dismiss="modal"
                                    >
                                        Save
                                    </button>
                                    <button
                                        type="button"
                                        className="btn_cancel_pro"
                                        onClick={(event) => this.onAddApproverGroupModalClose()}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                            <div className="clear20"></div>
                        </form>
                    </div>
                </Modal>
            </div>
        );
    }
}
